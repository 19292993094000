import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import URLMapper from '../../../functionality/URLMapper';
import SessionDataContext from '../../../contexts/SessionDataContext';
import UIManagementContext from '../../../contexts/UIManagementContext';
import WallsideError from '../../../functionality/WallsideError';
import ActionBtn from '../../ActionBtn/ActionBtn';
import SubMenu from '../../SubMenu/SubMenu';
import Btn from '../../Btn/Btn';
import html2canvas from 'html2canvas';
import './QuoteDetailActionBar.css';

const QuoteDetailActionBar = (props) => {
	// setting up the history object
	const history = useHistory();

	// we'll setup the contexts we use
	const { toggleOverlay, toggleSystemNotification } = useContext(UIManagementContext);
	const { activeQuote, activeAddressQuotes, createQuote, duplicateQuote } =
		useContext(SessionDataContext);

	const handleDelete = () => {
		// lets first figure out where we go on delete
		// if we have other quotes for this address we'll go to the next one
		// if there is no next one, go to first
		// if there are non left, then we go to dashboard
		let nextQuoteId = false;
		for (let i = 0; i < activeAddressQuotes.length; i++) {
			if (activeAddressQuotes[i].id === activeQuote.id) {
				if (activeAddressQuotes.length > 1) {
					nextQuoteId =
						i + 1 < activeAddressQuotes.length
							? activeAddressQuotes[i + 1].id
							: activeAddressQuotes[0].id;
				}
				break;
			}
		}
		// delete a quote
		toggleOverlay({
			active: true,
			type: 'confirm_delete_quote',
			data: {
				goToQuoteId: nextQuoteId,
			},
		});
	};

	const handleDuplicate = () => {
		duplicateQuote(activeQuote).then(
			(newQuote) => {
				// show the notification
				toggleSystemNotification({
					active: true,
					messageKey: 'quote_duplicated',
				});
				// we created the quote, now we go to this quotes page
				history.push(
					URLMapper('quote', {
						quoteId: newQuote.id,
					})
				);
			},
			(e) => {
				const error = new WallsideError(e);
				alert(error.globalErrors[0].message);
			}
		);
	};

	/* Rename the quote overly */
	const handleRename = () => {
		toggleOverlay({
			active: true,
			type: 'rename_quote',
			data: {},
		});
	};

	const handleQuoteTitleButtonClick = (quote) => {
		history.push(
			URLMapper('quote', {
				quoteId: quote.id,
			})
		);
	};

	const handleCreateQuoteButtonClick = async () => {
		try {
			const quote = await createQuote(
				'Quote ' + (activeAddressQuotes.length + 1),
				activeQuote.get('address')
			);

			// show the notification
			toggleSystemNotification({
				active: true,
				messageKey: 'create_quote',
			});
			// go to this quote
			history.push(URLMapper('quote', { quoteId: quote.id }));
		} catch (e) {
			const error = new WallsideError(e);
			alert(error.globalErrors[0].message);
		}
	};

	/* 
			We'll execute this via generating an image, and then letting the user download that image
			they could then print this image file.
		*/
	const handleActionButtonClick = async () => {
		try {
			const canvas = await html2canvas(document.querySelector('.layout2'));
			const fakeA = document.createElement('a');
			fakeA.href = canvas.toDataURL();
			fakeA.download = activeQuote.get('title') + '_screenshot.png';
			fakeA.click();
		} catch (e) {
			// Throw some error
		}
	};

	const handleActionButtonEmailClick = () => {
		// open the global settings overlay
		toggleOverlay({
			active: true,
			type: 'email_quote',
			data: {},
		});
	};

	// return the component
	return activeQuote !== false ? (
		<div
			className={`quotedetailactionbar ${
				props.presentation ? 'quotedetailactionbar--presentation' : ''
			}`}
		>
			<div className="quotedetailactionbar__quotes">
				{activeAddressQuotes !== false
					? activeAddressQuotes.map((quote) => {
							return (
								<React.Fragment key={quote.id}>
									{quote.id === activeQuote.id ? (
										<div className="quotedetailactionbar__quote quotedetailactionbar__quote--active">
											<h2 className="heading heading--5">
												{activeQuote.get('title')}
											</h2>
											<SubMenu
												onDuplicate={handleDuplicate}
												onDelete={handleDelete}
												onRename={handleRename}
											/>
										</div>
									) : (
										<button
											type="button"
											className="quotedetailactionbar__quote"
											onClick={() =>
												handleQuoteTitleButtonClick(quote)
											}
										>
											<h2 className="heading heading--5">
												{quote.get('title')}
											</h2>
										</button>
									)}
								</React.Fragment>
							);
					  })
					: ''}
				<button
					type="button"
					className="quotedetailactionbar__create"
					onClick={handleCreateQuoteButtonClick}
				>
					<span className="icon-far icon--plus"></span>
				</button>
			</div>
			<div className="quotedetailactionbar__actions">
				<div className="quotedetailactionbar__info">
					<div className="heading heading--3">
						{activeQuote.get('address').get('title')}
					</div>
				</div>
				<div
					className={`quotedetailactionbar__btns ${
						props.presentation
							? 'quotedetailactionbar__btns--presentation'
							: ''
					}`}
				>
					{props.presentation ? (
						<>
							<ActionBtn
								actionBtnClass="actionbtn--primary-outline"
								actionBtnType="icon--print"
								onClick={handleActionButtonClick}
							/>
							<ActionBtn
								actionBtnClass="actionbtn--primary-outline"
								actionBtnType="icon--email"
								onClick={handleActionButtonEmailClick}
							/>
						</>
					) : (
						<>
							<ActionBtn
								actionBtnClass="actionbtn--secondary"
								actionBtnWeight="icon-fas"
								actionBtnType="icon--notes"
								onClick={() => {
									// open the global settings overlay
									toggleOverlay({
										active: true,
										type: 'quote_notes',
										data: {},
									});
								}}
							/>
							<ActionBtn
								actionBtnClass="actionbtn--secondary"
								actionBtnWeight="icon-fas"
								actionBtnType="icon--globe"
								onClick={() => {
									// open the global settings overlay
									toggleOverlay({
										active: true,
										type: 'global_changes',
										data: {},
									});
								}}
							/>
							<Btn
								btnType={'btn--secondary'}
								onClick={() => {
									history.push(
										URLMapper('newproduct', {
											quoteId: activeQuote.id,
											productCode: 'MISC_ITEM_PROD',
										})
									);
								}}
							>
								Add Misc Item
							</Btn>
							<Btn
								btnType={'btn--primary'}
								onClick={() => {
									history.push(
										URLMapper('addproduct', {
											quoteId: activeQuote.id,
										})
									);
								}}
							>
								Add Product
							</Btn>
						</>
					)}
				</div>
			</div>
		</div>
	) : (
		''
	);
};

export default QuoteDetailActionBar;
