import ConfigurationLabelMapper from '../../functionality/ConfigurationLabelMapper';

const MullionLineItem = ({ groupName, name, product, quoteProduct, total }) => (
	<div className="quotelistviewlist__lineitem">
		<dl>
			<dt className="heading heading--12">{name}</dt>
			<dd className="heading heading--13">
				{ConfigurationLabelMapper(
					quoteProduct.values[
						product.configurations[groupName][total].fieldName
					]
				)}
			</dd>
		</dl>
	</div>
);

export default MullionLineItem;
