import React, { useContext } from 'react';

import CurrentQuoteProductContext from '../../../../../contexts/CurrentQuoteProductContext';

import ExteriorTrimInput from './ExteriorTrimInput';
import ExteriorTrimColorInput from './ExteriorTrimColorInput';

const ExteriorTrimButton = ({
	configuration,
	selectedExteriorTrimColorValue,
	selectedExteriorTrimOption,
	selectedExteriorTrimValue,
}) => {
	// Product context
	const { setQuoteProductContextProductValue } = useContext(CurrentQuoteProductContext);

	if (!configuration.exteriorTrim) {
		return '';
	}

	return (
		<>
			{configuration.interiorColor || configuration.exteriorColor ? (
				<div className="config-divider"></div>
			) : (
				''
			)}
			<div className="config-heading heading heading--4">
				{configuration.exteriorTrim.heading}
			</div>
			<div className="config-option-select">
				{configuration.exteriorTrim.values.map((option) => {
					return (
						<ExteriorTrimInput
							configuration={configuration}
							key={option.value}
							option={option}
							selectedExteriorTrimValue={selectedExteriorTrimValue}
							setQuoteProductContextProductValue={
								setQuoteProductContextProductValue
							}
						/>
					);
				})}
			</div>

			{configuration.trimColor && selectedExteriorTrimOption?.trimColorOptions ? (
				<>
					<div className="productconfiguration__color-secondary">
						<div className="config-subheading heading heading--6">
							{configuration.trimColor.heading}
						</div>
						<div className="config-notch-select">
							{configuration.trimColor.values.map((value) => {
								const isVisible =
									selectedExteriorTrimOption.trimColorOptions.indexOf(
										value
									) !== -1;

								if (!isVisible) {
									return '';
								}
								return (
									<ExteriorTrimColorInput
										configuration={configuration}
										key={value}
										selectedExteriorTrimColorValue={
											selectedExteriorTrimColorValue
										}
										setQuoteProductContextProductValue={
											setQuoteProductContextProductValue
										}
										value={value}
									/>
								);
							})}
						</div>
					</div>
				</>
			) : (
				''
			)}
		</>
	);
};

export default ExteriorTrimButton;
