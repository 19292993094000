import React from 'react';
import ConfigurationLabelMapper from '../../../../../functionality/ConfigurationLabelMapper';
import Input from '../../../../Input/Input';

/**
 * An independent component for just the SillTab
 */
export const SillTab = ({
	sill_config,
	sillData,
	sillInteriorChangeHandler,
	sillDepthChangeHandler,
}) => {
	const { fieldName, heading, values } = sill_config?.interiorSill;

	const showDepthDropdown =
		sillData &&
		sillData.selectedSillInteriorValue &&
		sillData.selectedSillInteriorValue !== 'NONE';

	return (
		<>
			<div className="config-heading heading heading--4">{heading}</div>
			<div className="config-option-select">
				{values.map((item) => {
					const value = item.value;
					return (
						<SillMappedFragment
							fieldName={fieldName}
							key={value}
							sillData={sillData}
							sillInteriorChangeHandler={sillInteriorChangeHandler}
							value={value}
						/>
					);
				})}
			</div>
			{showDepthDropdown && (
				<SillDepthFragment
					sill_config={sill_config}
					sillData={sillData}
					sillDepthChangeHandler={sillDepthChangeHandler}
				/>
			)}
		</>
	);
};

const SillMappedFragment = ({
	fieldName,
	sillData,
	sillInteriorChangeHandler,
	value,
}) => (
	<>
		<input
			type="radio"
			name={fieldName}
			id={`${fieldName}_${value}`}
			value={value}
			checked={value === sillData.selectedSillInteriorValue}
			onChange={sillInteriorChangeHandler}
		/>
		<label htmlFor={`${fieldName}_${value}`} className="config-option-select__option">
			<div className="config-option-select__check"></div>
			<div className="config-option-select__info">
				{ConfigurationLabelMapper(value)}
			</div>
		</label>
	</>
);

const SillDepthFragment = ({ sill_config, sillData, sillDepthChangeHandler }) => {
	const { fieldName, heading, values } = sill_config?.dimensions;
	return (
		<div className="quotelistviewlist__lineitem">
			<dl className="mt-4">
				<>
					<div className="config-heading heading heading--4">{heading}</div>
					<label
						htmlFor={fieldName}
						className="config-subheading heading heading--6"
					>
						Depth
					</label>
					<div className="config-select-field">
						<Input
							type={'select'}
							className={'input--1'}
							inputModifier={'input--select input--autowidth'}
							id={fieldName}
							name={fieldName}
							values={values.map(function (value) {
								return {
									label: ConfigurationLabelMapper(value),
									value: value,
								};
							})}
							value={sillData.selectedSillDepthValue}
							onChange={sillDepthChangeHandler}
						/>
					</div>
				</>
			</dl>
		</div>
	);
};
