import React, { useState, useContext } from 'react';
import SessionDataContext from '../../contexts/SessionDataContext';
import ProductMapper from '../../functionality/ProductMapper';
import ConfigurationLabelMapper from '../../functionality/ConfigurationLabelMapper';
import { centsToMoney } from '../../functionality/Money';
import IconBtn from '../IconBtn/IconBtn';

import './PresentationSidebar.css';
import { SummaryListView } from './components/SummaryListView';

import { getProductList } from '../../helpers/ActiveQuote';
import { MultiUnitUtil } from '../../util/MultiUnitUtil';
/**
 * This is the sidebar on the page where you can apply discounts to customers.
 */
const PresentationSidebar = () => {
	const [summaryListView, setSummaryListView] = useState(false);

	// use some contexts
	const { activeQuote } = useContext(SessionDataContext);
	const hasActiveQuote = !!activeQuote;
	const quoteProducts = hasActiveQuote ? activeQuote.get('products') : [];
	const processingFeeInCents = hasActiveQuote
		? activeQuote.get('processingFeeInCents')
		: 0;

	const windowList = getProductList(quoteProducts);

	const multiList = MultiUnitUtil.getMultiList();

	// set some variables
	const totalWindows = hasActiveQuote
		? windowList.filter((p) => p.type === 'window').length
		: 0;

	const totalDoorwalls = hasActiveQuote
		? windowList.filter((p) => p.type === 'doorwall').length
		: 0;

	const totalShells = hasActiveQuote
		? windowList.filter((p) => p.type === 'window_shell').length
		: 0;

	const totalMiscItems = hasActiveQuote
		? windowList.filter((p) => p.type === 'misc_item').length
		: 0;

	// let's bin our products and fees by location for the list view
	const misc = [];
	const other = [];
	const productsByLocation = {};

	if (hasActiveQuote && summaryListView) {
		// other (processing fee...)
		if (processingFeeInCents !== 0) {
			other.push({
				title: 'Processing Fee',
				cost: centsToMoney({
					amount: processingFeeInCents,
				}),
			});
		}
		quoteProducts.forEach((quoteProduct) => {
			const product = ProductMapper(quoteProduct.code);
			if (product.code === 'MISC_ITEM_PROD') {
				misc.push({
					title: quoteProduct.values[
						product.configurations.MISC_ITEM.name.fieldName
					],
					cost: centsToMoney({
						amount: quoteProduct.costInCents,
					}),
				});
			} else {
				const windowLocationFieldName =
					product.configurations.WINDOW_LOCATION.standard.fieldName;
				let windowLocation = ConfigurationLabelMapper(
					quoteProduct.values[windowLocationFieldName]
				);

				const qpWindowLocationFieldName =
					quoteProduct.values[windowLocationFieldName];

				if (quoteProduct.values[windowLocationFieldName] === 'CUSTOM') {
					windowLocation = qpWindowLocationFieldName;
				}
				const locationKey = windowLocation.substring(
					0,
					windowLocation.lastIndexOf(' ') + 1
				);

				const locationWindow = windowLocation.substring(
					windowLocation.lastIndexOf(' ') + 1,
					windowLocation.length
				);
				// now add this to the product location array
				if (!productsByLocation[locationKey]) {
					productsByLocation[locationKey] = [];
				}

				let windowCost = quoteProduct.costInCents;

				productsByLocation[locationKey].push({
					title:
						(locationWindow !== '' ? locationWindow + ' - ' : '') +
						product.title,
					cost: centsToMoney({
						amount: windowCost,
					}),
				});

				if (multiList.includes(product.code)) {
					const windowArray = quoteProduct?.values?.additional_items ?? [];
					for (const window of windowArray) {
						const windowProduct = ProductMapper(window.code);

						productsByLocation[locationKey].push({
							title:
								(locationWindow !== '' ? locationWindow + ' - ' : '') +
								windowProduct.title,
							cost: centsToMoney({
								amount: window.costInCents,
							}),
						});
					}
				}
			}
		});
	}
	const productByLocationKeys = Object.keys(productsByLocation);
	productByLocationKeys.sort();

	if (!hasActiveQuote) {
		return null;
	}

	return (
		<div className="presentationsidebar">
			<div className="presentationsidebar__header">
				<h2 className="heading heading--3">Summary</h2>
				<div className="presentationsidebar__toggle">
					<IconBtn
						iconWeight="icon-fas"
						btnType={
							summaryListView
								? 'iconbtn--no-bg iconbtn--light'
								: 'iconbtn--primary'
						}
						iconType={'icon--grid'}
						onClick={() => {
							setSummaryListView(false);
						}}
					/>
					<IconBtn
						iconWeight="icon-fas"
						btnType={
							summaryListView
								? 'iconbtn--primary'
								: 'iconbtn--no-bg iconbtn--light'
						}
						iconType={'icon--list'}
						onClick={() => {
							setSummaryListView(true);
						}}
					/>
				</div>
			</div>
			<div className="presentationsidebar__products">
				{summaryListView ? (
					<SummaryListView
						misc={misc}
						other={other}
						productsByLocation={productsByLocation}
						productByLocationKeys={productByLocationKeys}
					/>
				) : (
					<>
						<h3 className="heading heading--6">Products</h3>
						<div className="presentationsidebar__product">
							<div className="heading heading--5">Windows</div>
							<div className="heading heading--5">{totalWindows}</div>
						</div>
						<div className="presentationsidebar__product">
							<div className="heading heading--5">Doorwall</div>
							<div className="heading heading--5">{totalDoorwalls}</div>
						</div>
						<div className="presentationsidebar__product">
							<div className="heading heading--5">Shells</div>
							<div className="heading heading--5">{totalShells}</div>
						</div>
						<div className="presentationsidebar__product">
							<div className="heading heading--5">Misc Items</div>
							<div className="heading heading--5">{totalMiscItems}</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default PresentationSidebar;
