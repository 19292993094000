import React, { useContext, useState, useEffect } from 'react';
import SessionDataContext from '../../contexts/SessionDataContext';
import ProductMapper from '../../functionality/ProductMapper';
import TruncateString from '../../functionality/truncate-string';
import QuoteProductItem from './QuoteProductItem';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
	getTotalOfItem,
	getTotalOfShell,
	getTotalProducts,
	getTotalWindows,
} from './QuoteProductHelpers/Getters';
import './QuoteDetailProductList.css';
import { reorderList } from './QuoteProductHelpers/reorderList';
import useElementSize from '../../hooks/useElementSize';
import QuoteProductItemCollapse from './QuoteProductItemCollapse';

const getListStyle = (bodyHeight) => ({
	height: bodyHeight,
	display: 'inline-block',
	width: '100%',
});

const getItemStyle = (
	isDragging,
	draggingOver,
	isDropAnimating,
	draggableStyle,
	snapshot,
	draggableHeight
) => {
	if (isDropAnimating) {
		return {
			...draggableStyle,
			marginTop: '5px',
		};
	}
	if (!isDropAnimating) {
		return {
			...draggableStyle,
			backgroundColor: isDragging
				? draggingOver
					? 'rgba(144, 238, 144, .5)'
					: 'rgba(240, 52, 52, .5)'
				: null,

			transition: 'background-color 1s ease',
			height: isDragging ? `calc(${draggableHeight} + 1.3125rem)` : 'auto',
			overflow: 'visible',
			marginTop: '5px',
		};
	}

	const { moveTo, curve, duration } = snapshot.dropAnimation;
	const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;

	if (!draggingOver) {
		return {
			...draggableStyle,
			transform: `${translate}`,
			transition: `all ${curve} ${duration + 1}s`,
		};
	}

	return { ...draggableStyle };
};

/**
 * This is the bottom product count list on the quote details page.
 */
const QuoteDetailProductList = () => {
	// we'll setup the session data context
	const { activeQuote, saveQuote } = useContext(SessionDataContext);
	const hasActiveQuote = !!activeQuote;
	const quoteProducts = hasActiveQuote ? activeQuote.get('products') : [];

	// we'll use state or the notes, and an effect to set it
	const [visibleNotes, setVisibleNotes] = useState([]);
	const [showBackground, setShowBackground] = useState(false);
	const [draggingId, setDraggingId] = useState(null);
	const [bodyHeight, setBodyHeight] = useState(null);
	const [draggableHeights, setDraggableHeights] = useState({});
	const [draggableHeight, setDraggableHeight] = useState();
	const [list, setList] = useState([]);
	const [elementRef, { height: elementHeight }, resetHeight] = useElementSize();

	useEffect(() => {
		setBodyHeight('auto');
	}, [quoteProducts]);

	useEffect(() => {
		if (activeQuote) {
			const temp = getProductList();

			setList(temp);
		}
		setVisibleNotes({});

		return () => {
			setList([]);
			resetHeight();
		};
	}, [activeQuote]);

	const getProductList = (overrideArray) => {
		const temp = [];
		const arr = overrideArray ?? quoteProducts;
		for (const item of arr) {
			if (item.id && !item.code.includes('CUSTOM_WINDOW')) {
				temp.push(item.id);
			}

			if (item.values?.additional_items?.length > 0) {
				// Push the list of additional items itself but not the custom window.
				for (const prod of item.values.additional_items) {
					if (prod.id) {
						temp.push(prod.id);
					}
				}
			}
		}
		return temp;
	};

	// set some variables
	const totalProducts = hasActiveQuote ? getTotalProducts(quoteProducts) : 0;

	const totalWindows = getTotalWindows(quoteProducts);

	const totalDoorwalls = getTotalOfItem(quoteProducts, 'doorwall', 'DOOR_WALL_PROD');

	const totalShells = getTotalOfShell(quoteProducts);

	const totalMiscItems = getTotalOfItem(quoteProducts, 'misc_item', 'MISC_ITEM_PROD');

	// Show the proper multi-unit items
	const renderMultiUnitConfigurationItems = (quoteProduct) => {
		if (quoteProduct.values.additional_items) {
			const outsideInstall = quoteProduct.values
				.configuration_window_outside_install
				? 'Yes'
				: 'No';

			const productCount = quoteProduct.values.additional_items.length;

			const woodMullionCount =
				quoteProduct.values.configuration_window_mullions_wood_total;

			const vinylMullionCount =
				quoteProduct.values.configuration_window_vinyl_total;

			const totalMulls = woodMullionCount + vinylMullionCount;

			return (
				<div className="flex flex-col">
					<div>
						<dt className="heading heading--12">Outside Install:</dt>
						<dd className="heading heading--13">{outsideInstall}</dd>
					</div>
					<div>
						<dt className="heading heading--12">Product Count:</dt>
						<dd className="heading heading--13">{productCount}</dd>
					</div>
					<div>
						<dt className="heading heading--12">Mullion Count:</dt>
						<dd className="heading heading--13">{totalMulls}</dd>
					</div>
				</div>
			);
		}
	};

	const onDragEnd = (result) => {
		setDraggingId(null);
		setBodyHeight('auto');

		if (!result.destination) {
			return;
		}

		const updatedList = reorderList(
			quoteProducts,
			result.source.index,
			result.destination.index
		);

		activeQuote.set('products', updatedList);
		saveQuote();
		setList(getProductList(updatedList));
	};

	const handleOnMouseDown = (quoteProduct) => {
		setBodyHeight(`${elementHeight}px`);
		setDraggingId(quoteProduct.id);
		setDraggableHeight(`${draggableHeights[quoteProduct.id]}px`);
	};

	const handleOnClick = (quoteProduct) => {
		setDraggingId(quoteProduct.id);
		setDraggableHeight('auto');
	};

	if (totalProducts === 0) {
		return (
			<tr className="flex items-center justify-center w-full">
				<td className="quotedetailproductlist__empty">
					<p className="heading heading--4">This quote is currently empty.</p>
				</td>
			</tr>
		);
	}

	return (
		hasActiveQuote &&
		list.length > 0 && (
			<div className="quotedetailproductlist">
				<div className="quotedetailproductlist__scroller">
					<table className="quotedetailproductlist__table">
						<thead className="quotedetailproductlist__head">
							<tr>
								<th className="heading heading--6">Product</th>
								<th className="heading heading--6">Configuration</th>
								<th className="heading heading--6">Size</th>
								<th className="heading heading--6">House Type</th>
								<th className="heading heading--6">Removal</th>
							</tr>
						</thead>
						<tbody
							ref={elementRef}
							className="w-full quotedetailproductlist__body"
						>
							<tr>
								<DragDropContext onDragEnd={onDragEnd} className="w-full">
									<Droppable droppableId="list">
										{(provided) => (
											<div
												{...provided.droppableProps}
												ref={provided.innerRef}
												style={getListStyle(bodyHeight)}
											>
												{quoteProducts.map(
													(quoteProduct, index) => {
														const notesTruncationData =
															TruncateString(
																quoteProduct.notes,
																125
															);
														const product = ProductMapper(
															quoteProduct.code
														);
														return (
															<Draggable
																key={quoteProduct.id}
																draggableId={
																	quoteProduct.id
																}
																index={index}
															>
																{(provided, snapshot) => (
																	<div
																		ref={
																			provided.innerRef
																		}
																		{...provided.draggableProps}
																		style={getItemStyle(
																			snapshot.isDragging,
																			snapshot.draggingOver,
																			snapshot.isDropAnimating,
																			provided
																				.draggableProps
																				.style,
																			snapshot,
																			draggableHeight
																		)}
																	>
																		<div
																			{...provided.dragHandleProps}
																			className="flex items-center justify-center w-full h-[1.3125rem]"
																			onMouseDown={() =>
																				handleOnMouseDown(
																					quoteProduct
																				)
																			}
																			onTouchStart={() => {
																				handleOnMouseDown(
																					quoteProduct
																				);
																			}}
																			onClick={() =>
																				handleOnClick(
																					quoteProduct
																				)
																			}
																		>
																			<span className="rounded-t-lg w-[50%] text-center bg-white icon-fas icon--grip-lines icon--large" />
																		</div>
																		<QuoteProductItemCollapse
																			collapse={
																				quoteProduct.id ===
																				draggingId
																			}
																			height={
																				draggableHeight
																			}
																		>
																			<QuoteProductItem
																				isDragging={
																					snapshot.isDragging
																				}
																				setDraggableHeights={
																					setDraggableHeights
																				}
																				getProductList={
																					getProductList
																				}
																				index={
																					index
																				}
																				notesTruncationData={
																					notesTruncationData
																				}
																				product={
																					product
																				}
																				productList={
																					list
																				}
																				quoteProduct={
																					quoteProduct
																				}
																				renderMultiUnitConfigurationItems={
																					renderMultiUnitConfigurationItems
																				}
																				visibleNotes={
																					visibleNotes
																				}
																			/>
																		</QuoteProductItemCollapse>
																	</div>
																)}
															</Draggable>
														);
													}
												)}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="quotedetailproductlist__summary">
					<span className="heading heading--6">
						{totalProducts} Product{totalProducts === 1 ? '' : 's'}
					</span>
					<span className="heading heading--6">
						{totalWindows} Window{totalWindows === 1 ? '' : 's'}
					</span>
					<span className="heading heading--6">
						{totalDoorwalls} Doorwall{totalDoorwalls === 1 ? '' : 's'}
					</span>
					<span className="heading heading--6">
						{totalShells} Shell{totalShells === 1 ? '' : 's'}
					</span>
					<span className="heading heading--6">
						{totalMiscItems} Misc Item{totalMiscItems === 1 ? '' : 's'}
					</span>
				</div>
			</div>
		)
	);
};

export default QuoteDetailProductList;
