import React, { useState, useContext } from 'react';
import IconBtn from '../../IconBtn/IconBtn';
import Input from '../../Input/Input';
import CurrentQuoteProductContext, {
	useCurrentQuoteContext,
} from '../../../contexts/CurrentQuoteProductContext';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';
import UIManagementContext from '../../../contexts/UIManagementContext';
import { RenderMullionRemovalOption } from '../../../helpers';
import { usePartsGroupRelationships } from '../../../hooks';

const ProductConfigurationWindowInstructions = (props) => {
	// we'll do some work to handle the relationhsips of a few different options
	const { extensionJambsValue, cutbackSizeValue, buckValue, casingValue, stopOption } =
		usePartsGroupRelationships(props.configuration);
	// the ui management context
	const { openConfigurationData, toggleOpenConfiguration } =
		useContext(UIManagementContext);

	// context values
	const { setQuoteProductContextProductValue, getQuoteProductContextProductValue } =
		useCurrentQuoteContext();

	// component state
	const [subSectionsOpen, setSubSectionsOpen] = useState(
		props.configuration.groups.map(function (group, i) {
			return i === 0;
		})
	);

	// create the section details
	const createSectionHeadingDetails = () => {
		let text = '';
		props.configuration.groups.forEach((group) => {
			group.options.forEach((option) => {
				const heading = option.heading;
				const type = option.configurationType;
				const value = getQuoteProductContextProductValue(option.fieldName);
				if (type === 'boolean') {
					// this is a boolean, we only show this if its set to true
					if (value === true) {
						text += (text !== '' ? ' | ' : '') + heading;
					}
				} else if (type === 'number') {
					// this is a number field and its value is not 0
					if (value !== 0) {
						text += (text !== '' ? ' | ' : '') + heading + ': ' + value;
					}
				} else {
					// this is any other type and its value is not 'none'
					if (value !== 'NONE') {
						text +=
							(text !== '' ? ' | ' : '') +
							heading +
							': ' +
							ConfigurationLabelMapper(value);
					}
				}
			});
		});
		return text;
	};

	// return the component
	return (
		<div className="productconfiguration">
			<div
				className={`productconfiguration__section ${
					openConfigurationData() === props.orderIndex
						? 'productconfiguration__section--open'
						: ''
				}`}
			>
				<button
					type="button"
					className="productconfiguration__section-header"
					onClick={() => {
						toggleOpenConfiguration(props.orderIndex);
					}}
				>
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{props.orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">
							{props.configuration.heading}
						</div>
						<div className="heading heading--7">
							{createSectionHeadingDetails()}
						</div>
					</div>
					<div className="productconfiguration__section-toggle">
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--primary'}
							iconType={'icon--plus'}
						/>
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--secondary'}
							iconType={'icon--minus'}
						/>
					</div>
				</button>
				<div className="productconfiguration__section-body">
					{props.configuration.groups.map(function (group, i) {
						return (
							<div
								key={'group_' + i}
								className={`productconfiguration__subsection ${
									subSectionsOpen[i]
										? 'productconfiguration__subsection--open'
										: ''
								}`}
							>
								<button
									className="productconfiguration__subsection-header"
									onClick={() => {
										setSubSectionsOpen((prev) => {
											prev[i] = !prev[i];
											return Object.assign([], prev);
										});
									}}
								>
									<span className="heading heading--11">
										{group.heading}
									</span>
									<span className="config-divider"></span>
									<span
										className={`icon-far ${
											subSectionsOpen[i]
												? 'icon--dropdown-open'
												: 'icon--dropdown'
										}`}
									/>
								</button>
								<div className="productconfiguration__subsection-body">
									{group.options.map(function (groupOption, j) {
										return (
											<React.Fragment
												key={'groupoption_' + i + '_' + j}
											>
												{groupOption.configurationType ===
												'boolean' ? (
													<>
														<div
															className="config-boolean-field"
															key={groupOption.fieldName}
														>
															<div className="config-heading heading heading--4">
																{groupOption.heading}
															</div>
															<Input
																type="toggle"
																id={groupOption.fieldName}
																name={
																	groupOption.fieldName
																}
																checked={getQuoteProductContextProductValue(
																	groupOption.fieldName
																)}
																onChange={(e) => {
																	// update the value
																	setQuoteProductContextProductValue(
																		e.target.name,
																		e.target.checked
																	);
																	// we have a toggle that only allows one or the other
																	if (
																		e.target.name ===
																			'configuration_window_instructions_removal_baybow_removal' &&
																		e.target.checked
																	) {
																		setQuoteProductContextProductValue(
																			'configuration_window_instructions_removal_garden_removal',
																			false
																		);
																	} else if (
																		e.target.name ===
																			'configuration_window_instructions_removal_garden_removal' &&
																		e.target.checked
																	) {
																		setQuoteProductContextProductValue(
																			'configuration_window_instructions_removal_baybow_removal',
																			false
																		);
																	}
																}}
															/>
														</div>
														{groupOption.expand &&
														getQuoteProductContextProductValue(
															groupOption.fieldName
														) ? (
															<div className="productconfiguration__secondarydropdown">
																<label
																	htmlFor={
																		groupOption.expand
																			.fieldName
																	}
																	className="config-subheading heading heading--6"
																>
																	{
																		groupOption.expand
																			.heading
																	}
																</label>
																{groupOption.expand
																	.expandType ===
																'sqft' ? (
																	<div className="config-number-field">
																		<Input
																			type={'text'}
																			className={
																				'input--1 input--1 input--sqft input--white'
																			}
																			id={
																				groupOption
																					.expand
																					.fieldName
																			}
																			name={
																				groupOption
																					.expand
																					.fieldName
																			}
																			placeholder="#"
																			value={getQuoteProductContextProductValue(
																				groupOption
																					.expand
																					.fieldName
																			)}
																			onBlur={(
																				e
																			) => {
																				const v =
																					parseFloat(
																						e
																							.target
																							.value
																					);

																				setQuoteProductContextProductValue(
																					e
																						.target
																						.name,
																					isNaN(
																						v
																					)
																						? 0
																						: Math.max(
																								0,
																								v
																						  )
																				);
																			}}
																			onChange={(
																				e
																			) => {
																				setQuoteProductContextProductValue(
																					e
																						.target
																						.name,
																					e
																						.target
																						.value
																				);
																			}}
																		/>
																	</div>
																) : groupOption.expand
																		.expandType ===
																  'select' ? (
																	<Input
																		type={'select'}
																		className={
																			'input--1'
																		}
																		inputModifier={
																			'input--select input--white'
																		}
																		id={
																			groupOption
																				.expand
																				.fieldName
																		}
																		name={
																			groupOption
																				.expand
																				.fieldName
																		}
																		values={groupOption.expand.values.map(
																			function (
																				value
																			) {
																				return {
																					label: ConfigurationLabelMapper(
																						value
																					),
																					value: value,
																				};
																			}
																		)}
																		value={getQuoteProductContextProductValue(
																			groupOption
																				.expand
																				.fieldName
																		)}
																		onChange={(e) => {
																			setQuoteProductContextProductValue(
																				e.target
																					.name,
																				e.target
																					.value
																			);
																		}}
																	/>
																) : groupOption.expand
																		.expandType ===
																  'radio' ? (
																	<div className="config-option-select">
																		{groupOption.expand.values.map(
																			(value) => {
																				return (
																					<React.Fragment
																						key={
																							value
																						}
																					>
																						<input
																							type="radio"
																							name={
																								groupOption
																									.expand
																									.fieldName
																							}
																							id={`${groupOption.expand.fieldName}_${value}`}
																							value={
																								value
																							}
																							checked={
																								value ===
																								getQuoteProductContextProductValue(
																									groupOption
																										.expand
																										.fieldName
																								)
																							}
																							onChange={(
																								e
																							) => {
																								setQuoteProductContextProductValue(
																									e
																										.target
																										.name,
																									e
																										.target
																										.value
																								);
																							}}
																						/>
																						<label
																							htmlFor={`${groupOption.expand.fieldName}_${value}`}
																							className="config-option-select__option"
																						>
																							<div className="config-option-select__check"></div>
																							<div className="config-option-select__info">
																								{ConfigurationLabelMapper(
																									value
																								)}
																							</div>
																						</label>
																					</React.Fragment>
																				);
																			}
																		)}
																	</div>
																) : (
																	''
																)}
																{groupOption.expand
																	.notes && (
																	<div className="heading heading--5">
																		{
																			groupOption
																				.expand
																				.notes
																		}
																	</div>
																)}
															</div>
														) : (
															''
														)}
													</>
												) : groupOption.configurationType ===
												  'radio' ? (
													<>
														{j > 0 &&
														group.options[j - 1]
															.configurationType !==
															'radio' ? (
															<div className="config-divider"></div>
														) : (
															''
														)}
														<div className="config-heading heading heading--4">
															{groupOption.heading}
														</div>
														<div className="config-option-select">
															{groupOption.values.map(
																(value) => {
																	const thisValue =
																		groupOption.optionId ===
																		'CASING'
																			? value.value
																			: value;
																	const isDisabled =
																		(groupOption.optionId ===
																			'BUCKS' &&
																			extensionJambsValue &&
																			thisValue ===
																				'NONE') ||
																		(groupOption.optionId ===
																			'BUCKS' &&
																			extensionJambsValue &&
																			thisValue ===
																				'FULL_BUCK') ||
																		(groupOption.optionId ===
																			'BUCKS' &&
																			cutbackSizeValue &&
																			thisValue !==
																				'FULL_BUCK') ||
																		(groupOption.optionId ===
																			'CASING' &&
																			extensionJambsValue &&
																			thisValue ===
																				'NONE') ||
																		(groupOption.optionId ===
																			'CASING' &&
																			cutbackSizeValue &&
																			thisValue ===
																				'NONE') ||
																		(groupOption.optionId ===
																			'STOPS' &&
																			buckValue ===
																				'PARTIAL_BUCK' &&
																			thisValue ===
																				'NONE') ||
																		(groupOption.optionId ===
																			'STOPS' &&
																			casingValue ===
																				'WHITE_VINYL' &&
																			thisValue !==
																				'WHITE_VINYL');

																	return (
																		<React.Fragment
																			key={
																				thisValue
																			}
																		>
																			<input
																				type="radio"
																				name={
																					groupOption.fieldName
																				}
																				id={`${groupOption.fieldName}_${thisValue}`}
																				value={
																					thisValue
																				}
																				{...(isDisabled && {
																					disabled: true,
																				})}
																				checked={
																					!!(
																						thisValue ===
																						getQuoteProductContextProductValue(
																							groupOption.fieldName
																						)
																					)
																				}
																				onChange={(
																					e
																				) => {
																					setQuoteProductContextProductValue(
																						e
																							.target
																							.name,
																						e
																							.target
																							.value
																					);
																					// if we are changing the casing, then we want ot reset the sstops
																					if (
																						groupOption.optionId ===
																							'CASING' &&
																						stopOption
																					) {
																						setQuoteProductContextProductValue(
																							stopOption.fieldName,
																							''
																						);
																					}
																				}}
																			/>
																			<label
																				htmlFor={`${groupOption.fieldName}_${thisValue}`}
																				className={`config-option-select__option ${
																					isDisabled
																						? 'config-option-select__option--inactive'
																						: ''
																				}`}
																			>
																				<div className="config-option-select__check"></div>
																				<div className="config-option-select__info">
																					{ConfigurationLabelMapper(
																						thisValue
																					)}
																				</div>
																			</label>
																		</React.Fragment>
																	);
																}
															)}
														</div>
														{j < group.options.length - 1 ? (
															<div className="config-divider"></div>
														) : (
															''
														)}
													</>
												) : groupOption.configurationType ===
														'number' &&
												  RenderMullionRemovalOption(
														groupOption
												  ) ? (
													<>
														<div className="config-incremental-number-field">
															<div className="config-heading heading heading--4">
																{groupOption.heading}
															</div>
															<div className="qty-input qty-input__buttons">
																<button
																	type="button"
																	className="qty-input__minus"
																	onClick={() => {
																		setQuoteProductContextProductValue(
																			groupOption.fieldName,
																			Math.max(
																				0,
																				getQuoteProductContextProductValue(
																					groupOption.fieldName
																				) - 1
																			)
																		);
																	}}
																>
																	-
																</button>
																<input
																	type="number"
																	name={
																		groupOption.fieldName
																	}
																	id={
																		groupOption.fieldName
																	}
																	className="qty-input__input"
																	step="1"
																	min="0"
																	max=""
																	placeholder="#"
																	value={getQuoteProductContextProductValue(
																		groupOption.fieldName
																	)}
																	onChange={(e) => {
																		setQuoteProductContextProductValue(
																			e.target.name,
																			parseFloat(
																				e.target
																					.value
																			)
																		);
																	}}
																/>
																<button
																	type="button"
																	className="qty-input__plus"
																	onClick={() => {
																		setQuoteProductContextProductValue(
																			groupOption.fieldName,
																			getQuoteProductContextProductValue(
																				groupOption.fieldName
																			) + 1
																		);
																	}}
																>
																	+
																</button>
															</div>
														</div>
													</>
												) : (
													''
												)}
											</React.Fragment>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
export default ProductConfigurationWindowInstructions;
