export default {
	ATTIC_FRONT: 'Attic Front',
	ATTIC_SIDE: 'Attic Side',
	ATTIC_REAR: 'Attic Rear',
	BASEMENT_FRONT: 'Basement Front',
	BASEMENT_SIDE: 'Basement Side',
	BASEMENT_REAR: 'Basement Rear',
	BATHROOM1_FRONT: 'Bathroom1 Front',
	BATHROOM1_SIDE: 'Bathroom1 Side',
	BATHROOM1_REAR: 'Bathroom1 Rear',
	BATHROOM2_FRONT: 'Bathroom2 Front',
	BATHROOM2_SIDE: 'Bathroom2 Side',
	BATHROOM2_REAR: 'Bathroom2 Rear',
	BATHROOM3_FRONT: 'Bathroom3 Front',
	BATHROOM3_SIDE: 'Bathroom3 Side',
	BATHROOM3_REAR: 'Bathroom3 Rear',
	BEDROOM1_FRONT: 'Bedroom1 Front',
	BEDROOM1_SIDE: 'Bedroom1 Side',
	BEDROOM1_REAR: 'Bedroom1 Rear',
	BEDROOM2_FRONT: 'Bedroom2 Front',
	BEDROOM2_SIDE: 'Bedroom2 Side',
	BEDROOM2_REAR: 'Bedroom2 Rear',
	BEDROOM3_FRONT: 'Bedroom3 Front',
	BEDROOM3_SIDE: 'Bedroom3 Side',
	BEDROOM3_REAR: 'Bedroom3 Rear',
	BEDROOM4_FRONT: 'Bedroom4 Front',
	BEDROOM4_SIDE: 'Bedroom4 Side',
	BEDROOM4_REAR: 'Bedroom4 Rear',
	BEDROOM5_FRONT: 'Bedroom5 Front',
	BEDROOM5_SIDE: 'Bedroom5 Side',
	BEDROOM5_REAR: 'Bedroom5 Rear',
	BEDROOM6_FRONT: 'Bedroom6 Front',
	BEDROOM6_SIDE: 'Bedroom6 Side',
	BEDROOM6_REAR: 'Bedroom6 Rear',
	BREEZEWAY_FRONT: 'Breezeway Front',
	BREEZEWAY_SIDE: 'Breezeway Side',
	BREEZEWAY_REAR: 'Breezeway Rear',
	CLOSET_FRONT: 'Closet Front',
	CLOSET_SIDE: 'Closet Side',
	CLOSET_REAR: 'Closet Rear',
	DEN_FRONT: 'Den Front',
	DEN_SIDE: 'Den Side',
	DEN_REAR: 'Den Rear',
	DINING_ROOM_FRONT: 'Dining Room Front',
	DINING_ROOM_SIDE: 'Dining Room Side',
	DINING_ROOM_REAR: 'Dining Room Rear',
	FAMILY_ROOM_FRONT: 'Family Room Front',
	FAMILY_ROOM_SIDE: 'Family Room Side',
	FAMILY_ROOM_REAR: 'Family Room Rear',
	FOYER_FRONT: 'Foyer Front',
	FOYER_SIDE: 'Foyer Side',
	FOYER_REAR: 'Foyer Rear',
	GARAGE_FRONT: 'Garage Front',
	GARAGE_SIDE: 'Garage Side',
	GARAGE_REAR: 'Garage Rear',
	HALLWAY_FRONT: 'Hallway Front',
	HALLWAY_SIDE: 'Hallway Side',
	HALLWAY_REAR: 'Hallway Rear',
	KITCHEN_FRONT: 'Kitchen Front',
	KITCHEN_SIDE: 'Kitchen Side',
	KITCHEN_REAR: 'Kitchen Rear',
	LAUNDRY_ROOM: 'Laundry Room',
	LIBRARY_FRONT: 'Library Front',
	LIBRARY_SIDE: 'Library Side',
	LIBRARY_REAR: 'Library Rear',
	LIVING_ROOM_FRONT: 'Living Room Front',
	LIVING_ROOM_SIDE: 'Living Room Side',
	LIVING_ROOM_REAR: 'Living Room Rear',
	NOOK_FRONT: 'Nook Front',
	NOOK_SIDE: 'Nook Side',
	NOOK_REAR: 'Nook Rear',
	NURSERY: 'Nursery',
	OFFICE_FRONT: 'Office Front',
	OFFICE_SIDE: 'Office Side',
	OFFICE_REAR: 'Office Rear',
	PORCH_FRONT: 'Porch Front',
	PORCH_SIDE: 'Porch Side',
	PORCH_REAR: 'Porch Rear',
	SANCTUARY: 'Sanctuary',
	STAIRWAY_FRONT: 'Stairway Front',
	STAIRWAY_SIDE: 'Stairway Side',
	STAIRWAY_REAR: 'Stairway Rear',
	UNIT1: 'Unit 1',
	UNIT2: 'Unit 2',
	UNIT3: 'Unit 3',
	UNIT4: 'Unit 4',
	UNIT5: 'Unit 5',
	UNIT6: 'Unit 6',
	UNIT7: 'Unit 7',
	UNIT8: 'Unit 8',
	UNIT9: 'Unit 9',
	UNIT10: 'Unit 10',
	UNIT11: 'Unit 11',
	UNIT12: 'Unit 12',
	UNIT13: 'Unit 13',
	UNIT14: 'Unit 14',
	UNIT15: 'Unit 15',
	FRONT: 'Front',
	SIDE: 'Side',
	REAR: 'Rear',
	BUILD_ONLY: 'Build Only',
	LEAVE_ALL_EXISTING: 'Leave All Existing',
	LEAVE_PARTIAL_EXISTING: 'Leave Partial Existing',
	ALL_NEW_MULLS: 'All New Mulls',
};
