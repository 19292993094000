import { IsFromMulti } from './IsFromMulti';
// If this modal is being shown via the magic window, we'll
// need to hide the mullion removal option since it is handled
// in step 3.
/**
 *  If the modal is being shown via the magic window, we'll
 * need to hide the mullion removal option since it is handled
 * in step 3.
 * @param {*} groupOption configuration.groups.groupOption
 * @note To return a False groupOption must = 'configuration_window_instructions_removal_mullion'
 * @returns Boolean
 */
export const RenderMullionRemovalOption = (groupOption) => {
	const isFromMulti = IsFromMulti();
	if (
		isFromMulti &&
		groupOption.fieldName === 'configuration_window_instructions_removal_mullion'
	) {
		return false;
	}
	return true;
};
