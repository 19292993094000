import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useContext, useState, useRef, useEffect } from 'react';
import SessionDataContext from '../../contexts/SessionDataContext';
import CurrentQuoteProductContext from '../../contexts/CurrentQuoteProductContext';
// import UIManagementContext from '../../contexts/UIManagementContext';
import WallsideError from '../../functionality/WallsideError';
import ProductConfigurations from '../../components/ProductConfigurations/ProductConfigurations';
import {
	addCustomWindowProductAction,
	closeModalAction,
} from '../../containers/CustomWindow/Actions';
import { useCustomWindowContext } from '../../containers/CustomWindow/customWindowContext';

/**
 * This is called when we're adding/editing a window to our multi-unit grid.
 * We need to push this to some type of array or associate it with a multi-unit's
 * key.
 * @param {*} props
 * @returns
 */
export default function CustomProductSpecificationsModal(props) {
	const { customWindowDispatch, customWindowState } = useCustomWindowContext();
	// the contexts
	const { loadQuote, activeQuote } = useContext(SessionDataContext);
	const { product, quoteProductReset, product_values } = useContext(
		CurrentQuoteProductContext
	);
	// use some state
	const [ready, setReady] = useState(false);

	// we only want our effect to trigger 1 time, so we'll use a ref
	const isFirstTime = useRef(true);

	const { openModalState, openModalData } = customWindowState;

	// we need to fetch the correct quote for this account
	const closeModal = () => {
		const productValues = customWindowState.generalSpecifications;
		quoteProductReset('CUSTOM_WINDOW', productValues, '');
		customWindowDispatch(closeModalAction());
	};

	const saveSpecification = () => {
		customWindowDispatch(
			addCustomWindowProductAction({ ...product_values, ...openModalData })
		);
		closeModal();
	};

	// we need to fetch the correct quote for this account
	useEffect(() => {
		// we need to determine if this is an existing product or a new product
		let productCode = openModalData?.droppedData.code;
		let productValues = {};
		let productNotes = '';

		/* 
			Check if this is an existing product.
       		IF: load existing product values to the form state.
       		ELSE: load new initial product values to the form state.
        	INFO: Found product state obj: `customWindowState.js -> initializerArg.cell.product`.
      */
		const { product } = openModalData;
		if (product) {
			setReady(true);
			return quoteProductReset(productCode, product, productNotes);
		}

		if (isFirstTime.current) {
			// turn off first time
			isFirstTime.current = false;
			// load the quote and setup

			// IF isFirstTime set the product initial height & width values from the grid column state.
			const activeColumn = openModalData.col;
			const {
				configuration_window_size_height = 0,
				configuration_window_size_width = 0,
			} = activeColumn?.product ?? {};

			productValues = {
				configuration_window_size_height,
				configuration_window_size_width,
			};

			// if this isn't a new one, then we need to
			// find the data in the current quote
			if (!productCode) {
				let index = -1;
				for (let i = 0; i < activeQuote.get('products').length; i++) {
					if (
						activeQuote.get('products')[i].id === props.match.params.productId
					) {
						index = i;
						break;
					}
				}
				// if we couldn't find this product, throw error
				if (index === -1) {
					return Promise.reject(
						new WallsideError({ message: 'Product not found.' })
					);
				}
				// the index exists so grab the product, and update
				const quoteProduct = {
					...activeQuote.get('products')[index],
				};
				productCode = quoteProduct.code;
				productValues = quoteProduct.values;
				productNotes = quoteProduct.notes;
			}
			// now we init the quote product, calling quoteProductReset resets the selected quote product
			// but shows the proper info when editing one.
			setReady(true);
			return quoteProductReset(productCode, productValues, productNotes);
		}
	}, [loadQuote, activeQuote, quoteProductReset]);

	const renderProductConfigurations = () => {
		if (!ready) {
			return;
		}
		if (product !== false && product.code === openModalData?.droppedData.code) {
			const configurationGroups = [
				'WINDOW_SIZE',
				...product.configurationGroups.group2,
			];

			return configurationGroups.map((configId, i) => (
				<ProductConfigurations
					orderIndex={i + 1}
					configuration={product.configurations[configId]}
					key={configId}
					disabledWindowSize={true}
				/>
			));
		}
	};

	const renderHandler = ready ? (
		<>
			<Transition appear show={openModalState} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={closeModal}
				>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block bg-ws-x-light-blue w-full max-w-4xl my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">
								{/* Header/Title */}
								<Dialog.Title
									as="h3"
									className="text-lg font-medium leading-6 text-gray-900 bg-white p-5"
								>
									<div className="productconfiguration__header-info">
										<div className="heading heading--3">
											Product Specifications
										</div>
										<div className="heading heading--7">
											{product.title}
										</div>
									</div>
								</Dialog.Title>

								{/* Body */}
								<div className="px-8 pt-6 pb-8 mb-4 flex flex-col custom-window_layout">
									{renderProductConfigurations()}
								</div>

								{/* Footer */}
								<div className="flex justify-end gap-3 mb-6">
									<button
										type="button"
										className="btn btn--medium"
										onClick={closeModal}
									>
										CANCEL
									</button>
									<button
										type="button"
										className="btn btn--primary mr-5"
										onClick={saveSpecification}
									>
										SAVE SPECIFICATIONS
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	) : (
		<div />
	);
	return renderHandler;
}
