import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UIManagementContext from '../../../contexts/UIManagementContext';
import SessionDataContext from '../../../contexts/SessionDataContext';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import Btn from '../../../components/Btn/Btn';
import WallsideError from '../../../functionality/WallsideError';

const OverlayConfirmCloseWithoutSaving = (_props) => {
	// setting up the history object
	const history = useHistory();

	// setting up the contexts
	const { product, product_notes, product_values, quoteProductErrorList } = useContext(
		CurrentQuoteProductContext
	);
	const { overlayData, toggleOverlay, toggleSystemNotification, toggleBlockBack } =
		useContext(UIManagementContext);
	const { saveQuoteProduct } = useContext(SessionDataContext);

	// check for valid product
	const isValidProduct = () => {
		const errorList = quoteProductErrorList();
		if (errorList.length > 0) {
			toggleOverlay({
				active: true,
				type: 'error_messages',
				data: {
					heading: 'Error Adding To Quote',
					errors: errorList,
				},
			});
			return false;
		}
		return true;
	};

	// handle add to quote
	const handleAddToQuoteClick = () => {
		if (isValidProduct()) {
			// save the product
			saveQuoteProduct({
				id: overlayData().data.productId,
				code: product.code,
				type: product.type,
				notes: product_notes,
				values: product_values,
			}).then(
				() => {
					// set to allow back
					toggleBlockBack(false).then(() => {
						// we need to go back one spot, because we have a duplicate
						history.go(-2);
						// close any overlay
						toggleOverlay({ active: false });
						// show the notification
						toggleSystemNotification({
							active: true,
							messageKey: 'save_quote',
						});
					});
				},
				(e) => {
					const error = new WallsideError(e);
					alert(error.globalErrors[0].message);
				}
			);
		}
	};

	const hideOverlay = () => {
		toggleOverlay({ active: false });
	};

	const handleDontSaveButtonOnClick = () => {
		// set to allow back
		toggleBlockBack(false).then(() => {
			// go back
			history.go(-2);
			// close the overlay
			hideOverlay();
		});
	};

	const renderButtons = () => {
		const isMulti = window.location.pathname.includes('multi-unit');

		if (isMulti) {
			return (
				<div className="overlay-s1__btns">
					<Btn btnType={'btn--secondary'} onClick={hideOverlay}>
						Cancel
					</Btn>
					<Btn btnType={'btn--danger'} onClick={handleDontSaveButtonOnClick}>
						Don&apos;t Save
					</Btn>
				</div>
			);
		}

		return (
			<div className="overlay-s1__btns">
				<Btn btnType={'btn--secondary'} onClick={hideOverlay}>
					Cancel
				</Btn>
				<Btn btnType={'btn--danger'} onClick={handleDontSaveButtonOnClick}>
					Don&apos;t Save
				</Btn>
				<Btn btnType={'btn--primary'} onClick={handleAddToQuoteClick}>
					Save
				</Btn>
			</div>
		);
	};

	const renderModalText = () => {
		const isMulti = window.location.pathname.includes('multi-unit');

		if (isMulti) {
			return (
				<p className="heading heading--5">
					Would you like to exit? Your changes will be lost.
				</p>
			);
		}

		return (
			<p className="heading heading--5">
				Would you like to save your changes before exiting? If you don’t, your
				changes might be lost.
			</p>
		);
	};

	// return the component
	return (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s1">
						<div className="overlay-s1__heading">
							<h1 className="heading heading--4">Confirm Close</h1>
						</div>
						<div className="overlay-s1__body overlay-s1__body--message">
							{renderModalText()}
						</div>
						{renderButtons()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayConfirmCloseWithoutSaving;
