import React, { useContext, useState } from 'react';
import UIManagementContext from '../../../contexts/UIManagementContext';
import SessionDataContext from '../../../contexts/SessionDataContext';
import WallsideError from '../../../functionality/WallsideError';
import Btn from '../../Btn/Btn';
import Input from '../../Input/Input';

const OverlayPayByCashDiscount = (props) => {
	// setup our contexts
	const { toggleOverlay, toggleSystemNotification } = useContext(UIManagementContext);
	const { activeQuote, saveQuote } = useContext(SessionDataContext);

	// we'll use state for our inputs
	const [amount, setAmount] = useState(0);
	const [displayAmount, setDisplayAmount] = useState('');

	const handleApplyOnClick = () => {
		if (amount !== 0) {
			// add this discount to quote discounts (at the end)
			activeQuote.set(
				'discounts',
				activeQuote.get('discounts').concat([
					{
						title: 'Discount for Paying Cash, Credit or Check',
						description: displayAmount + '% off',
						type: 'percentage',
						amountInPercent: amount,
						code: 'system_pay_by_cash',
					},
				])
			);
			// now resave the quote
			saveQuote().then(
				() => {
					// show the notification
					toggleSystemNotification({
						active: true,
						messageKey: 'save_quote',
					});
					// now we close the overlay
					toggleOverlay({ active: false });
				},
				(e) => {
					const error = new WallsideError(e);
					alert(error.globalErrors[0].message);
				}
			);
		}
	};

	const handleOnBlur = (e) => {
		const valueDigitsPlusDecimal = e.target.value.replace(/[^0-9.]/g, '');
		const floatNumber = parseFloat(parseFloat(valueDigitsPlusDecimal).toFixed(2));
		const displayPercentage = isNaN(floatNumber) ? 0 : floatNumber;
		setAmount(parseFloat((displayPercentage / 100).toFixed(4)));
		setDisplayAmount(displayPercentage === 0 ? '' : displayPercentage);
	};

	const handleOnChange = (e) => {
		setDisplayAmount(e.target.value);
	};

	const hideOverlay = () => {
		toggleOverlay({ active: false });
	};

	// return the jsx component
	return (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s1">
						<div className="overlay-s1__heading">
							<h1 className="heading heading--4">
								Discount for Paying Cash, Credit or Check
							</h1>
							<button
								type="button"
								className="icon-fal icon--close"
								onClick={hideOverlay}
							/>
						</div>
						<div className="overlay-s1__body">
							<label
								htmlFor="the_notes"
								className="heading heading--form-element-2"
							>
								Discount
							</label>
							<Input
								type="text"
								id="discount_amount"
								name="discount_amount"
								className="input--1 input--percent input--white"
								value={displayAmount}
								onBlur={handleOnBlur}
								onChange={handleOnChange}
							/>
						</div>
						<div className="overlay-s1__btns">
							<Btn btnType={'btn--secondary'} onClick={hideOverlay}>
								Cancel
							</Btn>
							<Btn btnType={'btn--primary'} onClick={handleApplyOnClick}>
								Apply
							</Btn>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayPayByCashDiscount;
