import React, { useContext, useEffect } from 'react';
import IconBtn from '../../IconBtn/IconBtn';
import ImageAsset from '../../ImageAsset/ImageAsset';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import UIManagementContext from '../../../contexts/UIManagementContext';
import classNames from 'classnames';

const ProductConfigurationWindowSeat = ({ configuration, isBayBow, orderIndex }) => {
	// the ui management context
	const { openConfigurationData, toggleOpenConfiguration } =
		useContext(UIManagementContext);

	// product context
	const { setQuoteProductContextProductValue, getQuoteProductContextProductValue } =
		useContext(CurrentQuoteProductContext);

	// the selected values
	const selectedTypeValue = configuration.type
		? getQuoteProductContextProductValue(configuration.type.fieldName)
		: false;
	const selectedTypeOption = configuration.type
		? configuration.type.values.find((option) => {
				return option.value === selectedTypeValue;
		  })
		: false;
	const selectedColorValue = configuration.color
		? getQuoteProductContextProductValue(configuration.color.fieldName)
		: false;

	// create the section details
	const createSectionHeadingDetails = () => {
		return (
			ConfigurationLabelMapper(selectedTypeValue) +
			(selectedColorValue
				? ' | ' + ConfigurationLabelMapper(selectedColorValue)
				: '')
		);
	};

	// we need to check defaults as they switch
	useEffect(() => {
		if (configuration.color && configuration.type) {
			if (selectedTypeOption.seatColorOptions === false) {
				if (selectedColorValue !== false) {
					setQuoteProductContextProductValue(
						configuration.color.fieldName,
						selectedTypeOption.seatColorDefault
					);
				}
			} else if (
				selectedTypeOption.seatColorOptions.indexOf(selectedColorValue) === -1
			) {
				setQuoteProductContextProductValue(
					configuration.color.fieldName,
					selectedTypeOption.seatColorDefault
				);
			}
		}
	}, [
		selectedTypeOption,
		selectedColorValue,
		configuration.color,
		configuration.type,
		setQuoteProductContextProductValue,
	]);

	const containerStyle = classNames('productconfiguration__section', {
		'productconfiguration__section--open': openConfigurationData() === orderIndex,
	});

	const btnStyle = classNames('productconfiguration__section-header', {
		'cursor-default': isBayBow,
	});

	return (
		<div className="productconfiguration">
			<div className={containerStyle}>
				<button
					type="button"
					className={btnStyle}
					onClick={() => {
						isBayBow ? null : toggleOpenConfiguration(orderIndex);
					}}
				>
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">{configuration.heading}</div>
						<div className="heading heading--7">
							{createSectionHeadingDetails()}
						</div>
					</div>
					<div className="productconfiguration__section-toggle">
						{!isBayBow && (
							<>
								<IconBtn
									layoutOnly
									iconWeight="icon-far"
									btnType={'iconbtn--primary'}
									iconType={'icon--plus'}
								/>
								<IconBtn
									layoutOnly
									iconWeight="icon-far"
									btnType={'iconbtn--secondary'}
									iconType={'icon--minus'}
								/>
							</>
						)}
					</div>
				</button>
				<div className="productconfiguration__section-body">
					{configuration.type && (
						<>
							<div className="config-heading heading heading--4">
								{configuration.type.heading}
							</div>
							<div className="config-option-select">
								{configuration.type.values.map((option) => {
									return (
										<React.Fragment key={option.value}>
											<input
												type="radio"
												name={configuration.type.fieldName}
												id={`${configuration.type.fieldName}_${option.value}`}
												value={option.value}
												checked={
													option.value === selectedTypeValue
												}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${configuration.type.fieldName}_${option.value}`}
												className="config-option-select__option"
											>
												<div className="config-option-select__check"></div>
												<div className="config-option-select__info">
													{ConfigurationLabelMapper(
														option.value
													)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
							{configuration.color &&
								selectedTypeOption.seatColorOptions !== false &&
								selectedTypeOption.seatColorOptions.length > 0 && (
									<>
										<div className="productconfiguration__color-secondary">
											<div className="config-subheading heading heading--6">
												{configuration.color.heading}
											</div>
											<div className="config-notch-select">
												{configuration.color.values.map(
													(value) => {
														const isVisible =
															selectedTypeOption.seatColorOptions.indexOf(
																value
															) !== -1;
														return (
															isVisible && (
																<React.Fragment
																	key={value}
																>
																	<input
																		type="radio"
																		name={
																			configuration
																				.color
																				.fieldName
																		}
																		id={`${configuration.color.fieldName}_${value}`}
																		value={value}
																		checked={
																			value ===
																			selectedColorValue
																		}
																		onChange={(e) => {
																			setQuoteProductContextProductValue(
																				e.target
																					.name,
																				e.target
																					.value
																			);
																		}}
																	/>
																	<label
																		htmlFor={`${configuration.color.fieldName}_${value}`}
																		className="config-notch-select__option"
																	>
																		<div className="config-notch-select__button">
																			<span className="config-notch-select__notch"></span>
																			<ImageAsset
																				code={
																					value
																				}
																			/>
																		</div>
																		<div className="config-notch-select__label heading heading--10">
																			{ConfigurationLabelMapper(
																				value
																			)}
																		</div>
																	</label>
																</React.Fragment>
															)
														);
													}
												)}
											</div>
										</div>
									</>
								)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
export default ProductConfigurationWindowSeat;
