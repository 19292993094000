import React from 'react';
import { ProductLocationKeysItem } from './ProductLocationKeysItem';
import { SummaryListMiscItem } from './SummaryListMiscItem';
import { SummaryListOtherItem } from './SummaryListOtherItem';

export const SummaryListView = ({
	misc,
	other,
	productByLocationKeys,
	productsByLocation,
}) => {
	return (
		<>
			{productByLocationKeys.map((locationKey) => (
				<ProductLocationKeysItem
					key={locationKey}
					locationKey={locationKey}
					productsByLocation={productsByLocation}
				/>
			))}
			{misc.length > 0 && <SummaryListMiscItem misc={misc} />}
			{other.length > 0 && <SummaryListOtherItem other={other} />}
		</>
	);
};
