import SmartyStreetsSDK from 'smartystreets-javascript-sdk';

// our main smarty street stuff
const SmartyStreetsCore = SmartyStreetsSDK.core;
const SmartyStreetsAutoCompleteLookup = SmartyStreetsSDK.usAutocomplete.Lookup;
const SmartyStreetsStreetLookup = SmartyStreetsSDK.usStreet.Lookup;

// create the credentials
const key = process.env.REACT_APP_SMARTY_STREETS_WEBSITE_KEY;
const credentials = new SmartyStreetsCore.SharedCredentials(key);

// create the clients
const AutoCompleteClient = SmartyStreetsCore.buildClient.usAutocomplete(credentials);
const StreetClient = SmartyStreetsCore.buildClient.usStreet(credentials);

const autoCompleteAddress = (text) => {
	// create the lookup
	const lookup = new SmartyStreetsAutoCompleteLookup(text);
	// set any parameters
	lookup.maxSuggestions = 10;
	lookup.stateFilter = ['MI', 'OH'];
	// do the search
	return AutoCompleteClient.send(lookup);
};

const streetAddressVerification = (text) => {
	// create the lookup
	const lookup = new SmartyStreetsStreetLookup(text);
	// do the search
	return StreetClient.send(lookup);
};

export { autoCompleteAddress, streetAddressVerification };
