import React from 'react';

import Btn from '../../Btn/Btn';

import { centsToMoney } from '../../../functionality/Money';
import centsToNextDollar from '../../../functionality/cents-to-next-dollar';
import WallsideError from '../../../functionality/WallsideError';

import '../PresentationBody.css';

export const SystemDiscountItem = ({
	activeQuote,
	discount,
	discounts,
	saveQuote,
	toggleSystemNotification,
}) => {
	const discountCents = discount.get('amountInCents');
	const discountCode = discount.get('code');
	const discountDescription = discount.get('description');
	const discountPercent = discount.get('amountInPercent');
	const discountTitle = discount.get('title');
	const discountType = discount.get('type');

	const amount = centsToMoney({
		amount:
			discountType === 'cash'
				? discountCents
				: centsToNextDollar(
						activeQuote.get('totals').totalBeforeDiscountsInCents *
							discountPercent
				  ),
	});

	const isAlreadyApplied =
		discounts.find((entry) => {
			return entry.code === discountCode;
		}) !== undefined;

	const applyDiscount = () => {
		// add this discount to quote discounts
		activeQuote.set(
			'discounts',
			[
				{
					title: discountTitle,
					description: discountDescription,
					type: discountType,
					code: discountCode,
					amountInPercent: discountPercent,
					amountInCents: discountCents,
				},
			].concat(discounts)
		);
		// now resave the quote
		saveQuote().then(
			() => {
				// show the notification
				toggleSystemNotification({
					active: true,
					messageKey: 'save_quote',
				});
			},
			(e) => {
				const error = new WallsideError(e);
				alert(error.globalErrors[0].message);
			}
		);
	};

	if (isAlreadyApplied) {
		return null;
	}

	return (
		<div className="presentationbody__discount">
			<div className="presentationbody__discount-info">
				<p className="presentationbody__discount__strong heading heading--4">
					{discountTitle}
				</p>
				<p className="heading heading--5 heading--inactive">
					{discountDescription}
				</p>
			</div>
			<div className="presentationbody__discount-info">
				<p className="presentationbody__discount__strong heading heading--4">
					{amount}
				</p>
				<p className="heading heading--6">{/*Expires 10/3/19*/}</p>
			</div>
			<Btn
				btnType="btn--primary presentationbody__discount-btn"
				onClick={applyDiscount}
			>
				Apply
			</Btn>
		</div>
	);
};
