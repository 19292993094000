import { useContext } from 'react';
import SessionDataContext from '../../contexts/SessionDataContext';
import { useHistory } from 'react-router-dom';
import ImageAsset from '../ImageAsset/ImageAsset';
import ProductMapper from '../../functionality/ProductMapper.js';
import '../ProductSelectList/ProductSelectList.css';
import URLMapper from '../../functionality/URLMapper';
import { setSelectedCustomProduct } from '../../contexts/store/actions';
import Context from '../../contexts/store/context';

const MultiUnitSelectList = (_props) => {
	// lets setup the session data context
	const { globalDispatch } = useContext(Context);
	const { activeQuote } = useContext(SessionDataContext);

	// set up the history object
	const history = useHistory();

	// let's add the list of products
	const availableProducts = ['BAY_SHELL_PROD', 'BOW_SHELL_PROD'];

	// handle the multi unit selection action
	const handleCustomWindowSelection = async (code) => {
		try {
			// Go to MultiUnitSelectionRoute
			const val = typeof code === 'string' ? code : null;
			await globalDispatch(setSelectedCustomProduct(val));

			history.push(
				URLMapper('addCustomWindow', {
					quoteId: activeQuote.id,
					productCode: 'CUSTOM_WINDOW',
				})
			);
		} catch (e) {
			console.error('Navigation Error:', e);
		}
	};

	// return the component
	return (
		<div className="productselectionlist">
			{availableProducts.map((productCode) => {
				const product = ProductMapper(productCode);
				if (product) {
					return (
						<button
							type="button"
							className="productselectionlist__item"
							key={product.code}
							data-code={product.code}
							onClick={() => handleCustomWindowSelection(product.code)}
						>
							<div className="productselectionlist__item-image">
								<ImageAsset
									className="productselectionlist__item-image-file"
									code={product.code}
								/>
							</div>
							<p className="productselectionlist__item-name">
								{product.title}
							</p>
						</button>
					);
				}
				return '';
			})}
			<button
				type="button"
				className="productselectionlist__item"
				onClick={handleCustomWindowSelection}
			>
				<div className="productselectionlist__item-image">
					<ImageAsset
						className="productselectionlist__item-image-file"
						code="CUSTOM"
					/>
				</div>
				<p className="productselectionlist__item-name">Custom</p>
			</button>
		</div>
	);
};
export default MultiUnitSelectList;
