import React, { useContext } from 'react';
import IconBtn from '../IconBtn/IconBtn';
import ImageAsset from '../ImageAsset/ImageAsset';
import ProductConfigurations from '../ProductConfigurations/ProductConfigurations';
import './ProductConfigurationSideBar.css';
import CurrentQuoteProductContext from '../../contexts/CurrentQuoteProductContext';
import UIManagementContext from '../../contexts/UIManagementContext';

const ProductConfigurationSideBar = (props) => {
	// setup the quote product context values
	const { product, product_notes, product_values } = useContext(
		CurrentQuoteProductContext
	);
	const UIContext = useContext(UIManagementContext);

	// handle the expand photos click
	const handleExpandPhotos = (e) => {
		UIContext.toggleOverlay({
			active: true,
			type: 'expand_photos',
			data: {},
		});
	};

	if (!(product !== false && product_notes !== false && product_values !== false)) {
		return '';
	}

	// return the component
	return (
		<>
			<div className="productconfigurationsidebar">
				<div className="productconfigurationsidebar__image">
					<ImageAsset
						className="productconfigurationsidebar__image-file"
						code={product.code}
					/>
				</div>
				<p className="heading heading--5">{product.title}</p>
				{product.type === 'misc_item' ? (
					''
				) : (
					<IconBtn
						btnType={'iconbtn--primary'}
						iconType={'icon--expand'}
						onClick={handleExpandPhotos}
					/>
				)}
			</div>
			{product.configurationGroups.group1.map((configId) => (
				<ProductConfigurations
					configuration={product.configurations[configId]}
					key={configId}
				/>
			))}
		</>
	);
};
export default ProductConfigurationSideBar;
