import './Input.css';

const Input = (props) => {
	// determine if are using defaultValue or value
	let usingDefault = false;
	switch (props.type) {
		case 'checkbox':
		case 'toggle':
			usingDefault = props.checked === undefined;
			break;
		case 'textarea':
		case 'text':
		case 'password':
		case 'select':
		case 'number':
		default:
			usingDefault = props.value === undefined;
	}

	// handle on focus event
	const handleFocus = (e) => {
		const el = e.target.closest('.input');
		if (el) {
			el.classList.add('input--focus');
		}
		if (props.onFocus) {
			props.onFocus(e);
		}
	};

	// handle blur event
	const handleBlur = (e) => {
		const el = e.target.closest('.input');
		if (el) {
			el.classList.remove('input--focus');
		}
		if (props.onBlur) {
			props.onBlur(e);
		}
	};

	// handle mouse enter
	const handleMouseEnter = (e) => {
		const el = e.target.closest('.input');
		if (el) {
			el.classList.add('input--hover');
		}
	};

	// handle mouse leave
	const handleMouseLeave = (e) => {
		const el = e.target.closest('.input');
		if (el) {
			el.classList.remove('input--hover');
		}
	};

	// return the component
	return (
		<>
			{props.type === 'text' ? (
				<span
					className={`input ${props.className ? props.className : ''} ${
						props.inputModifier ? props.inputModifier : ''
					}`}
				>
					<input
						autoComplete={props.autoComplete ? props.autoComplete : 'off'}
						autoCapitalize={
							props.autoCapitalize ? props.autoCapitalize : 'off'
						}
						type={props.type}
						id={props.id}
						name={props.name}
						placeholder={props.placeholder}
						{...(!usingDefault && { value: props.value })}
						{...(usingDefault && { defaultValue: props.defaultValue })}
						onChange={props.onChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						disabled={props?.disabled}
					/>
				</span>
			) : props.type === 'textarea' ? (
				<span
					className={`input ${props.className ? props.className : ''} ${
						props.inputModifier ? props.inputModifier : ''
					}`}
				>
					<textarea
						id={props.id}
						name={props.name}
						rows={props.rows}
						placeholder={props.placeholder}
						{...(!usingDefault && { value: props.value })}
						{...(usingDefault && { defaultValue: props.defaultValue })}
						onChange={props.onChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					/>
				</span>
			) : props.type === 'password' ? (
				<span
					className={`input ${props.className ? props.className : ''} ${
						props.inputModifier ? props.inputModifier : ''
					}`}
				>
					<input
						type={'password'}
						id={props.id}
						name={props.name}
						placeholder={props.placeholder}
						{...(!usingDefault && { value: props.value })}
						{...(usingDefault && { defaultValue: props.defaultValue })}
						onChange={props.onChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					/>
				</span>
			) : props.type === 'select' ? (
				<span
					className={`input ${props.className ? props.className : ''} ${
						props.inputModifier ? props.inputModifier : ''
					}`}
				>
					<select
						id={props.id}
						name={props.name}
						{...(!usingDefault && { value: props.value })}
						{...(usingDefault && { defaultValue: props.defaultValue })}
						onChange={props.onChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						{props.values.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</span>
			) : props.type === 'checkbox' ? (
				<span
					className={`input input--checkbox ${
						props.className ? props.className : ''
					} ${props.inputModifier ? props.inputModifier : ''}`}
				>
					<input
						type="checkbox"
						id={props.id}
						name={props.name}
						{...(!usingDefault && { checked: props.checked })}
						{...(usingDefault && { defaultChecked: props.defaultChecked })}
						onChange={props.onChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					/>
					<label htmlFor={props.id} className="input__checkbox">
						<span className="icon-far icon icon--check"></span>
						<div className="heading heading--5 input__checkbox-text">
							{props.children}
						</div>
					</label>
				</span>
			) : props.type === 'toggle' ? (
				<span
					className={`input input--toggle ${
						props.className ? props.className : ''
					} ${props.inputModifier ? props.inputModifier : ''}`}
				>
					<input
						type="checkbox"
						id={props.id}
						name={props.name}
						className="input__toggle-checkbox"
						{...(!usingDefault && { checked: props.checked })}
						{...(usingDefault && { defaultChecked: props.defaultChecked })}
						onChange={props.onChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					/>
					<label htmlFor={props.id} className="input__toggle-switch">
						<span className="input__toggle-switch-label">No</span>
						<span className="input__toggle-switch-label">Yes</span>
					</label>
				</span>
			) : props.type === 'number' ? (
				<span
					className={`input ${props.className ? props.className : ''} ${
						props.inputModifier ? props.inputModifier : ''
					}`}
				>
					<input
						type="number"
						step={props.step}
						min={props.min}
						max={props.max}
						id={props.id}
						name={props.name}
						placeholder={props.placeholder}
						{...(!usingDefault && { value: props.value })}
						{...(usingDefault && { defaultValue: props.defaultValue })}
						onChange={props.onChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					/>
				</span>
			) : (
				<span
					className={`input ${props.className ? props.className : ''} ${
						props.inputModifier ? props.inputModifier : ''
					}`}
				>
					<input
						type={props.type}
						id={props.id}
						name={props.name}
						placeholder={props.placeholder}
						{...(!usingDefault && { value: props.value })}
						{...(usingDefault && { defaultValue: props.defaultValue })}
						onChange={props.onChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					/>
				</span>
			)}
		</>
	);
};

export default Input;
