import React from 'react';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';

export const WindowRoof = ({ groupName, product, quoteProduct }) => (
	<>
		{product.configurations[groupName].type && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Type:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].type.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].type &&
			quoteProduct.values[product.configurations[groupName].type.fieldName] !==
				'ATTACHED_TO_OVERHANG' &&
			product.configurations[groupName].material && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Material:</dt>
						<dd className="heading heading--13">
							{ConfigurationLabelMapper(
								quoteProduct.values[
									product.configurations[groupName].material.fieldName
								]
							)}
						</dd>
					</dl>
				</div>
			)}
		{product.configurations[groupName].type &&
			quoteProduct.values[product.configurations[groupName].type.fieldName] !==
				'ATTACHED_TO_OVERHANG' &&
			product.configurations[groupName].materialColor && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Material Color:</dt>
						<dd className="heading heading--13">
							{
								quoteProduct.values[
									product.configurations[groupName].materialColor
										.fieldName
								]
							}
						</dd>
					</dl>
				</div>
			)}
	</>
);
