import React from 'react';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';

export const WindowGlass = ({ groupName, product, quoteProduct }) => (
	<>
		{product.configurations[groupName].type && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Type:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].type.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].temper && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Temper:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].temper.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].obscure && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Obscure:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].obscure.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].tint && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Tint:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].tint.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
	</>
);
