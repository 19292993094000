import React, { useContext, useEffect, useState, useRef } from 'react';
import UIManagementContext from '../../../contexts/UIManagementContext';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import Btn from '../../../components/Btn/Btn';

const OverlayProductNotes = (props) => {
	// get the UIManagement context
	const { toggleOverlay } = useContext(UIManagementContext);

	// the current quote product context
	const { product_notes, setQuoteProductNotes } = useContext(
		CurrentQuoteProductContext
	);

	// we'll use state to track the text input, and the state of overlay
	const [textField, setTextField] = useState(product_notes);
	const [isCancel, setIsCancel] = useState(false);

	// add the effect to focus the input field, and make sure cursor goes to end
	const textInput = useRef(null);
	useEffect(() => {
		setTextField(product_notes + (product_notes !== '' ? '\n\n' : ''));
		textInput.current.focus();
	}, [product_notes]);

	const hideOverlay = () => {
		toggleOverlay({ active: false });
	};

	const handleUpdateClick = () => {
		setQuoteProductNotes(textField);
		hideOverlay();
	};

	const toggleConfirm = () => {
		setIsCancel(!isCancel);
	};

	const handleOnChange = (evt) => {
		setTextField(evt.currentTarget.value);
	};

	const handleSubmit = () => {
		setQuoteProductNotes(textField);
		hideOverlay();
	};

	// return the component
	return (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s1">
						<div className="overlay-s1__heading">
							<h1 className="heading heading--4">Add Product Notes</h1>
						</div>
						{isCancel ? (
							<>
								<div className="overlay-s1__body overlay-s1__body--message">
									<p className="heading heading--5">
										Would you like to update your product notes before
										closing? If you don’t, your changes will be lost.
									</p>
								</div>
								<div className="overlay-s1__btns">
									<Btn btnType={'btn--secondary'} onClick={hideOverlay}>
										Don't Update
									</Btn>
									<Btn
										btnType={'btn--secondary'}
										onClick={toggleConfirm}
									>
										Back
									</Btn>
									<Btn
										btnType={'btn--primary'}
										onClick={handleUpdateClick}
									>
										Update
									</Btn>
								</div>
							</>
						) : (
							<>
								<div className="overlay-s1__body">
									<label
										htmlFor="the_notes"
										className="heading heading--form-element-2"
									>
										Notes
									</label>
									<span className="input input--1 input--white">
										<textarea
											rows="12"
											type="text"
											id="the_notes"
											name="the_notes"
											placeholder="Enter your notes..."
											ref={textInput}
											value={textField}
											onChange={handleOnChange}
										/>
									</span>
								</div>
								<div className="overlay-s1__btns">
									<Btn
										btnType={'btn--secondary'}
										onClick={toggleConfirm}
									>
										Cancel
									</Btn>
									<Btn btnType={'btn--primary'} onClick={handleSubmit}>
										Update Notes
									</Btn>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayProductNotes;
