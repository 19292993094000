import React, { forwardRef, useContext } from 'react';
import moment from 'moment';
import SessionDataContext from '../../contexts/SessionDataContext';
import ProductMapper from '../../functionality/ProductMapper';
import { centsToMoney } from '../../functionality/Money';
import ConfigurationLabelMapper from '../../functionality/ConfigurationLabelMapper';
import { generateDetails } from './GenerateDetails';

import './QuotePricingProductList.css';

const QuotePricingProductList = forwardRef((props, ref) => {
	// lets setup the session data context
	const { activeQuote } = useContext(SessionDataContext);
	const quoteProducts = activeQuote?.get('products');
	const quoteCents = activeQuote?.get('processingFeeInCents');
	const quoteDiscounts = activeQuote?.get('discounts');
	const quoteTotals = activeQuote?.get('totals');

	// Instead of just rendering all the products of an active quote,
	// we'll add the multi-unit products to the list and iterate on through.
	const parseActiveQuote = () => {
		const tempArr = [];
		let multiNum = 0;
		let bayNum = 0;
		let bowNum = 0;
		for (const product of quoteProducts) {
			tempArr.push(product);
			if (product.values.additional_items) {
				if (product.code.includes('BOW_SHELL')) {
					bowNum++;
				} else if (product.code.includes('BAY_SHELL')) {
					bayNum++;
				} else {
					multiNum++;
				}
				for (let item of product.values.additional_items) {
					let prefix = 'Multi-Unit';
					let postfix = multiNum;
					if (product.code.includes('BOW_SHELL')) {
						prefix = 'Bow-Shell';
						postfix = bowNum;
					}
					if (product.code.includes('BAY_SHELL')) {
						prefix = 'Bay-Shell';
						postfix = bayNum;
					}
					item.groupTitle = `${prefix}-${postfix}: `;
					tempArr.push(item);
				}
			}
		}

		return tempArr;
	};

	const list = parseActiveQuote();

	// return the jsx
	return activeQuote !== false ? (
		<div className="quotelistviewlist" ref={ref}>
			{quoteProducts.length > 0 ? (
				<>
					{list.map((quoteProduct, i) => {
						const product = ProductMapper(quoteProduct.code);
						return (
							<div key={i} className="quotelistviewlist__product pagebreak">
								<div className="quotelistviewlist__box">
									<div className="quotelistviewlist__configuration">
										{product.configurations.WINDOW_LOCATION && (
											<>
												{quoteProduct.values[
													product.configurations.WINDOW_LOCATION
														.standard.fieldName
												] !== 'CUSTOM' ? (
													<div className="heading heading--6">
														{ConfigurationLabelMapper(
															quoteProduct.values[
																product.configurations
																	.WINDOW_LOCATION
																	.standard.fieldName
															]
														)}
													</div>
												) : (
													<div className="heading heading--6">
														{
															quoteProduct.values[
																product.configurations
																	.WINDOW_LOCATION
																	.custom.fieldName
															]
														}
													</div>
												)}
											</>
										)}
										{quoteProduct.costBreakdown.sections.BASE && (
											<div className="quotelistviewlist__configuration-price heading heading--13">
												{centsToMoney({
													amount: quoteProduct.costBreakdown
														.sections.BASE.cents,
												})}
											</div>
										)}
										<div className="quotelistviewlist__lineitem">
											<dl>
												<dt className="heading heading--4">
													{quoteProduct.groupTitle
														? quoteProduct.groupTitle
														: ''}
													{product.title}
												</dt>
											</dl>
										</div>
									</div>
									{product.configurationGroups.group1
										.concat(product.configurationGroups.group2)
										.map((groupName, j) => {
											const isNotCustom =
												product?.code !== 'CUSTOM_WINDOW';

											if (isNotCustom) {
												return (
													groupName !== 'WINDOW_LOCATION' && (
														<div
															key={j}
															className="quotelistviewlist__configuration"
														>
															<div className="heading heading--6">
																{
																	product
																		.configurations[
																		groupName
																	].heading
																}
															</div>
															{groupName !==
																'MISC_ITEM' && (
																<>
																	{quoteProduct
																		.costBreakdown
																		.sections[
																		groupName
																	] ? (
																		<div className="quotelistviewlist__configuration-price heading heading--13">
																			{centsToMoney(
																				{
																					amount: quoteProduct
																						.costBreakdown
																						.sections[
																						groupName
																					]
																						.cents,
																				}
																			)}
																		</div>
																	) : (
																		<div className="quotelistviewlist__configuration-price heading heading--13">
																			{centsToMoney(
																				{
																					amount: 0,
																				}
																			)}
																		</div>
																	)}
																</>
															)}
															{generateDetails(
																quoteProduct,
																product,
																groupName
															)}
														</div>
													)
												);
											} else {
												const canRenderRow =
													groupName === 'WINDOW_INSTRUCTIONS' ||
													groupName === 'WINDOW_MULLIONS';
												return (
													canRenderRow && (
														<div
															key={j}
															className="quotelistviewlist__configuration"
														>
															<div className="heading heading--6">
																{
																	product
																		.configurations[
																		groupName
																	].heading
																}
															</div>
															{groupName !==
																'MISC_ITEM' && (
																<>
																	{quoteProduct
																		.costBreakdown
																		.sections[
																		groupName
																	] ? (
																		<div className="quotelistviewlist__configuration-price heading heading--13">
																			{centsToMoney(
																				{
																					amount: quoteProduct
																						.costBreakdown
																						.sections[
																						groupName
																					]
																						.cents,
																				}
																			)}
																		</div>
																	) : (
																		<div className="quotelistviewlist__configuration-price heading heading--13">
																			{centsToMoney(
																				{
																					amount: 0,
																				}
																			)}
																		</div>
																	)}
																</>
															)}
															{generateDetails(
																quoteProduct,
																product,
																groupName
															)}
														</div>
													)
												);
											}
										})}
								</div>
								<div className="quotelistviewlist__product-total">
									<div className="heading heading--6 heading--white">
										Total Product Price:
									</div>
									<div className="heading heading--5 heading--white">
										{centsToMoney({
											amount: quoteProduct.costBreakdown
												.totalInCents,
										})}
									</div>
								</div>
							</div>
						);
					})}
					{quoteCents > 0 && (
						<div className="quotelistviewlist__product">
							<div className="quotelistviewlist__box">
								<div className="quotelistviewlist__configuration">
									<div className="heading heading--6">Fee</div>
									<div className="quotelistviewlist__configuration-price heading heading--13">
										{centsToMoney({
											amount: activeQuote.get(
												'processingFeeInCents'
											),
										})}
									</div>
									<div className="quotelistviewlist__lineitem">
										<dl>
											<dt className="heading heading--4">
												Processing Fee
											</dt>
										</dl>
									</div>
								</div>
							</div>
							<div className="quotelistviewlist__product-total">
								<div className="heading heading--6 heading--white">
									Total Price:
								</div>
								<div className="heading heading--5 heading--white">
									{centsToMoney({
										amount: quoteCents,
									})}
								</div>
							</div>
						</div>
					)}
					<div className="quotelistviewlist__summary">
						<div className="quotelistview__summary-section">
							<div className="quotelistview__summary-item">
								<p className="heading heading--5">
									Price Before Discounts
								</p>
								<p className="heading heading--13">
									{centsToMoney({
										amount: quoteTotals.totalBeforeDiscountsInCents,
									})}
								</p>
							</div>
						</div>
						{quoteDiscounts.length > 0 && (
							<div className="quotelistview__summary-section quotelistview__summary-section--discounts">
								<h4 className="heading heading--6">Discounts</h4>
								{quoteDiscounts.map((discount, i) => {
									return (
										<div
											key={i}
											className="quotelistview__summary-item"
										>
											<p className="heading heading--13">
												{discount.title}
											</p>
											<p className="heading heading--13">
												-
												{centsToMoney({
													amount: discount.cashValueInCents,
												})}
											</p>
										</div>
									);
								})}
							</div>
						)}
						<div className="quotelistview__summary-section quotelistview__summary-section--total">
							<div className="quotelistview__summary-item">
								<div>
									<p className="heading heading--5">Total Price</p>
									<p className="quotelistview__summary-date heading heading--6">
										Quoted{' '}
										{moment(activeQuote.updatedAt).format(
											'MM/DD/YYYY'
										)}
									</p>
								</div>
								<p className="heading quotelistview__summary-total">
									{centsToMoney({
										amount: quoteTotals.totalCashAfterDiscountsInCents,
									})}
								</p>
							</div>
						</div>
					</div>
				</>
			) : (
				<div className="quotelistviewlist__box quotelistviewlist__box--empty">
					<div className="heading heading--4">
						This quote does not have any products.
					</div>
				</div>
			)}
		</div>
	) : (
		''
	);
});

QuotePricingProductList.displayName = 'QuotePricingProductList';

export default QuotePricingProductList;
