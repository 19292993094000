export default {
	// Doorwall, Styles – Section
	SECTIONS_2_DOORWALL: {
		default: {
			description: '',
			url: '/configuration-images/styles/sections-2-doorwall.png',
		},
	},

	SECTIONS_3_DOORWALL: {
		default: {
			description: '',
			url: '/configuration-images/styles/sections-3-doorwall.png',
		},
	},

	// Doorwall, Styles - Operation
	OPERATION_LEFT_TO_RIGHT_DOORWALL: {
		default: {
			description: '',
			url: '/configuration-images/styles/operation-left-to-right-doorwall.png',
		},
	},

	OPERATION_LEFT_TO_RIGHT_DOORWALL_SECTIONS_3: {
		default: {
			description: '',
			url: '/configuration-images/styles/operation-left-to-right-doorwall-3-section.png',
		},
	},

	OPERATION_RIGHT_TO_LEFT_DOORWALL: {
		default: {
			description: '',
			url: '/configuration-images/styles/operation-right-to-left-doorwall.png',
		},
	},

	OPERATION_RIGHT_TO_LEFT_DOORWALL_SECTIONS_3: {
		default: {
			description: '',
			url: '/configuration-images/styles/operation-right-to-left-doorwall-3-section.png',
		},
	},

	// Doorwall, Styles - Vent Position
	VENT_POSITION_LEFT_DOORWALL: {
		default: {
			description: '',
			url: '/configuration-images/styles/vent-position-left-doorwall.png',
		},
	},

	VENT_POSITION_CENTER_DOORWALL: {
		default: {
			description: '',
			url: '/configuration-images/styles/vent-position-center-doorwall.png',
		},
	},

	VENT_POSITION_RIGHT_DOORWALL: {
		default: {
			description: '',
			url: '/configuration-images/styles/vent-position-right-doorwall.png',
		},
	},

	// Hardware Interior Handle Colors
	WHITE_HANDLE: {
		default: {
			description: '',
			url: '/configuration-images/styles/white-handle.png',
		},
	},

	ALMOND_HANDLE: {
		default: {
			description: '',
			url: '/configuration-images/styles/almond-handle.png',
		},
	},

	TERRATONE_HANDLE: {
		default: {
			description: '',
			url: '/configuration-images/styles/terratone-handle.png',
		},
	},

	BRASS_HANDLE: {
		default: {
			description: '',
			url: '/configuration-images/styles/brass-handle.png',
		},
	},

	BRUSHED_CHROME_HANDLE: {
		default: {
			description: '',
			url: '/configuration-images/styles/brushed-chrome-handle.png',
		},
	},

	ANTIQUE_BRASS_HANDLE: {
		default: {
			description: '',
			url: '/configuration-images/styles/antique-brass-handle.png',
		},
	},

	BLACK_NICKEL_HANDLE: {
		default: {
			description: '',
			url: '/configuration-images/styles/black-nickel-handle.png',
		},
	},

	FAUX_OIL_RUBBED_BRONZE_HANDLE: {
		default: {
			description: '',
			url: '/configuration-images/styles/faux-oil-rubbed-bronze-handle.png',
		},
	},

	//  Equal Sections
	EQUAL_SECTIONS: {
		default: {
			description: '',
			url: '/configuration-images/styles/equal-sections.png',
		},
	},

	STANDARD_SECTIONS: {
		default: {
			description: '',
			url: '/configuration-images/styles/standard-sections.png',
		},
	},

	// Number of Windows
	NUMBER_WINDOWS_4: {
		default: {
			description: '',
			url: '/configuration-images/styles/number-windows-4.png',
		},
	},

	NUMBER_WINDOWS_5: {
		default: {
			description: '',
			url: '/configuration-images/styles/number-windows-5.png',
		},
	},

	NUMBER_WINDOWS_6: {
		default: {
			description: '',
			url: '/configuration-images/styles/number-windows-6.png',
		},
	},
};
