import DOUBLE_HUNG from './product-data/DOUBLE_HUNG.js';
import DOUBLE_SLIDER from './product-data/DOUBLE_SLIDER.js';
import SINGLE_CASEMENT from './product-data/SINGLE_CASEMENT.js';
import DOUBLE_CASEMENT from './product-data/DOUBLE_CASEMENT.js';
import AWNING_CASEMENT from './product-data/AWNING_CASEMENT.js';
import CENTER_VENT_SLIDER from './product-data/CENTER_VENT_SLIDER.js';
import END_VENT_SLIDER from './product-data/END_VENT_SLIDER.js';
import PICTURE from './product-data/PICTURE.js';
import PICTURE_CASEMENT from './product-data/PICTURE_CASEMENT.js';
import SPECIALTY from './product-data/SPECIALTY.js';
import HOPPER from './product-data/HOPPER.js';
import GARDEN from './product-data/GARDEN.js';
import DOOR_WALL from './product-data/DOOR_WALL.js';
import BAY_SHELL from './product-data/BAY_SHELL.js';
import BOW_SHELL from './product-data/BOW_SHELL.js';
import MISC_ITEM from './product-data/MISC_ITEM.js';
import CUSTOM_WINDOW from './product-data/CUSTOM_WINDOW.js';
import DOOR_WALL_CUSTOM from './product-data/DOOR_WALL_CUSTOM.js';

import CH_CATHEDRAL from './product-data/CH_CATHEDRAL.js';
import CHX_CATHEDRAL_WITH_EXTENDED_LEGS from './product-data/CHX_CATHEDRAL_WITH_EXTENDED_LEGS.js';
import CT_CIRCLE_TOP from './product-data/CT_CIRCLE_TOP.js';
import CTX_CIRCLE_TOP_EXTENDED_LEGS from './product-data/CTX_CIRCLE_TOP_EXTENDED_LEGS.js';
import TZL_TZR_TRAPEZOID_RIGHT from './product-data/TZL_TZR_TRAPEZOID_RIGHT.js';
import TZL_TZR_TRAPEZOID_LEFT from './product-data/TZL_TZR_TRAPEZOID_LEFT.js';
import TRL_OR_TRR_TRIANGLE_RIGHT from './product-data/TRL_OR_TRR_TRIANGLE_RIGHT.js';
import TRL_OR_TRR_TRIANGLE_LEFT from './product-data/TRL_OR_TRR_TRIANGLE_LEFT.js';
import TRI_TRIANGLE_ISOSCELES from './product-data/TRI_TRIANGLE_ISOSCELES.js';
import TRE_TRIANGLE_EQUILATERAL from './product-data/TRE_TRIANGLE_EQUILATERAL.js';
import RT_RECTANGLE from './product-data/RT_RECTANGLE.js';
import QAL_QAR_QUARTER_ARCH_RIGHT from './product-data/QAL_QAR_QUARTER_ARCH_RIGHT.js';
import QAL_QAR_QUARTER_ARCH_RIGHT_1 from './product-data/QAL_QAR_QUARTER_ARCH_RIGHT_1.js';
import QAL_QAR_QUARTER_ARCH_LEFT from './product-data/QAL_QAR_QUARTER_ARCH_LEFT.js';
import QAL_QAR_QUARTER_ARCH_LEFT_1 from './product-data/QAL_QAR_QUARTER_ARCH_LEFT_1.js';
import PT_PENTAGON from './product-data/PT_PENTAGON.js';
import PTL_PTR_PENTAGON_RIGHT from './product-data/PTL_PTR_PENTAGON_RIGHT.js';
import PTL_PTR_PENTAGON_LEFT from './product-data/PTL_PTR_PENTAGON_LEFT.js';
import PTE_PENTAGON_EQUILATERAL from './product-data/PTE_PENTAGON_EQUILATERAL.js';
import OV_OVAL from './product-data/OV_OVAL.js';
import OT_OCTAGON from './product-data/OT_OCTAGON.js';
import OTX_OCTAGON_WITH_EXTENDED_LEGS from './product-data/OTX_OCTAGON_WITH_EXTENDED_LEGS.js';
import HX_HEXAGON from './product-data/HX_HEXAGON.js';
import HXX_HEXAGON_WITH_EXTENDED_LEGS from './product-data/HXX_HEXAGON_WITH_EXTENDED_LEGS.js';
import HXL_HXR_HALF_EYEBROW_RIGHT from './product-data/HXL_HXR_HALF_EYEBROW_RIGHT.js';
import HXL_HXR_HALF_EYEBROW_LEFT from './product-data/HXL_HXR_HALF_EYEBROW_LEFT.js';
import HBL_BR_HALF_EYEBROW_RIGHT from './product-data/HBL_BR_HALF_EYEBROW_RIGHT.js';
import HBL_BR_HALF_EYEBROW_LEFT from './product-data/HBL_BR_HALF_EYEBROW_LEFT.js';
import FC_FULL_CIRCLE from './product-data/FC_FULL_CIRCLE.js';
import EP_ELLIPSE from './product-data/EP_ELLIPSE.js';
import EB_EYEBROW from './product-data/EB_EYEBROW.js';
import EBX_EYEBROW_WITH_EXTENDED_LEGS from './product-data/EBX_EYEBROW_WITH_EXTENDED_LEGS.js';
import DCC_DOUBLE_CLIPPED_CORDER from './product-data/DCC_DOUBLE_CLIPPED_CORNER';

const productArray = [
	DOUBLE_HUNG,
	DOUBLE_SLIDER,
	SINGLE_CASEMENT,
	DOUBLE_CASEMENT,
	AWNING_CASEMENT,
	CENTER_VENT_SLIDER,
	END_VENT_SLIDER,
	PICTURE,
	PICTURE_CASEMENT,
	SPECIALTY,
	HOPPER,
	GARDEN,
	DOOR_WALL,
	BAY_SHELL,
	BOW_SHELL,
	MISC_ITEM,
	CUSTOM_WINDOW,
	DOOR_WALL_CUSTOM,
	CH_CATHEDRAL,
	CHX_CATHEDRAL_WITH_EXTENDED_LEGS,
	CT_CIRCLE_TOP,
	CTX_CIRCLE_TOP_EXTENDED_LEGS,
	TZL_TZR_TRAPEZOID_RIGHT,
	TZL_TZR_TRAPEZOID_LEFT,
	TRL_OR_TRR_TRIANGLE_RIGHT,
	TRL_OR_TRR_TRIANGLE_LEFT,
	TRI_TRIANGLE_ISOSCELES,
	TRE_TRIANGLE_EQUILATERAL,
	RT_RECTANGLE,
	QAL_QAR_QUARTER_ARCH_RIGHT,
	QAL_QAR_QUARTER_ARCH_RIGHT_1,
	QAL_QAR_QUARTER_ARCH_LEFT,
	QAL_QAR_QUARTER_ARCH_LEFT_1,
	PT_PENTAGON,
	PTL_PTR_PENTAGON_RIGHT,
	PTL_PTR_PENTAGON_LEFT,
	PTE_PENTAGON_EQUILATERAL,
	OV_OVAL,
	OT_OCTAGON,
	OTX_OCTAGON_WITH_EXTENDED_LEGS,
	HX_HEXAGON,
	HXX_HEXAGON_WITH_EXTENDED_LEGS,
	HXL_HXR_HALF_EYEBROW_RIGHT,
	HXL_HXR_HALF_EYEBROW_LEFT,
	HBL_BR_HALF_EYEBROW_RIGHT,
	HBL_BR_HALF_EYEBROW_LEFT,
	FC_FULL_CIRCLE,
	EP_ELLIPSE,
	EB_EYEBROW,
	EBX_EYEBROW_WITH_EXTENDED_LEGS,
	DCC_DOUBLE_CLIPPED_CORDER,
];
const productMap = {};

productArray.forEach(function (product) {
	productMap[product.code] = product;
});

export default function (c) {
	// set our code
	const code = c ? c : '_';
	return productMap[code] ? productMap[code] : false;
}
