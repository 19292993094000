import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import SessionDataContext from '../../contexts/SessionDataContext';

const PublicOnlyRoute = ({ component, ...props }) => {
	const { activeUser } = useContext(SessionDataContext);
	const Component = component;

	// return the comonent
	return (
		<Route
			{...props}
			render={(componentProps) => (
				<>
					{activeUser ? (
						<Redirect to={'/dashboard'} />
					) : (
						<Component {...componentProps} />
					)}
				</>
			)}
		/>
	);
};

export default PublicOnlyRoute;
