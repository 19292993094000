import React from 'react';

import { PaymentCashOption } from './components/PaymentCashOption';
import { PaymentFinanceOption } from './components/PaymentFinanceOption';

export const PresentationBodyPaymentOptions = ({
	activeQuote,
	paymentMappings,
	paymentOption,
	setPaymentOption,
	toggleOverlay,
	centsToMoney,
	financeTermMonths,
	setFinanceTermMonths,
	discounts,
}) => {
	return (
		<div className="presentationbody__payment-options">
			<h2 className="heading heading--3">Payment Options</h2>
			<div className="presentationbody__payment">
				<PaymentFinanceOption
					activeQuote={activeQuote}
					centsToMoney={centsToMoney}
					financeTermMonths={financeTermMonths}
					setFinanceTermMonths={setFinanceTermMonths}
					paymentMappings={paymentMappings}
					paymentOption={paymentOption}
					setPaymentOption={setPaymentOption}
					discounts={discounts}
				/>
				<PaymentCashOption
					activeQuote={activeQuote}
					paymentOption={paymentOption}
					setPaymentOption={setPaymentOption}
					toggleOverlay={toggleOverlay}
					centsToMoney={centsToMoney}
				/>
			</div>
		</div>
	);
};
