import React, { useContext, useEffect } from 'react';
import IconBtn from '../../IconBtn/IconBtn';
import Input from '../../Input/Input';
import ImageAsset from '../../ImageAsset/ImageAsset';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import UIManagementContext from '../../../contexts/UIManagementContext';

const ProductConfigurationWindowStyle = (props) => {
	// the ui management context
	const { openConfigurationData, toggleOpenConfiguration } =
		useContext(UIManagementContext);

	// product context
	const {
		product,
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
	} = useContext(CurrentQuoteProductContext);

	// the selected values
	const selectedShapeValue = props.configuration.shape
		? getQuoteProductContextProductValue(props.configuration.shape.fieldName)
		: false;
	const selectedSectionsValue = props.configuration.sections
		? getQuoteProductContextProductValue(props.configuration.sections.fieldName)
		: false;
	const selectedSectionsOption = props.configuration.sections
		? props.configuration.sections.values.find((option) => {
				return option.value === selectedSectionsValue;
		  })
		: false;
	const selectedOperationValue = props.configuration.operation
		? getQuoteProductContextProductValue(props.configuration.operation.fieldName)
		: false;
	const selectedVentPositionValue = props.configuration.ventPosition
		? getQuoteProductContextProductValue(props.configuration.ventPosition.fieldName)
		: false;
	const selectedStyleValue = props.configuration.style
		? getQuoteProductContextProductValue(props.configuration.style.fieldName)
		: false;
	const selectedNumberOfWindowsValue = props.configuration.numberOfWindows
		? getQuoteProductContextProductValue(
				props.configuration.numberOfWindows.fieldName
		  )
		: false;
	const selectedMaterialValue = props.configuration.material
		? getQuoteProductContextProductValue(props.configuration.material.fieldName)
		: false;

	// we have a few overrides baased on specific product logic
	const windowWidth = getQuoteProductContextProductValue(
		product.configurations.WINDOW_SIZE.fieldNameWidth
	);
	const overrideOakOnly =
		(product.code === 'BAY_SHELL_PROD' || product.code === 'BOW_SHELL_PROD') &&
		windowWidth >= 120 &&
		windowWidth <= 144;

	const overrideBirchOnly =
		(product.code === 'BAY_SHELL_PROD' || product.code === 'BOW_SHELL_PROD') &&
		windowWidth > 144;

	// disclaimer messages
	const showDoorwallOperationDisclaimer =
		product.code === 'DOOR_WALL_PROD' || product.code === 'DOOR_WALL_CUSTOM';

	// create the section details
	const createSectionHeadingDetails = () => {
		return (
			(selectedShapeValue !== false
				? ConfigurationLabelMapper(selectedShapeValue)
				: '') +
			(selectedMaterialValue !== false
				? ConfigurationLabelMapper(selectedMaterialValue)
				: '') +
			(selectedSectionsValue !== false
				? ConfigurationLabelMapper(selectedSectionsValue) + '-Section'
				: '') +
			(selectedNumberOfWindowsValue !== false
				? ' | ' +
				  ConfigurationLabelMapper(selectedNumberOfWindowsValue) +
				  ' Windows'
				: '') +
			(selectedStyleValue !== false
				? ' | ' + ConfigurationLabelMapper(selectedStyleValue)
				: '') +
			(selectedVentPositionValue !== false
				? ' | Vent: ' + ConfigurationLabelMapper(selectedVentPositionValue)
				: '') +
			(selectedOperationValue !== false
				? ' | Operation: ' + ConfigurationLabelMapper(selectedOperationValue)
				: '')
		);
	};

	// we need to check defaults as they switch
	useEffect(() => {
		if (
			props.configuration.shape &&
			selectedShapeValue !== '' &&
			props.configuration.shape.values.indexOf(selectedShapeValue) === -1
		) {
			setQuoteProductContextProductValue(
				props.configuration.shape.fieldName,
				props.configuration.shape.defaultValue
			);
		}
		if (props.configuration.sections) {
			if (props.configuration.ventPosition) {
				if (
					selectedSectionsOption.ventPositionOptions === false &&
					selectedVentPositionValue !== false
				) {
					setQuoteProductContextProductValue(
						props.configuration.ventPosition.fieldName,
						false
					);
				} else if (
					selectedSectionsOption.ventPositionOptions !== false &&
					selectedSectionsOption.ventPositionOptions.indexOf(
						selectedVentPositionValue
					) === -1
				) {
					setQuoteProductContextProductValue(
						props.configuration.ventPosition.fieldName,
						selectedSectionsOption.ventPositionDefault
					);
				}
			}
			if (props.configuration.operation) {
				if (
					(selectedSectionsValue === 'SECTIONS_2_DOORWALL' ||
						(selectedSectionsValue === 'SECTIONS_3_DOORWALL' &&
							selectedVentPositionValue ===
								'VENT_POSITION_CENTER_DOORWALL')) &&
					selectedSectionsOption.operationOptions.indexOf(
						selectedOperationValue
					) === -1
				) {
					setQuoteProductContextProductValue(
						props.configuration.operation.fieldName,
						selectedSectionsOption.operationDefault
					);
				} else if (
					!(selectedSectionsValue === 'SECTIONS_2_DOORWALL') &&
					!(
						selectedSectionsValue === 'SECTIONS_3_DOORWALL' &&
						selectedVentPositionValue === 'VENT_POSITION_CENTER_DOORWALL'
					) &&
					selectedOperationValue !== false
				) {
					setQuoteProductContextProductValue(
						props.configuration.operation.fieldName,
						false
					);
				}
			}
		}
	}, [
		props.configuration.shape,
		selectedShapeValue,
		selectedSectionsOption,
		selectedSectionsValue,
		selectedVentPositionValue,
		selectedOperationValue,
		props.configuration.ventPosition,
		props.configuration.operation,
		props.configuration.sections,
		setQuoteProductContextProductValue,
	]);

	// let's use an effect for the material changes
	useEffect(() => {
		if (props.configuration.material) {
			// we have do deal with these speciaal case overrides for the bay shell
			if (overrideBirchOnly && selectedMaterialValue !== 'BIRCH_PINE_MATERIAL') {
				setQuoteProductContextProductValue(
					props.configuration.material.fieldName,
					'BIRCH_PINE_MATERIAL'
				);
			} else if (overrideOakOnly && selectedMaterialValue !== 'OAK_MATERIAL') {
				setQuoteProductContextProductValue(
					props.configuration.material.fieldName,
					'OAK_MATERIAL'
				);
			}

			// for bay and bow shell, we want to default the casing values
			if (product.code === 'BOW_SHELL_PROD' || product.code === 'BAY_SHELL_PROD') {
				if (product.configurations.WINDOW_INSTRUCTIONS) {
					const partsGroup =
						product.configurations.WINDOW_INSTRUCTIONS.groups.find(
							(group) => {
								return group.groupId === 'PARTS';
							}
						);
					if (partsGroup) {
						const casingOption = partsGroup.options.find((option) => {
							return option.optionId === 'CASING';
						});
						if (casingOption) {
							const currentCasingValue = getQuoteProductContextProductValue(
								casingOption.fieldName
							);
							if (
								selectedMaterialValue === 'BIRCH_PINE_MATERIAL' &&
								currentCasingValue !== 'PINE'
							) {
								setQuoteProductContextProductValue(
									casingOption.fieldName,
									'PINE'
								);
							} else if (
								selectedMaterialValue === 'OAK_MATERIAL' &&
								currentCasingValue !== 'OAK'
							) {
								setQuoteProductContextProductValue(
									casingOption.fieldName,
									'OAK'
								);
							}
						}
					}
				}
			}
		}
	}, [
		props.configuration.material,
		selectedMaterialValue,
		overrideBirchOnly,
		overrideOakOnly,
		product,
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
	]);

	// return the component
	return (
		<div className="productconfiguration">
			<div
				className={`productconfiguration__section ${
					openConfigurationData() === props.orderIndex
						? 'productconfiguration__section--open'
						: ''
				}`}
			>
				<button
					type="button"
					className="productconfiguration__section-header"
					onClick={() => {
						toggleOpenConfiguration(props.orderIndex);
					}}
				>
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{props.orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">
							{props.configuration.heading}
						</div>
						<div className="heading heading--7">
							{createSectionHeadingDetails()}
						</div>
					</div>
					<div className="productconfiguration__section-toggle">
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--primary'}
							iconType={'icon--plus'}
						/>
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--secondary'}
							iconType={'icon--minus'}
						/>
					</div>
				</button>
				<div className="productconfiguration__section-body">
					{props.configuration.shape ? (
						<>
							<div className="config-heading heading heading--4">
								{props.configuration.shape.heading}
							</div>
							<Input
								type={'select'}
								className={'input--1'}
								inputModifier={'input--select input--autowidth'}
								id={props.configuration.shape.fieldName}
								name={props.configuration.shape.fieldName}
								values={[{ label: 'Select ...', value: '' }].concat(
									props.configuration.shape.values.map(function (
										value
									) {
										return {
											label: ConfigurationLabelMapper(value),
											value: value,
										};
									})
								)}
								value={selectedShapeValue}
								onChange={(e) => {
									setQuoteProductContextProductValue(
										e.target.name,
										e.target.value
									);
								}}
							/>
						</>
					) : (
						''
					)}
					{props.configuration.sections ? (
						<>
							{props.configuration.shape ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="config-heading heading heading--4">
								{props.configuration.sections.heading}
							</div>
							<div className="config-circle-select">
								{props.configuration.sections.values.map((option) => {
									return (
										<React.Fragment key={option.value}>
											<input
												type="radio"
												name={
													props.configuration.sections.fieldName
												}
												id={`${props.configuration.sections.fieldName}_${option.value}`}
												value={option.value}
												checked={
													option.value === selectedSectionsValue
												}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${props.configuration.sections.fieldName}_${option.value}`}
												className="config-circle-select__option"
											>
												<div className="config-circle-select__circle">
													<ImageAsset code={option.value} />
												</div>
												<div className="config-circle-select__label heading heading--5">
													{ConfigurationLabelMapper(
														option.value
													)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
						</>
					) : (
						''
					)}
					{props.configuration.ventPosition &&
					selectedSectionsOption.ventPositionOptions !== false ? (
						<>
							{props.configuration.shape ||
							props.configuration.sections ||
							(props.configuration.operation &&
								selectedSectionsOption.operationOptions !== false) ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="config-heading heading heading--4">
								{props.configuration.ventPosition.heading}
							</div>
							<div className="config-circle-select">
								{props.configuration.ventPosition.values.map((value) => {
									const isDisabled =
										selectedSectionsOption.ventPositionOptions.indexOf(
											value
										) === -1;
									return (
										<React.Fragment key={value}>
											<input
												type="radio"
												name={
													props.configuration.ventPosition
														.fieldName
												}
												id={`${props.configuration.ventPosition.fieldName}_${value}`}
												value={value}
												{...(isDisabled && { disabled: true })}
												checked={
													value === selectedVentPositionValue
												}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${props.configuration.ventPosition.fieldName}_${value}`}
												className={`config-circle-select__option ${
													isDisabled
														? 'config-circle-select__option--inactive'
														: ''
												}`}
											>
												<div className="config-circle-select__circle">
													<ImageAsset code={value} />
												</div>
												<div className="config-circle-select__label heading heading--5">
													{ConfigurationLabelMapper(value)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
						</>
					) : (
						''
					)}
					{props.configuration.operation &&
					(selectedSectionsValue === 'SECTIONS_2_DOORWALL' ||
						(selectedSectionsValue === 'SECTIONS_3_DOORWALL' &&
							selectedVentPositionValue ===
								'VENT_POSITION_CENTER_DOORWALL')) &&
					selectedSectionsOption.operationOptions !== false ? (
						<>
							{props.configuration.shape || props.configuration.sections ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="config-heading heading heading--4">
								{props.configuration.operation.heading}
							</div>
							<div className="config-circle-select">
								{props.configuration.operation.values.map((value) => {
									return selectedSectionsOption.operationOptions.indexOf(
										value
									) !== -1 ? (
										<React.Fragment key={value}>
											<input
												type="radio"
												name={
													props.configuration.operation
														.fieldName
												}
												id={`${props.configuration.operation.fieldName}_${value}`}
												value={value}
												checked={value === selectedOperationValue}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${props.configuration.operation.fieldName}_${value}`}
												className="config-circle-select__option"
											>
												<div className="config-circle-select__circle">
													<ImageAsset code={value} />
												</div>
												<div className="config-circle-select__label heading heading--5">
													{ConfigurationLabelMapper(value)}
												</div>
											</label>
										</React.Fragment>
									) : (
										''
									);
								})}
							</div>
							{showDoorwallOperationDisclaimer && (
								<p className="productconfiguration__disclaimer">
									Images show doorwall from the outside.
								</p>
							)}
						</>
					) : (
						''
					)}

					{props.configuration.style ? (
						<>
							{props.configuration.shape ||
							props.configuration.sections ||
							props.configuration.operation ||
							props.configuration.ventPosition ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="config-heading heading heading--4">
								{props.configuration.style.heading}
							</div>
							<div className="config-circle-select">
								{props.configuration.style.values.map((value) => {
									return (
										<React.Fragment key={value}>
											<input
												type="radio"
												name={props.configuration.style.fieldName}
												id={`${props.configuration.style.fieldName}_${value}`}
												value={value}
												checked={value === selectedStyleValue}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${props.configuration.style.fieldName}_${value}`}
												className="config-circle-select__option"
											>
												<div className="config-circle-select__circle">
													<ImageAsset code={value} />
												</div>
												<div className="config-circle-select__label heading heading--5">
													{ConfigurationLabelMapper(value)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
						</>
					) : (
						''
					)}
					{props.configuration.numberOfWindows ? (
						<>
							{props.configuration.shape ||
							props.configuration.sections ||
							props.configuration.operation ||
							props.configuration.ventPosition ||
							props.configuration.style ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="config-heading heading heading--4">
								{props.configuration.numberOfWindows.heading}
							</div>
							<div className="config-circle-select">
								{props.configuration.numberOfWindows.values.map(
									(value) => {
										return (
											<React.Fragment key={value}>
												<input
													type="radio"
													name={
														props.configuration
															.numberOfWindows.fieldName
													}
													id={`${props.configuration.numberOfWindows.fieldName}_${value}`}
													value={value}
													checked={
														value ===
														selectedNumberOfWindowsValue
													}
													onChange={(e) => {
														setQuoteProductContextProductValue(
															e.target.name,
															e.target.value
														);
													}}
												/>
												<label
													htmlFor={`${props.configuration.numberOfWindows.fieldName}_${value}`}
													className="config-circle-select__option"
												>
													<div className="config-circle-select__circle">
														<ImageAsset code={value} />
													</div>
													<div className="config-circle-select__label heading heading--5">
														{ConfigurationLabelMapper(value)}
													</div>
												</label>
											</React.Fragment>
										);
									}
								)}
							</div>
						</>
					) : (
						''
					)}
					{props.configuration.material ? (
						<>
							{props.configuration.shape ||
							props.configuration.sections ||
							props.configuration.operation ||
							props.configuration.ventPosition ||
							props.configuration.style ||
							props.configuration.numberOfWindows ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="config-heading heading heading--4">
								{props.configuration.material.heading}
							</div>
							<div className="config-option-select">
								{props.configuration.material.values.map((value) => {
									const isDisabled =
										(overrideBirchOnly &&
											value !== 'BIRCH_PINE_MATERIAL') ||
										(overrideOakOnly && value !== 'OAK_MATERIAL');
									return (
										<React.Fragment key={value}>
											<input
												type="radio"
												name={
													props.configuration.material.fieldName
												}
												id={`${props.configuration.material.fieldName}_${value}`}
												value={value}
												{...(isDisabled && { disabled: true })}
												checked={value === selectedMaterialValue}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${props.configuration.material.fieldName}_${value}`}
												className={`config-option-select__option ${
													isDisabled
														? 'config-option-select__option--inactive'
														: ''
												}`}
											>
												<div className="config-option-select__check"></div>
												<div className="config-option-select__info">
													{ConfigurationLabelMapper(value)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
						</>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
};
export default ProductConfigurationWindowStyle;
