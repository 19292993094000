import {
	OPEN_MODAL,
	CLOSE_MODAL,
	UPDATE_FORM_INPUTS_OBJECT,
	DROPPED_WINDOW_LOCATION,
	SELECT_COLUMN,
	RESET_SELECTED_COLUMNS,
	UPDATE_WINDOW_DIMENSION,
	ADD_CUSTOM_WINDOW_PRODUCT,
	UPDATE_CUSTOM_WINDOW_GENERAL_SPECIFICATIONS,
	SET_SELECTED_CUSTOM_PRODUCT,
} from './constants';

// Modal
export const openModalAction = (payload) => {
	return {
		type: OPEN_MODAL,
		payload,
	};
};
export const closeModalAction = () => {
	return {
		type: CLOSE_MODAL,
	};
};
export const updateFormInputsObjectAction = (payload) => {
	return {
		type: UPDATE_FORM_INPUTS_OBJECT,
		payload,
	};
};
export const droppedWindowLocationAction = (payload) => {
	return {
		type: DROPPED_WINDOW_LOCATION,
		payload,
	};
};
export const selectColumnAction = (payload) => {
	return {
		type: SELECT_COLUMN,
		payload,
	};
};
export const resetSelectedColumnsAction = () => {
	return {
		type: RESET_SELECTED_COLUMNS,
	};
};

export const updateWindowDimensionAction = (payload) => {
	return {
		type: UPDATE_WINDOW_DIMENSION,
		payload,
	};
};

export const addCustomWindowProductAction = (payload) => {
	return {
		type: ADD_CUSTOM_WINDOW_PRODUCT,
		payload,
	};
};

export const updateCustomWindowGeneralSpecifiAction = (payload) => {
	return {
		type: UPDATE_CUSTOM_WINDOW_GENERAL_SPECIFICATIONS,
		payload,
	};
};

export const setSelectedCustomProduct = (payload) => {
	return {
		type: SET_SELECTED_CUSTOM_PRODUCT,
		payload,
	};
};
