import React from 'react';
import './Layout0.css';

const Layout0 = (props) => {
	return (
		<main className="layout0">
			<div className="layout0__navbar">{props.navbar ? <props.navbar /> : ''}</div>
			<div className="layout0__main">
				<div className="layout0__content">{props.children}</div>
			</div>
		</main>
	);
};

export default Layout0;
