import React from 'react';
import './Layout1.css';

const Layout1 = (props) => {
	return (
		<div className={`layout1 ${props.mainClass ? props.mainClass : ''}`}>
			<section className="layout1__navbar">
				{props.navbar ? props.navbar : ''}
			</section>
			<section className="layout1__main">
				<div className="layout1__sidebar">
					{props.sideBarChildren ? props.sideBarChildren : ''}
				</div>
				<div className="layout1__content">
					{props.children ? props.children : ''}
				</div>
			</section>
		</div>
	);
};

export default Layout1;
