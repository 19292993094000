import React, { Fragment } from 'react';

import ImageAsset from '../../../ImageAsset/ImageAsset';

import ConfigurationLabelMapper from '../../../../functionality/ConfigurationLabelMapper';

const ExteriorColorsInput = ({
	configuration,
	isDisabled,
	selectedExteriorColorValue,
	setQuoteProductContextProductValue,
	value,
}) => {
	return (
		<Fragment>
			<input
				type="radio"
				name={configuration.exteriorColor.fieldName}
				id={`${configuration.exteriorColor.fieldName}_${value}`}
				value={value}
				disabled={isDisabled}
				checked={value === selectedExteriorColorValue}
				onChange={(e) => {
					setQuoteProductContextProductValue(e.target.name, e.target.value);
				}}
			/>
			<label
				htmlFor={`${configuration.exteriorColor.fieldName}_${value}`}
				className={`config-notch-select__option ${
					isDisabled ? 'config-notch-select__option--inactive' : ''
				}`}
			>
				<div className="config-notch-select__button">
					<span className="config-notch-select__notch"></span>
					<ImageAsset code={value} />
				</div>
				<div className="config-notch-select__label heading heading--10">
					{ConfigurationLabelMapper(value)}
				</div>
			</label>
		</Fragment>
	);
};

export default ExteriorColorsInput;
