import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

// we'll be using our api, so we'll import parse
const Parse = require('parse');

// the basic context
const SessionData = React.createContext();
export default SessionData;

export const useSessionDataContext = () => {
	return useContext(SessionData);
};

export class SessionDataProvider extends React.Component {
	state = Object.assign(
		{},
		{
			activityIndicator: 0,
			activeUser: false,
			activeQuote: false,
			activeAddressQuotes: false,
			lastQuoteConfiguration: false,
			customProduct: 'CUSTOM_WINDOW',
		}
	);

	// set activity
	setActivityIndicator = (state) => {
		this.setState((prev) => {
			return { activityIndicator: prev.activityIndicator + (state ? 1 : -1) };
		});
	};

	// set user
	setUser = (user) => {
		return new Promise((resolve, reject) => {
			this.setState(
				{
					activeUser: user,
				},
				() => {
					resolve();
				}
			);
		});
	};

	// set quote
	setQuote = (quote) => {
		return new Promise((resolve, reject) => {
			this.setState(
				{
					activeQuote: quote,
				},
				() => {
					resolve();
				}
			);
		});
	};

	// set the address quotes
	setAddressQuotes = (quotes) => {
		return new Promise((resolve, reject) => {
			this.setState(
				{
					activeAddressQuotes: quotes,
				},
				() => {
					resolve();
				}
			);
		});
	};

	// load address quotes
	loadAddressQuotes = (address) => {
		return new Parse.Query('Quote')
			.equalTo('address', address)
			.limit(100)
			.find()
			.then((results) => {
				return new Promise((resolve, reject) => {
					this.setState(
						{
							activeAddressQuotes: results,
						},
						() => {
							resolve();
						}
					);
				});
			});
	};

	// loads a quote
	loadQuote = (quoteId, withAddressQuotes) => {
		const quote = new Parse.Object('Quote', {
			id: quoteId,
		});
		this.setActivityIndicator(true);
		return quote
			.fetch({ include: 'address' })
			.then(() => {
				return Promise.all([
					// set the quote
					this.setQuote(quote),
					// set the address quotes
					withAddressQuotes
						? new Parse.Query('Quote')
								.equalTo('address', quote.get('address'))
								.ascending('uniqueTitle')
								.find()
								.then((results) => {
									return this.setAddressQuotes(results);
								})
						: Promise.resolve(),
				]);
			})
			.then(
				() => {
					this.setActivityIndicator(false);
					return Promise.resolve();
				},
				(e) => {
					this.setActivityIndicator(false);
					return Promise.reject(e);
				}
			);
	};

	// save the quote
	saveQuote = () => {
		try {
			// save the quote
			this.setActivityIndicator(true);
			return this.state.activeQuote
				.save()
				.then((quote) => {
					return this.setQuote(quote);
				})
				.then(
					(res) => {
						this.setActivityIndicator(false);
						return Promise.resolve(res);
					},
					(e) => {
						console.error('Save Quote Error:', e);
						this.setActivityIndicator(false);
						return Promise.reject(e);
					}
				);
		} catch (error) {
			console.error('Save Quote Catch:', error);
			return Promise.reject(error);
		}
	};

	// deletes the quote
	deleteQuote = () => {
		this.setActivityIndicator(true);
		return this.state.activeQuote
			.destroy()
			.then(() => {
				return this.setQuote(false);
			})
			.then(
				() => {
					this.setActivityIndicator(false);
					return Promise.resolve();
				},
				(e) => {
					this.setActivityIndicator(false);
					return Promise.reject(e);
				}
			);
	};

	// creates a quote
	createQuote = (title, address) => {
		const quote = new Parse.Object('Quote', {
			title: title || 'Quote 1',
			address: address,
			priceMarkupPercentage: this.state.activeUser.get('priceMarkupPercentage'),
			productPriceMarkupCents: this.state.activeUser.get('productPriceMarkupCents'),
		});
		this.setActivityIndicator(true);
		return quote.save().then(
			(quote) => {
				this.setActivityIndicator(false);
				return Promise.resolve(quote);
			},
			(e) => {
				this.setActivityIndicator(false);
				return Promise.reject(e);
			}
		);
	};

	// duplicate quote
	duplicateQuote = (existingQuote) => {
		const quote = new Parse.Object('Quote', {
			status: 'open',
			title: existingQuote.get('title') + ' (COPY)',
			address: existingQuote.get('address'),
			products: existingQuote.get('products'),
			priceMarkupPercentage: this.state.activeUser.get('priceMarkupPercentage'),
			productPriceMarkupCents: this.state.activeUser.get('productPriceMarkupCents'),
		});
		return quote.save();
	};

	// update a single product in the quote
	saveQuoteProduct = (data) => {
		const products = [].concat(this.state.activeQuote.get('products'));
		const productData = {
			...{
				id: '-1',
				code: '',
				type: '',
				notes: '',
				values: {},
			},
			...data,
		};

		if (productData.id === '-1') {
			// this is a new product, so create a unique id
			productData.id = uuidv4();
			// add this to the products
			products.push(productData);
			this.setState({ lastQuoteConfiguration: data });
		} else {
			// this is an update, so we want to find the current index of this product
			for (let i = 0; i < products.length; i++) {
				if (products[i].id === productData.id) {
					products[i] = productData;
					break;
				}
			}
		}

		// now save this updated quote
		this.state.activeQuote.set('products', products);
		return this.saveQuote();
	};

	// deletes a product in the current quote
	deleteQuoteProduct = (id) => {
		// update the products arrray
		const products = [].concat(this.state.activeQuote.get('products'));
		for (let i = 0; i < products.length; i++) {
			if (products[i].id === id) {
				products.splice(i, 1);
				break;
			}
		}
		// now save this updated quote
		this.state.activeQuote.set('products', products);
		return this.saveQuote();
	};

	// component mount
	componentDidMount() {
		if (Parse.User.current()) {
			this.setUser(Parse.User.current());
		}
	}

	// component update
	componentDidUpdate(prevProps, prevState, snapshot) {
		// what we do if we changed the user
		if (prevState.activeUser !== this.state.activeUser) {
			if (this.state.activeUser === false) {
				// reset any state that is needeed
			} else {
				// let's fetch the user data
				this.state.activeUser
					.fetch()
					.then(() => {
						return Promise.resolve();
					})
					.catch((e) => {
						// we encountered an error
						// so lets' just ensure the user is logged out
						Parse.User.logOut().then(
							() => {
								this.setUser(false);
							},
							(e) => {
								this.setUser(false);
							}
						);
					});
			}
		}
	}

	clearLastQuoteConfiguration = () => {
		this.setState({
			lastQuoteConfiguration: false,
		});
	};

	// the render
	render() {
		const value = {
			// data
			activityIndicator: this.state.activityIndicator,
			activeUser: this.state.activeUser,
			activeQuote: this.state.activeQuote,
			activeAddressQuotes: this.state.activeAddressQuotes,
			lastQuoteConfiguration: this.state.lastQuoteConfiguration,
			// methods
			setActivityIndicator: this.setActivityIndicator,
			setUser: this.setUser,
			loadQuote: this.loadQuote,
			saveQuote: this.saveQuote,
			deleteQuote: this.deleteQuote,
			createQuote: this.createQuote,
			duplicateQuote: this.duplicateQuote,
			saveQuoteProduct: this.saveQuoteProduct,
			deleteQuoteProduct: this.deleteQuoteProduct,
			clearLastQuoteConfiguration: this.clearLastQuoteConfiguration,
		};

		return (
			<SessionData.Provider value={value} displayName="Session Data Context">
				{this.props.children}
			</SessionData.Provider>
		);
	}
}
