import React, { useCallback, useContext, useEffect, useRef } from 'react';
import Input from '../../Input/Input';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper.js';

const ProductConfigurationWindowRemovalType = (props) => {
	// get the quote product context
	const {
		product,
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
		setPreviousQuoteProductContextValue,
	} = useContext(CurrentQuoteProductContext);

	// we'll use a reference to monitor for the first run
	// this allows us to not update outside install on init
	// while the useRef, doesn't cause aa re-render
	const isFirstRender = useRef(true);

	// set the data we need to use
	const removalTypeValue = getQuoteProductContextProductValue(
		props.configuration.fieldName
	);

	// Set to use the previous value
	const setPreviousContextValue = useCallback(async () => {
		await setPreviousQuoteProductContextValue(props.configuration.fieldName);
	}, [props.configuration.fieldName, setPreviousQuoteProductContextValue]);

	useEffect(() => {
		setPreviousContextValue();
	}, [setPreviousContextValue]);

	// we have some relationships with other fields
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
		} else {
			// what is our outside install value
			let outsideInstallValue = product.configurations['OUTSIDE_INSTALL']
				? getQuoteProductContextProductValue(
						product.configurations['OUTSIDE_INSTALL'].fieldName
				  )
				: false;
			// then we need to change the outside install
			// we skip this on the initial render sso it only affects if someeone changes the value
			if (product.configurations['OUTSIDE_INSTALL']) {
				const outsideInstallFieldName =
					product.configurations['OUTSIDE_INSTALL'].fieldName;
				if (outsideInstallValue === true) {
					outsideInstallValue = false;
					setQuoteProductContextProductValue(outsideInstallFieldName, false);
				}
			}
			// when the removal type is updated, this changes the selected parts
			// we skip this on the initial render, so it only affects if someone changes the value
			if (product.configurations['WINDOW_INSTRUCTIONS']) {
				const findPartFieldName = (groupId, optionId) => {
					let fieldName =
						product.configurations['WINDOW_INSTRUCTIONS'].groups.find(
							(group) => {
								return group.groupId === groupId;
							}
						) || false;
					if (fieldName !== false) {
						fieldName =
							fieldName.options.find((option) => {
								return option.optionId === optionId;
							}) || false;
					}
					if (fieldName !== false) {
						fieldName = fieldName.fieldName;
					}
					return fieldName;
				};
				// update the intial jam selection
				const jamsFieldName = findPartFieldName('PARTS', 'EXTENSION_JAMBS');
				if (jamsFieldName !== false) {
					setQuoteProductContextProductValue(jamsFieldName, false);
				}
				// update the bucks
				const bucksFieldName = findPartFieldName('PARTS', 'BUCKS');
				if (bucksFieldName !== false) {
					if (
						product.code === 'GARDEN_PROD' ||
						product.code === 'DOOR_WALL_PROD' ||
						product.code === 'HOPPER_PROD' ||
						product.code === 'DOOR_WALL_CUSTOM'
					) {
						// special case for hopper, garden, doorwall
						setQuoteProductContextProductValue(bucksFieldName, 'FULL_BUCK');
					} else {
						if (
							removalTypeValue === 'VINYL' ||
							removalTypeValue === 'ALUMINUM' ||
							removalTypeValue === 'STEEL' ||
							(removalTypeValue === 'WOOD' && !outsideInstallValue)
						) {
							setQuoteProductContextProductValue(
								bucksFieldName,
								'PARTIAL_BUCK'
							);
						} else if (removalTypeValue === 'GLASS_BLOCK') {
							setQuoteProductContextProductValue(
								bucksFieldName,
								'FULL_BUCK'
							);
						} else if (removalTypeValue === '') {
							setQuoteProductContextProductValue(bucksFieldName, 'NONE');
						} else {
							setQuoteProductContextProductValue(bucksFieldName, 'NONE');
						}
					}
				}
				// update the casing
				const casingFieldName = findPartFieldName('PARTS', 'CASING');
				if (casingFieldName !== false) {
					if (
						product.code === 'GARDEN_PROD' ||
						product.code === 'DOOR_WALL_PROD' ||
						product.code === 'DOOR_WALL_CUSTOM'
					) {
						// special case for garden and doorwall
						setQuoteProductContextProductValue(casingFieldName, 'PINE');
					} else {
						if (removalTypeValue === 'GLASS_BLOCK') {
							setQuoteProductContextProductValue(casingFieldName, 'PINE');
						} else if (removalTypeValue === '') {
							setQuoteProductContextProductValue(casingFieldName, 'NONE');
						} else {
							setQuoteProductContextProductValue(casingFieldName, 'NONE');
						}
					}
				}
				// update the stops
				const stopsFieldName = findPartFieldName('PARTS', 'STOPS');
				if (stopsFieldName !== false) {
					if (removalTypeValue === 'WOOD' && outsideInstallValue) {
						setQuoteProductContextProductValue(stopsFieldName, 'NONE');
					} else if (removalTypeValue === '') {
						setQuoteProductContextProductValue(stopsFieldName, 'NONE');
					} else {
						setQuoteProductContextProductValue(stopsFieldName, 'PINE');
					}
				}
			}
		}
	}, [
		removalTypeValue,
		product,
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
	]);

	// return the component
	return (
		<div className="productconfiguration">
			<p className="heading heading--5 productconfiguration__heading">
				<label htmlFor={props.configuration.fieldName}>
					{props.configuration.heading}
				</label>
			</p>
			<Input
				type={'select'}
				className={'input--1'}
				inputModifier={'input--select'}
				id={props.configuration.fieldName}
				name={props.configuration.fieldName}
				values={[{ label: 'Select ...', value: '' }].concat(
					props.configuration.values.map(function (value) {
						return { label: ConfigurationLabelMapper(value), value: value };
					})
				)}
				value={getQuoteProductContextProductValue(props.configuration.fieldName)}
				onChange={(e) => {
					setQuoteProductContextProductValue(e.target.name, e.target.value);
				}}
			/>
		</div>
	);
};
export default ProductConfigurationWindowRemovalType;
