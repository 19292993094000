import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import GridCell from './GridCell';

// Wrap our RGL with a width provider to dynamically set the width of our "grid"
const ResponsiveReactGridLayout = WidthProvider(Responsive);

// More info on this package below:
// https://github.com/react-grid-layout/react-grid-layout

// Layout:
// {i, x, y, w, h}
// i is a key for an item in our layout array.
// x is the current x location on our grid.
// y is the current y location on our grid.
// w is the width of the object
// h is the height of the object
const ShowcaseLayout = (props) => {
	const [mounted, setMounted] = useState(false);
	const compactType = 'vertical';
	const [layouts, setLayouts] = useState({ xxs: props.layout, lg: props.layout });

	const onLayoutChange = (layout) => {
		props.onLayoutChange(layout);
	};

	useEffect(() => {
		setMounted(true);
		return () => {
			setMounted(false);
		};
	}, []);

	// If we've added more cells to our grid, we need to update our structure here.
	useEffect(() => {
		setLayouts({ xxs: props.layout, lg: props.layout });
	}, [props.layout]);

	/**
	 * This function should generate our "grid".
	 * @returns An array of table cells.
	 */
	const generateDOM = () => {
		return layouts.lg.map((Cell) => (
			<div key={Cell.key}>
				<GridCell
					cell={Cell}
					inMultiUnitPath={props.inMultiUnitPath}
					key={Cell.key}
					overrideClick={props.overrideClick}
				/>
			</div>
		));
	};

	// Uncomment for a more responsive layout.
	const onBreakpointChange = (breakpoint) => {
		return;
	};

	if (props.openCustomWindowConfigurationData() === props.orderIndex) {
		return (
			<ResponsiveReactGridLayout
				{...props}
				autoSize
				layouts={layouts}
				margin={[5, 5]}
				className="w-full h-full"
				breakpoints={{ lg: 400, xxs: 0 }}
				onBreakpointChange={onBreakpointChange}
				onLayoutChange={onLayoutChange}
				useCSSTransforms={mounted}
				compactType={compactType}
				isResizable={false}
				preventCollision={!compactType}
				measureBeforeMount={true}
			>
				{generateDOM()}
			</ResponsiveReactGridLayout>
		);
	}
	return <div></div>;
};

export default ShowcaseLayout;
