/*
 *  constants
 */
export const OPEN_MODAL = 'OPEN_MODAL ';
export const CLOSE_MODAL = 'CLOSE_MODAL ';
export const UPDATE_FORM_INPUTS_OBJECT = '  UPDATE_FORM_INPUTS_OBJECT';
export const DROPPED_WINDOW_LOCATION = '  DROPPED_WINDOW_LOCATION';
export const SELECT_COLUMN = ' SELECT_COLUMN';
export const RESET_SELECTED_COLUMNS = 'RESET_SELECTED_COLUMNS';
export const UPDATE_WINDOW_DIMENSION = 'UPDATE_WINDOW_DIMENSION';
export const ADD_CUSTOM_WINDOW_PRODUCT = 'ADD_CUSTOM_WINDOW_PRODUCT';
export const UPDATE_CUSTOM_WINDOW_GENERAL_SPECIFICATIONS =
	'UPDATE_CUSTOM_WINDOW_GENERAL_SPECIFICATIONS';
export const SET_SELECTED_CUSTOM_PRODUCT = 'SET_SELECTED_CUSTOM_PRODUCT';
