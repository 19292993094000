import React, { useState, useContext, useEffect, useRef } from 'react';
import Parse from 'parse';
import ContentBox1 from '../ContentBox1/ContentBox1';
import Input from '../Input/Input';
import Btn from '../Btn/Btn';
import {
	getFormData,
	validateFormDataPromise,
} from '../../functionality/FormUtilities.js';
import WallsideError from '../../functionality/WallsideError';
import SessionDataContext from '../../contexts/SessionDataContext';
import UIManagementContext from '../../contexts/UIManagementContext';
import URLMapper from '../../functionality/URLMapper';
import { useHistory } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import moment from 'moment';
import {
	autoCompleteAddress,
	streetAddressVerification,
} from '../../functionality/SmartyStreets';
import './Dashboard.css';

const Dashboard = () => {
	// we'll use history here
	const history = useHistory();

	// setup the contexts
	const { toggleSystemNotification, toggleOverlay } = useContext(UIManagementContext);
	const { setActivityIndicator, activeUser, createQuote, clearLastQuoteConfiguration } =
		useContext(SessionDataContext);

	// we'll have some stateful variables
	const limit = 4;
	const [offset, setOffset] = useState(0);
	const [search, setSearch] = useState(false);
	const [quotes, setQuotes] = useState([]);
	const [autoCompleteResults, setAutoCompleteResults] = useState([]);
	const [addressInput, setAddressInput] = useState(false);

	// we need to use a ref, for some of the paging stuff to cache requests
	const prevSearch = useRef(false);
	const offsetStatus = useRef({});
	const resetOffsetStatus = useRef(true);

	// a some refs for our search functionality
	const theCreateForm = useRef();
	const theCreateTimeout = useRef();

	// we'll use an effect for to loaad quotee if the paging is updated
	useEffect(() => {
		if (search !== prevSearch.current) {
			resetOffsetStatus.current = true;
			prevSearch.current = search;
		}

		if (resetOffsetStatus.current) {
			offsetStatus.current = {};
			resetOffsetStatus.current = false;
		}

		if (!offsetStatus.current['offset_' + offset]) {
			// update the offset status
			offsetStatus.current['offset_' + offset] = true;
			// do the query
			const query = new Parse.Query('Quote')
				.descending('updatedAt')
				.equalTo('createdBy', activeUser)
				.include('address')
				.limit(limit + 1)
				.skip(offset);

			if (search) {
				query.contains('searchIndex', search);
			}

			// show activity
			setActivityIndicator(true);
			query.find().then(
				(results) => {
					// hide activity
					setActivityIndicator(false);
					if (offset === 0) {
						setQuotes(results);
					} else {
						setQuotes((prev) => {
							results.splice(0, 1);
							return prev.concat(results);
						});
					}
				},
				(e) => {
					// hide activity
					setActivityIndicator(false);
					const error = new WallsideError(e);
					alert(error.globalErrors[0].message);
				}
			);
		}
	}, [setActivityIndicator, search, activeUser, offset, offsetStatus]);

	useEffect(() => {
		clearLastQuoteConfiguration();
	}, [clearLastQuoteConfiguration]);

	// some event handlers
	const handleForward = () => {
		// moving foward is updating the offset
		setOffset(offset + limit);
	};
	const handleBackward = () => {
		// moving backward is updating the offset
		setOffset(offset - limit);
	};

	// handle the create quote button
	const handleCreateQuote = (evt) => {
		// For custom quote address
		if (addressInput) {
			new Parse.Query('Address')
				.equalTo('uniqueTitle', addressInput.trim().toLowerCase())
				.first()
				.then((result) => {
					if (result) {
						return Promise.resolve(result);
					}
					const newAddress = new Parse.Object('Address', {
						title: addressInput,
						meta: {},
					});
					return newAddress.save();
				})
				.then((address) => {
					// now to name the quote we use a numbering scheme,
					// to use this scheme we need to query for all the quotees from this addreess
					return new Parse.Query('Quote')
						.equalTo('address', address)
						.limit(100)
						.find()
						.then((results) => {
							// now we can create the quote
							return createQuote('Quote ' + (results.length + 1), address);
						});
				})
				.then(
					(quote) => {
						// show the notification
						toggleSystemNotification({
							active: true,
							messageKey: 'create_quote',
						});
						// go to add product
						history.push(URLMapper('addproduct', { quoteId: quote.id }));
					},
					(e) => {
						toggleOverlay({
							active: true,
							type: 'error_messages',
							data: {
								heading: 'Error Creating Quote',
								errors: ['Unable to validate this address.'],
							},
						});
					}
				);
		} else {
			streetAddressVerification(evt.currentTarget.value)
				.then((response) => {
					const theLookup = response.lookups[0];
					if (theLookup.result.length > 0) {
						return Promise.resolve(theLookup.result[0]);
					}
					return Promise.reject();
				})
				.then(async (result) => {
					// create the title
					let title = result.deliveryLine1;
					if (result.deliveryLine2) {
						title += ', ' + result.deliveryLine2;
					}
					title += ', ' + result.lastLine;
					// the meta to save
					const meta = {
						line1: result.deliveryLine1,
						line2: result.deliveryLine2 || '',
						line3: result.lastLine,
						components: {
							...result.components,
						},
					};

					// first we look for an existing match for this address
					return await new Parse.Query('Address')
						.equalTo('uniqueTitle', title.trim().toLowerCase())
						.first()
						.then((result) => {
							if (result) {
								return Promise.resolve(result);
							}
							const newAddress = new Parse.Object('Address', {
								title: title,
								meta: meta,
							});
							return newAddress.save();
						});
				})
				.then(async (address) => {
					// now to name the quote we use a numbering scheme,
					// to use this scheme we need to query for all the quotees from this addreess
					return await new Parse.Query('Quote')
						.equalTo('address', address)
						.limit(100)
						.find()
						.then((results) => {
							// now we can create the quote
							return createQuote('Quote ' + (results.length + 1), address);
						});
				})
				.then(
					(quote) => {
						// show the notification
						toggleSystemNotification({
							active: true,
							messageKey: 'create_quote',
						});
						// go to add product
						history.push(URLMapper('addproduct', { quoteId: quote.id }));
					},
					(e) => {
						toggleOverlay({
							active: true,
							type: 'error_messages',
							data: {
								heading: 'Error Creating Quote',
								errors: ['Unable to validate this address.'],
							},
						});
					}
				);
		}
	};

	// handle the autocompete
	const handleAutoComplete = (e) => {
		e.preventDefault();
		clearTimeout(theCreateTimeout.current);
		theCreateTimeout.current = setTimeout(() => {
			// we'll get our form data
			const formData = {
				job_address: theCreateForm.current.elements.job_address.value.trim(),
			};
			// validate the form
			validateFormDataPromise(formData).then(
				() => {
					autoCompleteAddress(formData.job_address).then(
						(response) => {
							setAutoCompleteResults(response.result);
							if (response.result.length > 0) {
								setAddressInput(false);
							} else {
								setAddressInput(
									theCreateForm.current.elements.job_address.value.trim()
								);
							}
						},
						(e) => {
							alert(e);
						}
					);
				},
				(e) => {
					// didn't pass validation, do nothing
					setAutoCompleteResults([]);
				}
			);
		}, 175);
	};

	const handleNoNewQuotesSubmit = async (e) => {
		e.preventDefault();
		try {
			// we'll now try to get the data from the form
			const formData = getFormData(e.currentTarget);
			// validate the form
			await validateFormDataPromise(formData);
			setOffset(0);
			setSearch(formData.search.toLowerCase());
		} catch (error) {
			// didn't pass validation, do nothing (reset)
			setOffset(0);
			setSearch(false);
		}
	};

	// return the render
	return (
		<div className="dashboard">
			<ContentBox1 headingTitle="Create New Quote">
				<form
					ref={theCreateForm}
					className={`dashboard__create ${
						autoCompleteResults.length > 0 && 'dashboard__create--open'
					}`}
					onSubmit={handleAutoComplete}
				>
					<Input
						type="text"
						id="job_address"
						name="job_address"
						className="input--1"
						placeholder="Enter job location address"
						onChange={handleAutoComplete}
					/>
					{!addressInput || (
						<Btn
							type="button"
							value={addressInput}
							btnType="btn--medium"
							onClick={handleCreateQuote}
						>
							Create Quote
						</Btn>
					)}
				</form>
				{autoCompleteResults.length > 0 && (
					<div className="dashboard__create-search-results">
						<div className="dashboard__create-search-results-content">
							{autoCompleteResults.map((result, i) => {
								return (
									<div
										key={i}
										className="dashboard__create-search-item"
									>
										<div className="dashboard__create-search-item-content">
											<div className="heading heading--5">
												{result.streetLine}
											</div>
											<div className="heading heading--5 dashboard__create-search-item-people">
												{result.city}, {result.state}
											</div>
										</div>
										<Btn
											type="button"
											value={result.text}
											btnType="btn--medium"
											onClick={handleCreateQuote}
										>
											Create Quote
										</Btn>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</ContentBox1>
			<ContentBox1
				emptyMessage="You do not have any quotes."
				headingTitle="My Quotes"
				headingElement={
					<form
						className="dashboard__quote-search"
						onSubmit={handleNoNewQuotesSubmit}
					>
						<Input
							type="text"
							id="search"
							name="search"
							className="input--1 input--search"
							placeholder="       Search Quotes..."
						/>
					</form>
				}
			>
				{quotes.length > 0
					? quotes.map((quote, index) => {
							return index >= offset && index < offset + limit ? (
								<div key={index} className="dashboard__quote">
									<div className="dashboard__quote-content">
										<p className="dashboard__quote-title">
											{quote.get('title')}
										</p>
										<p className="dashboard__quote-address">
											{quote.get('address').get('title')}
										</p>
									</div>
									<p className="dashboard__quote-date">
										{moment(quote.get('updatedAt')).format(
											'MM/DD/YYYY'
										)}
									</p>
									<Btn
										btnType={'btn--medium'}
										onClick={() => {
											history.push(
												URLMapper('quotepricing', {
													quoteId: quote.id,
												})
											);
										}}
									>
										List View
									</Btn>
									<Btn
										btnType={'btn--medium btn--primary'}
										onClick={() => {
											history.push(
												URLMapper('quote', { quoteId: quote.id })
											);
										}}
									>
										Edit
									</Btn>
								</div>
							) : (
								''
							);
					  })
					: ''}
			</ContentBox1>
			<Pagination
				limit={limit}
				offset={offset}
				total={quotes.length}
				onForward={handleForward}
				onBackward={handleBackward}
			/>
		</div>
	);
};

export default Dashboard;
