export default {
	DOOR_WALL_CUSTOM: {
		default: {
			description: 'Doorwall',
			svg: '/drag-drop-products/svg/Doorwall@2x.svg',
			png: '/drag-drop-products/png/Doorwall.png',
			windowType: 'non-bay-bow',
		},
	},
	END_VENT_SLIDER_PROD: {
		default: {
			description: 'End Vent Slider',
			svg: '/drag-drop-products/svg/End-Vent-Slider@2x.svg',
			png: '/drag-drop-products/png/End-Vent-Slider.png',
		},
	},
	DOUBLE_HUNG_PROD: {
		default: {
			description: 'Double Hung',
			svg: '/drag-drop-products/svg/Double-Hung@2x.svg',
			png: '/drag-drop-products/png/Double-Hung.png',
		},
	},
	PICTURE_PROD: {
		default: {
			description: 'Picture Window',
			svg: '/drag-drop-products/svg/Picture Window—new@4x.svg',
			png: '/drag-drop-products/png/Picture Window—new.png',
		},
	},
	DOUBLE_SLIDER_PROD: {
		default: {
			description: 'Double Slider',
			svg: '/drag-drop-products/png/Double-Slider.png',
			png: '/drag-drop-products/png/Double-Slider.png',
		},
	},
	SINGLE_CASEMENT_PROD: {
		default: {
			description: 'Single Casement',
			svg: '/drag-drop-products/png/Single-Casement.png',
			png: '/drag-drop-products/png/Single-Casement.png',
		},
	},
	DOUBLE_CASEMENT_PROD: {
		default: {
			description: 'Double Casement',
			svg: '/drag-drop-products/png/Double-Casement.png',
			png: '/drag-drop-products/png/Double-Casement.png',
		},
	},
	AWNING_CASEMENT_PROD: {
		default: {
			description: 'Awning Casement',
			svg: '/drag-drop-products/png/Awning-Casement.png',
			png: '/drag-drop-products/png/Awning-Casement.png',
		},
	},
	CENTER_VENT_SLIDER_PROD: {
		default: {
			description: 'Center Vent Slider',
			svg: '/drag-drop-products/png/Center-Vent-Slider.png',
			png: '/drag-drop-products/png/Center-Vent-Slider.png',
		},
	},
	PICTURE_CASEMENT_PROD: {
		default: {
			description: 'Picture Casement',
			svg: '/drag-drop-products/png/Picture Casement.png',
			png: '/drag-drop-products/png/Picture Casement.png',
		},
	},
	CH_CATHEDRAL: {
		default: {
			description: 'CH Cathedral',
			svg: '/drag-drop-products/svg/CH– Cathedra.svg',
			png: '/drag-drop-products/png/CH– Cathedra@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	CHX_CATHEDRAL_WITH_EXTENDED_LEGS: {
		default: {
			description: 'CHX Cathedral with Extended Legs',
			svg: '/drag-drop-products/svg/CHX– Cathedral with Extended Legs.svg',
			png: '/drag-drop-products/png/CHX– Cathedral with Extended Legs@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	CT_CIRCLE_TOP: {
		default: {
			description: 'CT Circle Top',
			svg: '/drag-drop-products/svg/CT– Circle Top.svg',
			png: '/drag-drop-products/png/CT– Circle Top@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	CTX_CIRCLE_TOP_EXTENDED_LEGS: {
		default: {
			description: 'CH Circle Top Extended Legs',
			svg: '/drag-drop-products/svg/CTX– Circle Top with Extended Legs.svg',
			png: '/drag-drop-products/png/CTX– Circle Top with Extended Legs@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	// GARDEN: {
	// 	default: {
	// 		description: 'Garden Window',
	// 		svg: '/drag-drop-products/svg/', // Add svg for garden
	// 		png: '/drag-drop-products/png/', // Add png for garden
	// 		windowType: 'non-bay-bow'
	// 	}
	// },
	TZL_TZR_TRAPEZOID_RIGHT: {
		default: {
			description: 'TZL OR TZR Trapezoid Right',
			svg: '/drag-drop-products/svg/TZL or TZR– Trapezoid Right.svg',
			png: '/drag-drop-products/png/TZL or TZR– Trapezoid Right@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	TZL_TZR_TRAPEZOID_LEFT: {
		default: {
			description: 'TZL OR TZR Trapezoid Left',
			svg: '/drag-drop-products/svg/TZL or TZR– Trapezoid Left.svg',
			png: '/drag-drop-products/png/TZL or TZR– Trapezoid Left@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	TRL_OR_TRR_TRIANGLE_RIGHT: {
		default: {
			description: 'TRL OR TRR Triangle Right',
			svg: '/drag-drop-products/svg/TRL or TRR– Triangle Right.png',
			png: '/drag-drop-products/png/TRL or TRR– Triangle Right@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	TRL_OR_TRR_TRIANGLE_LEFT: {
		default: {
			description: 'TRL OR TRR Triangle Left',
			svg: '/drag-drop-products/svg/TRL or TRR– Triangle Left.png',
			png: '/drag-drop-products/png/TRL or TRR– Triangle Left@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	TRI_TRIANGLE_ISOSCELES: {
		default: {
			description: 'TRI Triangle Isosceles',
			svg: '/drag-drop-products/svg/TRI– Triangle Isosceles.svg',
			png: '/drag-drop-products/png/TRI– Triangle Isosceles@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	TRE_TRIANGLE_EQUILATERAL: {
		default: {
			description: 'TRE Triangle Equilateral',
			svg: '/drag-drop-products/svg/TRE– Triangle Equilateral@.svg',
			png: '/drag-drop-products/png/TRE– Triangle Equilateral@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	RT_RECTANGLE: {
		default: {
			description: 'RT Rectangle',
			svg: '/drag-drop-products/svg/RT– Rectangle.svg',
			png: '/drag-drop-products/png/RT– Rectangle@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	QAL_QAR_QUARTER_ARCH_RIGHT: {
		default: {
			description: 'QAL Or QAR Quarter Arch Right',
			svg: '/drag-drop-products/svg/QAL or QAR– Quarter Arch Right.svg',
			png: '/drag-drop-products/png/QAL or QAR– Quarter Arch Right@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	QAL_QAR_QUARTER_ARCH_RIGHT_1: {
		default: {
			description: 'QAL Or QAR Quarter Arch Right 1',
			svg: '/drag-drop-products/svg/QAL or QAR– Quarter Arch Right_1.svg',
			png: '/drag-drop-products/png/QAL or QAR– Quarter Arch Right_1@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	QAL_QAR_QUARTER_ARCH_LEFT: {
		default: {
			description: 'QAL Or QAR Quarter Arch Left',
			svg: '/drag-drop-products/svg/QAL or QAR– Quarter Arch Left.svg',
			png: '/drag-drop-products/png/QAL or QAR– Quarter Arch Left@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	QAL_QAR_QUARTER_ARCH_LEFT_1: {
		default: {
			description: 'QAL Or QAR Quarter Arch Left 1',
			svg: '/drag-drop-products/svg/QAL or QAR– Quarter Arch Left_1.svg',
			png: '/drag-drop-products/png/QAL or QAR– Quarter Arch Left_1@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	PT_PENTAGON: {
		default: {
			description: 'PT Pentagon',
			svg: '/drag-drop-products/svg/PT– Pentagon.svg',
			png: '/drag-drop-products/png/PT– Pentagon@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	PTL_PTR_PENTAGON_RIGHT: {
		default: {
			description: 'PTL Or PTR Pentagon Right',
			svg: '/drag-drop-products/svg/PTL or PTR– Pentagon Right.svg',
			png: '/drag-drop-products/png/PTL or PTR– Pentagon Right@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	PTL_PTR_PENTAGON_LEFT: {
		default: {
			description: 'PTL Or PTR Pentagon Left',
			svg: '/drag-drop-products/svg/PTL or PTR– Pentagon Left.svg',
			png: '/drag-drop-products/png/PTL or PTR– Pentagon Left@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	PTE_PENTAGON_EQUILATERAL: {
		default: {
			description: 'PTE Pentagon Equilateral',
			svg: '/drag-drop-products/svg/PTE– Pentagon Equilateral.svg',
			png: '/drag-drop-products/png/PTE– Pentagon Equilateral@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	OV_OVAL: {
		default: {
			description: 'OV Oval',
			svg: '/drag-drop-products/svg/OV– Oval.svg',
			png: '/drag-drop-products/png/OV– Oval@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	OT_OCTAGON: {
		default: {
			description: 'OT Octagon',
			svg: '/drag-drop-products/svg/OT– Octagon.svg',
			png: '/drag-drop-products/png/OT– Octagon@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	OTX_OCTAGON_WITH_EXTENDED_LEGS: {
		default: {
			description: 'OTX Octagon with Extended Legs',
			svg: '/drag-drop-products/svg/OTX– Octagon with Extended Legs.svg',
			png: '/drag-drop-products/png/OTX– Octagon with Extended Legs@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	HX_HEXAGON: {
		default: {
			description: 'HX Hexagon',
			svg: '/drag-drop-products/svg/HX– Hexagon.svg',
			png: '/drag-drop-products/png/HX– Hexagon@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	HXX_HEXAGON_WITH_EXTENDED_LEGS: {
		default: {
			description: 'HXX Hexagon with Extended Legs',
			svg: '/drag-drop-products/svg/HXX– Hexagon with Extended Legs.svg',
			png: '/drag-drop-products/png/HXX– Hexagon with Extended Legs@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	HXL_HXR_HALF_EYEBROW_RIGHT: {
		default: {
			description: 'HXL or HXR Half Eyebrow Right',
			svg: '/drag-drop-products/svg/HXL or HXR– Half Eyebrow Right.svg',
			png: '/drag-drop-products/png/HXL or HXR– Half Eyebrow Right@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	HXL_HXR_HALF_EYEBROW_LEFT: {
		default: {
			description: 'HXL or HXR Half Eyebrow Left',
			svg: '/drag-drop-products/svg/HXL or HXR– Half Eyebrow Left.svg',
			png: '/drag-drop-products/png/HXL or HXR– Half Eyebrow Left@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	HBL_BR_HALF_EYEBROW_RIGHT: {
		default: {
			description: 'HBL or HBR Half Eyebrow Right',
			svg: '/drag-drop-products/svg/HBL or HBR– Half Eyebrow Right.svg',
			png: '/drag-drop-products/png/HBL or HBR– Half Eyebrow Right@2x.png',
			windowType: 'non-bay-bow',
		},
	},
	HBL_BR_HALF_EYEBROW_LEFT: {
		default: {
			description: 'HBL or HBR Half Eyebrow Left',
			svg: '/drag-drop-products/svg/HBL or HBR– Half Eyebrow Left.svg',
			png: '/drag-drop-products/png/HBL or HBR– Half Eyebrow Left@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	FC_FULL_CIRCLE: {
		default: {
			description: 'FC Full Circle',
			svg: '/drag-drop-products/svg/FC– Full Circle.svg',
			png: '/drag-drop-products/png/FC– Full Circle@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	EP_ELLIPSE: {
		default: {
			description: 'EP Ellipse',
			svg: '/drag-drop-products/svg/EP– Ellipse.svg',
			png: '/drag-drop-products/png/EP– Ellipse@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	EB_EYEBROW: {
		default: {
			description: 'EB Eyebrow',
			svg: '/drag-drop-products/svg/EB– Eyebrow.svg',
			png: '/drag-drop-products/png/EB– Eyebrow@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	EBX_EYEBROW_WITH_EXTENDED_LEGS: {
		default: {
			description: 'EBX Eyebrow with Extended Legs',
			svg: '/drag-drop-products/svg/EBX– Eyebrow with Extended Legs.svg',
			png: '/drag-drop-products/png/EBX– Eyebrow with Extended Legs@2x.png',
			windowType: 'non-bay-bow',
		},
	},

	DCC_DOUBLE_CLIPPED_CORNER: {
		default: {
			description: 'DCC Double Clipped Corner',
			svg: '/drag-drop-products/svg/DCC — Double Clipped Corner.svg',
			png: '/drag-drop-products/png/DCC — Double Clipped Corner.png',
			windowType: 'non-bay-bow',
		},
	},
};
