import React from 'react';
import { useConfirmMessage } from './confirmMessageContext';
import Btn from '../../Btn/Btn';

const ConfirmMessage = ({ children }) => {
	const { onConfirm, onCancel, confirmMessageState } = useConfirmMessage();
	// return the component
	return confirmMessageState.show ? (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s1">
						<div className="overlay-s1__heading">
							<h1 className="heading heading--4">Warning</h1>
						</div>
						<div className="overlay-s1__body overlay-s1__body--message">
							<p className="heading heading--5">
								{confirmMessageState?.text && confirmMessageState.text}
							</p>
						</div>
						<div className="overlay-s1__btns">
							<Btn btnType={'btn--secondary'} onClick={onCancel}>
								Cancel
							</Btn>
							<Btn btnType={'btn--primary'} onClick={onConfirm}>
								{confirmMessageState?.confirmBtnText
									? confirmMessageState.confirmBtnText
									: 'Confirm'}
							</Btn>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default ConfirmMessage;
