/**
 * Get the lowest and largest heights and widths from an array of cells.
 */
export const getLowestLargestCells = (cells) => {
	// To avoid having a nested for loop, we're gonna find the lowest/highest
	// cells and save their values then compare them to the mapped window.
	let lowestHeight = 999;
	let lowestWidth = 999;
	let largestHeight = 0;
	let largestWidth = 0;

	for (const cell of cells) {
		const height = cell.product.configuration_window_size_height;
		const width = cell.product.configuration_window_size_width;
		if (height < lowestHeight) {
			lowestHeight = height;
		}
		if (width < lowestWidth) {
			lowestWidth = width;
		}
		if (height > largestHeight) {
			largestHeight = height;
		}
		if (width > largestWidth) {
			largestWidth = width;
		}
	}
	return { largestHeight, largestWidth, lowestHeight, lowestWidth };
};
