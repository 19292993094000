export default {
	COLONIAL: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/colonial.png',
		},
	},
	VERTICAL: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/vertical.png',
		},
	},
	HORIZONTAL: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/horizontal.png',
		},
	},
	SINGLE_PRAIRIE: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/single-prairie.png',
		},
	},
	DOUBLE_PRAIRIE: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/double-prairie.png',
		},
	},
	DIAMOND: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/diamond.png',
		},
	},
	CUSTOM: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/custom.png',
		},
	},
	MARQUIS: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/marquis.png',
		},
	},
	FLORENTINE: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/florentine.png',
		},
	},
	PETAL: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/petal.png',
		},
	},
	TRELLIS: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/trellis.png',
		},
	},
	TUDOR: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/tudor.png',
		},
	},
	SQUARE: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/square.png',
		},
	},
	DIAMOND_ETCHED: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/diamond-etched.png',
		},
	},
	SPOKES: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/spokes.png',
		},
	},
	HUB_AND_SPOKES: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/hub-and-spokes.png',
		},
	},
	DOUBLE_HUB_AND_SPOKES: {
		default: {
			description: '',
			url: '/configuration-images/grid-patterns/double-hub-and-spokes.png',
		},
	},
};
