import React, { useContext, useEffect } from 'react';
import IconBtn from '../../IconBtn/IconBtn';
import ImageAsset from '../../ImageAsset/ImageAsset';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import UIManagementContext from '../../../contexts/UIManagementContext';

const ProductConfigurationWindowHardware = (props) => {
	// the ui management context
	const { openConfigurationData, toggleOpenConfiguration } =
		useContext(UIManagementContext);

	// product context
	const { setQuoteProductContextProductValue, getQuoteProductContextProductValue } =
		useContext(CurrentQuoteProductContext);

	// the selected values
	const selectedInteriorHandleColorValue = props.configuration.interiorHandleColor
		? getQuoteProductContextProductValue(
				props.configuration.interiorHandleColor.fieldName
		  )
		: false;
	const selectedInteriorHandleColorOption = props.configuration.interiorHandleColor
		? props.configuration.interiorHandleColor.values.find((option) => {
				return option.value === selectedInteriorHandleColorValue;
		  })
		: false;
	const selectedExteriorHandleColorValue = props.configuration.exteriorHandleColor
		? getQuoteProductContextProductValue(
				props.configuration.exteriorHandleColor.fieldName
		  )
		: false;

	// create the section details
	const createSectionHeadingDetails = () => {
		return props.configuration.interiorHandleColor &&
			props.configuration.exteriorHandleColor
			? ConfigurationLabelMapper(selectedInteriorHandleColorValue) +
					'/' +
					ConfigurationLabelMapper(selectedExteriorHandleColorValue)
			: '';
	};

	// we need to check defaults as they switch
	useEffect(() => {
		if (
			props.configuration.interiorHandleColor &&
			props.configuration.exteriorHandleColor
		) {
			if (
				selectedInteriorHandleColorOption.exteriorColorOptions.indexOf(
					selectedExteriorHandleColorValue
				) === -1
			) {
				setQuoteProductContextProductValue(
					props.configuration.exteriorHandleColor.fieldName,
					selectedInteriorHandleColorOption.exteriorColorDefault
				);
			}
		}
	}, [
		setQuoteProductContextProductValue,
		props.configuration.interiorHandleColor,
		props.configuration.exteriorHandleColor,
		selectedInteriorHandleColorOption,
		selectedExteriorHandleColorValue,
	]);

	// return the component
	return (
		<div className="productconfiguration">
			<div
				className={`productconfiguration__section ${
					openConfigurationData() === props.orderIndex
						? 'productconfiguration__section--open'
						: ''
				}`}
			>
				<button
					type="button"
					className="productconfiguration__section-header"
					onClick={() => {
						toggleOpenConfiguration(props.orderIndex);
					}}
				>
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{props.orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">
							{props.configuration.heading}
						</div>
						<div className="heading heading--7">
							{createSectionHeadingDetails()}
						</div>
					</div>
					<div className="productconfiguration__section-toggle">
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--primary'}
							iconType={'icon--plus'}
						/>
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--secondary'}
							iconType={'icon--minus'}
						/>
					</div>
				</button>
				<div className="productconfiguration__section-body">
					{props.configuration.interiorHandleColor ? (
						<>
							<div className="config-heading heading heading--4">
								{props.configuration.interiorHandleColor.heading}
							</div>
							<div className="config-circle-select">
								{props.configuration.interiorHandleColor.values.map(
									(option) => {
										return (
											<React.Fragment key={option.value}>
												<input
													type="radio"
													name={
														props.configuration
															.interiorHandleColor.fieldName
													}
													id={`${props.configuration.interiorHandleColor.fieldName}_${option.value}`}
													value={option.value}
													checked={
														option.value ===
														selectedInteriorHandleColorValue
													}
													onChange={(e) => {
														setQuoteProductContextProductValue(
															e.target.name,
															e.target.value
														);
													}}
												/>
												<label
													htmlFor={`${props.configuration.interiorHandleColor.fieldName}_${option.value}`}
													className="config-circle-select__option"
												>
													<div className="config-circle-select__circle">
														<ImageAsset code={option.value} />
													</div>
													<div className="config-circle-select__label heading heading--5">
														{ConfigurationLabelMapper(
															option.value
														)}
													</div>
												</label>
											</React.Fragment>
										);
									}
								)}
							</div>
						</>
					) : (
						''
					)}
					{props.configuration.exteriorHandleColor ? (
						<>
							{props.configuration.interiorHandleColor ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="config-heading heading heading--4">
								{props.configuration.exteriorHandleColor.heading}
							</div>
							<div className="config-notch-select">
								{props.configuration.exteriorHandleColor.values.map(
									(value) => {
										const isDisabled =
											selectedInteriorHandleColorOption.exteriorColorOptions.indexOf(
												value
											) === -1;
										return (
											<React.Fragment key={value}>
												<input
													type="radio"
													name={
														props.configuration
															.exteriorHandleColor.fieldName
													}
													id={`${props.configuration.exteriorHandleColor.fieldName}_${value}`}
													value={value}
													{...(isDisabled && {
														disabled: true,
													})}
													checked={
														value ===
														selectedExteriorHandleColorValue
													}
													onChange={(e) => {
														setQuoteProductContextProductValue(
															e.target.name,
															e.target.value
														);
													}}
												/>
												<label
													htmlFor={`${props.configuration.exteriorHandleColor.fieldName}_${value}`}
													className={`config-notch-select__option ${
														isDisabled
															? 'config-notch-select__option--inactive'
															: ''
													}`}
												>
													<div className="config-notch-select__button">
														<span className="config-notch-select__notch"></span>
														<ImageAsset code={value} />
													</div>
													<div className="config-notch-select__label heading heading--10">
														{ConfigurationLabelMapper(value)}
													</div>
												</label>
											</React.Fragment>
										);
									}
								)}
							</div>
						</>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductConfigurationWindowHardware;
