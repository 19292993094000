import { toFixed } from '../../helpers/Calculations';

/**
 * Iterate over every previous cell that is not part of the column we are adding/removing.
 */
export const formatPreviousCols = (isAdding, oldCells, cells, width, newTotalWidth) => {
	const newCells = [];

	const iteratedArray = isAdding ? oldCells : cells;

	iteratedArray.map((cell) => {
		const cellWidth = cell.product.configuration_window_size_width;

		if (isAdding) {
			const ratio = cellWidth / width;
			cell.product.configuration_window_size_width = toFixed(
				ratio * newTotalWidth,
				1000
			);
		} else {
			const ratio = cellWidth / newTotalWidth;
			cell.product.configuration_window_size_width = toFixed(ratio * width, 1000);
		}

		newCells.push(cell);
	});

	return newCells;
};

/**
 * Iterate over every previous cell that is not part of the row we are adding/removing.
 */
export const formatPreviousRows = (isAdding, oldCells, cells, height, newTotalHeight) => {
	const newCells = [];

	const iteratedArray = isAdding ? oldCells : cells;

	iteratedArray.map((cell) => {
		const cellHeight = cell.product.configuration_window_size_height;

		if (isAdding) {
			const ratio = cellHeight / height;
			cell.product.configuration_window_size_height = toFixed(
				ratio * newTotalHeight,
				1000
			);
		} else {
			const ratio = cellHeight / newTotalHeight;
			cell.product.configuration_window_size_height = toFixed(ratio * height, 1000);
		}

		newCells.push(cell);
	});

	return newCells;
};

/**
 * Assign our resizingInputsValues the values they should have after adding/removing a column.
 */
export const formatResizingInputCols = (
	isAdding,
	oldResizingInputsValues,
	oldColumnsBaseSize,
	newColumnsBaseSize,
	width,
	newTotalWidth,
	appendedCells,
	newRowsSize,
	newCells
) => {
	const resizingInputsValues = oldResizingInputsValues;

	// For each column, each cell must have their product updated.
	// If we are updating the grid itself, remove all products from each cell.
	// We will also "fix" the grid if we have any merged cells.
	const comparator = isAdding ? oldColumnsBaseSize : newColumnsBaseSize;
	for (let i = 0; i < comparator; i++) {
		const input = resizingInputsValues.x[i];

		if (isAdding) {
			const ratio = input / width;
			resizingInputsValues.x[i] = ratio * newTotalWidth;
		} else {
			const ratio = input / newTotalWidth;
			resizingInputsValues.x[i] = toFixed(ratio * width, 1000);
		}
	}

	// Add the newest resizingInputValue
	if (isAdding) {
		resizingInputsValues.x[oldColumnsBaseSize] =
			appendedCells[0].product.configuration_window_size_width;
	} else {
		delete resizingInputsValues.x[newColumnsBaseSize];
	}

	for (let i = 0; i < newRowsSize; i++) {
		const cellHeight = newCells[i].product.configuration_window_size_height;
		resizingInputsValues.y[i] = cellHeight;
	}

	return resizingInputsValues;
};

/**
 * Assign our resizingInputsValues the values they should have after adding/removing a row.
 */
export const formatResizingInputRows = (
	isAdding,
	oldResizingInputsValues,
	oldRowsBaseSize,
	newRowsBaseSize,
	height,
	newTotalHeight,
	appendedCells,
	newColumnsSize,
	newCells
) => {
	const resizingInputsValues = oldResizingInputsValues;

	// For each column, each cell must have their product updated.
	// If we are updating the grid itself, remove all products from each cell.
	// We will also "fix" the grid if we have any merged cells.
	const comparator = isAdding ? oldRowsBaseSize : newRowsBaseSize;
	for (let i = 0; i < comparator; i++) {
		const input = resizingInputsValues.y[i];

		if (isAdding) {
			const ratio = input / height;
			resizingInputsValues.y[i] = ratio * newTotalHeight;
		} else {
			const ratio = input / newTotalHeight;
			resizingInputsValues.y[i] = toFixed(ratio * height, 1000);
		}
	}

	// Add the newest resizingInputValue
	if (isAdding) {
		resizingInputsValues.y[oldRowsBaseSize] =
			appendedCells[0].product.configuration_window_size_height;
	} else {
		delete resizingInputsValues.y[newRowsBaseSize];
	}

	for (let i = 0; i < newColumnsSize; i++) {
		const cellWidth = newCells[i].product.configuration_window_size_width;
		resizingInputsValues.x[i] = cellWidth;
	}

	return resizingInputsValues;
};

/**
 * Assign our resizingInputsValues the values they should have after adding/removing a row.
 * Navigate through the list, if there's a key that's "missing" (0, 2) means that "1" is missing,
 * move 2 over to 1 and then navigate.
 */
export const formatInputs = (resizingInputs) => {
	const xArr = Object.values(resizingInputs.x);
	const yArr = Object.values(resizingInputs.y);

	const product = { x: {}, y: {} };

	for (let i = 0; i < xArr.length; i++) {
		product.x[i] = xArr[i];
	}

	for (let i = 0; i < yArr.length; i++) {
		product.y[i] = yArr[i];
	}

	return product;
};
