const scrollWindow = (opts) => {
	const options = Object.assign(
		{
			destination: 0,
			duration: 250,
			easing: 'linear',
			callback: () => {},
		},
		opts
	);
	const easings = {
		linear(t) {
			return t;
		},
		easeInQuad(t) {
			return t * t;
		},
		easeOutQuad(t) {
			return t * (2 - t);
		},
		easeInOutQuad(t) {
			return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
		},
		easeInCubic(t) {
			return t * t * t;
		},
		easeOutCubic(t) {
			return --t * t * t + 1;
		},
		easeInOutCubic(t) {
			return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
		},
		easeInQuart(t) {
			return t * t * t * t;
		},
		easeOutQuart(t) {
			return 1 - --t * t * t * t;
		},
		easeInOutQuart(t) {
			return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
		},
		easeInQuint(t) {
			return t * t * t * t * t;
		},
		easeOutQuint(t) {
			return 1 + --t * t * t * t * t;
		},
		easeInOutQuint(t) {
			return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
		},
	};

	const startTime =
		'now' in window.performance ? performance.now() : new Date().getTime();
	const startOffset = Math.floor(window.scrollY);
	const destinationOffset = Math.min(
		Math.max(0, document.body.scrollHeight - window.innerHeight - 2),
		Math.max(0, Math.floor(options.destination))
	);
	const amountToScroll = Math.floor(window.scrollY - destinationOffset);

	if ('requestAnimationFrame' in window === false) {
		window.scroll(0, destinationOffset);
		if (options.callback) {
			options.callback();
		}
		return;
	}

	let lastScrollPosition;
	function scroll() {
		const now =
			'now' in window.performance ? performance.now() : new Date().getTime();
		const time = (now - startTime) / options.duration;
		const timeFunction = easings[options.easing](Math.min(1, time));
		const newOffset = startOffset + -1 * timeFunction * amountToScroll;

		// do the actual scroll
		window.scroll(0, newOffset);
		if (
			Math.floor(window.pageYOffset) === Math.floor(destinationOffset) ||
			(time > 1 && lastScrollPosition === window.pageYOffset)
		) {
			if (options.callback) {
				options.callback();
			}
			return;
		}

		// set the last scrolled position
		lastScrollPosition = window.pageYOffset;

		// do the next frame
		requestAnimationFrame(scroll);
	}

	// start animation
	scroll();
};

export { scrollWindow };
