import PRODUCTS from './asset-data/dragDropProductsAsset';

const assetArray = [
	// basic collection
	{
		FALLBACK_IMAGE: {
			default: {
				description: '',
				url: '/misc-images/fallback.png',
			},
		},
	},
	PRODUCTS,
];

const assetMap = {};
assetArray.forEach(function (assetCollection) {
	Object.assign(assetMap, assetCollection);
});

function find(c, type) {
	// set our code and size
	const code = c ? c : '_';

	// use the image based on code
	if (assetMap[code]) {
		return {
			description: assetMap[code].default.description,
			img:
				process.env.PUBLIC_URL + type
					? assetMap[code].default[type]
					: assetMap[code].default.svg,
		};
	}

	// use the fallback
	return {
		description: assetMap['FALLBACK_IMAGE']['default'].description,
		url: process.env.PUBLIC_URL + assetMap['FALLBACK_IMAGE']['default'].url,
	};
}

function all(type = false) {
	const productsResult = [];

	Object.keys(PRODUCTS).forEach((key) => {
		const img = type ? PRODUCTS[key].default[type] : PRODUCTS[key].default['svg'];
		productsResult.push({
			code: key,
			description: PRODUCTS[key].default.description,
			img: process.env.PUBLIC_URL + img,
			windowType: PRODUCTS[key].default.windowType,
		});
	});

	return productsResult;
}

export { find, all };
