import LoginForm from '../../components/LoginForm/LoginForm';
import NavbarLogo from '../../components/NavbarLogo/NavbarLogo';
import Layout0 from '../../layouts/Layout0/Layout0';

const LoginRoute = () => (
	<Layout0 navbar={NavbarLogo}>
		<LoginForm />
	</Layout0>
);

export default LoginRoute;
