import './Btn.css';

const Btn = (props) => (
	<button
		type={props.type ? props.type : 'button'}
		{...(props.value && { value: props.value })}
		className={`btn ${props.btnType}`}
		onClick={props.onClick}
	>
		{props.children}
	</button>
);
export default Btn;
