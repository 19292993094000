export default {
	DOUBLE_HUNG_PROD: {
		default: {
			description: 'Double Hung Window',
			url: '/product-images/double-hung-prod.png',
		},
	},
	DOUBLE_HUNG_PROD_1: {
		default: {
			description: 'Double Hung Window',
			url: '/product-images/double-hung-prod.png',
		},
	},
	DOUBLE_HUNG_PROD_2: {
		default: {
			description: 'Double Hung Window',
			url: '/product-images/double-hung-prod-2.png',
		},
	},
	DOUBLE_HUNG_PROD_3: {
		default: {
			description: 'Double Hung Window',
			url: '/product-images/double-hung-prod-3.png',
		},
	},
	DOUBLE_HUNG_PROD_4: {
		default: {
			description: 'Double Hung Window',
			url: '/product-images/double-hung-prod-4.png',
		},
	},
	DOUBLE_HUNG_PROD_5: {
		default: {
			description: 'Double Hung Window',
			url: '/product-images/double-hung-prod-5.png',
		},
	},
	DOUBLE_HUNG_PROD_6: {
		default: {
			description: 'Double Hung Window',
			url: '/product-images/double-hung-prod-6.png',
		},
	},
	DOUBLE_HUNG_PROD_7: {
		default: {
			description: 'Double Hung Window',
			url: '/product-images/double-hung-prod-7.png',
		},
	},
	DOUBLE_HUNG_PROD_8: {
		default: {
			description: 'Double Hung Window',
			url: '/product-images/double-hung-prod-8.png',
		},
	},
	DOUBLE_SLIDER_PROD: {
		default: {
			description: 'Double Slider Window',
			url: '/product-images/double-slider-prod.png',
		},
	},
	DOUBLE_SLIDER_PROD_1: {
		default: {
			description: 'Double Slider Window',
			url: '/product-images/double-slider-prod-1.png',
		},
	},
	DOUBLE_SLIDER_PROD_2: {
		default: {
			description: 'Double Slider Window',
			url: '/product-images/double-slider-prod-2.png',
		},
	},
	DOUBLE_SLIDER_PROD_3: {
		default: {
			description: 'Double Slider Window',
			url: '/product-images/double-slider-prod-3.png',
		},
	},
	DOUBLE_SLIDER_PROD_4: {
		default: {
			description: 'Double Slider Window',
			url: '/product-images/double-slider-prod-4.png',
		},
	},
	DOUBLE_SLIDER_PROD_5: {
		default: {
			description: 'Double Slider Window',
			url: '/product-images/double-slider-prod-5.png',
		},
	},
	SINGLE_CASEMENT_PROD: {
		default: {
			description: 'Single Casement Window',
			url: '/product-images/single-casement-prod.png',
		},
	},
	SINGLE_CASEMENT_PROD_1: {
		default: {
			description: 'Single Casement Window',
			url: '/product-images/single-casement-prod-1.png',
		},
	},
	SINGLE_CASEMENT_PROD_2: {
		default: {
			description: 'Single Casement Window',
			url: '/product-images/single-casement-prod-2.png',
		},
	},
	SINGLE_CASEMENT_PROD_3: {
		default: {
			description: 'Single Casement Window',
			url: '/product-images/single-casement-prod-3.png',
		},
	},
	SINGLE_CASEMENT_PROD_4: {
		default: {
			description: 'Single Casement Window',
			url: '/product-images/single-casement-prod-4.png',
		},
	},
	SINGLE_CASEMENT_PROD_5: {
		default: {
			description: 'Single Casement Window',
			url: '/product-images/single-casement-prod-5.png',
		},
	},
	DOUBLE_CASEMENT_PROD: {
		default: {
			description: 'Double Casement Window',
			url: '/product-images/double-casement-prod.png',
		},
	},
	AWNING_CASEMENT_PROD: {
		default: {
			description: 'Awning Casement Window',
			url: '/product-images/awning-casement-prod.png',
		},
	},
	AWNING_CASEMENT_PROD_1: {
		default: {
			description: 'Awning Casement Window',
			url: '/product-images/awning-casement-prod-1.png',
		},
	},
	AWNING_CASEMENT_PROD_2: {
		default: {
			description: 'Awning Casement Window',
			url: '/product-images/awning-casement-prod-2.png',
		},
	},
	AWNING_CASEMENT_PROD_3: {
		default: {
			description: 'Awning Casement Window',
			url: '/product-images/awning-casement-prod-3.png',
		},
	},
	AWNING_CASEMENT_PROD_4: {
		default: {
			description: 'Awning Casement Window',
			url: '/product-images/awning-casement-prod-4.png',
		},
	},
	AWNING_CASEMENT_PROD_5: {
		default: {
			description: 'Awning Casement Window',
			url: '/product-images/awning-casement-prod-5.png',
		},
	},
	CENTER_VENT_SLIDER_PROD: {
		default: {
			description: 'Center Vent Slider Window',
			url: '/product-images/center-vent-slider-prod.png',
		},
	},
	CENTER_VENT_SLIDER_PROD_1: {
		default: {
			description: 'Center Vent Slider Window',
			url: '/product-images/center-vent-slider-prod-1.png',
		},
	},
	CENTER_VENT_SLIDER_PROD_2: {
		default: {
			description: 'Center Vent Slider Window',
			url: '/product-images/center-vent-slider-prod-2.png',
		},
	},
	CENTER_VENT_SLIDER_PROD_3: {
		default: {
			description: 'Center Vent Slider Window',
			url: '/product-images/center-vent-slider-prod-3.png',
		},
	},
	CENTER_VENT_SLIDER_PROD_4: {
		default: {
			description: 'Center Vent Slider Window',
			url: '/product-images/center-vent-slider-prod-4.png',
		},
	},
	CENTER_VENT_SLIDER_PROD_5: {
		default: {
			description: 'Center Vent Slider Window',
			url: '/product-images/center-vent-slider-prod-5.png',
		},
	},
	END_VENT_SLIDER_PROD: {
		default: {
			description: 'End Vent Slider Window',
			url: '/product-images/end-vent-slider-prod.png',
		},
	},
	END_VENT_SLIDER_PROD_1: {
		default: {
			description: 'End Vent Slider Window',
			url: '/product-images/end-vent-slider-prod-1.png',
		},
	},
	END_VENT_SLIDER_PROD_2: {
		default: {
			description: 'End Vent Slider Window',
			url: '/product-images/end-vent-slider-prod-2.png',
		},
	},
	END_VENT_SLIDER_PROD_3: {
		default: {
			description: 'End Vent Slider Window',
			url: '/product-images/end-vent-slider-prod-3.png',
		},
	},
	END_VENT_SLIDER_PROD_4: {
		default: {
			description: 'End Vent Slider Window',
			url: '/product-images/end-vent-slider-prod-4.png',
		},
	},
	END_VENT_SLIDER_PROD_5: {
		default: {
			description: 'End Vent Slider Window',
			url: '/product-images/end-vent-slider-prod-5.png',
		},
	},
	END_VENT_SLIDER_PROD_6: {
		default: {
			description: 'End Vent Slider Window',
			url: '/product-images/end-vent-slider-prod-6.png',
		},
	},
	END_VENT_SLIDER_PROD_7: {
		default: {
			description: 'End Vent Slider Window',
			url: '/product-images/end-vent-slider-prod-7.png',
		},
	},
	END_VENT_SLIDER_PROD_8: {
		default: {
			description: 'End Vent Slider Window',
			url: '/product-images/end-vent-slider-prod-8.png',
		},
	},
	PICTURE_PROD: {
		default: {
			description: 'Picture Window',
			url: '/product-images/picture-prod.png',
		},
	},
	PICTURE_PROD_1: {
		default: {
			description: 'Picture Window',
			url: '/product-images/picture-prod-1.png',
		},
	},
	PICTURE_PROD_2: {
		default: {
			description: 'Picture Window',
			url: '/product-images/picture-prod-2.png',
		},
	},
	PICTURE_CASEMENT_PROD: {
		default: {
			description: 'Picture Casement Window',
			url: '/product-images/picture-casement-prod.png',
		},
	},
	SPECIALTY_PROD: {
		default: {
			description: 'Specialty Window',
			url: '/product-images/specialty-prod.png',
		},
	},
	SPECIALTY_PROD_1: {
		default: {
			description: 'Specialty Window',
			url: '/product-images/specialty-prod-1.png',
		},
	},
	SPECIALTY_PROD_2: {
		default: {
			description: 'Specialty Window',
			url: '/product-images/specialty-prod-2.png',
		},
	},
	SPECIALTY_PROD_3: {
		default: {
			description: 'Specialty Window',
			url: '/product-images/specialty-prod-3.png',
		},
	},
	SPECIALTY_PROD_4: {
		default: {
			description: 'Specialty Window',
			url: '/product-images/specialty-prod-4.png',
		},
	},
	SPECIALTY_PROD_5: {
		default: {
			description: 'Specialty Window',
			url: '/product-images/specialty-prod-5.png',
		},
	},
	SPECIALTY_PROD_6: {
		default: {
			description: 'Specialty Window',
			url: '/product-images/specialty-prod-6.png',
		},
	},
	SPECIALTY_PROD_7: {
		default: {
			description: 'Specialty Window',
			url: '/product-images/specialty-prod-7.png',
		},
	},
	SPECIALTY_PROD_8: {
		default: {
			description: 'Specialty Window',
			url: '/product-images/specialty-prod-8.png',
		},
	},
	HOPPER_PROD: {
		default: {
			description: 'Hopper Window',
			url: '/product-images/hopper-prod.png',
		},
	},
	HOPPER_PROD_1: {
		default: {
			description: 'Hopper Window',
			url: '/product-images/hopper-prod-1.png',
		},
	},
	GARDEN_PROD: {
		default: {
			description: 'Garden Window',
			url: '/product-images/garden-prod.png',
		},
	},
	DOOR_WALL_PROD: {
		default: {
			description: 'Doorwall Window',
			url: '/product-images/door-wall-prod.png',
		},
	},
	DOOR_WALL_PROD_1: {
		default: {
			description: 'Doorwall Window',
			url: '/product-images/door-wall-prod-1.png',
		},
	},
	DOOR_WALL_PROD_2: {
		default: {
			description: 'Doorwall Window',
			url: '/product-images/door-wall-prod-2.png',
		},
	},
	DOOR_WALL_PROD_3: {
		default: {
			description: 'Doorwall Window',
			url: '/product-images/door-wall-prod-3.png',
		},
	},
	DOOR_WALL_PROD_4: {
		default: {
			description: 'Doorwall Window',
			url: '/product-images/door-wall-prod-4.png',
		},
	},
	DOOR_WALL_PROD_5: {
		default: {
			description: 'Doorwall Window',
			url: '/product-images/door-wall-prod-5.png',
		},
	},
	DOOR_WALL_PROD_6: {
		default: {
			description: 'Doorwall Window',
			url: '/product-images/door-wall-prod-6.png',
		},
	},
	BAY_SHELL_PROD: {
		default: {
			description: 'Bay Shell Window',
			url: '/product-images/bay-shell-prod.png',
		},
	},
	BAY_SHELL_PROD_1: {
		default: {
			description: 'Bay Shell Window',
			url: '/product-images/bay-shell-prod-1.png',
		},
	},
	BAY_SHELL_PROD_2: {
		default: {
			description: 'Bay Shell Window',
			url: '/product-images/bay-shell-prod-2.png',
		},
	},
	BAY_SHELL_PROD_3: {
		default: {
			description: 'Bay Shell Window',
			url: '/product-images/bay-shell-prod-3.png',
		},
	},
	BOW_SHELL_PROD: {
		default: {
			description: 'Bow Shell Window',
			url: '/product-images/bow-shell-prod.png',
		},
	},
	BOW_SHELL_PROD_1: {
		default: {
			description: 'Bow Shell Window',
			url: '/product-images/bow-shell-prod-1.png',
		},
	},
	BOW_SHELL_PROD_2: {
		default: {
			description: 'Bow Shell Window',
			url: '/product-images/bow-shell-prod-2.png',
		},
	},
	BOW_SHELL_PROD_3: {
		default: {
			description: 'Bow Shell Window',
			url: '/product-images/bow-shell-prod-3.png',
		},
	},
	MISC_ITEM_PROD: {
		default: {
			description: 'Misc Item',
			url: '/product-images/misc-item-prod.png',
		},
	},
};
