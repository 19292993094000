import { useHistory } from 'react-router-dom';
import './ProductSelectNavBar.css';

const ProductSelectNavBar = ({ title }) => {
	const history = useHistory();
	return (
		<nav className="productselectionnavbar">
			<span className="productselectionnavbar__back">
				<button
					type="button"
					className="icon-fas icon--back icon--white icon--large"
					onClick={history.goBack}
				/>
			</span>
			<p className="productselectionnavbar__text heading heading--3 heading--white">
				{title}
			</p>
		</nav>
	);
};

export default ProductSelectNavBar;
