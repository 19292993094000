/**
 * Find the lowest x,y pair in an array of grid cells.
 */
export const findLowestXY = (cellArray) => {
	const temp = JSON.parse(JSON.stringify(cellArray));
	temp.sort((a, b) => {
		const axy = a.location[0].x + a.location[0].y;
		const bxy = b.location[0].x + b.location[0].y;

		return axy - bxy;
	});
	return temp[0].location[0];
};

/**
 * Given an array and a {height or width} return the formatted merged cell.
 */
export const formatMergedCell = (cellArray, configuration) => {
	const mergedCell = cellArray[0];

	const lowestXY = findLowestXY(cellArray);

	for (let i = 1; i < cellArray.length; i++) {
		mergedCell.location[0].x = lowestXY.x;
		mergedCell.location[0].y = lowestXY.y;
		mergedCell.product[`configuration_window_size_${configuration}`] +=
			cellArray[i].product[`configuration_window_size_${configuration}`];
	}

	return mergedCell;
};
