import React, { useEffect } from 'react';
import { useCurrentQuoteContext } from '../../../contexts/CurrentQuoteProductContext';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';
import { RenderMullionRemovalOption } from '../../../helpers';
import { usePartsGroupRelationships } from '../../../hooks';
import Input from '../../Input/Input';

const ProductConfigurationWindowInstructionRemoval = ({ configuration }) => {
	const { extensionJambsValue, cutbackSizeValue, buckValue, casingValue, stopOption } =
		usePartsGroupRelationships(configuration);
	// product context
	const {
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
		setPreviousQuoteProductContextValue,
	} = useCurrentQuoteContext();

	// Set to use the previous value
	const setPreviousContextValue = React.useCallback(() => {
		setPreviousQuoteProductContextValue(configuration.fieldName);
	}, [configuration.fieldName, setPreviousQuoteProductContextValue]);

	useEffect(() => {
		setPreviousContextValue();
	}, [setPreviousContextValue]);

	return configuration.groups.map((group) => {
		if (group.heading === 'Removal') {
			return group.options.map((groupOption, j) => {
				return (
					<React.Fragment key={'groupoption_' + '_' + j}>
						{groupOption.configurationType === 'boolean' ? (
							<>
								<div
									className="config-boolean-field"
									key={groupOption.fieldName}
								>
									<div className="config-heading heading heading--4">
										{groupOption.heading}
									</div>
									<Input
										type="toggle"
										id={groupOption.fieldName}
										name={groupOption.fieldName}
										checked={getQuoteProductContextProductValue(
											groupOption.fieldName
										)}
										onChange={(e) => {
											// update the value
											setQuoteProductContextProductValue(
												e.target.name,
												e.target.checked
											);
											// we have a toggle that only allows one or the other
											if (
												e.target.name ===
													'configuration_window_instructions_removal_baybow_removal' &&
												e.target.checked
											) {
												setQuoteProductContextProductValue(
													'configuration_window_instructions_removal_garden_removal',
													false
												);
											} else if (
												e.target.name ===
													'configuration_window_instructions_removal_garden_removal' &&
												e.target.checked
											) {
												setQuoteProductContextProductValue(
													'configuration_window_instructions_removal_baybow_removal',
													false
												);
											}
										}}
									/>
								</div>
								{groupOption.expand &&
								getQuoteProductContextProductValue(
									groupOption.fieldName
								) ? (
									<div className="productconfiguration__secondarydropdown">
										<label
											htmlFor={groupOption.expand.fieldName}
											className="config-subheading heading heading--6"
										>
											{groupOption.expand.heading}
										</label>
										{groupOption.expand.expandType === 'sqft' ? (
											<div className="config-number-field">
												<Input
													type={'text'}
													className={
														'input--1 input--1 input--sqft input--white'
													}
													id={groupOption.expand.fieldName}
													name={groupOption.expand.fieldName}
													placeholder="#"
													value={getQuoteProductContextProductValue(
														groupOption.expand.fieldName
													)}
													onBlur={(e) => {
														const v = parseFloat(
															e.target.value
														);

														setQuoteProductContextProductValue(
															e.target.name,
															isNaN(v) ? 0 : Math.max(0, v)
														);
													}}
													onChange={(e) => {
														setQuoteProductContextProductValue(
															e.target.name,
															e.target.value
														);
													}}
												/>
											</div>
										) : groupOption.expand.expandType === 'select' ? (
											<Input
												type={'select'}
												className={'input--1'}
												inputModifier={
													'input--select input--white'
												}
												id={groupOption.expand.fieldName}
												name={groupOption.expand.fieldName}
												values={groupOption.expand.values.map(
													function (value) {
														return {
															label: ConfigurationLabelMapper(
																value
															),
															value: value,
														};
													}
												)}
												value={getQuoteProductContextProductValue(
													groupOption.expand.fieldName
												)}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
										) : groupOption.expand.expandType === 'radio' ? (
											<div className="config-option-select">
												{groupOption.expand.values.map(
													(value) => {
														return (
															<React.Fragment key={value}>
																<input
																	type="radio"
																	name={
																		groupOption.expand
																			.fieldName
																	}
																	id={`${groupOption.expand.fieldName}_${value}`}
																	value={value}
																	checked={
																		value ===
																		getQuoteProductContextProductValue(
																			groupOption
																				.expand
																				.fieldName
																		)
																	}
																	onChange={(e) => {
																		setQuoteProductContextProductValue(
																			e.target.name,
																			e.target.value
																		);
																	}}
																/>
																<label
																	htmlFor={`${groupOption.expand.fieldName}_${value}`}
																	className="config-option-select__option"
																>
																	<div className="config-option-select__check"></div>
																	<div className="config-option-select__info">
																		{ConfigurationLabelMapper(
																			value
																		)}
																	</div>
																</label>
															</React.Fragment>
														);
													}
												)}
											</div>
										) : (
											''
										)}
										{groupOption.expand.notes && (
											<div className="heading heading--5">
												{groupOption.expand.notes}
											</div>
										)}
									</div>
								) : (
									''
								)}
							</>
						) : groupOption.configurationType === 'radio' ? (
							<>
								{j > 0 &&
								group.options[j - 1].configurationType !== 'radio' ? (
									<div className="config-divider"></div>
								) : (
									''
								)}
								<div className="config-heading heading heading--4">
									{groupOption.heading}
								</div>
								<div className="config-option-select">
									{groupOption.values.map((value) => {
										const thisValue =
											groupOption.optionId === 'CASING'
												? value.value
												: value;
										const isDisabled =
											(groupOption.optionId === 'BUCKS' &&
												extensionJambsValue &&
												thisValue === 'NONE') ||
											(groupOption.optionId === 'BUCKS' &&
												extensionJambsValue &&
												thisValue === 'FULL_BUCK') ||
											(groupOption.optionId === 'BUCKS' &&
												cutbackSizeValue &&
												thisValue !== 'FULL_BUCK') ||
											(groupOption.optionId === 'CASING' &&
												extensionJambsValue &&
												thisValue === 'NONE') ||
											(groupOption.optionId === 'CASING' &&
												cutbackSizeValue &&
												thisValue === 'NONE') ||
											(groupOption.optionId === 'STOPS' &&
												buckValue === 'PARTIAL_BUCK' &&
												thisValue === 'NONE') ||
											(groupOption.optionId === 'STOPS' &&
												casingValue === 'WHITE_VINYL' &&
												thisValue !== 'WHITE_VINYL');

										return (
											<React.Fragment key={thisValue}>
												<input
													type="radio"
													name={groupOption.fieldName}
													id={`${groupOption.fieldName}_${thisValue}`}
													value={thisValue}
													{...(isDisabled && {
														disabled: true,
													})}
													checked={
														!!(
															thisValue ===
															getQuoteProductContextProductValue(
																groupOption.fieldName
															)
														)
													}
													onChange={(e) => {
														setQuoteProductContextProductValue(
															e.target.name,
															e.target.value
														);
														// if we are changing the casing, then we want ot reset the sstops
														if (
															groupOption.optionId ===
																'CASING' &&
															stopOption
														) {
															setQuoteProductContextProductValue(
																stopOption.fieldName,
																''
															);
														}
													}}
												/>
												<label
													htmlFor={`${groupOption.fieldName}_${thisValue}`}
													className={`config-option-select__option ${
														isDisabled
															? 'config-option-select__option--inactive'
															: ''
													}`}
												>
													<div className="config-option-select__check"></div>
													<div className="config-option-select__info">
														{ConfigurationLabelMapper(
															thisValue
														)}
													</div>
												</label>
											</React.Fragment>
										);
									})}
								</div>
								{j < group.options.length - 1 ? (
									<div className="config-divider"></div>
								) : (
									''
								)}
							</>
						) : groupOption.configurationType === 'number' &&
						  RenderMullionRemovalOption(groupOption) ? (
							<>
								<div className="config-incremental-number-field">
									<div className="config-heading heading heading--4">
										{groupOption.heading}
									</div>
									<div className="qty-input qty-input__buttons">
										<button
											type="button"
											className="qty-input__minus"
											onClick={() => {
												setQuoteProductContextProductValue(
													groupOption.fieldName,
													Math.max(
														0,
														getQuoteProductContextProductValue(
															groupOption.fieldName
														) - 1
													)
												);
											}}
										>
											-
										</button>
										<input
											type="number"
											name={groupOption.fieldName}
											id={groupOption.fieldName}
											className="qty-input__input"
											step="1"
											min="0"
											max=""
											placeholder="#"
											value={getQuoteProductContextProductValue(
												groupOption.fieldName
											)}
											onChange={(e) => {
												setQuoteProductContextProductValue(
													e.target.name,
													parseFloat(e.target.value)
												);
											}}
										/>
										<button
											type="button"
											className="qty-input__plus"
											onClick={() => {
												setQuoteProductContextProductValue(
													groupOption.fieldName,
													getQuoteProductContextProductValue(
														groupOption.fieldName
													) + 1
												);
											}}
										>
											+
										</button>
									</div>
								</div>
							</>
						) : (
							''
						)}
					</React.Fragment>
				);
			});
		}
		return <div key={group.heading}>{group.heading}</div>;
	});
};

export default ProductConfigurationWindowInstructionRemoval;
