/**
 * This file should be used to create helper "generators" for the rest of
 * the project. An example is generating keys for mapped values when it
 * may be hard to generate them otherwise.
 */

export const generateKey = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};
