export default {
	CUSTOM: {
		default: {
			description: 'Custom',
			url: '/icons/custom-Icon.png',
		},
	},
	CUSTOM_ICON_SM: {
		default: {
			description: 'Custom Window',
			url: '/icons/custom-multi-unit-icon.png',
		},
	},
	MULTI_UNIT: {
		default: {
			description: 'Multi Unit',
			url: '/icons/multi-unit-icon.png',
		},
	},
};
