import React, { useContext, useEffect, useState } from 'react';
import IconBtn from '../../IconBtn/IconBtn';
import Input from '../../Input/Input';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import UIManagementContext from '../../../contexts/UIManagementContext';
import { centsToMoney } from '../../../functionality/Money';
import { toFixed } from '../../../helpers/Calculations';

const ProductConfigurationMiscItem = (props) => {
	// the ui management context
	const { openConfigurationData, toggleOpenConfiguration } =
		useContext(UIManagementContext);

	// context values
	const { setQuoteProductContextProductValue, getQuoteProductContextProductValue } =
		useContext(CurrentQuoteProductContext);

	// let's use state for thee price input
	const [priceValue, setPriceValue] = useState(
		centsToMoney({
			amount: getQuoteProductContextProductValue(
				props.configuration.price.fieldName
			),
			symbol: false,
		})
	);

	// create the section details
	const createSectionHeadingDetails = () => {
		return '';
	};

	// use an effect ot update wwhen the price changes outside this module
	const priceInCents = centsToMoney({
		amount: getQuoteProductContextProductValue(props.configuration.price.fieldName),
		symbol: false,
	});

	useEffect(() => {
		setPriceValue(priceInCents);
	}, [priceInCents]);

	// the component
	return (
		<div className="productconfiguration">
			<div
				className={`productconfiguration__section ${
					openConfigurationData() === props.orderIndex
						? 'productconfiguration__section--open'
						: ''
				}`}
			>
				<button
					type="button"
					className="productconfiguration__section-header"
					onClick={() => {
						toggleOpenConfiguration(props.orderIndex);
					}}
				>
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{props.orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">
							{props.configuration.heading}
						</div>
						<div className="heading heading--7">
							{createSectionHeadingDetails()}
						</div>
					</div>
					<div className="productconfiguration__section-toggle">
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--primary'}
							iconType={'icon--plus'}
						/>
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--secondary'}
							iconType={'icon--minus'}
						/>
					</div>
				</button>
				<div className="productconfiguration__section-body">
					<div className="productconfigurationmiscproduct">
						<div className="productconfigurationmiscproduct__topfields">
							<div className="productconfigurationmiscproduct__field">
								<label
									htmlFor={props.configuration.name.fieldName}
									className="heading heading--5"
								>
									{props.configuration.name.heading}
								</label>
								<Input
									type={'text'}
									id={props.configuration.name.fieldName}
									name={props.configuration.name.fieldName}
									className={'input--1'}
									placeholder="Enter name..."
									value={getQuoteProductContextProductValue(
										props.configuration.name.fieldName
									)}
									onChange={(e) => {
										setQuoteProductContextProductValue(
											props.configuration.name.fieldName,
											e.target.value
										);
									}}
								/>
							</div>
							<div className="productconfigurationmiscproduct__field">
								<label
									htmlFor={props.configuration.price.fieldName}
									className="heading heading--5"
								>
									{props.configuration.price.heading}
								</label>
								<Input
									type={'text'}
									id={props.configuration.price.fieldName}
									name={props.configuration.price.fieldName}
									className={'input--1 input--price'}
									placeholder="Enter price..."
									value={priceValue === '0' ? '' : priceValue}
									onBlur={(e) => {
										const valueDigitsPlusDecimal =
											e.target.value.replace(/[^0-9.]/g, '');
										const dollars = toFixed(
											valueDigitsPlusDecimal,
											100
										);

										const cents = isNaN(dollars)
											? 0
											: parseInt(dollars * 100);
										setPriceValue(
											centsToMoney({
												amount: cents,
												symbol: false,
											})
										);
										setQuoteProductContextProductValue(
											props.configuration.price.fieldName,
											cents
										);
									}}
									onChange={(e) => {
										setPriceValue(e.target.value);
									}}
								/>
							</div>
						</div>
						<label
							htmlFor={props.configuration.description.fieldName}
							className="heading heading--5"
						>
							{props.configuration.description.heading}
						</label>
						<textarea
							className="productconfigurationmiscproduct__textarea"
							id={props.configuration.description.fieldName}
							name={props.configuration.description.fieldName}
							placeholder="Enter Description..."
							value={getQuoteProductContextProductValue(
								props.configuration.description.fieldName
							)}
							onChange={(e) => {
								setQuoteProductContextProductValue(
									props.configuration.description.fieldName,
									e.target.value
								);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductConfigurationMiscItem;
