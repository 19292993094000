const FinalProductImage = ({ cells, code }) => {
	// Determine whether or not a Bay shell window is standard or equal.
	const findSections = () => {
		if (
			cells[0].product.configuration_window_size_width ===
			cells[1].product.configuration_window_size_width
		) {
			return 'equal-sections';
		}
		return 'standard-sections';
	};

	if (code === 'BOW_SHELL_PROD')
		return (
			<img
				src={`/configuration-images/styles/number-windows-${cells.length}.png`}
				alt="your-finished-product"
				className="h-1/2 w-1/2"
			/>
		);
	if (code === 'BAY_SHELL_PROD')
		return (
			<img
				src={`/configuration-images/styles/${findSections()}.png`}
				alt="your-finished-product"
				className="h-1/2 w-1/2"
			/>
		);
};

export default FinalProductImage;
