import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from '../../routes/ProtectedRoute/ProtectedRoute';
import PublicOnlyRoute from '../../routes/PublicOnlyRoute/PublicOnlyRoute';
import SessionDataContext from '../../contexts/SessionDataContext';
import LoginRoute from '../../routes/LoginRoute/LoginRoute';
import ForgotPasswordRoute from '../../routes/ForgotPasswordRoute/ForgotPasswordRoute';
import DashboardRoute from '../../routes/DashboardRoute/DashboardRoute';
import ViewQuoteRoute from '../../routes/ViewQuoteRoute/ViewQuoteRoute';
import PresentQuoteRoute from '../../routes/PresentQuoteRoute/PresentQuoteRoute';
import PricingQuoteRoute from '../../routes/PricingQuoteRoute/PricingQuoteRoute';
import ProductSelectionRoute from '../../routes/ProductSelectionRoute/ProductSelectionRoute';
import ProductConfigurationRoute from '../../routes/ProductConfigurationRoute/ProductConfigurationRoute';
import Overlays from '../Overlays/Overlays';
import SystemNotification from '../SystemNotification/SystemNotification';
import UIManagementContext from '../../contexts/UIManagementContext';
import MultiUnitSelectionRoute from '../../routes/MultiUnitSelectionRoute/Index';
import CustomWindowRoute from '../../routes/CustomWindowRoute/Index';
import UiExamples from '../../containers/UiExamples';

// setup the parse server
const Parse = require('parse');
Parse.initialize(process.env.REACT_APP_PARSE_APP_ID);
Parse.serverURL = process.env.REACT_APP_PARSE_URL + '/1';

// the app component
const App = (_props) => {
	// set the ui managment context
	const { systemNotification, overlayData } = useContext(UIManagementContext);
	const { activityIndicator } = useContext(SessionDataContext);

	// return the routes
	return (
		<>
			<Switch>
				{/* Public Routes */}
				<PublicOnlyRoute exact path={'/login'} component={LoginRoute} />
				<PublicOnlyRoute
					exact
					path={'/forgot-password'}
					component={ForgotPasswordRoute}
				/>

				{/* Private Routes */}
				<ProtectedRoute exact path={'/index.html'} component={DashboardRoute} />
				<ProtectedRoute exact path={'/'} component={DashboardRoute} />
				<ProtectedRoute exact path={'/dashboard'} component={DashboardRoute} />
				<ProtectedRoute
					exact
					path={`/quotes/:quoteId`}
					component={ViewQuoteRoute}
				/>
				<ProtectedRoute
					exact
					path={`/quotes/:quoteId/presentation`}
					component={PresentQuoteRoute}
				/>
				<ProtectedRoute
					exact
					path={`/quotes/:quoteId/pricing`}
					component={PricingQuoteRoute}
				/>
				<ProtectedRoute
					exact
					path={`/quotes/:quoteId/products/new`}
					component={ProductSelectionRoute}
				/>
				<ProtectedRoute
					exact
					path={'/quotes/:quoteId/products/:productId'}
					component={ProductConfigurationRoute}
				/>
				<ProtectedRoute
					exact
					path={'/quotes/:quoteId/products/new/multi-unit'}
					component={MultiUnitSelectionRoute}
				/>
				<ProtectedRoute
					exact
					path={'/quotes/:quoteId/products/new/multi-unit/:productId'}
					component={CustomWindowRoute}
				/>

				<Route path="/ui-examples">
					<UiExamples />
				</Route>

				{/* The catch all route */}
				<Route
					render={function () {
						return <h1>404 Error</h1>;
					}}
				/>
			</Switch>
			{overlayData().active && <Overlays />}
			{systemNotification.active && <SystemNotification />}
			{activityIndicator > 0 && !systemNotification.active && (
				<div className="icon-far activity-indicator"></div>
			)}
		</>
	);
};

export default App;
