/**
 * This file aims to clean up the reused logic for active quotes throughout the site.
 */
export const getProductList = (quoteProducts) => {
	const temp = [];
	for (const item of quoteProducts) {
		if (item.id && !item.code.includes('CUSTOM_WINDOW')) {
			temp.push(item);
		}

		if (item.values?.additional_items?.length > 0) {
			// Push the list of additional items itself but not the custom window.
			for (const prod of item.values.additional_items) {
				if (prod.id) {
					temp.push(prod);
				}
			}
		}
	}
	return temp;
};
