import React, { createContext, useReducer, useContext } from 'react';

export const SHOW_CONFIRM = 'SHOW_CONFIRM';
export const HIDE_CONFIRM = 'HIDE_CONFIRM';

const initialState = {
	show: false,
	text: '',
	confirmBtnText: '',
};

export const reducer = (_state = initialState, action) => {
	switch (action.type) {
		case SHOW_CONFIRM:
			return {
				show: true,
				text: action.payload.text,
				confirmBtnText: action.payload.confirmBtnText,
			};
		case HIDE_CONFIRM:
			return initialState;
		default:
			return initialState;
	}
};

export const useConfirmMessageReducer = () => {
	const [confirmMessageState, confirmMessageDispatch] = useReducer(
		reducer,
		initialState
	);
	return { confirmMessageState, confirmMessageDispatch };
};

export const ConfirmMessageContext = createContext();

export const useConfirmMessageContext = () => {
	return useContext(ConfirmMessageContext);
};

let resolveCallback;
export const useConfirmMessage = () => {
	const { confirmMessageState, confirmMessageDispatch } = useConfirmMessageContext();
	const onConfirm = () => {
		closeConfirm();
		resolveCallback(true);
	};

	const onCancel = () => {
		closeConfirm();
		resolveCallback(false);
	};
	const confirm = ({ text, confirmBtnText }) => {
		confirmMessageDispatch({
			type: SHOW_CONFIRM,
			payload: {
				text,
				confirmBtnText,
			},
		});
		return new Promise((res, _rej) => {
			resolveCallback = res;
		});
	};

	const closeConfirm = () => {
		confirmMessageDispatch({
			type: HIDE_CONFIRM,
		});
	};

	return { confirm, onConfirm, onCancel, confirmMessageState };
};

export const ConfirmMessageContextProvider = ({ children }) => {
	const { confirmMessageState, confirmMessageDispatch } = useConfirmMessageReducer();
	const value = { confirmMessageState, confirmMessageDispatch };
	return (
		<ConfirmMessageContext.Provider
			value={value}
			displayName="Confirm Message Context"
		>
			{children}
		</ConfirmMessageContext.Provider>
	);
};
