export default {
	// options for double hung
	OPTION_STYLE_DOUBLE_HUNG_NORMAL: {
		default: {
			description: '',
			url: '/configuration-images/options/normal.png',
		},
	},
	OPTION_STYLE_DOUBLE_HUNG_ORIEL: {
		default: {
			description: '',
			url: '/configuration-images/options/oriel.png',
		},
	},
	OPTION_STYLE_DOUBLE_HUNG_COTTAGE: {
		default: {
			description: '',
			url: '/configuration-images/options/cottage.png',
		},
	},

	// Options, Single Casement
	OPTION_STYLE_SINGLE_CASEMENT_LEFT_HINGE: {
		default: {
			description: '',
			url: '/configuration-images/options/option-style-single-casement-left-hinge.png',
		},
	},
	OPTION_STYLE_SINGLE_CASEMENT_RIGHT_HINGE: {
		default: {
			description: '',
			url: '/configuration-images/options/option-style-single-casement-right-hinge.png',
		},
	},

	// Options, End Vent SLider
	OPTION_STYLE_END_VENT_3_3_3: {
		default: {
			description: '',
			url: '/configuration-images/options/option-style-end-vent-3-3-3.png',
		},
	},
	OPTION_STYLE_END_VENT_4_2_4: {
		default: {
			description: '',
			url: '/configuration-images/options/option-style-end-vent-4-2-4.png',
		},
	},
	// options for double slider
	OPTION_STYLE_DOUBLE_SLIDER_NORMAL: {
		default: {
			description: '',
			url: '/configuration-images/options/option-style-double-slider-normal.png',
		},
	},
	OPTION_STYLE_DOUBLE_SLIDER_ORIEL: {
		default: {
			description: '',
			url: '/configuration-images/options/option-style-double-slider-oriel.png',
		},
	},
	OPTION_STYLE_DOUBLE_SLIDER_COTTAGE: {
		default: {
			description: '',
			url: '/configuration-images/options/option-style-double-slider-cottage.png',
		},
	},
	OPTION_STYLE_DOUBLE_SLIDER_TRACK_OPPOSITE: {
		default: {
			description: '',
			url: '/configuration-images/options/option-style-double-slider-track-opposite.png',
		},
	},
};
