const key_remember_me = 'wallside_remember_me';

// the remembver me functionality
const getRememberedLoginInfo = () => {
	// the intial data
	let data = {
		user_login: '',
		user_rememberme: false,
	};
	// now we'll look for this data in localstorage
	const localStorageData = localStorage.getItem(key_remember_me);
	if (localStorageData) {
		data = Object.assign(data, JSON.parse(localStorageData));
	}
	// return our remembered login data
	return data;
};

const setRememberedLoginInfo = (d) => {
	let data = Object.assign(
		{
			user_login: '',
			user_rememberme: false,
		},
		d
	);
	localStorage.setItem(key_remember_me, JSON.stringify(data));
};

const removeRememberedLoginInfo = () => {
	localStorage.removeItem(key_remember_me);
};

export { getRememberedLoginInfo, setRememberedLoginInfo, removeRememberedLoginInfo };
