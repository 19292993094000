import React from 'react';
import MullionLineItem from '../MullionLineItem';

export const WindowMullions = ({ groupName, product, quoteProduct }) => (
	<>
		{product.configurations[groupName].vinyl_total && (
			<MullionLineItem
				groupName={groupName}
				name="Vinyl Mullions:"
				product={product}
				quoteProduct={quoteProduct}
				total="vinyl_total"
			/>
		)}
		{product.configurations[groupName].mullions_wood_total && (
			<MullionLineItem
				groupName={groupName}
				name="Wood Mullions:"
				product={product}
				quoteProduct={quoteProduct}
				total="mullions_wood_total"
			/>
		)}
	</>
);
