import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import SessionDataContext from '../../contexts/SessionDataContext';

const ProtectedRoute = ({ component, ...props }) => {
	const Component = component;
	const { activeUser } = useContext(SessionDataContext);

	// return the route
	return (
		<Route
			{...props}
			render={(componentProps) =>
				// simplify the rendering method
				activeUser ? (
					<Component {...componentProps} />
				) : (
					<Redirect to={'/login'} />
				)
			}
		/>
	);
};

export default ProtectedRoute;
