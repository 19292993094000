import React, { Fragment } from 'react';
import ConfigurationLabelMapper from '../../../../../functionality/ConfigurationLabelMapper';
/**
 *
 */
export const GlassTab = ({ glass_config, glassData, setGlassData }) => {
	return (
		<>
			<div className="config-heading heading heading--4">
				{glass_config.type.heading}
			</div>
			<div className="config-option-select">
				{glass_config.type.values.map((value) => {
					return (
						<Fragment key={value}>
							<input
								type="radio"
								name={glass_config.type.fieldName}
								id={`${glass_config.type.fieldName}_${value}`}
								value={value}
								checked={value === glassData.selectedGlassTypeValue}
								onChange={(e) => {
									const value = e.currentTarget.value;
									setGlassData((prev) => {
										return {
											...prev,
											...{
												selectedGlassTypeValue: value,
											},
										};
									});
								}}
							/>
							<label
								htmlFor={`${glass_config.type.fieldName}_${value}`}
								className="config-option-select__option"
							>
								<div className="config-option-select__check"></div>
								<div className="config-option-select__info">
									{ConfigurationLabelMapper(value)}
								</div>
							</label>
						</Fragment>
					);
				})}
			</div>
		</>
	);
};
