import React from 'react';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';

export const WindowSill = ({ groupName, product, quoteProduct }) => (
	<>
		{product.configurations[groupName].interiorSill && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Interior Sill:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].interiorSill.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].interiorSill &&
			quoteProduct.values[
				product.configurations[groupName].interiorSill.fieldName
			] !== 'NONE' &&
			product.configurations[groupName].dimensions && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Interior Sill Dimensions:</dt>
						<dd className="heading heading--13">
							{ConfigurationLabelMapper(
								quoteProduct.values[
									product.configurations[groupName].dimensions.fieldName
								]
							)}
						</dd>
					</dl>
				</div>
			)}
		{product.configurations[groupName].exteriorSill && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Exterior Sill:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].exteriorSill.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product?.configurations[groupName]?.exteriorSill &&
			quoteProduct?.values[
				product?.configurations[groupName]?.exteriorSill?.fieldName
			] === 'STONE' &&
			product?.configurations[groupName]?.exteriorDimensions && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Exterior Sill Dimensions:</dt>
						<dd className="heading heading--13">
							{ConfigurationLabelMapper(
								quoteProduct.values[
									product.configurations[groupName].exteriorDimensions
										.fieldName
								]
							)}{' '}
							ft.
						</dd>
					</dl>
				</div>
			)}
	</>
);
