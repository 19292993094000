import React, { useContext, useEffect, useState } from 'react';
import Input from '../../Input/Input';
import { useCustomWindowContext } from '../../../containers/CustomWindow/customWindowContext';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import {
	setCommonWidthAction,
	updateRowsAction,
	updateColumnsAction,
} from '../../../containers/CustomWindow/Actions';
import ConfirmMessage from '../../Overlays/ConfirmMessage/index';
import { useConfirmMessage } from '../../Overlays/ConfirmMessage/confirmMessageContext';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';

/**
 * This component is our selector for the amount of columns and rows the form should get.
 * Any change here will communicate with a reducer action and update our customWindow
 * context appropriately.
 */
const GridStructureForm = ({ product }) => {
	const [commonWidthInput, setCommonWidthInput] = useState(1);
	const { customWindowState, customWindowDispatch } = useCustomWindowContext();
	const { getQuoteProductContextProductValue, setQuoteProductContextProductValue } =
		useContext(CurrentQuoteProductContext);
	const {
		columnsBaseSize,
		commonWidthList,
		dimensionsOfOpening,
		isGridColumnsEmpty,
		rowsBaseSize,
	} = customWindowState;

	const { confirm } = useConfirmMessage();

	const showConfirm = async () => {
		if (!isGridColumnsEmpty) {
			const isConfirmed = await confirm({
				text: 'Changing the grid structure will remove any products that have already been selected.',
				confirmBtnText: "Don't Save",
			});
			if (!isConfirmed) throw 'Canceled!';
		}
	};

	const updateRows = async (value) => {
		try {
			await showConfirm();
			customWindowDispatch(updateRowsAction(value));
			setCommonWidthInput('');
		} catch (e) {
			console.error('E:', e);
			return;
		}
	};

	const removeRow = async () => {
		if (rowsBaseSize === 1) return;
		try {
			await showConfirm();
			updateRows(-1);
			setCommonWidthInput('');
		} catch (e) {
			console.error('E:', e);
			return;
		}
	};

	const updateColumns = async (value) => {
		if (value < 1 && columnsBaseSize == 1) return;
		try {
			await showConfirm();
			customWindowDispatch(updateColumnsAction(value));
			setCommonWidthInput('');
		} catch (e) {
			console.error('E:', e);
			return;
		}
	};

	const selectOnChange = async (e) => {
		const value = e.target.value;

		if (value !== '') {
			try {
				await showConfirm();
				const commonWidth = dropDownOptions[value].obj;
				setCommonWidthInput(value);
				customWindowDispatch(setCommonWidthAction(commonWidth));
			} catch (e) {
				console.error('e', e);
				return;
			}
		}
	};

	const selectNewMaterial = (e) => {
		setQuoteProductContextProductValue(
			'configuration_window_style_material',
			e.target.value
		);
	};

	// Parse through our product to show the appropriate values.
	const dropdownLabel = product ? 'STYLE OPTIONS' : 'COMMON COLUMNS (WIDTH)';
	const bayShellList = [
		{ label: '3 (1/3, 1/3, 1/3)', value: 0, obj: { y: 3, x: [3, 3, 3] } },
		{ label: '3 (1/4, 1/2, 1/4)', value: 1, obj: { y: 3, x: [4, 2, 4] } },
	];
	const bowShellList = [
		{ label: '4 Windows', value: 0, obj: { y: 4, x: [4, 4, 4, 4] } },
		{
			label: '5 Windows',
			value: 1,
			obj: { y: 5, x: [5, 5, 5, 5, 5] },
		},
		{
			label: '6 Windows',
			value: 2,
			obj: { y: 6, x: [6, 6, 6, 6, 6, 6] },
		},
	];

	const isBayShell = (product) => {
		return product && product.code.includes('BAY_SHELL');
	};

	const isBowShell = (product) => {
		return product && product.code.includes('BOW_SHELL');
	};

	let dropDownOptions;

	if (isBayShell(product)) {
		dropDownOptions = bayShellList;
	} else if (isBowShell(product)) {
		dropDownOptions = bowShellList;
	} else {
		dropDownOptions = commonWidthList;
	}

	let selectedMaterialValue = '';
	if (product) {
		selectedMaterialValue = getQuoteProductContextProductValue(
			'configuration_window_style_material'
		);
	}

	/**
	 * When the comp first loads, we'll need to dispatch the default number of columns/cells
	 * if this is a Bow shell window.
	 * */
	useEffect(() => {
		if (isBowShell(product) && dimensionsOfOpening.width > 0) {
			const commonWidth = { y: 5, x: [5, 5, 5, 5, 5] };
			customWindowDispatch(setCommonWidthAction(commonWidth));
		} else if (isBayShell(product) && dimensionsOfOpening.width > 0) {
			const commonWidth = { y: 3, x: [4, 2, 4] };
			customWindowDispatch(setCommonWidthAction(commonWidth));
		} else {
			customWindowDispatch(setCommonWidthAction(commonWidthList[1].obj));
		}
	}, [dimensionsOfOpening]);

	return (
		<div className="flex flex-row gap-10 items-start border-b border-solid border-gray-300 pb-5">
			<div className="pr-10">
				<div className="heading heading--3">Window Grid</div>
				<div className="heading heading--7">
					Specify grid structure within the opening.
				</div>
			</div>

			{/* Common width size */}
			<div className="flex flex-col justify-items-start">
				<label
					htmlFor="fieldName"
					className="uppercase text-xs text-gray-500 mb-2"
				>
					{dropdownLabel}
				</label>
				<Input
					type={'select'}
					className={'input--1'}
					inputModifier={'input--select'}
					id="fieldName"
					name="fieldName"
					values={dropDownOptions}
					value={commonWidthInput}
					onChange={selectOnChange}
				/>
			</div>

			{product ? (
				<div className="flex flex-col">
					<div className="uppercase text-xs text-gray-500 mb-2">
						{product.configurations.WINDOW_STYLE.material.heading}
					</div>
					<div className="flex justify-start -my-2">
						{product.configurations.WINDOW_STYLE.material.values.map(
							(value) => (
								<div className="pt-2" key={value}>
									<input
										type="radio"
										name="configuration_window_style_material"
										id={`configuration_window_style_material_${value}`}
										value={value}
										checked={value === selectedMaterialValue}
										onChange={selectNewMaterial}
									/>
									<label
										htmlFor={`configuration_window_style_material_${value}`}
										className="config-option-select__material__option"
									>
										<div
											className={
												value === selectedMaterialValue
													? 'config-option-select__checked'
													: 'config-option-select__not_checked'
											}
										/>
										<div className="config-option-select__info">
											{ConfigurationLabelMapper(value)}
										</div>
									</label>
								</div>
							)
						)}
					</div>
				</div>
			) : (
				<>
					<div className="flex flex-col justify-items-start mx-2">
						<p className="uppercase text-xs text-gray-500 mb-2">
							Columns (width)
						</p>
						<div>
							<div className="qty-input qty-input__buttons">
								<button
									type="button"
									className="qty-input__minus"
									onClick={() => updateColumns(-1)}
								>
									-
								</button>
								<input
									type="number"
									name="fieldNameNumbersInput"
									id="fieldNameNumbersInput"
									className="qty-input__input"
									step="1"
									min="0"
									max=""
									placeholder="#"
									readOnly
									value={columnsBaseSize}
									disabled
								/>
								<button
									type="button"
									className="qty-input__plus"
									onClick={() => {
										updateColumns(1);
									}}
								>
									+
								</button>
							</div>
						</div>
					</div>

					<div className="flex flex-col justify-items-start">
						<p className="uppercase text-xs text-gray-500 mb-2">
							Rows (Height)
						</p>
						<div>
							<div className="qty-input qty-input__buttons">
								<button
									type="button"
									className="qty-input__minus"
									onClick={removeRow}
								>
									-
								</button>
								<input
									type="number"
									name={'fieldNameNumbersInput'}
									id={'fieldNameNumbersInput'}
									className="qty-input__input"
									step="1"
									min="0"
									max=""
									placeholder="#"
									readOnly
									value={rowsBaseSize}
								/>
								<button
									type="button"
									className="qty-input__plus"
									onClick={() => {
										updateRows(1);
									}}
								>
									+
								</button>
							</div>
						</div>
					</div>
				</>
			)}

			<ConfirmMessage />
		</div>
	);
};

export default GridStructureForm;
