import React from 'react';

export const MiscItem = ({ groupName, product, quoteProduct }) => (
	<>
		{product.configurations[groupName].name && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Name:</dt>
					<dd className="heading heading--13">
						{
							quoteProduct.values[
								product.configurations[groupName].name.fieldName
							]
						}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].description && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Description:</dt>
					<dd className="heading heading--13">
						{
							quoteProduct.values[
								product.configurations[groupName].description.fieldName
							]
						}
					</dd>
				</dl>
			</div>
		)}
	</>
);
