import React, { Fragment } from 'react';

// Mapped through our product.configurationGroups.group1, show some relevant data
const RenderProductDescriptionFragment = ({
	configurationKey,
	product,
	quoteProduct,
}) => {
	const configuration = product.configurations[configurationKey];
	const { fieldName, heading } = configuration;

	const getDescription = () => {
		if (configurationKey === 'OUTSIDE_INSTALL') {
			return quoteProduct.values[fieldName] === true ? 'Yes' : '';
		} else if (configurationKey === 'WINDOW_SIZE') {
			const { fieldNameHeight, fieldNameWidth } = configuration;
			const height = quoteProduct.values[fieldNameHeight];
			const width = quoteProduct.values[fieldNameWidth];
			return `Height: ${height}, Width: ${width}`;
		}
	};

	const description = getDescription();

	if (!description) {
		return null;
	}

	return (
		<Fragment>
			<dt className="heading heading--12">{heading}:</dt>
			<dd className="heading heading--13">{description}</dd>
		</Fragment>
	);
};

export default RenderProductDescriptionFragment;
