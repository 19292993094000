import React, { useContext, useEffect, useState } from 'react';
import IconBtn from '../IconBtn/IconBtn';
import ProductConfigurationWindowColors from '../ProductConfigurations/ConfigurationComponents/ProductConfigurationWindowColors';
import ProductConfigurationWindowLocation from '../ProductConfigurations/ConfigurationComponents/ProductConfigurationWindowLocation';
import ProductConfigurationBasicSelect from '../ProductConfigurations/ConfigurationComponents/ProductConfigurationBasicSelect';
import ProductConfigurationWindowRemovalType from '../ProductConfigurations/ConfigurationComponents/ProductConfigurationWindowRemovalType';
import ProductConfigurationWindowOutsideInstall from '../ProductConfigurations/ConfigurationComponents/ProductConfigurationWindowOutsideInstall';
import ProductConfigurationWindowLeaveExisting from '../ProductConfigurations/ProductConfigurationWindowLeaveExisting/ProductConfigurationWindowLeaveExisting';
import ProductConfigurationWindowInstructionRemoval from '../ProductConfigurations/ProductConfigurationWindowInstructionRemoval/ProductConfigurationWindowInstructionRemoval';
import ProductConfigurationWindowNote from '../ProductConfigurations/ConfigurationComponents/ProductConfigurationWindowNote';
import ProductConfigurationWindowSill from '../ProductConfigurations/ConfigurationComponents/ProductConfigurationWindowSill';
import ProductConfigurationWindowSeat from '../ProductConfigurations/ConfigurationComponents/ProductConfigurationWindowSeat';
import ProductConfigurationWindowRoof from '../ProductConfigurations/ConfigurationComponents/ProductConfigurationWindowRoof';
import CurrentQuoteProductContext from '../../contexts/CurrentQuoteProductContext';
import GridReadOnly from '../../components/CustomWindowLayout/Grid/GridReadOnly';
import { updateCustomWindowGeneralSpecifiAction } from '../../contexts/store/actions';
import { useUIManagementContext } from '../../contexts/UIManagementContext';
import { useCustomWindowContext } from '../../containers/CustomWindow/customWindowContext';
import { commitLayoutAction } from '../../containers/CustomWindow/Actions';

const CustomWindowGeneralSpecifications = ({
	code,
	globalDispatch,
	globalState,
	orderIndex,
	productValues,
	triggerEdit,
}) => {
	const { customWindowDispatch, customWindowState } = useCustomWindowContext();
	const { customWindowLayoutCommitted } = customWindowState;

	// the ui management context
	const {
		openCustomWindowConfigurationData,
		toggleCustomWindowOpenConfiguration,
		toggleOverlay,
	} = useUIManagementContext();

	const customWindowConfigurationData = openCustomWindowConfigurationData();

	// Product context
	const { product, quoteProductReset, product_values } = useContext(
		CurrentQuoteProductContext
	);

	// use some state
	const [ready, setReady] = useState(false);

	const dataRender = () => {
		const productCode = 'CUSTOM_WINDOW';
		const productValues = globalState.customWindow.generalSpecifications;
		quoteProductReset(productCode, productValues, '');
		setReady(true);
	};

	useEffect(dataRender, [customWindowConfigurationData === orderIndex]);

	// This only needs to fire if we are editing a product.
	useEffect(() => {
		const search = window.location.pathname;

		if (search.includes('productid=')) {
			customWindowDispatch(commitLayoutAction());
			triggerEdit();
		}
	}, []);

	const saveSpecification = () => {
		if (product.code === 'CUSTOM_WINDOW') {
			globalDispatch(updateCustomWindowGeneralSpecifiAction(product_values));
		}
	};

	useEffect(saveSpecification, [customWindowConfigurationData === orderIndex]);

	const toggleCustomWindowOpenConfigurationHandler = () => {
		if (customWindowLayoutCommitted) {
			toggleCustomWindowOpenConfiguration(orderIndex);
		} else {
			toggleOverlay({
				active: true,
				type: 'error_messages',
				data: {
					errors: ['Please create the layout first!'],
				},
			});
		}
	};

	// Determine if this thing is a bay or bow shell window.
	const showBayBow = code.includes('BAY_SHELL') || code.includes('BOW_SHELL');

	return (
		<div
			className={`productconfiguration ${
				!customWindowLayoutCommitted && 'opacity-50'
			}`}
		>
			<div
				className={`productconfiguration__section ${
					customWindowConfigurationData === orderIndex
						? 'productconfiguration__section--open'
						: ''
				}`}
			>
				<button
					type="button"
					className="productconfiguration__section-header"
					onClick={toggleCustomWindowOpenConfigurationHandler}
				>
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">General Specifications</div>
						<div className="heading heading--7">
							Window Location | House Type | Removal Type | Outside Install
						</div>
					</div>
					<div className="flex flex-row items-center productconfiguration__section-toggle">
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--primary'}
							iconType={'icon--plus'}
						/>
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--secondary'}
							iconType={'icon--minus'}
						/>
					</div>
				</button>

				{ready && (
					<div className="productconfiguration__section-body">
						{customWindowConfigurationData === orderIndex && (
							<>
								{/* END: Section body */}

								<h3 className="mb-8 font-semibold">
									Location & House Details
								</h3>
								<div className="grid grid-cols-2">
									<div>
										<div className="grid grid-cols-2 gap-12">
											<div className="space-y-6">
												<ProductConfigurationWindowLocation
													configuration={
														product.configurations
															.WINDOW_LOCATION
													}
												/>
												<ProductConfigurationBasicSelect
													configuration={
														product.configurations.HOUSE_TYPE
													}
												/>
												<ProductConfigurationWindowRemovalType
													configuration={
														product.configurations
															.REMOVAL_TYPE
													}
												/>
											</div>
											<div className="flex items-end">
												<ProductConfigurationWindowOutsideInstall
													configuration={
														product.configurations
															.OUTSIDE_INSTALL
													}
												/>
											</div>
										</div>
									</div>
									{/* Grid Read Only */}
									<GridReadOnly
										orderIndex={orderIndex}
										productValues={productValues}
									/>
								</div>

								{showBayBow && (
									<>
										<hr className="w-2/5 my-8 border-gray-200 border-solid border-1" />
										<strong>Color</strong>
										<ProductConfigurationWindowColors
											configuration={
												product.configurations.WINDOW_COLOR
											}
											orderIndex={orderIndex}
										/>
									</>
								)}

								{showBayBow ? (
									<>
										{/* Divider */}
										<hr className="w-2/5 my-8 border-gray-200 border-solid border-1" />
										{/* Sill */}
										<ProductConfigurationWindowSill
											isBayBow={true}
											configuration={
												product.configurations.WINDOW_SILL
											}
										/>
										{/* Seat */}
										<ProductConfigurationWindowSeat
											isBayBow={true}
											configuration={
												product.configurations.WINDOW_SEAT
											}
										/>
										{/* Roof */}
										<ProductConfigurationWindowRoof
											isBayBow={true}
											configuration={
												product.configurations.WINDOW_ROOF
											}
										/>
									</>
								) : (
									<>
										{/* Divider */}
										<hr className="w-2/5 my-8 border-gray-200 border-solid border-1" />
										<h3 className="my-8 font-semibold">Mullions</h3>
										<ProductConfigurationWindowLeaveExisting
											isBayBow={showBayBow}
											configuration={
												product.configurations.WINDOW_MULLIONS
											}
										/>
									</>
								)}
								<ProductConfigurationWindowInstructionRemoval
									isBayBow={showBayBow}
									configuration={
										product.configurations.WINDOW_INSTRUCTIONS
									}
									qpValues={productValues}
								/>

								{/* Divider */}
								<hr className="w-2/5 my-8 border-gray-200 border-solid border-1" />
								<ProductConfigurationWindowNote
									configuration={product.configurations.WINDOW_NOTE}
								/>
							</>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default CustomWindowGeneralSpecifications;
