import React from 'react';
import './Layout2.css';

const Layout2 = (props) => {
	return (
		<div className="layout2">
			<header className="layout2__navbar">
				{props.navbar ? props.navbar : ''}
			</header>
			<div className="layout2__main">
				<div
					className={`layout2__content ${
						props.contentClass ? props.contentClass : ''
					}`}
				>
					{props.children}
				</div>
			</div>
		</div>
	);
};

export default Layout2;
