import { customWindowConstants } from './customWindowContext';

const {
	OPEN_MODAL,
	CLOSE_MODAL,
	DIMENSIONS_OF_OPENING_INPUTS_CHANGE,
	COMMIT_WINDOW_SIZE,
	CUSTOM_WINDOW_RESET,
	SET_COMMON_WIDTH,
	ADD_CUSTOM_WINDOW_PRODUCT,
	UPDATE_CUSTOM_WINDOW_GENERAL_SPECIFICATIONS,
	SELECT_CELL,
	RESET_SELECTED_CELLS,
	COMMIT_LAYOUT,
	MERGE_CELLS,
	UNMERGE_CELLS,
	UPDATE_ROWS,
	UPDATE_COLUMNS,
	UPDATE_CELLS,
	DELETE_PRODUCT,
	UPDATE_GRID_DIMENSION,
	EDIT_MENU_TOGGLE,
	RESIZE_GRID_DIMENSION,
	UPDATE_RESIZING_DIMENSION_INPUTS,
	UPDATE_DEFAULT_RESIZING_DIMENSION_INPUTS,
	RESET_RESIZING_DIMENSION_INPUTS,
} = customWindowConstants;

// Modal
export const openModalAction = (payload) => {
	return {
		type: OPEN_MODAL,
		payload,
	};
};
export const closeModalAction = () => {
	return {
		type: CLOSE_MODAL,
	};
};
// Merge columns
export const selectCellsAction = (payload) => {
	return {
		type: SELECT_CELL,
		payload,
	};
};
export const resetSelectedCellsAction = () => {
	return {
		type: RESET_SELECTED_CELLS,
	};
};

export const dimensionsOfOpeningInputsAction = (payload) => {
	return {
		type: DIMENSIONS_OF_OPENING_INPUTS_CHANGE,
		payload,
	};
};

export const commitOpeningDimensionAction = (payload) => {
	return {
		type: COMMIT_WINDOW_SIZE,
		payload,
	};
};

export const customWindowResetAction = () => {
	return {
		type: CUSTOM_WINDOW_RESET,
	};
};

export const setCommonWidthAction = (payload) => {
	return {
		type: SET_COMMON_WIDTH,
		payload,
	};
};

export const addCustomWindowProductAction = (payload) => {
	return {
		type: ADD_CUSTOM_WINDOW_PRODUCT,
		payload,
	};
};

export const updateCustomWindowGeneralSpecifiAction = (payload) => {
	return {
		type: UPDATE_CUSTOM_WINDOW_GENERAL_SPECIFICATIONS,
		payload,
	};
};

export const commitLayoutAction = (payload) => {
	return {
		type: COMMIT_LAYOUT,
		payload,
	};
};

export const mergeCellsAction = (payload) => {
	return {
		type: MERGE_CELLS,
		payload,
	};
};

export const unmergeCellsAction = (payload) => {
	return {
		type: UNMERGE_CELLS,
		payload,
	};
};

export const updateRowsAction = (payload) => {
	return {
		type: UPDATE_ROWS,
		payload,
	};
};

export const updateColumnsAction = (payload) => {
	return {
		type: UPDATE_COLUMNS,
		payload,
	};
};

export const updateCells = (payload) => {
	return {
		type: UPDATE_CELLS,
		payload,
	};
};

export const deleteProductAction = (payload) => {
	return {
		type: DELETE_PRODUCT,
		payload,
	};
};

export const updateGridDimensionAction = (payload) => {
	return {
		type: UPDATE_GRID_DIMENSION,
		payload,
	};
};

export const editMenuToggleAction = (payload) => {
	return {
		type: EDIT_MENU_TOGGLE,
		payload,
	};
};

export const resizeGridDimensionAction = (payload) => {
	return {
		type: RESIZE_GRID_DIMENSION,
		payload,
	};
};

export const updateDefaultResizingDimensionInputs = (payload) => {
	return {
		type: UPDATE_DEFAULT_RESIZING_DIMENSION_INPUTS,
		payload,
	};
};

export const updateResizingDimensionInputsAction = (payload) => {
	return {
		type: UPDATE_RESIZING_DIMENSION_INPUTS,
		payload,
	};
};

export const resetResizingDimensionInputsAction = (payload) => {
	return {
		type: RESET_RESIZING_DIMENSION_INPUTS,
		payload,
	};
};
