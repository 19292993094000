import React from 'react';
import IconBtn from '../IconBtn/IconBtn';
import './Pagination.css';

const Pagination = (props) => {
	// we'll use some props
	const offset = props.offset || 0;
	const limit = props.limit || 0;
	const total = props.total || 0;

	// determine if the previous button is available
	const allowBackward = offset > 0;

	// determine if the next button is available
	const allowForward = offset + limit < total;

	// event to handle forward, backward
	const handleBackward = function (e) {
		if (allowBackward && props.onBackward) {
			props.onBackward(e);
		}
	};
	const handleForward = function (e) {
		if (allowForward && props.onForward) {
			props.onForward(e);
		}
	};

	// the component ui
	return (
		<div className="pagination">
			<div className="pagination__back">
				<IconBtn
					iconWeight="icon-fal"
					btnType={
						'iconbtn--solid' + (allowBackward ? '' : ' iconbtn--inactive')
					}
					iconType={'icon--back'}
					onClick={handleBackward}
				></IconBtn>
			</div>
			<div className="pagination__forward">
				<IconBtn
					iconWeight="icon-fal"
					btnType={
						'iconbtn--solid' + (allowForward ? '' : ' iconbtn--inactive')
					}
					iconType={'icon--forward'}
					onClick={handleForward}
				></IconBtn>
			</div>
		</div>
	);
};

export default Pagination;
