import React from 'react';
import Btn from '../../Btn/Btn';
import WallsideError from '../../../functionality/WallsideError';
import { centsToMoney } from '../../../functionality/Money';

export const DiscountItem = ({
	discount,
	discounts,
	index,
	paymentOption,
	saveQuote,
	toggleSystemNotification,
}) => {
	const amount = centsToMoney({
		amount: discount[
			paymentOption === 'paynow' ? 'cashValueInCents' : 'financingValueInCents'
		],
	});

	const removeDiscount = () => {
		// remove this discount by index
		discounts.splice(index, 1);
		// now resave the quote
		saveQuote().then(
			() => {
				// show the notification
				toggleSystemNotification({
					active: true,
					messageKey: 'save_quote',
				});
			},
			(e) => {
				const error = new WallsideError(e);
				alert(error.globalErrors[0].message);
			}
		);
	};

	return (
		<div className="presentationbody__discount presentationbody__discount--active">
			<div className="presentationbody__discount-info">
				<p className="presentationbody__discount__strong heading heading--4">
					{discount.title}
				</p>
				<p className="heading heading--5 heading--inactive">
					{discount.description}
				</p>
			</div>
			<div className="presentationbody__discount-info">
				<p className="presentationbody__discount__strong heading heading--4">
					{amount}
				</p>
				<p className="heading heading--6">{/*Expires 10/3/19*/}</p>
			</div>
			<Btn
				btnType="btn--tertiary presentationbody__discount-btn"
				onClick={removeDiscount}
			>
				Remove
			</Btn>
		</div>
	);
};
