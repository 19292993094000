import { useReducer } from 'react';
import {
	OPEN_MODAL,
	CLOSE_MODAL,
	UPDATE_FORM_INPUTS_OBJECT,
	DROPPED_WINDOW_LOCATION,
	SELECT_COLUMN,
	RESET_SELECTED_COLUMNS,
	ADD_CUSTOM_WINDOW_PRODUCT,
	UPDATE_CUSTOM_WINDOW_GENERAL_SPECIFICATIONS,
	SET_SELECTED_CUSTOM_PRODUCT,
} from './constants';

const reducer = (state, action) => {
	switch (action.type) {
		case OPEN_MODAL:
			return {
				...state,
				openModalState: true,
				openModalData: action.payload,
			};
		case CLOSE_MODAL:
			return {
				...state,
				openModalState: false,
				openModalData: false,
			};
		case UPDATE_FORM_INPUTS_OBJECT:
			return {
				...state,
				formState: action.payload,
			};
		case DROPPED_WINDOW_LOCATION:
			return {
				...state,
				droppedWindowLocation: action.payload,
			};
		case SELECT_COLUMN: {
			const col = action.payload;
			const selectedColumns = state.selectedColumns;

			// If the user has already selected the column, deselect it.
			if (selectedColumns.includes(col)) {
				const id = selectedColumns.indexOf(col);
				selectedColumns.splice(id, 1);
			} else {
				selectedColumns.push(action.payload);
			}

			return {
				...state,
				selectedColumns,
			};
		}
		case RESET_SELECTED_COLUMNS:
			return {
				...state,
				selectedColumns: [],
			};

		/* Attach a custom window to a cell on our grid. */
		case ADD_CUSTOM_WINDOW_PRODUCT: {
			const { rowKey, colKey, col } = state.openModalData;
			const newCustomWindowColState = state.customWindow.columns;
			newCustomWindowColState[rowKey][colKey].product = {
				...action.payload,
				code: col.code,
			};
			return {
				...state,
				customWindow: { ...state.customWindow, columns: newCustomWindowColState },
			};
		}
		case UPDATE_CUSTOM_WINDOW_GENERAL_SPECIFICATIONS:
			return {
				...state,
				customWindow: {
					...state.customWindow,
					generalSpecifications: action.payload,
				},
			};
		case SET_SELECTED_CUSTOM_PRODUCT: {
			const updatedProduct = action.payload;
			return {
				...state,
				selectedCustomProduct: updatedProduct,
			};
		}

		default:
			return { state };
	}
};

const initializerArg = {
	payload: null,
	generalSettings: {},
	loading: false,
	errors: false,
	errorsMsg: '',
	openModalState: false,
	openModalData: false,
	selectedCustomProduct: 'CUSTOM_PRODUCT',
	droppedWindowLocation: {},
	selectedColumns: [],
	customWindow: {
		totalWidthInUnit: 300,
		totalHeightInUnit: 300,
		totalCalculatedSize: { width: 24, height: 44, total: 66 },
		rows: [{}, {}, {}],
		generalSpecifications: {},
		columns: [
			[
				{
					location: [
						{ y: 0, x: 0 },
						{ y: 0, x: 1 },
					],
					colspan: 2,
					rowspan: 0,
					product: {
						configuration_window_size_height: 100,
						configuration_window_size_width: 200,
					},
				},
				{
					location: [{ y: 0, x: 2 }],
					colspan: 0,
					rowspan: 0,
					product: {
						configuration_window_size_height: 100,
						configuration_window_size_width: 100,
					},
				},
			],
			[
				{
					location: [{ y: 1, x: 0 }],
					colspan: 0,
					rowspan: 0,
					product: {
						configuration_window_size_height: 100,
						configuration_window_size_width: 100,
					},
				},
				{
					location: [{ y: 1, x: 1 }],
					colspan: 0,
					rowspan: 0,
					product: {
						configuration_window_size_height: 100,
						configuration_window_size_width: 100,
					},
				},
				{
					location: [{ y: 1, x: 2 }],
					colspan: 0,
					rowspan: 0,
					product: {
						configuration_window_size_height: 100,
						configuration_window_size_width: 100,
					},
				},
			],
			[
				{
					location: [{ y: 2, x: 0 }],
					colspan: 0,
					rowspan: 2,
					product: {
						configuration_window_size_height: 100,
						configuration_window_size_width: 100,
					},
				},
				{
					location: [{ y: 2, x: 1 }],
					colspan: 0,
					rowspan: 0,
					product: {
						configuration_window_size_height: 100,
						configuration_window_size_width: 100,
					},
				},
				{
					location: [{ y: 2, x: 2 }],
					colspan: 0,
					rowspan: 0,
					product: {
						configuration_window_size_height: 100,
						configuration_window_size_width: 100,
					},
				},
			],
		],
	},
};

const useGlobalState = () => {
	const [globalState, globalDispatch] = useReducer(reducer, initializerArg);
	return { globalState, globalDispatch };
};
export default useGlobalState;
