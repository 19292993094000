import { useHistory } from 'react-router-dom';
import './SimpleNavbar.css';

const SimpleNavbar = ({ title }) => {
	// we need the history object
	const history = useHistory();

	// the component ui
	return (
		<div className="simplenavbar">
			<button
				type="button"
				className="icon-fas icon--back icon--white icon--large"
				onClick={() => {
					history.goBack();
				}}
			/>
			<p className="heading heading--3 heading--white">{title}</p>
		</div>
	);
};

export default SimpleNavbar;
