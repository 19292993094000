export default {
	WHITE: 'White',
	ALMOND: 'Almond',
	MEDIUM_WOOD: 'Medium Woodgrain',
	DARK_WOOD: 'Dark Woodgrain',
	BLACK: 'Black',
	CLAY: 'Clay',
	TERRATONE: 'Terratone',
	PEARL_GRAY_102: 'Pearl Gray (102)',
	ANTIQUE_IVORY_105: 'Antique Ivory (105)',
	TUXEDO_GRAY_112: 'Tuxedo Gray (112)',
	BROWN_190: 'Brown (190)',
	BLACK_200: 'Black (200)',
	ROYAL_BROWN_202: 'Royal Brown (202)',
	GRECIAN_GREEN_204: 'Grecian Green (204)',
	SCOTCH_RED_209: 'Scotch Red (209)',
	WOODLAND_GREEN_214: 'Woodland Green (214)',
	AUTUMN_RED_215: 'Autumn Red (215)',
	IMPERIAL_BROWN_223: 'Imperial Brown (223)',
	BUCKSKIN_224: 'Buckskin (224)',
	FLAGSTONE_232: 'Flagstone (232)',
	HICKORY_CLAY_238: 'Hickory Clay (238)',
	MUSKET_BROWN_250: 'Musket Brown (250)',
	WHITE_280: 'White (280)',
	SILVER_ASH_318: 'Silver Ash (318)',
	SANDSTONE_BEIGE_325: 'Sandstone Beige (325)',
	DESERT_TAN_327: 'Desert Tan (327)',
	LIGHT_MAPLE_355: 'Light Maple (355)',
	TERRATONE_360: 'Terratone (360)',
	OXFORD_BLUE_396: 'Oxford Blue (396)',
	WICKER_713: 'Wicker (713)',
	NORWEGIAN_WOOD_791: 'Norwegian Wood (791)',
	HEATHER_792: 'Heather (792)',
	SANDCASTLE_793: 'Sandcastle (793)',
	ALMOND_801: 'Almond (801)',
	PEWTER_805: 'Pewter (805)',
	CREAM_817: 'Cream (817)',
	BRONZE_818: 'Bronze (818)',
	HERRINGBONE_819: 'Herringbone (819)',
	CRANBERRY_820: 'Cranberry (820)',
	IVY_821: 'Ivy (821)',
	PACIFIC_BLUE_822: 'Pacific Blue (822)',
	CHAMPAGNE_827: 'Champagne (827)',
	BUCKSKIN_841: 'Buckskin (841)',
	IVORY_901: 'Ivory (901)',
	PVC_WHITE_800: 'PVC White (800)',
	PVC_ALMOND_801: 'PVC Almond (801)',
	PVC_PACIFIC_BLUE_27: 'PVC Pacific Blue (27)',
	PVC_IVY_28: 'PVC Ivy (28)',
	PVC_PEWTER_805: 'PVC Pewter (805)',
	PVC_CLAY_907: 'PVC Clay (907)',
	PVC_WICKER_PVC_SHALE_810: 'PVC Wicker, PVC Shale (810)',
	PVC_CREAM_817: 'PVC Cream (817)',
	PVC_HERRINGBONE_819: 'PVC Herringbone (819)',
	PVC_ICE_832: 'PVC Ice (832)',
	PVC_SAND_833: 'PVC Sand (833)',
	PVC_WHEAT_836: 'PVC Wheat (836)',
	PVC_IVORY_901: 'PVC Ivory (901)',
	PVC_MUSKET_BROWN_909: 'PVC Musket Brown (909)',
	PVC_DESERT_TAN: 'PVC Desert Tan',
	BRONZEH: 'Bronze H.',
	DARK_BROWN: 'Dark Brown',
	WHITE_SLIM: 'White',
	GOLD_SLIM: 'Gold',
	BRONZE_SLIM: 'Bronze',
	ARCTIC_WHITE_S06: 'Arctic White (S06)',
	ALMOND_S02: 'Almond (S02)',
	WHITE_QUARTZ_G04: 'White Quartz (G04)',
	ROSE_QUARTZ_G06: 'Rose Quartz (G06)',
	PLATINUM_GRANITE_G07: 'Platinum Granite (G07)',
	BLACK_PEARL_G10: 'Black Pearl (G10)',
	VANILLA_SUGAR_GT913: 'Vanilla Sugar (GT913)',
	CAMEO_WHITE: 'Cameo White',
	BEACH: 'Beach',
	SANDSTONE: 'Sandstorm',
	DUSK: 'Dusk',
	AUTUMN_BROWN: 'Autumn Brown',
	ASPEN_GRAY: 'Aspen Gray',
	AMBER: 'Amber',
	BARKWOOD: 'Barkwood',
	BROWNWOOD: 'Brownwood',
	CHAPEL_GRAY: 'Chapel Gray',
	CHARCOAL: 'Charcoal',
	CHATEAU_GREEN: 'Chateau Green',
	CANYON_RED: 'Canyon Red',
	COLONIAL_SLATE: 'Colonial Slate',
	DESERT_TAN: 'Desert Tan',
	DRIFTWOOD: 'Driftwood',
	ESTATE_GRAY: 'Estate Gray',
	HARBOR_BLUE: 'Harbor Blue',
	HICKORY: 'Hickory',
	HUNTER_GREEN: 'Hunter Green',
	ONYX_BLACK: 'Onyx Black',
	QUARRY_GRAY: 'Quarry Gray',
	SURF_GREEN: 'Surf Green',
	SHAKEWOOD: 'Shakewood',
	SIERRA_GRAY: 'Sierra Gray',
	SLATE: 'Slate',
	SLATESTONE_GRAY: 'Slatestone Gray',
	SHASTA_WHITE: 'Shasta White',
	TERRACOTTA: 'Terracotta',
	TEAK: 'Teak',
	WEATHERED_WOOD: 'Weathered Wood',
};
