import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import { BrowserRouter } from 'react-router-dom';
import { CurrentQuoteProductContextProvider } from './contexts/CurrentQuoteProductContext';
import { UIManagementContextProvider } from './contexts/UIManagementContext';
import { SessionDataProvider } from './contexts/SessionDataContext';
import GlobalStateProvider from './contexts/store/GlobalStateProvider';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<BrowserRouter>
		<UIManagementContextProvider>
			<SessionDataProvider>
				<CurrentQuoteProductContextProvider>
					<GlobalStateProvider>
						<App />
					</GlobalStateProvider>
				</CurrentQuoteProductContextProvider>
			</SessionDataProvider>
		</UIManagementContextProvider>
	</BrowserRouter>,

	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
