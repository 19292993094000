import React, { useContext } from 'react';
import ProductMapper from '../functionality/ProductMapper.js';
import SessionData from './SessionDataContext.js';

// the basic context
const CurrentQuoteProductContext = React.createContext();
export default CurrentQuoteProductContext;

export const useCurrentQuoteContext = () => {
	return useContext(CurrentQuoteProductContext);
};

// create our own provider
export class CurrentQuoteProductContextProvider extends React.Component {
	static contextType = SessionData;

	state = Object.assign(
		{},
		{
			additional_items: [],
			rowsBaseSize: 1,
			product: false,
			product_notes: false,
			product_values: false,
		}
	);

	// get the product notes
	setQuoteProductNotes = (notes) => {
		this.setState({
			product_notes: notes || '',
		});
	};

	// set a product value
	setProductValue = (fieldName, value) => {
		// update the new value
		this.setState((prevState) => {
			return {
				product_values: {
					...prevState.product_values,
					...{ [fieldName]: value },
				},
			};
		});
	};

	// get a product value
	getProductValue = (fieldName) => {
		return typeof this.state.product_values[fieldName] === 'undefined'
			? null
			: this.state.product_values[fieldName];
	};

	getProductPrevValue = (fieldName) => {
		const { lastQuoteConfiguration } = this.context;
		return lastQuoteConfiguration ? lastQuoteConfiguration.values[fieldName] : '';
	};

	setAdditionalItems = (value) => {
		this.setState({
			additional_items: value,
		});
	};

	getAdditionalItems = () => {
		return this.state.additional_items;
	};

	setRowsBaseSize = (value) => {
		this.setState({
			rowsBaseSize: value,
		});
	};

	getRowsBaseSize = () => {
		return this.state.rowsBaseSize;
	};

	usePreviousQuoteProductContextValue = (fieldName) => {
		const { lastQuoteConfiguration } = this.context;
		const value = lastQuoteConfiguration && lastQuoteConfiguration.values[fieldName];
		return new Promise((resolve) => {
			if (lastQuoteConfiguration) {
				setTimeout(() => {
					this.setState((prevState) => {
						if (prevState.product_values[fieldName] !== value) {
							return {
								product_values: {
									...prevState.product_values,
									...{ [fieldName]: value },
								},
							};
						}
					});
				}, 1000);
			}
			resolve();
		});
	};

	// NOTE: resets the state of the component
	// NOTE: Initial state of the component
	quoteProductReset = (code, values, notes) => {
		return new Promise((resolve, reject) => {
			// let' create our new state
			const newState = {
				product: ProductMapper(code),
				product_notes: notes || '',
				product_values: {},
			};

			// let's do the work to set all the appropriate values for this product
			for (let id in newState.product.configurations) {
				const config = newState.product.configurations[id];
				switch (config.configurationId) {
					case 'WINDOW_LOCATION':
						newState.product_values[config.standard.fieldName] =
							config.standard.defaultValue;
						newState.product_values[config.custom.fieldName] =
							config.custom.defaultValue;
						break;
					case 'HOUSE_TYPE':
					case 'REMOVAL_TYPE':
						newState.product_values[config.fieldName] = config.defaultValue;
						break;
					case 'OUTSIDE_INSTALL':
						// the outside install is based on removal type
						if (
							newState.product.configurations['REMOVAL_TYPE'] &&
							newState.product.configurations['REMOVAL_TYPE']
								.defaultValue === 'WOOD'
						) {
							newState.product_values[config.fieldName] = true;
						} else {
							newState.product_values[config.fieldName] =
								config.defaultValue;
						}
						break;
					case 'WINDOW_SIZE':
						newState.product_values[config.fieldNameWidth] =
							config.defaultValueWidth;
						newState.product_values[config.fieldNameHeight] =
							config.defaultValueHeight;
						break;
					case 'WINDOW_COLOR':
						if (config.interiorColor) {
							newState.product_values[config.interiorColor.fieldName] =
								config.interiorColor.defaultValue;
							if (config.exteriorColor) {
								const interiorColorOption =
									config.interiorColor.values.find((option) => {
										return (
											option.value ===
											config.interiorColor.defaultValue
										);
									});
								newState.product_values[config.exteriorColor.fieldName] =
									interiorColorOption.defaultExteriorColor;
							}
						}
						if (config.exteriorTrim) {
							newState.product_values[config.exteriorTrim.fieldName] =
								config.exteriorTrim.defaultValue;
							const exteriorTrimOption = config.exteriorTrim.values.find(
								(option) => {
									return (
										option.value === config.exteriorTrim.defaultValue
									);
								}
							);
							if (config.trimColor) {
								newState.product_values[config.trimColor.fieldName] =
									exteriorTrimOption.trimColorDefault;
							}
						}
						if (config.exteriorCedarTrim) {
							newState.product_values[config.exteriorCedarTrim.fieldName] =
								config.exteriorCedarTrim.defaultValue;
							if (
								config.exteriorCedarSize &&
								config.exteriorCedarTrim.defaultValue !== false
							) {
								newState.product_values[
									config.exteriorCedarSize.fieldName
								] = config.exteriorCedarTrim.defaultValue
									? config.exteriorCedarTrim.defaultValue
									: false;
							}
							if (config.exteriorCedarDimensions) {
								newState.product_values[
									config.exteriorCedarDimensions.fieldName
								] =
									config.exteriorCedarTrim.defaultValue !== false
										? config.exteriorCedarDimensions.defaultValue
										: 0;
							}
						}
						break;
					case 'WINDOW_GLASS':
						['type', 'temper', 'obscure', 'tint'].forEach(function (section) {
							if (config[section]) {
								newState.product_values[config[section].fieldName] =
									config[section].defaultValue;
							}
						});
						break;
					case 'WINDOW_SCREEN':
						['size', 'mesh'].forEach(function (section) {
							if (config[section]) {
								newState.product_values[config[section].fieldName] =
									config[section].defaultValue;
							}
						});
						break;
					case 'WINDOW_STYLE':
						[
							'shape',
							'sections',
							'style',
							'numberOfWindows',
							'material',
						].forEach(function (section) {
							if (config[section]) {
								newState.product_values[config[section].fieldName] =
									config[section].defaultValue;
							}
						});
						if (config.ventPosition || config.operation) {
							const selectedSectionOption = config.sections.values.find(
								(option) => {
									return option.value === config.sections.defaultValue;
								}
							);
							if (config.ventPosition) {
								newState.product_values[config.ventPosition.fieldName] =
									selectedSectionOption.ventPositionDefault;
							}
							if (config.operation) {
								newState.product_values[config.operation.fieldName] =
									selectedSectionOption.value ===
										'SECTIONS_2_DOORWALL' ||
									(selectedSectionOption.value ===
										'SECTIONS_3_DOORWALL' &&
										selectedSectionOption.ventPositionDefault ===
											'VENT_POSITION_CENTER_DOORWALL')
										? selectedSectionOption.operationDefault
										: false;
							}
						}
						break;
					case 'WINDOW_SILL':
						['interiorSill', 'exteriorSill'].forEach(function (section) {
							if (config[section]) {
								newState.product_values[config[section].fieldName] =
									config[section].defaultValue;
							}
						});
						if (config.dimensions) {
							const selectedInteriorSillOption =
								config.interiorSill.values.find((option) => {
									return (
										option.value === config.interiorSill.defaultValue
									);
								});
							newState.product_values[config.dimensions.fieldName] =
								selectedInteriorSillOption.dimensionsDefault;
						}
						if (config.exteriorDimensions) {
							const selectedExteriorSillOption =
								config.exteriorSill.values.find((option) => {
									return (
										option.value === config.exteriorSill.defaultValue
									);
								});
							newState.product_values[config.exteriorDimensions.fieldName] =
								selectedExteriorSillOption.dimensions
									? config.exteriorDimensions.defaultValue
									: false;
						}
						break;
					case 'WINDOW_OPTIONS_HARDWARE':
						['style', 'hardware'].forEach(function (section) {
							if (config[section]) {
								newState.product_values[config[section].fieldName] =
									config[section].defaultValue;
							}
						});
						break;
					case 'WINDOW_HARDWARE':
						['interiorHandleColor'].forEach(function (section) {
							if (config[section]) {
								newState.product_values[config[section].fieldName] =
									config[section].defaultValue;
							}
						});
						if (config.exteriorHandleColor) {
							const selectedInteriorColorOption =
								config.interiorHandleColor.values.find((option) => {
									return (
										option.value ===
										config.interiorHandleColor.defaultValue
									);
								});
							newState.product_values[
								config.exteriorHandleColor.fieldName
							] = selectedInteriorColorOption.exteriorColorDefault;
						}
						break;
					case 'WINDOW_SEAT':
						['type'].forEach(function (section) {
							if (config[section]) {
								newState.product_values[config[section].fieldName] =
									config[section].defaultValue;
							}
						});
						if (config.color) {
							const selectedSeatTypeOption = config.type.values.find(
								(option) => {
									return option.value === config.type.defaultValue;
								}
							);
							newState.product_values[config.color.fieldName] =
								selectedSeatTypeOption.seatColorDefault;
						}
						break;
					case 'WINDOW_ROOF':
						['type'].forEach(function (section) {
							if (config[section]) {
								newState.product_values[config[section].fieldName] =
									config[section].defaultValue;
							}
						});
						if (config.material) {
							const selectedTypeOption = config.type.values.find(
								(option) => {
									return option.value === config.type.defaultValue;
								}
							);
							newState.product_values[config.material.fieldName] =
								selectedTypeOption.materialDefault;
						}
						if (config.materialColor) {
							newState.product_values[config.materialColor.fieldName] = '';
						}
						break;
					case 'WINDOW_INSTRUCTIONS':
						// set the basic defaults
						config.groups.forEach(function (instruction_group) {
							instruction_group.options.forEach(function (
								instruction_option
							) {
								newState.product_values[instruction_option.fieldName] =
									instruction_option.defaultValue;
								// we have some special cases we have to look for
								if (instruction_option.expand) {
									newState.product_values[
										instruction_option.expand.fieldName
									] = instruction_option.expand.defaultValue;
								}
							});
						});
						break;
					case 'WINDOW_GRID':
						if (config.style) {
							newState.product_values[config.style.fieldName] =
								config.style.defaultValue;
							const selectedStyleOption = config.style.values.find(
								(option) => {
									return option.value === config.style.defaultValue;
								}
							);
							if (config.pattern) {
								newState.product_values[config.pattern.fieldName] =
									selectedStyleOption.patternDefault;
							}
							if (config.location) {
								config.location.values.forEach((option) => {
									newState.product_values[option.fieldName] = true;
								});
							}
							if (config.details) {
								newState.product_values[config.details.fieldName] = '';
							}
							if (config.interiorMuntinColor) {
								newState.product_values[
									config.interiorMuntinColor.fieldName
								] = selectedStyleOption.interiorMutinColorDefault;
								if (config.exteriorMuntinColor) {
									if (
										selectedStyleOption.interiorMutinColorDefault ===
										false
									) {
										newState.product_values[
											config.exteriorMuntinColor.fieldName
										] = false;
									} else {
										const selectedInteriorColorOption =
											config.interiorMuntinColor.values.find(
												(option) => {
													return (
														option.value ===
														selectedStyleOption.interiorMutinColorDefault
													);
												}
											);
										newState.product_values[
											config.exteriorMuntinColor.fieldName
										] =
											selectedInteriorColorOption.exteriorMutinColorDefault;
									}
								}
							}
						}
						break;
					case 'MISC_ITEM':
						['name', 'description', 'price'].forEach(function (section) {
							if (config[section]) {
								newState.product_values[config[section].fieldName] =
									config[section].defaultValue;
							}
						});
						break;
					case 'WINDOW_MULLIONS':
						[
							'leave_existing',
							'vinyl',
							'vinyl_total',
							'mullions_wood',
							'mullions_wood_total',
							'mullions_removal_total',
						].forEach(function (section) {
							if (config[section]) {
								newState.product_values[config[section].fieldName] =
									config[section].defaultValue;
							}
						});
						break;
					case 'WINDOW_NOTE':
						newState.product_values[config.fieldName] = config.defaultValue;
						break;
					default:
					// do noting
				}
			}

			// next we have some default options that are based on what the default
			// these are a bit more complex and can't be determined until the intial defaults are all set

			// if we have a removal type set, this will change the instruction options
			if (
				newState.product.configurations['REMOVAL_TYPE'] &&
				newState.product.configurations['WINDOW_INSTRUCTIONS']
			) {
				const removalValue =
					newState.product_values[
						newState.product.configurations['REMOVAL_TYPE'].fieldName
					];
				if (removalValue !== '') {
					const isOutsideInstall = newState.product.configurations[
						'OUTSIDE_INSTALL'
					]
						? newState.product_values[
								newState.product.configurations['OUTSIDE_INSTALL']
									.fieldName
						  ]
						: false;
					const findPartFieldName = (groupId, optionId) => {
						let fieldName =
							newState.product.configurations[
								'WINDOW_INSTRUCTIONS'
							].groups.find((group) => {
								return group.groupId === groupId;
							}) || false;
						if (fieldName !== false) {
							fieldName =
								fieldName.options.find((option) => {
									return option.optionId === optionId;
								}) || false;
						}
						if (fieldName !== false) {
							fieldName = fieldName.fieldName;
						}
						return fieldName;
					};
					// update the intial jam selection
					const jamsFieldName = findPartFieldName('PARTS', 'EXTENSION_JAMBS');
					if (jamsFieldName !== false) {
						newState.product_values[jamsFieldName] = false;
					}
					// update the bucks
					const bucksFieldName = findPartFieldName('PARTS', 'BUCKS');
					if (bucksFieldName !== false) {
						if (
							removalValue === 'VINYL' ||
							removalValue === 'ALUMINUM' ||
							removalValue === 'STEEL' ||
							(removalValue === 'WOOD' && !isOutsideInstall)
						) {
							newState.product_values[bucksFieldName] = 'PARTIAL_BUCK';
						} else if (removalValue === 'GLASS_BLOCK') {
							newState.product_values[bucksFieldName] = 'FULL_BUCK';
						} else {
							newState.product_values[bucksFieldName] = 'NONE';
						}
					}
					// update the casing
					const casingFieldName = findPartFieldName('PARTS', 'CASING');
					if (casingFieldName !== false) {
						if (removalValue === 'GLASS_BLOCK') {
							newState.product_values[casingFieldName] = 'PINE';
						} else {
							newState.product_values[casingFieldName] = 'NONE';
						}
					}
					// update the stops
					const stopsFieldName = findPartFieldName('PARTS', 'STOPS');
					if (stopsFieldName !== false) {
						if (removalValue === 'WOOD' && isOutsideInstall) {
							newState.product_values[stopsFieldName] = 'NONE';
						} else {
							newState.product_values[stopsFieldName] = 'PINE';
						}
					}
				}
			}

			// there is an intended relationship with the interior / exterior colors
			// are used to default the grid colors, so we'll attempt this
			if (
				newState.product.configurations['WINDOW_COLOR'] &&
				newState.product.configurations['WINDOW_GRID']
			) {
				const gridStyleValue = newState.product.configurations['WINDOW_GRID']
					.style
					? newState.product_values[
							newState.product.configurations['WINDOW_GRID'].style.fieldName
					  ]
					: false;
				if (gridStyleValue === 'FLAT' || gridStyleValue === 'CONTOUR') {
					// interior color
					const selectedInteriorColor =
						newState.product_values[
							newState.product.configurations['WINDOW_COLOR'].interiorColor
								.fieldName
						];
					const gridStyleOption = newState.product.configurations[
						'WINDOW_GRID'
					].style.values.find((option) => {
						return option.value === gridStyleValue;
					});
					if (
						gridStyleOption &&
						gridStyleOption.interiorMutinColorOptions.indexOf(
							selectedInteriorColor
						) !== -1
					) {
						newState.product_values[
							newState.product.configurations[
								'WINDOW_GRID'
							].interiorMuntinColor.fieldName
						] = selectedInteriorColor;
					}
					// exterior color
					const selectedInteriorColorGrid =
						newState.product_values[
							newState.product.configurations['WINDOW_GRID']
								.interiorMuntinColor.fieldName
						];
					const selectedInteriorColorGridOption =
						newState.product.configurations[
							'WINDOW_GRID'
						].interiorMuntinColor.values.find((option) => {
							return option.value === selectedInteriorColorGrid;
						});
					const selectedExteriorColor =
						newState.product_values[
							newState.product.configurations['WINDOW_COLOR'].exteriorColor
								.fieldName
						];
					if (
						selectedInteriorColorGridOption &&
						selectedInteriorColorGridOption.exteriorMutinColorOptions.indexOf(
							selectedExteriorColor
						) !== -1
					) {
						newState.product_values[
							newState.product.configurations[
								'WINDOW_GRID'
							].exteriorMuntinColor.fieldName
						] = selectedExteriorColor;
					}
				}
			}

			// let's extend anything passed in to our value
			newState.product_values = { ...newState.product_values, ...values };

			// now we'll set the state
			this.setState(newState, () => {
				resolve();
			});
		});
	};

	// NOTE: get the error list from a quote product
	quoteProductErrorList = () => {
		let errors = [];

		// check for window location
		if (this.state.product.configurations['WINDOW_LOCATION']) {
			const standardValue =
				this.state.product_values[
					this.state.product.configurations['WINDOW_LOCATION'].standard
						.fieldName
				];
			if (standardValue === '') {
				errors.push('Window Location is required');
			} else if (standardValue === 'CUSTOM') {
				const customValue =
					this.state.product_values[
						this.state.product.configurations['WINDOW_LOCATION'].custom
							.fieldName
					];
				if (customValue === '') {
					errors.push('Window Location is required');
				}
			}
		}

		// check window size
		if (this.state.product.configurations['WINDOW_SIZE']) {
			// width and height checks
			const widthValue =
				this.state.product_values[
					this.state.product.configurations['WINDOW_SIZE'].fieldNameWidth
				];
			const heightValue =
				this.state.product_values[
					this.state.product.configurations['WINDOW_SIZE'].fieldNameHeight
				];

			let minWidthValue =
				this.state.product.configurations['WINDOW_SIZE'].minimumValueWidth;
			let maxWidthValue =
				this.state.product.configurations['WINDOW_SIZE'].maximumValueWidth;
			let minHeightValue =
				this.state.product.configurations['WINDOW_SIZE'].minimumValueHeight;
			let maxHeightValue =
				this.state.product.configurations['WINDOW_SIZE'].maximumValueHeight;
			let maximumValueTotal =
				this.state.product.configurations['WINDOW_SIZE'].maximumValueTotal;

			// we hav a special rule for min/max width for doorwall
			if (this.state.product.code === 'DOOR_WALL_PROD') {
				const numSectionsValue =
					this.state.product_values[
						this.state.product.configurations.WINDOW_STYLE.sections.fieldName
					];
				if (numSectionsValue === 'SECTIONS_3_DOORWALL') {
					minWidthValue =
						this.state.product.configurations.WINDOW_SIZE
							.minimumValueWidthOverride1;
					maxWidthValue =
						this.state.product.configurations.WINDOW_SIZE
							.maximumValueWidthOverride1;
				}
			}

			// we have a special rule for max width for end vent
			if (this.state.product.code === 'END_VENT_SLIDER_PROD') {
				const hardwareStyleValue =
					this.state.product_values[
						this.state.product.configurations['WINDOW_OPTIONS_HARDWARE'].style
							.fieldName
					];
				if (hardwareStyleValue === 'OPTION_STYLE_END_VENT_3_3_3') {
					maxWidthValue =
						this.state.product.configurations['WINDOW_SIZE']
							.maximumValueWidthOverride1;
				}
			}

			// we have a special rule for picture window
			if (
				this.state.product.code === 'PICTURE_PROD' ||
				this.state.product.code === 'PICTURE_CASEMENT_PROD' ||
				this.state.product.code === 'SPECIALTY_PROD'
			) {
				if (widthValue >= minWidthValue && heightValue >= minHeightValue) {
					const smallestValue = Math.min(widthValue, heightValue);
					const largestValue = Math.max(widthValue, heightValue);
					const smallestLeg = widthValue < heightValue ? 'w' : 'h';
					const largestLeg = smallestLeg === 'w' ? 'h' : 'w';
					// check for if we have a leg over 70
					if (largestValue > 70) {
						if (largestLeg === 'w') {
							maxHeightValue = 70;
						} else {
							maxWidthValue = 70;
						}
					}
					// check if we have a leg smaller theen 14
					if (smallestValue < 14) {
						if (smallestLeg === 'w') {
							maxHeightValue = 93;
						} else {
							maxWidthValue = 93;
						}
					}
				}
			}

			// min and max width check
			// We don't need this for custom window layouts.
			if (widthValue < minWidthValue || widthValue > maxWidthValue) {
				errors.push(
					'Width must be between ' +
						minWidthValue +
						'" and ' +
						maxWidthValue +
						'"'
				);
			}

			// min and max height check
			if (heightValue < minHeightValue || heightValue > maxHeightValue) {
				errors.push(
					'Height must be between ' +
						minHeightValue +
						'" and ' +
						maxHeightValue +
						'"'
				);
			}

			// total checkes
			const unitedInches = widthValue + heightValue;
			if (maximumValueTotal !== false && unitedInches > maximumValueTotal) {
				errors.push(
					'United Inches cannot exceed a maximum of ' + maximumValueTotal + '"'
				);
			}
		}

		// check house type
		if (this.state.product.configurations['HOUSE_TYPE']) {
			const houseTypeValue =
				this.state.product_values[
					this.state.product.configurations['HOUSE_TYPE'].fieldName
				];
			if (houseTypeValue === '') {
				errors.push('House Type is required');
			}
		}

		// removal type
		if (this.state.product.configurations['REMOVAL_TYPE']) {
			const removalTypeValue =
				this.state.product_values[
					this.state.product.configurations['REMOVAL_TYPE'].fieldName
				];
			if (removalTypeValue === '') {
				errors.push('Removal Type is required');
			}
		}

		// check misc item
		if (this.state.product.configurations['MISC_ITEM']) {
			const nameValue =
				this.state.product_values[
					this.state.product.configurations['MISC_ITEM'].name.fieldName
				];
			const priceValue =
				this.state.product_values[
					this.state.product.configurations['MISC_ITEM'].price.fieldName
				];
			if (nameValue === '') {
				errors.push('Name is required');
			}
			if (priceValue === 0) {
				errors.push('Price is required');
			}
		}

		// check window style rules
		if (this.state.product.configurations['WINDOW_STYLE']) {
			// if we have a shape selection for style, we need to make sure its set
			if (this.state.product.configurations['WINDOW_STYLE'].shape) {
				const shapeValue =
					this.state.product_values[
						this.state.product.configurations['WINDOW_STYLE'].shape.fieldName
					];
				if (shapeValue === '') {
					errors.push('Shape is required');
				}
			}
			// if we have operation, we need to make sure its set appropriately
			if (this.state.product.configurations['WINDOW_STYLE'].operation) {
				const operationValue =
					this.state.product_values[
						this.state.product.configurations['WINDOW_STYLE'].operation
							.fieldName
					];
				let requireOperation = true;
				if (this.state.product.configurations['WINDOW_STYLE'].sections) {
					const sectionValue =
						this.state.product_values[
							this.state.product.configurations['WINDOW_STYLE'].sections
								.fieldName
						];
					if (sectionValue === 'SECTIONS_3_DOORWALL') {
						if (
							this.state.product.configurations['WINDOW_STYLE'].ventPosition
						) {
							const ventValue =
								this.state.product_values[
									this.state.product.configurations['WINDOW_STYLE']
										.ventPosition.fieldName
								];
							if (ventValue !== 'VENT_POSITION_CENTER_DOORWALL') {
								requireOperation = false;
							}
						}
					}
				}
				if (requireOperation && operationValue === false) {
					errors.push('Operation is required');
				}
			}
		}

		// check grid for grid details
		if (this.state.product.configurations['WINDOW_GRID']) {
			if (
				this.state.product.configurations.WINDOW_GRID.style &&
				this.state.product.configurations.WINDOW_GRID.details
			) {
				const gridStyleValue =
					this.state.product_values[
						this.state.product.configurations.WINDOW_GRID.style.fieldName
					];
				const gridDetailsValue =
					this.state.product_values[
						this.state.product.configurations.WINDOW_GRID.details.fieldName
					];
				if (gridStyleValue !== 'NONE' && gridDetailsValue.trim() === '') {
					errors.push('Grid details is required');
				}
			}
		}

		// check window roof
		if (this.state.product.configurations['WINDOW_ROOF']) {
			if (
				this.state.product.configurations.WINDOW_ROOF.type &&
				this.state.product_values[
					this.state.product.configurations.WINDOW_ROOF.type.fieldName
				] !== 'ATTACHED_TO_OVERHANG' &&
				this.state.product.configurations.WINDOW_ROOF.materialColor &&
				this.state.product_values[
					this.state.product.configurations.WINDOW_ROOF.materialColor.fieldName
				] === ''
			) {
				errors.push('Roof material color required');
			}
		}

		// chck any window option and hardware checks
		if (this.state.product.configurations['WINDOW_OPTIONS_HARDWARE']) {
			// we want to require the hinge style on single casement products
			if (
				this.state.product.code === 'SINGLE_CASEMENT_PROD' &&
				this.state.product.configurations['WINDOW_OPTIONS_HARDWARE'].style
			) {
				const styleValue =
					this.state.product_values[
						this.state.product.configurations.WINDOW_OPTIONS_HARDWARE.style
							.fieldName
					];
				if (styleValue === false) {
					errors.push('Hinge Style is required');
				}
			}
		}

		// return the errors
		return errors;
	};

	// the render
	render() {
		const value = {
			// data
			additional_items: this.state.additional_items,
			product: this.state.product,
			product_notes: this.state.product_notes,
			product_values: this.state.product_values,
			rowsBaseSize: this.state.rowsBaseSize,
			// methods
			quoteProductReset: this.quoteProductReset,
			quoteProductErrorList: this.quoteProductErrorList,
			setQuoteProductNotes: this.setQuoteProductNotes,
			setQuoteProductContextProductValue: this.setProductValue,
			getQuoteProductContextProductValue: this.getProductValue,
			getProductPrevValue: this.getProductPrevValue,
			usePreviousQuoteProductContextValue: this.usePreviousQuoteProductContextValue,
			setPreviousQuoteProductContextValue: this.usePreviousQuoteProductContextValue,
			setQuoteProductAdditionalItems: this.setAdditionalItems,
			getQuoteProductAdditionalItems: this.getAdditionalItems,
			setQuoteRowsBaseSize: this.setRowsBaseSize,
			getQuoteRowsBaseSize: this.getRowsBaseSize,
		};
		return (
			<CurrentQuoteProductContext.Provider
				value={value}
				displayName="Current Quote Product Context"
			>
				{this.props.children}
			</CurrentQuoteProductContext.Provider>
		);
	}
}
