import React, { useEffect } from 'react';
import { useCurrentQuoteContext } from '../contexts/CurrentQuoteProductContext';

/**
 * handle the relationhsips of a few different options
 * @param {*} configuration
 *
 */

export const usePartsGroupRelationships = (configuration) => {
	// handle Context
	const {
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
		setPreviousQuoteProductContextValue,
	} = useCurrentQuoteContext();
	// Set to use the previous value
	const setPreviousContextValue = React.useCallback(() => {
		setPreviousQuoteProductContextValue(configuration.fieldName);
	}, [configuration.fieldName, setPreviousQuoteProductContextValue]);

	useEffect(() => {
		setPreviousContextValue();
	}, [setPreviousContextValue]);
	// part data
	const partsGroup = configuration.groups.find((group) => {
		return group.groupId === 'PARTS';
	});

	const extensionJambsOption = partsGroup
		? partsGroup.options.find((option) => {
				return option.optionId === 'EXTENSION_JAMBS';
		  })
		: undefined;
	const extensionJambsValue =
		extensionJambsOption !== undefined
			? getQuoteProductContextProductValue(extensionJambsOption.fieldName)
			: '';
	const buckOption = partsGroup
		? partsGroup.options.find((option) => {
				return option.optionId === 'BUCKS';
		  })
		: undefined;
	const buckValue =
		buckOption !== undefined
			? getQuoteProductContextProductValue(buckOption.fieldName)
			: '';
	const casingOption = partsGroup
		? partsGroup.options.find((option) => {
				return option.optionId === 'CASING';
		  })
		: undefined;
	const casingValue =
		casingOption !== undefined
			? getQuoteProductContextProductValue(casingOption.fieldName)
			: '';
	const stopOption = partsGroup
		? partsGroup.options.find((option) => {
				return option.optionId === 'STOPS';
		  })
		: undefined;
	const stopValue =
		stopOption !== undefined
			? getQuoteProductContextProductValue(stopOption.fieldName)
			: '';

	// installlation data
	const installationGroup = configuration.groups.find((group) => {
		return group.groupId === 'INSTALLATION';
	});
	const cutbackSizeOption = installationGroup.options.find((option) => {
		return option.optionId === 'CUTBACK_SIZE';
	});
	const cutbackSizeValue = cutbackSizeOption
		? getQuoteProductContextProductValue(cutbackSizeOption.fieldName)
		: false;
	const reduceOpeningOption = installationGroup.options.find((option) => {
		return option.optionId === 'REDUCE_WINDOW_OPENING';
	});
	const reduceOpeningValue = reduceOpeningOption
		? getQuoteProductContextProductValue(reduceOpeningOption.fieldName)
		: false;
	const enlargeOpeningOption = installationGroup.options.find((option) => {
		return option.optionId === 'ENLARGE_WINDOW_OPENING';
	});
	const enlargeOpeningValue = enlargeOpeningOption
		? getQuoteProductContextProductValue(enlargeOpeningOption.fieldName)
		: false;

	// the effect that checks all the stuff
	useEffect(() => {
		// conditions based on jambs value
		if (extensionJambsValue) {
			if (buckValue === 'NONE' || buckValue === 'FULL_BUCK') {
				setQuoteProductContextProductValue(
					buckOption.fieldName,
					extensionJambsOption.defaultBuck
				);
			}
			if (casingValue === 'NONE') {
				setQuoteProductContextProductValue(
					casingOption.fieldName,
					extensionJambsOption.defaultCasing
				);
			}
		}
		// conditions based on the enlarge / reduce opening
		if (reduceOpeningValue || enlargeOpeningValue) {
			if (buckValue !== 'FULL_BUCK') {
				setQuoteProductContextProductValue(buckOption.fieldName, 'FULL_BUCK');
			}
			if (casingValue === 'NONE') {
				setQuoteProductContextProductValue(casingOption.fieldName, 'PINE');
			}
		}
		// condition based on the bucks value
		if (buckValue === 'PARTIAL_BUCK' && stopValue === 'NONE') {
			setQuoteProductContextProductValue(stopOption.fieldName, 'PINE');
		}
		// condition based on the casing value
		if (casingValue === 'WHITE_VINYL' && stopValue !== 'WHITE_VINYL') {
			setQuoteProductContextProductValue(stopOption.fieldName, 'WHITE_VINYL');
		}
		// let' make sure that stops get set correctly
		// we'll make sure if the value doesnt' match that we use the right default
		if (stopOption) {
			if (stopOption.values.indexOf(stopValue) === -1) {
				const currentCasingOption = casingOption.values.find((option) => {
					return option.value === casingValue;
				});
				if (currentCasingOption) {
					setQuoteProductContextProductValue(
						stopOption.fieldName,
						currentCasingOption.defaultStop
					);
				}
			}
		}
		// handle the cutback to original
		if (cutbackSizeValue) {
			if (buckValue !== 'FULL_BUCK') {
				setQuoteProductContextProductValue(buckOption.fieldName, 'FULL_BUCK');
			}
			if (casingValue === 'NONE') {
				setQuoteProductContextProductValue(
					casingOption.fieldName,
					casingOption.defaultValue
				);
			}
		}
	}, [
		reduceOpeningValue,
		enlargeOpeningValue,
		buckOption,
		buckValue,
		extensionJambsOption,
		extensionJambsValue,
		casingOption,
		casingValue,
		stopOption,
		stopValue,
		cutbackSizeOption,
		cutbackSizeValue,
		setQuoteProductContextProductValue,
	]);
	return {
		reduceOpeningOption,
		enlargeOpeningOption,
		buckOption,
		buckValue,
		extensionJambsOption,
		extensionJambsValue,
		casingOption,
		casingValue,
		stopOption,
		stopValue,
		cutbackSizeOption,
		cutbackSizeValue,
	};
};
