import React, { useContext, useEffect, useState, useRef } from 'react';
import SessionDataContext from '../../contexts/SessionDataContext';
import CurrentQuoteProductContext from '../../contexts/CurrentQuoteProductContext';
import WallsideError from '../../functionality/WallsideError';
import CustomWindow from '../../containers/CustomWindow';
import ProductConfigurationNavBar from '../../components/NavBars/ProductConfigurationNavBar/ProductConfigurationNavBar';
import ProductConfigurationSideBar from '../../components/ProductConfigurationSideBar/ProductConfigurationSideBar';
import Layout2 from '../../layouts/Layout2/Layout2';

const CustomWindowRoute = (props) => {
	// use the session data context and set the quote ID to the url params
	// the contexts
	const { product, quoteProductReset, product_values } = useContext(
		CurrentQuoteProductContext
	);

	const { loadQuote, activeQuote } = useContext(SessionDataContext);

	// // use some state
	const [ready, setReady] = useState(true);

	// // we only want our effect to trigger 1 time, so we'll use a ref
	const isFirstTime = useRef(true);

	// // we need to fetch the correct quote for this account
	useEffect(() => {
		if (isFirstTime.current) {
			// turn off first time
			isFirstTime.current = false;

			// load the quote and setup
			loadQuote(props.match.params.quoteId)
				.then(() => {
					// we need to determine if this is an existing product or a new product
					const pathname = window.location.pathname;
					const productStr = pathname.split('/')[6];
					const productId = productStr.split('=')[1];
					const isNew = productId === '-1';

					let productCode = false;
					let productValues = {};
					let productNotes = '';

					// if this isn't a new one, then we need to
					// find the data in the current quote
					if (!isNew) {
						let index = -1;
						for (let i = 0; i < activeQuote.get('products').length; i++) {
							if (activeQuote.get('products')[i].id === productId) {
								index = i;
								break;
							}
						}
						// if we couldn't find this product, throw error
						if (index === -1) {
							return Promise.reject(
								new WallsideError({ message: 'Product not found.' })
							);
						}
						// the index exists so grab the product, and update
						const quoteProduct = {
							...activeQuote.get('products')[index],
						};

						productCode = quoteProduct.code;
						productValues = quoteProduct.values;
						productNotes = quoteProduct.notes;
					}

					// now we init the quote product
					return quoteProductReset(productCode, productValues, productNotes);
				})
				.then(
					() => {
						// done
						setReady(true);
					},
					(e) => {
						const error = new WallsideError(e);
						console.error('Loading QuoteProduct Error:', e);
						// alert(error.globalErrors[0].message);
					}
				)
				.catch((err) => {
					console.error('Loading Error:', err);
				});
		}
	}, [
		loadQuote,
		activeQuote,
		quoteProductReset,
		props.location.search,
		props.match.params.quoteId,
		props.match.params.productId,
	]);

	// return the component
	return (
		<Layout2
			mainClass="layout1--productconfiguration"
			navbar={
				<ProductConfigurationNavBar productId={props.match.params.productId} />
			}
			sideBarChildren={<ProductConfigurationSideBar />}
		>
			{ready && (
				<CustomWindow
					product={product}
					product_values={product_values}
					{...props}
				/>
			)}
		</Layout2>
	);
};

export default CustomWindowRoute;
