import React, { Fragment } from 'react';
import ConfigurationLabelMapper from '../../functionality/ConfigurationLabelMapper';

// Mapped through our product.configurationGroups.group2, show some relevant data
const RenderProductSectionFragment = ({ configurationKey, product, quoteProduct }) => {
	const configuration = product.configurations[configurationKey];
	const {
		color,
		dimensions,
		exteriorCedarTrim,
		exteriorColor,
		exteriorDimensions,
		exteriorHandleColor,
		exteriorMuntinColor,
		exteriorSill,
		exteriorTrim,
		groups,
		hardware,
		heading,
		interiorColor,
		interiorHandleColor,
		interiorMuntinColor,
		interiorSill,
		material,
		materialColor,
		mesh,
		numberOfWindows,
		obscure,
		operation,
		pattern,
		sections,
		shape,
		size,
		style,
		temper,
		tint,
		trimColor,
		ventPosition,
	} = configuration;
	const configurationType = configuration.type;

	// we have to build the description text based on the configuration
	const getDescription = () => {
		switch (configurationKey) {
			case 'WINDOW_COLOR': {
				let description = '';
				const selectedExteriorTrimValue = exteriorTrim
					? quoteProduct.values[exteriorTrim.fieldName]
					: 'NONE';

				const selectedExteriorTrimColorValue = trimColor
					? quoteProduct.values[trimColor.fieldName]
					: false;

				// go through window color
				if (interiorColor || exteriorColor) {
					description +=
						product.type === 'window_shell' ? 'Shell: ' : 'Window: ';
					if (interiorColor) {
						description += ConfigurationLabelMapper(
							quoteProduct.values[interiorColor.fieldName]
						);
					}
					if (exteriorColor) {
						description +=
							(interiorColor ? '/' : '') +
							ConfigurationLabelMapper(
								quoteProduct.values[exteriorColor.fieldName]
							);
					}
				}
				// go through the trim values
				if (exteriorTrim) {
					description +=
						(description !== '' ? ', ' : '') +
						'Trim: ' +
						ConfigurationLabelMapper(selectedExteriorTrimValue);
					if (selectedExteriorTrimColorValue) {
						description +=
							' - ' +
							ConfigurationLabelMapper(selectedExteriorTrimColorValue);
					}
					const hasValue = quoteProduct.values[exteriorCedarTrim.fieldName];

					if (exteriorCedarTrim && hasValue) {
						description += ', Cedar Trim: Yes';
					}
				}
				return description;
			}
			case 'WINDOW_GRID': {
				let description = '';
				const selectedGridStyleValue = style
					? quoteProduct.values[style.fieldName]
					: false;

				const selectedInteriorColorValue = interiorMuntinColor
					? quoteProduct.values[interiorMuntinColor.fieldName]
					: false;

				const selectedExteriorColorValue = exteriorMuntinColor
					? quoteProduct.values[exteriorMuntinColor.fieldName]
					: false;

				const selectedGirdPatternValue = pattern
					? quoteProduct.values[pattern.fieldName]
					: false;

				// start by adding the basic style selections
				if (selectedGridStyleValue !== false) {
					description +=
						'Style: ' +
						ConfigurationLabelMapper(quoteProduct.values[style.fieldName]);
					// now we'll add the color information
					if (
						selectedInteriorColorValue !== false ||
						selectedExteriorColorValue !== false
					) {
						description +=
							' - ' +
							(selectedInteriorColorValue !== false
								? ConfigurationLabelMapper(selectedInteriorColorValue)
								: '') +
							((selectedInteriorColorValue !== false &&
							selectedExteriorColorValue !== false
								? '/'
								: '') +
								(selectedExteriorColorValue !== false
									? ConfigurationLabelMapper(selectedExteriorColorValue)
									: ''));
					}
				}
				// now we add the pattern information
				if (selectedGirdPatternValue !== false) {
					description +=
						(description === '' ? '' : ', ') +
						'Pattern: ' +
						ConfigurationLabelMapper(selectedGirdPatternValue);
				}
				return description;
			}

			case 'WINDOW_GLASS': {
				const selectedTemper = quoteProduct?.values[temper?.fieldName];

				const selectedObscure = quoteProduct?.values[obscure?.fieldName];

				const selectedTint = quoteProduct?.values[tint?.fieldName];

				return (
					ConfigurationLabelMapper(
						quoteProduct.values[configurationType.fieldName]
					) +
					(selectedTemper && selectedTemper !== 'NONE'
						? ', Temper: ' + ConfigurationLabelMapper(selectedTemper)
						: '') +
					(selectedObscure && selectedObscure !== 'NONE'
						? ', Obscure: ' + ConfigurationLabelMapper(selectedObscure)
						: '') +
					(selectedTint && selectedTint !== 'NONE'
						? ', Tint: ' + ConfigurationLabelMapper(selectedTint)
						: '')
				);
			}
			case 'WINDOW_SCREEN':
				return (
					(size
						? ConfigurationLabelMapper(quoteProduct.values[size.fieldName])
						: '') +
					(size && mesh ? ', ' : '') +
					(mesh
						? ConfigurationLabelMapper(quoteProduct.values[mesh.fieldName])
						: '')
				);
			case 'WINDOW_SEAT': {
				const selectedTypeSeatValue =
					quoteProduct?.values[configurationType?.fieldName];

				const selectedColorSeatValue = quoteProduct?.values[color?.fieldName];

				return (
					ConfigurationLabelMapper(selectedTypeSeatValue) +
					(selectedColorSeatValue
						? ', ' + ConfigurationLabelMapper(selectedColorSeatValue)
						: '')
				);
			}

			case 'WINDOW_ROOF': {
				const selectedTypeRoofValue = configurationType
					? quoteProduct.values[configurationType.fieldName]
					: false;
				const selectedMaterialRoofValue = material
					? quoteProduct.values[material.fieldName]
					: false;
				const selectedMaterialColorRoofValue = materialColor
					? quoteProduct.values[materialColor.fieldName] || ''
					: '';

				return (
					(selectedTypeRoofValue !== false
						? ConfigurationLabelMapper(selectedTypeRoofValue)
						: '') +
					(selectedMaterialRoofValue !== false
						? ', ' + ConfigurationLabelMapper(selectedMaterialRoofValue)
						: '') +
					(selectedMaterialRoofValue !== false
						? ', ' + selectedMaterialColorRoofValue
						: '')
				);
			}

			case 'WINDOW_SILL': {
				let description = '';
				const sillMaterialValue = interiorSill
					? quoteProduct.values[interiorSill.fieldName]
					: false;
				const sillDimensionValue = dimensions
					? quoteProduct.values[dimensions.fieldName]
					: false;
				const sillExteriorValue = exteriorSill
					? quoteProduct.values[exteriorSill.fieldName]
					: false;
				const sillExteriorDimensionValue = exteriorDimensions
					? quoteProduct.values[exteriorDimensions.fieldName]
					: false;

				// add the interior
				if (sillMaterialValue !== false) {
					description +=
						'Interior: ' + ConfigurationLabelMapper(sillMaterialValue);
					if (sillMaterialValue !== 'NONE' && sillDimensionValue !== false) {
						description +=
							' - ' + ConfigurationLabelMapper(sillDimensionValue) + '"';
					}
				}
				// add the exterior
				if (sillExteriorValue !== false) {
					description +=
						(description !== '' ? ', ' : '') +
						'Exterior: ' +
						ConfigurationLabelMapper(sillExteriorValue);
					if (
						sillExteriorValue !== 'NONE' &&
						sillExteriorDimensionValue !== false &&
						sillExteriorDimensionValue !== 0
					) {
						description += ' - ' + sillExteriorDimensionValue + "'";
					}
				}
				return description;
			}

			case 'WINDOW_HARDWARE': {
				const selectedInteriorHandleColorValue = interiorHandleColor
					? quoteProduct.values[interiorHandleColor.fieldName]
					: false;

				const selectedExteriorHandleColorValue = exteriorHandleColor
					? quoteProduct.values[exteriorHandleColor.fieldName]
					: false;

				return interiorHandleColor && exteriorHandleColor
					? ConfigurationLabelMapper(selectedInteriorHandleColorValue) +
							'/' +
							ConfigurationLabelMapper(selectedExteriorHandleColorValue)
					: '';
			}

			case 'WINDOW_OPTIONS_HARDWARE':
				return (
					(style
						? 'Style: ' +
						  ConfigurationLabelMapper(quoteProduct.values[style.fieldName])
						: '') +
					(style && hardware && quoteProduct.values[hardware.fieldName]
						? ', '
						: '') +
					(hardware
						? quoteProduct.values[hardware.fieldName]
							? 'WOCD'
							: ''
						: '')
				);

			case 'WINDOW_STYLE': {
				const selectedShapeValue = shape
					? quoteProduct.values[shape.fieldName]
					: false;

				const selectedMaterialValue = material
					? quoteProduct.values[material.fieldName]
					: false;

				const selectedSectionsValue = sections
					? quoteProduct.values[sections.fieldName]
					: false;

				const selectedNumberOfWindowsValue = numberOfWindows
					? quoteProduct.values[numberOfWindows.fieldName]
					: false;

				const selectedStyleValue = style
					? quoteProduct.values[style.fieldName]
					: false;

				const selectedOperationValue = operation
					? quoteProduct.values[operation.fieldName]
					: false;

				const selectedVentPositionValue = ventPosition
					? quoteProduct.values[ventPosition.fieldName]
					: false;

				return (
					(selectedShapeValue !== false
						? ConfigurationLabelMapper(selectedShapeValue)
						: '') +
					(selectedMaterialValue !== false
						? ConfigurationLabelMapper(selectedMaterialValue)
						: '') +
					(selectedSectionsValue !== false
						? ConfigurationLabelMapper(selectedSectionsValue) + '-Section'
						: '') +
					(selectedNumberOfWindowsValue !== false
						? ', ' +
						  ConfigurationLabelMapper(selectedNumberOfWindowsValue) +
						  ' Windows'
						: '') +
					(selectedStyleValue !== false
						? ', ' + ConfigurationLabelMapper(selectedStyleValue)
						: '') +
					(selectedVentPositionValue !== false
						? ', Vent: ' + ConfigurationLabelMapper(selectedVentPositionValue)
						: '') +
					(selectedOperationValue !== false
						? ', Operation:' +
						  ConfigurationLabelMapper(selectedOperationValue)
						: '')
				);
			}

			case 'WINDOW_INSTRUCTIONS': {
				let description = '';
				groups.forEach((group) => {
					group.options.forEach((option) => {
						const heading = option.heading;
						const type = option.configurationType;
						const value = quoteProduct.values[option.fieldName];

						if (type === 'boolean') {
							// this is a boolean, we only show this if its set to true
							if (value === true) {
								description += (description !== '' ? ', ' : '') + heading;
							}
						} else if (type === 'number') {
							// this is a number field and its value is not 0
							if (value !== 0) {
								description +=
									(description !== '' ? ', ' : '') +
									heading +
									': ' +
									value;
							}
						} else {
							// this is any other type and its value is not 'none'
							if (value !== 'NONE') {
								description +=
									(description !== '' ? ', ' : '') +
									heading +
									': ' +
									ConfigurationLabelMapper(value);
							}
						}
					});
				});
				return description;
			}

			case 'MISC_ITEM':
				return quoteProduct.values[name.fieldName];

			default:
				return '\u00A0';
		}
	};

	const description = getDescription();

	if (!description) {
		return null;
	}

	return (
		<Fragment>
			<dt className="heading heading--12">{heading}:</dt>
			<dd className="heading heading--13">{description}</dd>
		</Fragment>
	);
};

export default RenderProductSectionFragment;
