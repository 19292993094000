import React, { useEffect, useRef, useContext } from 'react';
import IconBtn from '../../IconBtn/IconBtn';
import ImageAsset from '../../ImageAsset/ImageAsset';
import Input from '../../Input/Input';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import UIManagementContext from '../../../contexts/UIManagementContext';

const ProductConfigurationWindowGrid = (props) => {
	// the ui management context
	const { openConfigurationData, toggleOpenConfiguration } =
		useContext(UIManagementContext);

	// product context
	const {
		product,
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
	} = useContext(CurrentQuoteProductContext);

	// we need to do some stuff with the grid location in the DOM
	// we'll use a ref for the grid location area
	const locationEl = useRef();

	// some selected values and options
	const selectedGridStyleValue = props.configuration.style
		? getQuoteProductContextProductValue(props.configuration.style.fieldName)
		: false;
	const selectedGridStyleOption = props.configuration.style
		? props.configuration.style.values.find((option) => {
				return option.value === selectedGridStyleValue;
		  })
		: false;
	const selectedInteriorColorValue = props.configuration.interiorMuntinColor
		? getQuoteProductContextProductValue(
				props.configuration.interiorMuntinColor.fieldName
		  )
		: false;
	const selectedInteriorColorOption = props.configuration.interiorMuntinColor
		? props.configuration.interiorMuntinColor.values.find((option) => {
				return option.value === selectedInteriorColorValue;
		  })
		: false;
	const selectedExteriorColorValue = props.configuration.exteriorMuntinColor
		? getQuoteProductContextProductValue(
				props.configuration.exteriorMuntinColor.fieldName
		  )
		: false;
	const selectedGirdPatternValue = props.configuration.pattern
		? getQuoteProductContextProductValue(props.configuration.pattern.fieldName)
		: false;
	const selectedGridPatternOption = props.configuration.pattern
		? props.configuration.pattern.values.find((option) => {
				return option.value === selectedGirdPatternValue;
		  })
		: false;

	// create the section details
	const createSectionHeadingDetails = () => {
		let finalText = '';
		// start by adding the basic style selections
		if (selectedGridStyleValue !== false) {
			finalText += 'Style: ' + ConfigurationLabelMapper(selectedGridStyleValue);
			// now we'll add the color information
			if (
				selectedInteriorColorValue !== false ||
				selectedExteriorColorValue !== false
			) {
				finalText +=
					' - ' +
					(selectedInteriorColorValue !== false
						? ConfigurationLabelMapper(selectedInteriorColorValue)
						: '') +
					((selectedInteriorColorValue !== false &&
					selectedExteriorColorValue !== false
						? '/'
						: '') +
						(selectedExteriorColorValue !== false
							? ConfigurationLabelMapper(selectedExteriorColorValue)
							: ''));
			}
		}
		// now we add the pattern information
		if (selectedGirdPatternValue !== false) {
			finalText +=
				(finalText === '' ? '' : ' | ') +
				'Pattern: ' +
				ConfigurationLabelMapper(selectedGirdPatternValue);
		}
		// return completed text
		return finalText;
	};

	// we need to check defaults as they switch
	useEffect(() => {
		if (props.configuration.pattern) {
			// check the pattern value
			if (
				selectedGridStyleOption.patternOptions === false &&
				selectedGirdPatternValue !== false
			) {
				setQuoteProductContextProductValue(
					props.configuration.pattern.fieldName,
					false
				);
			} else if (
				selectedGridStyleOption.patternOptions !== false &&
				selectedGridStyleOption.patternOptions.indexOf(
					selectedGirdPatternValue
				) === -1
			) {
				setQuoteProductContextProductValue(
					props.configuration.pattern.fieldName,
					selectedGridStyleOption.patternDefault
				);
			}
		}

		if (props.configuration.interiorMuntinColor) {
			if (
				selectedGridStyleOption.interiorMutinColorDefault === false &&
				selectedInteriorColorValue !== false
			) {
				setQuoteProductContextProductValue(
					props.configuration.interiorMuntinColor.fieldName,
					false
				);
			} else if (
				selectedGridStyleOption.interiorMutinColorDefault !== false &&
				selectedGridStyleOption.interiorMutinColorOptions.indexOf(
					selectedInteriorColorValue
				) === -1
			) {
				setQuoteProductContextProductValue(
					props.configuration.interiorMuntinColor.fieldName,
					selectedGridStyleOption.interiorMutinColorDefault
				);
			}
		}

		if (props.configuration.exteriorMuntinColor) {
			if (
				selectedInteriorColorValue === false &&
				selectedExteriorColorValue !== false
			) {
				setQuoteProductContextProductValue(
					props.configuration.exteriorMuntinColor.fieldName,
					false
				);
			} else if (
				selectedInteriorColorValue !== false &&
				selectedInteriorColorOption.exteriorMutinColorOptions !== false &&
				selectedInteriorColorOption.exteriorMutinColorOptions.indexOf(
					selectedExteriorColorValue
				) === -1
			) {
				setQuoteProductContextProductValue(
					props.configuration.exteriorMuntinColor.fieldName,
					selectedInteriorColorOption.exteriorMutinColorDefault
				);
			}
		}
	}, [
		selectedInteriorColorValue,
		selectedInteriorColorOption,
		selectedExteriorColorValue,
		selectedGirdPatternValue,
		selectedGridPatternOption,
		selectedGridStyleOption,
		props.configuration.interiorMuntinColor,
		props.configuration.exteriorMuntinColor,
		props.configuration.pattern,
		setQuoteProductContextProductValue,
	]);

	// return the component
	return (
		<div className="productconfiguration">
			<div
				className={`productconfiguration__section ${
					openConfigurationData() === props.orderIndex
						? 'productconfiguration__section--open'
						: ''
				}`}
			>
				<button
					type="button"
					className="productconfiguration__section-header"
					onClick={() => {
						toggleOpenConfiguration(props.orderIndex);
					}}
				>
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{props.orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">
							{props.configuration.heading}
						</div>
						<div className="heading heading--7">
							{createSectionHeadingDetails()}
						</div>
					</div>
					<div className="productconfiguration__section-thumbnail--colors">
						{selectedExteriorColorValue ? (
							<div className="productconfiguration__thumbnail-color">
								<ImageAsset code={selectedExteriorColorValue} />
							</div>
						) : (
							''
						)}
						{selectedInteriorColorValue ? (
							<div className="productconfiguration__thumbnail-color">
								<ImageAsset code={selectedInteriorColorValue} />
							</div>
						) : (
							''
						)}
					</div>
					<div className="productconfiguration__section-toggle">
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--primary'}
							iconType={'icon--plus'}
						/>
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--secondary'}
							iconType={'icon--minus'}
						/>
					</div>
				</button>
				<div className="productconfiguration__section-body">
					{props.configuration.style ? (
						<>
							<div className="heading heading--4 config-heading">
								{props.configuration.style.heading}
							</div>
							<div className="config-circle-select">
								{props.configuration.style.values.map((option) => {
									return (
										<React.Fragment key={option.value}>
											<input
												type="radio"
												name={props.configuration.style.fieldName}
												id={`${props.configuration.style.fieldName}_${option.value}`}
												value={option.value}
												checked={
													option.value ===
													selectedGridStyleValue
												}
												onChange={(e) => {
													// update the mutin style
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
													// try to set the colors to what was selected for color
													const newSelectedStyleOption =
														props.configuration.style.values.find(
															(option) => {
																return (
																	option.value ===
																	e.target.value
																);
															}
														);
													const hasInteriorColor =
														newSelectedStyleOption.interiorMutinColorDefault !==
														false;
													const hasExteriorColor =
														hasInteriorColor &&
														props.configuration
															.exteriorMuntinColor;
													if (
														product.configurations[
															'WINDOW_COLOR'
														] &&
														product.configurations[
															'WINDOW_COLOR'
														].interiorColor &&
														hasInteriorColor
													) {
														setQuoteProductContextProductValue(
															props.configuration
																.interiorMuntinColor
																.fieldName,
															getQuoteProductContextProductValue(
																product.configurations[
																	'WINDOW_COLOR'
																].interiorColor.fieldName
															)
														);
													}
													if (
														product.configurations[
															'WINDOW_COLOR'
														] &&
														product.configurations[
															'WINDOW_COLOR'
														].exteriorColor &&
														hasExteriorColor
													) {
														setQuoteProductContextProductValue(
															props.configuration
																.exteriorMuntinColor
																.fieldName,
															getQuoteProductContextProductValue(
																product.configurations[
																	'WINDOW_COLOR'
																].exteriorColor.fieldName
															)
														);
													}
												}}
											/>
											<label
												htmlFor={`${props.configuration.style.fieldName}_${option.value}`}
												className="config-circle-select__option"
											>
												<div
													className={`config-circle-select__circle ${
														option.value === 'NONE'
															? 'config-circle-select__circle--none'
															: ''
													}`}
												>
													{option.value !== 'NONE' ? (
														<ImageAsset code={option.value} />
													) : (
														''
													)}
												</div>
												<div className="config-circle-select__label heading heading--5">
													{ConfigurationLabelMapper(
														option.value
													)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
						</>
					) : (
						''
					)}

					{props.configuration.pattern &&
					selectedGirdPatternValue !== false &&
					selectedGridStyleValue !== 'NONE' ? (
						<>
							{props.configuration.style ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="heading heading--4 config-heading">
								{props.configuration.pattern.heading}
							</div>
							<div className="config-circle-select">
								{props.configuration.pattern.values.map((option) => {
									const isDisabled =
										selectedGridStyleOption.patternOptions.indexOf(
											option.value
										) === -1;
									return (
										<React.Fragment key={option.value}>
											<input
												type="radio"
												name={
													props.configuration.pattern.fieldName
												}
												id={`${props.configuration.pattern.fieldName}_${option.value}`}
												value={option.value}
												{...(isDisabled && { disabled: true })}
												checked={
													option.value ===
													selectedGirdPatternValue
												}
												onChange={(e) => {
													// update the grid pattern,
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${props.configuration.pattern.fieldName}_${option.value}`}
												className={`config-circle-select__option ${
													isDisabled
														? 'config-circle-select__option--inactive'
														: ''
												}`}
											>
												<div className="config-circle-select__circle">
													<ImageAsset code={option.value} />
												</div>
												<div className="config-circle-select__label heading heading--5">
													{ConfigurationLabelMapper(
														option.value
													)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
							{(props.configuration.details ||
								props.configuration.location) &&
							selectedGirdPatternValue !== false ? (
								<>
									<div className="config-divider"></div>
									<div className="productconfiguration__grid-location-details">
										{props.configuration.location && (
											<>
												<div className="productconfiguration__grid-location">
													<div className="heading heading--4 config-heading">
														{
															props.configuration.location
																.heading
														}
													</div>
													<div
														ref={locationEl}
														className="config-checkboxes"
													>
														{props.configuration.location.values.map(
															(option) => {
																let showLocation = true;
																if (
																	product.code ===
																		'DOOR_WALL_PROD' &&
																	option.value ===
																		'CENTER'
																) {
																	showLocation =
																		getQuoteProductContextProductValue(
																			product
																				.configurations
																				.WINDOW_STYLE
																				.sections
																				.fieldName
																		) ===
																		'SECTIONS_3_DOORWALL';
																}
																return showLocation ? (
																	<React.Fragment
																		key={option.value}
																	>
																		<input
																			type="checkbox"
																			id={
																				option.fieldName
																			}
																			name={
																				option.fieldName
																			}
																			checked={getQuoteProductContextProductValue(
																				option.fieldName
																			)}
																			onChange={(
																				e
																			) => {
																				setQuoteProductContextProductValue(
																					e
																						.target
																						.name,
																					e
																						.target
																						.checked
																				);
																				// we'll check to see if we have any boxes checked, we must have at least 1
																				const els =
																					locationEl.current.querySelectorAll(
																						'input[type=checkbox'
																					);
																				for (
																					let i = 0;
																					i <
																					els.length;
																					i++
																				) {
																					if (
																						els[
																							i
																						]
																							.checked
																					) {
																						return;
																					}
																				}
																				els.forEach(
																					(
																						el
																					) => {
																						setQuoteProductContextProductValue(
																							el.name,
																							true
																						);
																					}
																				);
																			}}
																		/>
																		<label
																			htmlFor={
																				option.fieldName
																			}
																			className="config-checkboxes__checkbox"
																		>
																			<span className="config-checkboxes__box icon-far"></span>
																			<span className="config-checkboxes__label">
																				{
																					option.label
																				}
																			</span>
																		</label>
																	</React.Fragment>
																) : (
																	''
																);
															}
														)}
													</div>
												</div>
												<div className="config-divider"></div>
											</>
										)}
										{props.configuration.details && (
											<div className="productconfiguration__grid-details">
												<div className="heading heading--4 config-heading">
													Grid Details
												</div>
												<Input
													type="textarea"
													rows="4"
													id={
														props.configuration.details
															.fieldName
													}
													name={
														props.configuration.details
															.fieldName
													}
													className={'input--1'}
													value={getQuoteProductContextProductValue(
														props.configuration.details
															.fieldName
													)}
													placeholder="Enter grid details..."
													onChange={(evt) => {
														setQuoteProductContextProductValue(
															evt.currentTarget.name,
															evt.currentTarget.value
														);
													}}
												/>
											</div>
										)}
									</div>
								</>
							) : (
								''
							)}
						</>
					) : (
						''
					)}

					{props.configuration.interiorMuntinColor &&
					selectedInteriorColorValue !== false &&
					selectedGridStyleOption.interiorMutinColorDefault !== false ? (
						<>
							{props.configuration.style ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="productconfiguration__color-secondary">
								<div className="config-heading heading heading--4">
									{props.configuration.interiorMuntinColor.heading}
								</div>
								<div className="config-subheading heading heading--6">
									Select a Muntin color
								</div>
								<div className="config-notch-select">
									{props.configuration.interiorMuntinColor.values.map(
										(option) => {
											const isVisible =
												selectedGridStyleOption.interiorMutinColorOptions.indexOf(
													option.value
												) !== -1;
											return isVisible ? (
												<React.Fragment key={option.value}>
													<input
														type="radio"
														name={
															props.configuration
																.interiorMuntinColor
																.fieldName
														}
														id={`${props.configuration.interiorMuntinColor.fieldName}_${option.value}`}
														value={option.value}
														checked={
															option.value ===
															selectedInteriorColorValue
														}
														onChange={(e) => {
															setQuoteProductContextProductValue(
																e.target.name,
																e.target.value
															);
														}}
													/>
													<label
														htmlFor={`${props.configuration.interiorMuntinColor.fieldName}_${option.value}`}
														className={`config-notch-select__option`}
													>
														<div className="config-notch-select__button">
															<span className="config-notch-select__notch"></span>
															<ImageAsset
																code={option.value}
															/>
														</div>
														<div className="config-notch-select__label heading heading--10">
															{ConfigurationLabelMapper(
																option.value
															)}
														</div>
													</label>
												</React.Fragment>
											) : (
												''
											);
										}
									)}
								</div>
							</div>
						</>
					) : (
						''
					)}

					{props.configuration.exteriorMuntinColor &&
					selectedExteriorColorValue !== false &&
					selectedInteriorColorValue !== false ? (
						<>
							{props.configuration.style ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="productconfiguration__color-secondary">
								<div className="config-heading heading heading--4">
									{props.configuration.exteriorMuntinColor.heading}
								</div>
								<div className="config-subheading heading heading--6">
									Select a Muntin color
								</div>
								<div className="config-notch-select">
									{props.configuration.exteriorMuntinColor.values.map(
										(value) => {
											let isVisible =
												selectedInteriorColorOption.exteriorMutinColorOptions.indexOf(
													value
												) !== -1;
											return isVisible ? (
												<React.Fragment key={value}>
													<input
														type="radio"
														name={
															props.configuration
																.exteriorMuntinColor
																.fieldName
														}
														id={`${props.configuration.exteriorMuntinColor.fieldName}_${value}`}
														value={value}
														checked={
															value ===
															selectedExteriorColorValue
														}
														onChange={(e) => {
															setQuoteProductContextProductValue(
																e.target.name,
																e.target.value
															);
														}}
													/>
													<label
														htmlFor={`${props.configuration.exteriorMuntinColor.fieldName}_${value}`}
														className={`config-notch-select__option`}
													>
														<div className="config-notch-select__button">
															<span className="config-notch-select__notch"></span>
															<ImageAsset code={value} />
														</div>
														<div className="config-notch-select__label heading heading--10">
															{ConfigurationLabelMapper(
																value
															)}
														</div>
													</label>
												</React.Fragment>
											) : (
												''
											);
										}
									)}
								</div>
							</div>
						</>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductConfigurationWindowGrid;
