import React, { useCallback, useContext, useEffect } from 'react';
import Input from '../../Input/Input';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper.js';

const ProductConfigurationBasicSelect = (props) => {
	// get the quote product context
	const {
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
		setPreviousQuoteProductContextValue,
	} = useContext(CurrentQuoteProductContext);

	// Set to use the previous value
	const setPreviousContextValue = useCallback(async () => {
		await setPreviousQuoteProductContextValue(props.configuration.fieldName);
	}, [props.configuration.fieldName, setPreviousQuoteProductContextValue]);

	useEffect(() => {
		setPreviousContextValue();
	}, [setPreviousContextValue]);

	const handleOnChange = (e) => {
		setQuoteProductContextProductValue(e.target.name, e.target.value);
	};

	// return the component
	return (
		<div className="productconfiguration">
			<p className="heading heading--5 productconfiguration__heading">
				<label htmlFor={props.configuration.fieldName}>
					{props.configuration.heading}
				</label>
			</p>
			<Input
				type={'select'}
				className={'input--1'}
				inputModifier={'input--select'}
				id={props.configuration.fieldName}
				name={props.configuration.fieldName}
				values={[{ label: 'Select ...', value: '' }].concat(
					props.configuration.values.map(function (value) {
						return { label: ConfigurationLabelMapper(value), value: value };
					})
				)}
				value={getQuoteProductContextProductValue(props.configuration.fieldName)}
				onChange={handleOnChange}
			/>
		</div>
	);
};
export default ProductConfigurationBasicSelect;
