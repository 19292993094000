// create the class
export default class {
	// constructor
	constructor(d) {
		// set the default fields
		this.globalErrors = [];
		this.fieldErrors = {};
		// set the properties based on response type
		let data = d.message;
		try {
			data = JSON.parse(data);
			this.globalErrors = data.globalErrors;
			this.fieldErrors = data.fieldErrors;
		} catch (error) {
			this.globalErrors = [
				{
					code: 'api_error',
					message: data,
				},
			];
		}
	}
}
