import React from 'react';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';

export const WindowStyle = ({ groupName, product, quoteProduct }) => (
	<>
		{product.configurations[groupName].style && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Style:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].style.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].numberOfWindows && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Number of Windows:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].numberOfWindows
									.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].material && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Material:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].material.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].sections && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Sections:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].sections.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].ventPosition &&
			quoteProduct.values[
				product.configurations[groupName].ventPosition.fieldName
			] && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Vent Position:</dt>
						<dd className="heading heading--13">
							{ConfigurationLabelMapper(
								quoteProduct.values[
									product.configurations[groupName].ventPosition
										.fieldName
								]
							)}
						</dd>
					</dl>
				</div>
			)}
		{product.configurations[groupName].operation &&
			quoteProduct.values[
				product.configurations[groupName].operation.fieldName
			] && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Operation:</dt>
						<dd className="heading heading--13">
							{ConfigurationLabelMapper(
								quoteProduct.values[
									product.configurations[groupName].operation.fieldName
								]
							)}
						</dd>
					</dl>
				</div>
			)}
		{product.configurations[groupName].shape && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Shape:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].shape.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
	</>
);
