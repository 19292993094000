import React, { useContext } from 'react';

import IconBtn from '../../../IconBtn/IconBtn';
import ImageAsset from '../../../ImageAsset/ImageAsset';
import UIManagementContext from '../../../../contexts/UIManagementContext';

const OpenConfigurationButton = ({
	configuration,
	createSectionHeadingDetails,
	orderIndex,
	selectedExteriorColorValue,
	selectedExteriorTrimColorValue,
	selectedInteriorColorValue,
}) => {
	// The UI management context
	const { toggleOpenConfiguration } = useContext(UIManagementContext);

	return (
		<button
			type="button"
			className="productconfiguration__section-header"
			onClick={() => {
				toggleOpenConfiguration(orderIndex);
			}}
		>
			<div className="productconfiguration__header-number">
				<div className="heading heading--9">{orderIndex}</div>
			</div>
			<div className="productconfiguration__header-info">
				<div className="heading heading--3">{configuration.heading}</div>
				<div className="heading heading--7">{createSectionHeadingDetails()}</div>
			</div>
			<div className="productconfiguration__section-thumbnail--colors">
				{selectedExteriorTrimColorValue && (
					<div className="productconfiguration__thumbnail-color">
						<ImageAsset code={selectedExteriorTrimColorValue} />
					</div>
				)}
				{selectedExteriorColorValue && (
					<div className="productconfiguration__thumbnail-color">
						<ImageAsset code={selectedExteriorColorValue} />
					</div>
				)}
				{selectedInteriorColorValue && selectedInteriorColorValue !== 'NONE' && (
					<div className="productconfiguration__thumbnail-color">
						<ImageAsset code={selectedInteriorColorValue} />
					</div>
				)}
			</div>
			<div className="productconfiguration__section-toggle">
				<IconBtn
					layoutOnly
					iconWeight="icon-far"
					btnType={'iconbtn--primary'}
					iconType={'icon--plus'}
				/>
				<IconBtn
					layoutOnly
					iconWeight="icon-far"
					btnType={'iconbtn--secondary'}
					iconType={'icon--minus'}
				/>
			</div>
		</button>
	);
};

export default OpenConfigurationButton;
