import React from 'react';
import Layout1 from '../../layouts/Layout1/Layout1';
import NavbarLogo from '../../components/NavbarLogo/NavbarLogo';
import UserWelcome from '../../components/UserWelcome/UserWelcome';
import Dashboard from '../../components/Dashboard/Dashboard';

const DashboardRoute = (props) => {
	// return the component
	return (
		<main>
			<Layout1 navbar={<NavbarLogo />} sideBarChildren={<UserWelcome />}>
				<Dashboard />
			</Layout1>
		</main>
	);
};

export default DashboardRoute;
