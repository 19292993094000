import React, { useContext } from 'react';
import Parse from 'parse';
import SessionDataContext from '../../contexts/SessionDataContext';
import UIManagementContext from '../../contexts/UIManagementContext';
import Btn from '../Btn/Btn';
import './UserWelcome.css';

const UserWelcome = (props) => {
	// setup our session data context
	const { toggleOverlay } = useContext(UIManagementContext);
	const { activeUser, setUser } = useContext(SessionDataContext);

	// return the component
	return (
		<aside className="userwelcome">
			<div className="userwelcome__account">
				<div className="heading heading--6">Welcome Back</div>
				<div className="heading heading--3">
					{activeUser.get('firstName')} {activeUser.get('lastName')}
				</div>
				<div className="userwelcome__link">
					<Btn
						btnType="btn--text-underline"
						onClick={() => {
							Parse.User.logOut().then(
								() => {
									setUser(false);
								},
								(e) => {
									setUser(false);
								}
							);
						}}
					>
						Sign Out
					</Btn>
				</div>
			</div>
			<ul className="userwelcome__actions">
				<li>
					<button
						type="button"
						className="userwelcome__action icon-far icon--usercog"
						onClick={() => {
							toggleOverlay({
								active: true,
								type: 'personal_settings',
								data: {},
							});
						}}
					>
						Personal Settings
					</button>
				</li>
			</ul>
		</aside>
	);
};

export default UserWelcome;
