import React, { useContext } from 'react';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import { toFixed } from '../../../helpers/Calculations';
import Input from '../../Input/Input';

const ProductConfigurationWindowSize = (props) => {
	// get and set context values
	const { setQuoteProductContextProductValue, getQuoteProductContextProductValue } =
		useContext(CurrentQuoteProductContext);

	// the total ui
	const width = parseFloat(
		getQuoteProductContextProductValue(props.configuration.fieldNameWidth)
	);
	const height = parseFloat(
		getQuoteProductContextProductValue(props.configuration.fieldNameHeight)
	);
	const unitedInches =
		isNaN(width) || isNaN(height) ? 0 : toFixed(width + height, 1000);

	// the component render
	return (
		<div className="productconfiguration window-size-container">
			<div className="heading heading--5 productconfiguration__heading">
				{props.configuration.heading}
			</div>
			<div className="window-size-inputs-container">
				<div className="productconfiguration__windowsize-inputs">
					<div className="productconfiguration__windowsize-input">
						<div>
							<label
								htmlFor={props.configuration.fieldNameWidth}
								className="config-subheading heading heading--6"
							>
								Width
							</label>
							<Input
								type="text"
								id={props.configuration.fieldNameWidth}
								name={props.configuration.fieldNameWidth}
								className={'input--1 input--inches'}
								value={getQuoteProductContextProductValue(
									props.configuration.fieldNameWidth
								)}
								onBlur={(e) => {
									const value = parseFloat(e.target.value);
									setQuoteProductContextProductValue(
										props.configuration.fieldNameWidth,
										isNaN(value)
											? props.configuration.defaultValueWidth
											: Math.max(0, value)
									);
								}}
								onChange={(e) => {
									setQuoteProductContextProductValue(
										props.configuration.fieldNameWidth,
										e.target.value
									);
								}}
								disabled={props?.disabled}
							/>
						</div>
					</div>
					<div className="productconfiguration__windowsize-input">
						<span className="icon-fal icon icon--x productconfiguration__windowsize-times"></span>
						<div>
							<label
								htmlFor={props.configuration.fieldNameHeight}
								className="config-subheading heading heading--6"
							>
								Height
							</label>
							<Input
								type="text"
								id={props.configuration.fieldNameHeight}
								name={props.configuration.fieldNameHeight}
								className={'input--1 input--inches'}
								value={getQuoteProductContextProductValue(
									props.configuration.fieldNameHeight
								)}
								onBlur={(e) => {
									const value = parseFloat(e.target.value);
									setQuoteProductContextProductValue(
										props.configuration.fieldNameHeight,
										isNaN(value)
											? props.configuration.defaultValueHeight
											: Math.max(0, value)
									);
								}}
								onChange={(e) => {
									setQuoteProductContextProductValue(
										props.configuration.fieldNameHeight,
										e.target.value
									);
								}}
								disabled={props?.disabled}
							/>
						</div>
					</div>
				</div>
				<div className="productconfiguration__windowsize-output">
					<div className="productconfiguration__windowsize-output-icon">
						<span className="icon-far icon icon--ruler"></span>
					</div>
					<div className="productconfiguration__windowsize-output-calculation">
						<div className="heading heading--5">
							= {unitedInches} United Inches
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductConfigurationWindowSize;
