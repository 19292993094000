import React, { Fragment } from 'react';

import ConfigurationLabelMapper from '../../../../../functionality/ConfigurationLabelMapper';
/**
 *
 */
export const ScreenTab = ({
	screen_config,
	screenData,
	screenMeshChangeHandler,
	screenSizeChangeHandler,
}) => {
	const { mesh, size } = screen_config;
	return (
		<>
			{/* START: Size options */}
			<div className="config-heading heading heading--4">{size.heading}</div>
			<div className="config-option-select">
				{size.values.map((value) => {
					return (
						<Fragment key={value}>
							<input
								type="radio"
								name={size.fieldName}
								id={`${size.fieldName}_${value}`}
								value={value}
								checked={value === screenData.selectedScreenSizeValue}
								onChange={screenSizeChangeHandler}
							/>
							<label
								htmlFor={`${size.fieldName}_${value}`}
								className="config-option-select__option"
							>
								<div className="config-option-select__check"></div>
								<div className="config-option-select__info">
									{ConfigurationLabelMapper(value)}
								</div>
							</label>
						</Fragment>
					);
				})}
			</div>
			{/* END: Size options */}

			<div className="config-divider"></div>

			{/* START: Mesh options */}
			<div className="config-heading heading heading--4">{mesh.heading}</div>
			<div className="config-option-select">
				{mesh.values.map((value) => {
					return (
						<React.Fragment key={value}>
							<input
								type="radio"
								name={mesh.fieldName}
								id={`${mesh.fieldName}_${value}`}
								value={value}
								checked={value === screenData.selectedScreenMeshValue}
								onChange={screenMeshChangeHandler}
							/>
							<label
								htmlFor={`${mesh.fieldName}_${value}`}
								className="config-option-select__option"
							>
								<div className="config-option-select__check"></div>
								<div className="config-option-select__info">
									{ConfigurationLabelMapper(value)}
								</div>
							</label>
						</React.Fragment>
					);
				})}
			</div>
		</>
	);
};
