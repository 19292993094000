import React from 'react';

import ImageAsset from '../../../ImageAsset/ImageAsset';
import ConfigurationLabelMapper from '../../../../functionality/ConfigurationLabelMapper';

const ConfigurationInteriorColorInput = ({
	configuration,
	selectedInteriorColorValue,
	setQuoteProductContextProductValue,
}) => {
	if (!configuration.interiorColor) {
		return '';
	}

	return (
		<>
			<div className="config-heading heading heading--4">
				{configuration.interiorColor.heading}
			</div>
			<div className="config-notch-select">
				{configuration.interiorColor.values.map((option) => {
					const isNone = option.value === 'NONE';
					return (
						<React.Fragment key={option.value}>
							<input
								type="radio"
								name={configuration.interiorColor.fieldName}
								id={`${configuration.interiorColor.fieldName}_${option.value}`}
								value={option.value}
								checked={option.value === selectedInteriorColorValue}
								onChange={(e) => {
									setQuoteProductContextProductValue(
										e.target.name,
										e.target.value
									);
								}}
							/>
							<label
								htmlFor={`${configuration.interiorColor.fieldName}_${option.value}`}
								className="config-notch-select__option"
							>
								<div className="config-notch-select__button">
									<span className="config-notch-select__notch"></span>
									{!isNone ? <ImageAsset code={option.value} /> : ''}
								</div>
								<div className="config-notch-select__label heading heading--10">
									{ConfigurationLabelMapper(option.value)}
								</div>
							</label>
						</React.Fragment>
					);
				})}
			</div>
		</>
	);
};

export default ConfigurationInteriorColorInput;
