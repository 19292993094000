import React, { useContext } from 'react';
import IconBtn from '../../IconBtn/IconBtn';
import '../ProductConfigurations.css';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper.js';
import UIManagementContext from '../../../contexts/UIManagementContext';

const ProductConfigurationWindowScreen = (props) => {
	// the ui management context
	const { openConfigurationData, toggleOpenConfiguration } =
		useContext(UIManagementContext);

	// context values
	const { setQuoteProductContextProductValue, getQuoteProductContextProductValue } =
		useContext(CurrentQuoteProductContext);

	// there are some special rules for the 'size' option
	const showSize =
		props.configuration.size && props.configuration.size.values.length > 1
			? true
			: false;

	// create the section details
	const createSectionHeadingDetails = () => {
		return (
			(props.configuration.size
				? ConfigurationLabelMapper(
						getQuoteProductContextProductValue(
							props.configuration.size.fieldName
						)
				  )
				: '') +
			(props.configuration.size && props.configuration.mesh ? ' | ' : '') +
			(props.configuration.mesh
				? ConfigurationLabelMapper(
						getQuoteProductContextProductValue(
							props.configuration.mesh.fieldName
						)
				  )
				: '')
		);
	};

	return (
		<div className="productconfiguration">
			<div
				className={`productconfiguration__section ${
					openConfigurationData() === props.orderIndex
						? 'productconfiguration__section--open'
						: ''
				}`}
			>
				<button
					type="button"
					className="productconfiguration__section-header"
					onClick={() => {
						toggleOpenConfiguration(props.orderIndex);
					}}
				>
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{props.orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">
							{props.configuration.heading}
						</div>
						<div className="heading heading--7">
							{createSectionHeadingDetails()}
						</div>
					</div>
					<div className="productconfiguration__section-toggle">
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--primary'}
							iconType={'icon--plus'}
						/>
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--secondary'}
							iconType={'icon--minus'}
						/>
					</div>
				</button>
				<div className="productconfiguration__section-body">
					{props.configuration.size && showSize ? (
						<>
							<div className="config-heading heading heading--4">
								{props.configuration.size.heading}
							</div>
							<div className="config-option-select">
								{props.configuration.size.values.map((value) => {
									return (
										<React.Fragment key={value}>
											<input
												type="radio"
												name={props.configuration.size.fieldName}
												id={`${props.configuration.size.fieldName}_${value}`}
												value={value}
												checked={
													!!(
														value ===
														getQuoteProductContextProductValue(
															props.configuration.size
																.fieldName
														)
													)
												}
												onChange={(e) =>
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													)
												}
											/>
											<label
												htmlFor={`${props.configuration.size.fieldName}_${value}`}
												className="config-option-select__option"
											>
												<div className="config-option-select__check"></div>
												<div className="config-option-select__info">
													{ConfigurationLabelMapper(value)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
						</>
					) : (
						''
					)}

					{props.configuration.mesh ? (
						<>
							{props.configuration.size && showSize ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="config-heading heading heading--4">
								{props.configuration.mesh.heading}
							</div>
							<div className="config-option-select">
								{props.configuration.mesh.values.map((value) => {
									return (
										<React.Fragment key={value}>
											<input
												type="radio"
												name={props.configuration.mesh.fieldName}
												id={`${props.configuration.mesh.fieldName}_${value}`}
												value={value}
												checked={
													!!(
														value ===
														getQuoteProductContextProductValue(
															props.configuration.mesh
																.fieldName
														)
													)
												}
												onChange={(e) =>
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													)
												}
											/>
											<label
												htmlFor={`${props.configuration.mesh.fieldName}_${value}`}
												className="config-option-select__option"
											>
												<div className="config-option-select__check"></div>
												<div className="config-option-select__info">
													{ConfigurationLabelMapper(value)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
						</>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductConfigurationWindowScreen;
