import React, { useContext, useEffect, useRef } from 'react';
import SessionDataContext from '../../contexts/SessionDataContext';
import WallsideError from '../../functionality/WallsideError';
import SimpleNavbar from '../../components/NavBars/SimpleNavbar/SimpleNavbar';
import QuotePricingProductList from '../../components/QuotePricingProductList/QuotePricingProductList';
import Layout2 from '../../layouts/Layout2/Layout2';
import ReactToPrint from 'react-to-print';

const PricingQuoteRoute = (props) => {
	const pricingRef = useRef();
	// we'll setup the session data contenxt
	const { loadQuote, activeQuote } = useContext(SessionDataContext);

	// we'll fetch the latest content needed
	useEffect(() => {
		loadQuote(props.match.params.quoteId, false).then(
			() => {
				// nothing
			},
			(e) => {
				const error = new WallsideError(e);
				alert(error.globalErrors[0].message);
			}
		);
	}, [loadQuote, props.match.params.quoteId]);

	// return the component ui
	return (
		<Layout2
			navbar={<SimpleNavbar title={activeQuote ? activeQuote.get('title') : ''} />}
		>
			<ReactToPrint
				trigger={() => (
					<div className="flex flex-col items-center justify-center w-full">
						<button className="p-3 px-5 bg-blue-300 rounded">Print</button>
					</div>
				)}
				content={() => pricingRef.current}
			/>
			<QuotePricingProductList ref={pricingRef} />
		</Layout2>
	);
};

export default PricingQuoteRoute;
