import React from 'react';
import CustomProductSpecificationsModal from '../CustomProductSpecificationsModal';
import { useCustomWindowContext } from '../../containers/CustomWindow/customWindowContext';
import { DragDropItemsHandler } from './DragDropItemsHandler';
import './style.css';

const DragDropProducts = ({ product }) => {
	const { customWindowState } = useCustomWindowContext();
	const { openModalState } = customWindowState;
	const isNotCustom =
		product &&
		(product.code.includes('BAY_SHELL') || product.code.includes('BOW_SHELL'));

	return (
		<div className="flex flex-row w-1/3 gap-1 ml-3 max-w-1/3">
			<div className="border-l border-gray-200 border-solid" />
			<div className="w-full">
				<h3 className="mb-2">Drag & Drop Products</h3>
				<div className="flex w-full drag_elements_container flex-center ws-inner-box-shadow item-end">
					<div className="flex flex-wrap w-full overflow-y-scroll h-100">
						<DragDropItemsHandler
							isNotCustom={isNotCustom}
							product={product}
						/>
					</div>
				</div>
			</div>
			{openModalState && <CustomProductSpecificationsModal className="flex" />}
		</div>
	);
};

export default DragDropProducts;
