export default {
	// Temper
	TEMPER_DOUBLE_HUNG_BOTH: {
		default: {
			description: 'Window with both panels tempered',
			url: '/configuration-images/temper/temper-both.png',
		},
	},
	TEMPER_DOUBLE_HUNG_LOWER: {
		default: {
			description: 'Window with lower panel tempered',
			url: '/configuration-images/temper/temper-lower.png',
		},
	},

	// Temper, Double Slider
	TEMPER_DOUBLE_SLIDER_BOTH: {
		default: {
			description: 'Window with both panels tempered',
			url: '/configuration-images/temper/temper-double-slider-both.png',
		},
	},

	// Temper, Single Casement
	TEMPER_SINGLE_CASEMENT_ALL: {
		default: {
			description: 'Tempered casement window',
			url: '/configuration-images/temper/temper-single-casement-all.png',
		},
	},

	// Temper, Double Casement
	TEMPER_DOUBLE_CASEMENT_BOTH: {
		default: {
			description: 'Tempered double casement window',
			url: '/configuration-images/temper/temper-double-casement-both.png',
		},
	},

	// Temper, Awning Casement
	TEMPER_AWNING_CASEMENT_ALL: {
		default: {
			description: 'Tempered awning casement window',
			url: '/configuration-images/temper/temper-awning-casement-all.png',
		},
	},

	// Temper, Hopper
	TEMPER_HOPPER_ALL: {
		default: {
			description: 'Tempered hopper window',
			url: '/configuration-images/temper/temper-hopper-all.png',
		},
	},

	// Temper, Center Vent
	TEMPER_CENTER_VENT_ALL: {
		default: {
			description: 'Tempered center vent window',
			url: '/configuration-images/temper/temper-center-vent-all.png',
		},
	},

	// Temper, End Vent
	TEMPER_END_VENT_ALL: {
		default: {
			description: 'Tempered end vent slider window',
			url: '/configuration-images/temper/temper-end-vent-all.png',
		},
	},

	// Temper, Doorwall
	TEMPER_DOORWALL_ALL: {
		default: {
			description: 'Tempered doorwall window',
			url: '/configuration-images/temper/temper-door-wall-all.png',
		},
	},

	// Temper, Garden window
	TEMPER_GARDEN_ALL: {
		default: {
			description: 'Tempered garden window',
			url: '/configuration-images/temper/temper-garden-window.png',
		},
	},

	// Temper, Picture
	TEMPER_PICTURE_ALL: {
		default: {
			description: 'Tempered picture window',
			url: '/configuration-images/temper/temper-picture-all.png',
		},
	},

	// Temper, Specialty
	TEMPER_SPECIALTY_ALL: {
		default: {
			description: 'Tempered specialty window',
			url: '/configuration-images/temper/temper-specialty-all.png',
		},
	},

	// Obscure
	OBSCURE_DOUBLE_HUNG_BOTH: {
		default: {
			description: 'Window with both panels obscured',
			url: '/configuration-images/obscure/obscure-both.png',
		},
	},
	OBSCURE_DOUBLE_HUNG_UPPER: {
		default: {
			description: 'Window with upper panel obscured',
			url: '/configuration-images/obscure/obscure-upper.png',
		},
	},
	OBSCURE_DOUBLE_HUNG_LOWER: {
		default: {
			description: 'Window with lower panel obscured',
			url: '/configuration-images/obscure/obscure-lower.png',
		},
	},

	// Obscure, Double Slider
	OBSCURE_DOUBLE_SLIDER_BOTH: {
		default: {
			description: 'Window with both panels obscured',
			url: '/configuration-images/obscure/obscure-double-slider-both.png',
		},
	},

	// Obscure, Single Casement
	OBSCURE_SINGLE_CASEMENT_ALL: {
		default: {
			description: 'Obscured casement window',
			url: '/configuration-images/obscure/obscure-single-casement-all.png',
		},
	},

	// Obscure, Double Casement
	OBSCURE_DOUBLE_CASEMENT_BOTH: {
		default: {
			description: 'Obscured double casement window',
			url: '/configuration-images/obscure/obscure-double-casement-both.png',
		},
	},

	// Obscure, Awning Casement
	OBSCURE_AWNING_CASEMENT_ALL: {
		default: {
			description: 'Obscured awning casement window',
			url: '/configuration-images/obscure/obscure-awning-casement-all.png',
		},
	},

	// Obscure, Hopper
	OBSCURE_HOPPER_ALL: {
		default: {
			description: 'Obscured hopper window',
			url: '/configuration-images/obscure/obscure-hopper-all.png',
		},
	},

	// Obscure, Center Vent
	OBSCURE_CENTER_VENT_ALL: {
		default: {
			description: 'Obscured center vent slider window',
			url: '/configuration-images/obscure/obscure-center-vent-all.png',
		},
	},

	// Obscure, End Vent
	OBSCURE_END_VENT_ALL: {
		default: {
			description: 'Obscured end vent slider window',
			url: '/configuration-images/obscure/obscure-end-vent-all.png',
		},
	},

	// Obscure, Doorwall
	OBSCURE_DOORWALL_ALL: {
		default: {
			description: 'Obscured doorwall window',
			url: '/configuration-images/obscure/obscure-door-wall-all.png',
		},
	},

	// Obscure, Garden Window
	OBSCURE_GARDEN_ALL: {
		default: {
			description: 'Obscured garden window',
			url: '/configuration-images/obscure/obscure-garden-window.png',
		},
	},

	// Obscure, Picture
	OBSCURE_PICTURE_ALL: {
		default: {
			description: 'Obscured picture window',
			url: '/configuration-images/obscure/obscure-picture-all.png',
		},
	},

	// Obscure, Picture
	OBSCURE_SPECIALTY_ALL: {
		default: {
			description: 'Obscured specialty window',
			url: '/configuration-images/obscure/obscure-specialty-all.png',
		},
	},

	// Tint
	TINT_GRAY: {
		default: {
			description: 'Window tinted gray',
			url: '/configuration-images/tint/tint-gray.png',
		},
	},
	TINT_BRONZE: {
		default: {
			description: 'Window tinted bronze',
			url: '/configuration-images/tint/tint-bronze.png',
		},
	},
};
