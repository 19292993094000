import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import UIManagementContext from '../../../contexts/UIManagementContext';
import SessionDataContext from '../../../contexts/SessionDataContext';
import Btn from '../../../components/Btn/Btn';
import WallsideError from '../../../functionality/WallsideError';

const OverlayQuoteNotes = (props) => {
	// setting up the history object
	const history = useHistory();

	// get the UIManagement context
	const { toggleOverlay, getBlockBack, toggleBlockBack, toggleSystemNotification } =
		useContext(UIManagementContext);

	// the current quote product context
	const { activeQuote, saveQuote } = useContext(SessionDataContext);

	// we'll use state to track the text input, and the state of overlay
	const [textField, setTextField] = useState(activeQuote.get('notes') || '');
	const [isCancel, setIsCancel] = useState(false);

	// add the effect to focus the input field, and make sure cursor goes to end
	const textInput = useRef(null);
	useEffect(
		function () {
			const v = activeQuote.get('notes');
			setTextField(v + (v !== '' ? '\n\n' : ''));
			textInput.current.focus();
		},
		[activeQuote]
	);

	// we want to use an effect to block the back button while the notes is open
	useEffect(() => {
		// set the block back
		toggleBlockBack(true);
		// add extra history entry, to block the back button
		history.push(history.location.pathname);
		// now we'll listen for the 'pop' event, which we know is a 'back' action
		const unlisten = history.listen((location, action) => {
			if (getBlockBack() && action === 'POP') {
				// user hit back, so we move them back forward
				history.go(1);
			}
		});
		return function cleanup() {
			unlisten();
		};
	}, [history, getBlockBack, toggleBlockBack]);

	// function that updates the notes
	const updateQuoteNotes = () => {
		activeQuote.set('notes', textField);
		saveQuote().then(
			() => {
				toggleBlockBack(false).then(() => {
					// go back because we duplicated the history
					history.go(-1);
					// close the overlay
					toggleOverlay({ active: false });
					// show the notification
					toggleSystemNotification({ active: true, messageKey: 'save_quote' });
				});
			},
			(e) => {
				// revert the notes
				activeQuote.revert('notes');
				// had an error
				const error = new WallsideError(e);
				alert(error.globalErrors[0].message);
			}
		);
	};

	// return the component
	return (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s1">
						<div className="overlay-s1__heading">
							<h1 className="heading heading--4">Add Quote Notes</h1>
						</div>
						{isCancel ? (
							<>
								<div className="overlay-s1__body overlay-s1__body--message">
									<p className="heading heading--5">
										Would you like to update your quote notes before
										closing? If you don’t, your changes will be lost.
									</p>
								</div>
								<div className="overlay-s1__btns">
									<Btn
										btnType={'btn--secondary'}
										onClick={() => {
											toggleBlockBack(false).then(() => {
												history.go(-1);
												toggleOverlay({ active: false });
											});
										}}
									>
										Don't Update
									</Btn>
									<Btn
										btnType={'btn--secondary'}
										onClick={(e) => {
											setIsCancel(false);
										}}
									>
										Back
									</Btn>
									<Btn
										btnType={'btn--primary'}
										onClick={updateQuoteNotes}
									>
										Update
									</Btn>
								</div>
							</>
						) : (
							<>
								<div className="overlay-s1__body">
									<label
										htmlFor="the_notes"
										className="heading heading--form-element-2"
									>
										Notes
									</label>
									<span className="input input--1 input--white">
										<textarea
											rows="12"
											type="text"
											id="the_notes"
											name="the_notes"
											placeholder="Enter your notes..."
											ref={textInput}
											value={textField}
											onChange={(evt) => {
												setTextField(evt.currentTarget.value);
											}}
										/>
									</span>
								</div>
								<div className="overlay-s1__btns">
									<Btn
										btnType={'btn--secondary'}
										onClick={(e) => {
											setIsCancel(true);
										}}
									>
										Cancel
									</Btn>
									<Btn
										btnType={'btn--primary'}
										onClick={updateQuoteNotes}
									>
										Update Notes
									</Btn>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayQuoteNotes;
