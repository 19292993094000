import React, { Fragment } from 'react';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';

export const WindowInstructions = ({ booleanText, groupName, product, quoteProduct }) => {
	return product.configurations[groupName].groups.map((group) => {
		return group.options.map((option, n) => {
			switch (option.configurationType) {
				case 'boolean':
					return (
						<Fragment key={n}>
							<div className="quotelistviewlist__lineitem">
								<dl>
									<dt className="heading heading--12">
										{option.heading}:
									</dt>
									<dd className="heading heading--13">
										{booleanText(
											quoteProduct.values[option.fieldName]
										)}
									</dd>
								</dl>
							</div>
							{quoteProduct.values[option.fieldName] && option.expand && (
								<div className="quotelistviewlist__lineitem">
									<dl>
										<dt className="heading heading--12">
											{option.expand.heading}:
										</dt>
										{option.expand.expandType === 'radio' ? (
											<dd className="heading heading--13">
												{ConfigurationLabelMapper(
													quoteProduct.values[
														option.expand.fieldName
													]
												)}
											</dd>
										) : (
											<dd className="heading heading--13">
												{
													quoteProduct.values[
														option.expand.fieldName
													]
												}
											</dd>
										)}
									</dl>
								</div>
							)}
						</Fragment>
					);
				case 'radio':
					return (
						<div key={n} className="quotelistviewlist__lineitem">
							<dl>
								<dt className="heading heading--12">{option.heading}:</dt>
								<dd className="heading heading--13">
									{ConfigurationLabelMapper(
										quoteProduct.values[option.fieldName]
									)}
								</dd>
							</dl>
						</div>
					);
				case 'number':
					return (
						<div key={n} className="quotelistviewlist__lineitem">
							<dl>
								<dt className="heading heading--12">{option.heading}:</dt>
								<dd className="heading heading--13">
									{quoteProduct.values[option.fieldName]}
								</dd>
							</dl>
						</div>
					);
				default:
					return 'UNKNOWN';
			}
		});
	});
};
