export default {
	// we need to track the high level product information
	code: 'CUSTOM_WINDOW',
	title: 'Multi Unit',
	description: '<p></p>',
	type: 'window_shell',
	images: [
		'BAY_SHELL_PROD',
		'BAY_SHELL_PROD_1',
		'BAY_SHELL_PROD_2',
		'BAY_SHELL_PROD_3',
	],

	// let's define our configurations
	configurations: {
		// window location
		WINDOW_LOCATION: {
			configurationId: 'WINDOW_LOCATION',
			configurationType: 'window_location',
			heading: 'Window Location',
			standard: {
				fieldName: 'configuration_window_location',
				defaultValue: '',
				values: [
					'ATTIC_FRONT',
					'ATTIC_SIDE',
					'ATTIC_REAR',
					'BASEMENT_FRONT',
					'BASEMENT_SIDE',
					'BASEMENT_REAR',
					'BATHROOM1_FRONT',
					'BATHROOM1_SIDE',
					'BATHROOM1_REAR',
					'BATHROOM2_FRONT',
					'BATHROOM2_SIDE',
					'BATHROOM2_REAR',
					'BATHROOM3_FRONT',
					'BATHROOM3_SIDE',
					'BATHROOM3_REAR',
					'BEDROOM1_FRONT',
					'BEDROOM1_SIDE',
					'BEDROOM1_REAR',
					'BEDROOM2_FRONT',
					'BEDROOM2_SIDE',
					'BEDROOM2_REAR',
					'BEDROOM3_FRONT',
					'BEDROOM3_SIDE',
					'BEDROOM3_REAR',
					'BEDROOM4_FRONT',
					'BEDROOM4_SIDE',
					'BEDROOM4_REAR',
					'BEDROOM5_FRONT',
					'BEDROOM5_SIDE',
					'BEDROOM5_REAR',
					'BEDROOM6_FRONT',
					'BEDROOM6_SIDE',
					'BEDROOM6_REAR',
					'BREEZEWAY_FRONT',
					'BREEZEWAY_SIDE',
					'BREEZEWAY_REAR',
					'CLOSET_FRONT',
					'CLOSET_SIDE',
					'CLOSET_REAR',
					'DEN_FRONT',
					'DEN_SIDE',
					'DEN_REAR',
					'DINING_ROOM_FRONT',
					'DINING_ROOM_SIDE',
					'DINING_ROOM_REAR',
					'FAMILY_ROOM_FRONT',
					'FAMILY_ROOM_SIDE',
					'FAMILY_ROOM_REAR',
					'FOYER_FRONT',
					'FOYER_SIDE',
					'FOYER_REAR',
					'GARAGE_FRONT',
					'GARAGE_SIDE',
					'GARAGE_REAR',
					'HALLWAY_FRONT',
					'HALLWAY_SIDE',
					'HALLWAY_REAR',
					'KITCHEN_FRONT',
					'KITCHEN_SIDE',
					'KITCHEN_REAR',
					'LAUNDRY_ROOM',
					'LIBRARY_FRONT',
					'LIBRARY_SIDE',
					'LIBRARY_REAR',
					'LIVING_ROOM_FRONT',
					'LIVING_ROOM_SIDE',
					'LIVING_ROOM_REAR',
					'NOOK_FRONT',
					'NOOK_SIDE',
					'NOOK_REAR',
					'NURSERY',
					'OFFICE_FRONT',
					'OFFICE_SIDE',
					'OFFICE_REAR',
					'PORCH_FRONT',
					'PORCH_SIDE',
					'PORCH_REAR',
					'SANCTUARY',
					'STAIRWAY_FRONT',
					'STAIRWAY_SIDE',
					'STAIRWAY_REAR',
					'UNIT1',
					'UNIT2',
					'UNIT3',
					'UNIT4',
					'UNIT5',
					'UNIT6',
					'UNIT7',
					'UNIT8',
					'UNIT9',
					'UNIT10',
					'UNIT11',
					'UNIT12',
					'UNIT13',
					'UNIT14',
					'UNIT15',
					'FRONT',
					'SIDE',
					'REAR',
					'BUILD_ONLY',
					'CUSTOM',
				],
			},
			custom: {
				fieldName: 'configuration_window_location_custom',
				defaultValue: '',
			},
		},

		// window leave existing
		WINDOW_MULLIONS: {
			configurationId: 'WINDOW_MULLIONS',
			configurationType: 'window_mullions',
			heading: 'Mullions',
			leave_existing: {
				heading: 'Leave existing?',
				fieldName: 'configuration_window_leave_existing_option',
				defaultValue: 'ALL_NEW_MULLS',
				values: ['LEAVE_ALL_EXISTING', 'LEAVE_PARTIAL_EXISTING', 'ALL_NEW_MULLS'],
			},
			vinyl: {
				heading: 'Vinyl',
				fieldName: 'configuration_window_vinyl',
				defaultValue: false,
			},
			vinyl_total: {
				fieldName: 'configuration_window_vinyl_total',
				configurationType: 'number',
				defaultValue: 0,
			},
			mullions_wood: {
				heading: 'Wood',
				fieldName: 'configuration_window_mullions_wood',
				defaultValue: false,
			},
			mullions_wood_total: {
				fieldName: 'configuration_window_mullions_wood_total',
				configurationType: 'number',
				defaultValue: 0,
			},
			mullions_removal_total: {
				fieldName: 'configuration_window_mullions_removal_total',
				configurationType: 'number',
				defaultValue: 0,
			},
		},

		WINDOW_NOTE: {
			fieldName: 'configuration_window_note',
			configurationId: 'WINDOW_NOTE',
			configurationType: 'text',
			heading: 'Notes',
			defaultValue: '',
			desc: 'Enter notes or instructions…',
		},

		// window size
		WINDOW_SIZE: {
			configurationId: 'WINDOW_SIZE',
			configurationType: 'window_size',
			heading: 'Window Size',
			fieldNameWidth: 'configuration_window_size_width',
			fieldNameHeight: 'configuration_window_size_height',
			defaultValueWidth: 0,
			minimumValueWidth: 0,
			maximumValueWidth: 99999,
			defaultValueHeight: 0,
			minimumValueHeight: 0,
			maximumValueHeight: 99999,
			maximumValueTotal: false,
		},

		// house type
		HOUSE_TYPE: {
			configurationId: 'HOUSE_TYPE',
			configurationType: 'basic_select',
			heading: 'House Type',
			fieldName: 'configuration_window_house_type',
			defaultValue: '',
			values: ['FRAME', 'BRICK', 'BLOCK'],
		},

		// removal type
		REMOVAL_TYPE: {
			configurationId: 'REMOVAL_TYPE',
			configurationType: 'window_removal_type',
			heading: 'Removal Type',
			fieldName: 'configuration_window_removal_type',
			defaultValue: '',
			values: ['VINYL', 'WOOD', 'ALUMINUM', 'STEEL', 'GLASS_BLOCK'],
		},

		// outside install
		OUTSIDE_INSTALL: {
			configurationId: 'OUTSIDE_INSTALL',
			configurationType: 'window_outside_install',
			heading: 'Outside Install',
			fieldName: 'configuration_window_outside_install',
			defaultValue: false,
		},

		// window style
		WINDOW_STYLE: {
			configurationId: 'WINDOW_STYLE',
			configurationType: 'window_style',
			heading: 'Style Options',
			style: {
				heading: 'Style',
				fieldName: 'configuration_window_style_style',
				defaultValue: 'STANDARD_SECTIONS',
				values: ['STANDARD_SECTIONS', 'EQUAL_SECTIONS'],
			},
			material: {
				heading: 'Material',
				fieldName: 'configuration_window_style_material',
				defaultValue: 'BIRCH_PINE_MATERIAL',
				values: ['BIRCH_PINE_MATERIAL', 'OAK_MATERIAL'],
			},
		},

		// window color
		WINDOW_COLOR: {
			configurationId: 'WINDOW_COLOR',
			configurationType: 'window_color',
			heading: 'Color',
			exteriorTrim: {
				heading: 'Exterior Trim',
				fieldName: 'configuration_window_exterior_trim_material',
				defaultValue: 'STANDARD',
				values: [
					{
						value: 'STANDARD',
						trimColorDefault: 'WHITE_280',
						trimColorOptions: ['WHITE', 'DESERT_TAN'],
					},
					{
						value: 'PAINTED',
						trimColorDefault: 'PEARL_GRAY_102',
						trimColorOptions: [
							'PEARL_GRAY_102',
							'ANTIQUE_IVORY_105',
							'TUXEDO_GRAY_112',
							'BROWN_190',
							'BLACK_200',
							'ROYAL_BROWN_202',
							'GRECIAN_GREEN_204',
							'SCOTCH_RED_209',
							'WOODLAND_GREEN_214',
							'AUTUMN_RED_215',
							'IMPERIAL_BROWN_223',
							'BUCKSKIN_224',
							'FLAGSTONE_232',
							'HICKORY_CLAY_238',
							'MUSKET_BROWN_250',
							'SILVER_ASH_318',
							'SANDSTONE_BEIGE_325',
							'LIGHT_MAPLE_355',
							'TERRATONE_360',
							'OXFORD_BLUE_396',
							'WICKER_713',
							'NORWEGIAN_WOOD_791',
							'HEATHER_792',
							'SANDCASTLE_793',
							'ALMOND_801',
							'PEWTER_805',
							'CREAM_817',
							'BRONZE_818',
							'HERRINGBONE_819',
							'CRANBERRY_820',
							'IVY_821',
							'PACIFIC_BLUE_822',
							'CHAMPAGNE_827',
							'BUCKSKIN_841',
							'IVORY_901',
						],
					},
				],
			},
			interiorColor: {
				heading: 'Interior Color',
				fieldName: 'configuration_window_interior_color',
				defaultValue: 'WHITE',
				values: [
					{
						value: 'WHITE',
						defaultExteriorColor: 'WHITE',
						exteriorColorOptions: [
							'WHITE',
							'ALMOND',
							'BLACK',
							'CLAY',
							'TERRATONE',
						],
					},
					{
						value: 'ALMOND',
						defaultExteriorColor: 'ALMOND',
						exteriorColorOptions: ['ALMOND'],
					},
					{
						value: 'MEDIUM_WOOD',
						defaultExteriorColor: 'WHITE',
						exteriorColorOptions: ['WHITE', 'ALMOND', 'CLAY', 'TERRATONE'],
					},
					{
						value: 'DARK_WOOD',
						defaultExteriorColor: 'WHITE',
						exteriorColorOptions: ['WHITE', 'ALMOND', 'CLAY', 'TERRATONE'],
					},
				],
			},
			exteriorColor: {
				heading: 'Exterior Color',
				fieldName: 'configuration_window_exterior_color',
				values: ['WHITE', 'ALMOND', 'BLACK', 'CLAY', 'TERRATONE'],
			},
			trimColor: {
				heading: 'Select a Trim Color',
				fieldName: 'configuration_window_exterior_trim_color',
				values: [
					'WHITE',
					'DESERT_TAN',
					'PEARL_GRAY_102',
					'ANTIQUE_IVORY_105',
					'TUXEDO_GRAY_112',
					'BROWN_190',
					'BLACK_200',
					'ROYAL_BROWN_202',
					'GRECIAN_GREEN_204',
					'SCOTCH_RED_209',
					'WOODLAND_GREEN_214',
					'AUTUMN_RED_215',
					'IMPERIAL_BROWN_223',
					'BUCKSKIN_224',
					'FLAGSTONE_232',
					'HICKORY_CLAY_238',
					'MUSKET_BROWN_250',
					'SILVER_ASH_318',
					'SANDSTONE_BEIGE_325',
					'LIGHT_MAPLE_355',
					'TERRATONE_360',
					'OXFORD_BLUE_396',
					'WICKER_713',
					'NORWEGIAN_WOOD_791',
					'HEATHER_792',
					'SANDCASTLE_793',
					'ALMOND_801',
					'PEWTER_805',
					'CREAM_817',
					'BRONZE_818',
					'HERRINGBONE_819',
					'CRANBERRY_820',
					'IVY_821',
					'PACIFIC_BLUE_822',
					'CHAMPAGNE_827',
					'BUCKSKIN_841',
					'IVORY_901',
					'PVC_WHITE_800',
					'PVC_PACIFIC_BLUE_27',
					'PVC_IVY_28',
					'PVC_ALMOND_801',
					'PVC_PEWTER_805',
					'PVC_CLAY_907',
					'PVC_WICKER_PVC_SHALE_810',
					'PVC_CREAM_817',
					'PVC_HERRINGBONE_819',
					'PVC_ICE_832',
					'PVC_SAND_833',
					'PVC_WHEAT_836',
					'PVC_IVORY_901',
					'PVC_MUSKET_BROWN_909',
					'PVC_DESERT_TAN',
				],
			},
			exteriorCedarTrim: {
				heading: 'Exterior Cedar Trim',
				fieldName: 'configuration_window_exterior_trim_cedar',
				defaultValue: false,
			},
			exteriorCedarSize: {
				heading: 'Select a Size',
				fieldName: 'configuration_window_exterior_trim_cedar_size',
				defaultValue: '1X6',
				values: ['1X6', '1X8', '1X10', '1X12'],
			},
			exteriorCedarDimensions: {
				heading: 'Exterior Cedar Trim Dimensions',
				fieldName: 'configuration_window_exterior_trim_cedar_dimensions',
				defaultValue: 0,
				minimumValue: 0,
			},
		},

		// window glass
		WINDOW_GLASS: {
			configurationId: 'WINDOW_GLASS',
			configurationType: 'window_glass',
			heading: 'Glass',
			type: {
				heading: 'Type',
				fieldName: 'configuration_window_glass_type',
				defaultValue: 'RLE',
				values: ['RLE', 'PLE', 'TPLE'],
			},
			temper: {
				heading: 'Temper',
				fieldName: 'configuration_window_glass_temper',
				defaultValue: 'NONE',
				values: ['NONE', 'TEMPER_SPECIALTY_ALL'],
			},
			obscure: {
				heading: 'Obscure',
				fieldName: 'configuration_window_glass_obscure',
				defaultValue: 'NONE',
				values: ['NONE', 'OBSCURE_SPECIALTY_ALL'],
			},
			tint: {
				heading: 'Tint',
				fieldName: 'configuration_window_glass_tint',
				defaultValue: 'NONE',
				values: ['NONE', 'TINT_GRAY', 'TINT_BRONZE'],
			},
		},

		// window sill
		WINDOW_SILL: {
			configurationId: 'WINDOW_SILL',
			configurationType: 'window_sill',
			heading: 'Sill',
			exteriorSill: {
				heading: 'Exterior Sill',
				fieldName: 'configuration_window_sill_exterior',
				defaultValue: 'NONE',
				values: [
					{
						value: 'NONE',
						dimensions: false,
					},
					{
						value: 'STONE',
						dimensions: true,
					},
				],
			},
			exteriorDimensions: {
				heading: 'Exterior Sill Dimensions',
				fieldName: 'configuration_window_sill_exterior_dimensions',
				defaultValue: 0,
				minimumValue: 0,
			},
		},

		// window seat
		WINDOW_SEAT: {
			configurationId: 'WINDOW_SEAT',
			configurationType: 'window_seat',
			heading: 'Seat',
			type: {
				heading: 'Type',
				fieldName: 'configuration_window_seat_type',
				defaultValue: 'NONE',
				values: [
					{
						value: 'NONE',
						seatColorDefault: false,
						seatColorOptions: false,
					},
					{
						value: 'AVONITE',
						seatColorDefault: 'CAMEO_WHITE',
						seatColorOptions: ['CAMEO_WHITE', 'BEACH', 'SANDSTONE'],
					},
				],
			},
			color: {
				heading: 'Select a Seat Color',
				fieldName: 'configuration_window_seat_color',
				values: ['CAMEO_WHITE', 'BEACH', 'SANDSTONE'],
			},
		},

		// window roof
		WINDOW_ROOF: {
			configurationId: 'WINDOW_ROOF',
			configurationType: 'window_roof',
			heading: 'Roof',
			type: {
				heading: 'Type',
				fieldName: 'configuration_window_roof_type',
				defaultValue: 'ATTACHED_TO_OVERHANG',
				values: [
					{
						value: 'HIP',
						materialDefault: 'SHINGLE',
						materialOptions: ['SHINGLE', 'ALUMINUM'],
					},
					{
						value: 'SHED',
						materialDefault: 'SHINGLE',
						materialOptions: ['SHINGLE', 'ALUMINUM'],
					},
					{
						value: 'ATTACHED_TO_OVERHANG',
						materialDefault: false,
						materialOptions: false,
					},
				],
			},
			material: {
				heading: 'Material',
				fieldName: 'configuration_window_roof_material',
				values: ['SHINGLE', 'ALUMINUM'],
			},
			materialColor: {
				heading: 'Material Color',
				fieldName: 'configuration_window_roof_material_color',
			},
		},

		// window instructions
		WINDOW_INSTRUCTIONS: {
			configurationId: 'WINDOW_INSTRUCTIONS',
			configurationType: 'window_instructions',
			heading: 'Instructions',
			groups: [
				{
					groupId: 'INSTALLATION',
					heading: 'Installation',
					options: [
						{
							optionId: 'BAY_SKIRT',
							heading: 'Bay Skirt',
							fieldName:
								'configuration_window_instructions_installation_bay_skirt',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							optionId: 'TRIM_OUT_SKIRT',
							heading: 'Trim Out Skirt',
							fieldName:
								'configuration_window_instructions_installation_trim_out_skirt',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							optionId: 'REDUCE_WINDOW_OPENING',
							heading: 'Reduce Window Opening',
							fieldName:
								'configuration_window_instructions_installation_reduce_window_opening',
							configurationType: 'boolean',
							defaultValue: false,
							expand: {
								expandType: 'sqft',
								heading: 'Square Foot of Alteration',
								notes: 'Note: Please include alteration notes, and a photo of the opening',
								fieldName:
									'configuration_window_instructions_installation_reduce_window_square_foot',
								defaultValue: 0,
							},
						},
						{
							optionId: 'ENLARGE_WINDOW_OPENING',
							heading: 'Enlarge Window Opening',
							fieldName:
								'configuration_window_instructions_installation_enlarge_window_opening',
							configurationType: 'boolean',
							defaultValue: false,
							expand: {
								expandType: 'sqft',
								heading: 'Square Foot of Alteration',
								notes: 'Note: Please include alteration notes, and a photo of the opening',
								fieldName:
									'configuration_window_instructions_installation_expand_window_square_foot',
								defaultValue: 0,
							},
						},
						{
							optionId: 'NEW_HEADER',
							heading: 'New Header',
							fieldName:
								'configuration_window_instructions_installation_new_header',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							optionId: 'NEW_LINEL',
							heading: 'New Steel Lintel',
							fieldName:
								'configuration_window_instructions_installation_new_lintel',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							optionId: 'CUTBACK_SIZE',
							heading: 'Cutback to Original Size',
							fieldName:
								'configuration_window_instructions_installation_cutback_original',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							optionId: 'CUTBACK_DRYWALL',
							heading: 'Cutback Drywall',
							fieldName:
								'configuration_window_instructions_installation_cutback_drywall',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							optionId: 'CUTBACK_SIDING',
							heading: 'Cutback for Siding',
							fieldName:
								'configuration_window_instructions_installation_cutback_siding',
							configurationType: 'boolean',
							defaultValue: false,
						},
					],
				},
				{
					groupId: 'REMOVAL',
					heading: 'Removal',
					options: [
						{
							heading: 'Mullion Removal',
							fieldName:
								'configuration_window_instructions_removal_mullion',
							configurationType: 'number',
							defaultValue: 0,
						},
						{
							heading: 'Shutters',
							fieldName: 'configuration_window_instructions_shutters',
							configurationType: 'radio',
							defaultValue: 'NONE',
							values: ['NONE', 'REMOVE', 'REMOVE_AND_INSTALL'],
						},
						{
							heading: 'Blinds',
							fieldName: 'configuration_window_instructions_blinds',
							configurationType: 'radio',
							defaultValue: 'NONE',
							values: ['NONE', 'REMOVE', 'REMOVE_AND_INSTALL'],
						},
						{
							heading: 'Security Bars',
							fieldName: 'configuration_window_instructions_securitybars',
							configurationType: 'radio',
							defaultValue: 'NONE',
							values: ['NONE', 'REMOVE', 'REMOVE_AND_INSTALL'],
						},
						{
							heading: 'Window Awnings',
							fieldName: 'configuration_window_instructions_awnings',
							configurationType: 'radio',
							defaultValue: 'NONE',
							values: ['NONE', 'REMOVE', 'REMOVE_AND_INSTALL'],
						},
						{
							heading: 'Security Roll Up Shutter Removal',
							fieldName:
								'configuration_window_instructions_security_shutter_removal',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							heading: 'Bay/Bow Removal',
							fieldName:
								'configuration_window_instructions_removal_baybow_removal',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							heading: 'Garden Window Removal',
							fieldName:
								'configuration_window_instructions_removal_garden_removal',
							configurationType: 'boolean',
							defaultValue: false,
						},
					],
				},
			],
		},
	},

	// let's group our configurations into thee order we want them to display
	configurationGroups: {
		group1: [
			'WINDOW_LOCATION',
			'WINDOW_SIZE',
			'HOUSE_TYPE',
			'REMOVAL_TYPE',
			'OUTSIDE_INSTALL',
		],
		group2: [
			'WINDOW_STYLE',
			'WINDOW_COLOR',
			'WINDOW_SILL',
			'WINDOW_SEAT',
			'WINDOW_ROOF',
			'WINDOW_INSTRUCTIONS',
			'WINDOW_MULLIONS',
		],
	},
};
