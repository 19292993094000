import { useContext, useEffect, useState } from 'react';
import { useUIManagementContext } from '../../../contexts/UIManagementContext';
import { useCustomWindowContext } from '../../../containers/CustomWindow/customWindowContext';
import Context from '../../../contexts/store/context';
import FinalProductImage from './FinalProductImage';
import ShowcaseLayout from './ShowcaseLayout';
import { isMultiUnit } from '../../../helpers/Comparators';
import {
	calculateHeight,
	calculateWidth,
	calcX,
	calcY,
	computeWidth,
	computeRowHeight,
	calcSnapshotX,
} from '../../../helpers/Calculations';
import { noOp } from '../../../helpers/Operations';

/**
 * This is our "snapshot" of how the grid should look when the user is in step 3.
 * If the user selected a Bay/Bow shell option,
 */
const GridReadOnly = ({ orderIndex, productValues }) => {
	const { customWindowState } = useCustomWindowContext();
	const { globalState } = useContext(Context);
	const { selectedCustomProduct } = globalState;
	const { dimensionsOfOpening } = customWindowState;
	const { openCustomWindowConfigurationData } = useUIManagementContext();

	const [rowsBaseSize, setRowsBaseSize] = useState(customWindowState.rowsBaseSize ?? 1);

	const customArray = ['CUSTOM_ICON_SM', 'CUSTOM_PRODUCT', 'CUSTOM_WINDOW'];

	const [height, setHeight] = useState(
		dimensionsOfOpening.height > 0
			? dimensionsOfOpening.height
			: productValues.configuration_window_size_height
	);
	const [width, setWidth] = useState(
		dimensionsOfOpening.width > 0
			? dimensionsOfOpening.width
			: productValues.configuration_window_size_width
	);

	// Parse the selectedproduct
	const code = selectedCustomProduct ?? 'CUSTOM_ICON_SM';
	const rowHeight = computeRowHeight(height, width) / 2;
	const canShowShowcase = computeWidth(height, width) > 0;
	const isMulti = isMultiUnit(customArray, code);

	// Check to see if we are editing a product or about to save one.
	const isEditingMultiUnit = () => {
		if (productValues?.additionalItems && productValues?.rowsBaseSize) {
			return true;
		} else if (window.location.pathname?.includes('productid')) {
			return true;
		}

		return false;
	};

	const [cells, setCells] = useState(
		isEditingMultiUnit()
			? productValues.additional_items ?? []
			: customWindowState.cells ?? []
	);

	useEffect(() => {
		if (
			productValues.additional_items &&
			customArray.includes(selectedCustomProduct)
		) {
			setCells(productValues.additional_items);
		}
		if (productValues.rowsBaseSize && customArray.includes(selectedCustomProduct)) {
			setRowsBaseSize(productValues.rowsBaseSize);
		}

		if (productValues.configuration_window_size_height) {
			setHeight(
				dimensionsOfOpening.height > 0
					? dimensionsOfOpening.height
					: productValues.configuration_window_size_height
			);
		}

		if (productValues.configuration_window_size_width) {
			setWidth(
				dimensionsOfOpening.width > 0
					? dimensionsOfOpening.width
					: productValues.configuration_window_size_width
			);
		}
	}, [productValues]);

	const structureLayout = () => {
		try {
			return cells.map((cell, index) => {
				if (productValues.additional_items) {
					const droppedData = cell.values
						? cell.values.droppedData
						: cells.product.droppedData;
					cell = cell.values ? cell.values.col : cell;
					cell.product.droppedData = droppedData;
				}
				cell.i = index.toString();
				cell.h = parseInt(calculateHeight(cell, { height }, rowsBaseSize));
				cell.maxH = rowsBaseSize;
				cell.w = parseInt(calculateWidth(cell));
				cell.x = isEditingMultiUnit()
					? calcSnapshotX(cell, index, cells[index - 1])
					: calcX(cell, index, cells);
				cell.y = calcY(cell, { height }, rowsBaseSize);
				cell.key = index.toString();
				return cell;
			});
		} catch (error) {
			console.error('Structured Layout Error:', error);
			return [];
		}
	};

	return (
		<div className="w-full h-full">
			<div className="grid-container">
				<h4 className="my-2 text-base text-gray-400">Multi-Unit Layout</h4>
				{/* Show the proper icon for the window. */}
				{isMulti ? (
					<div
						style={{
							width: computeWidth(height, width),
						}}
					>
						{canShowShowcase && (
							<ShowcaseLayout
								cols={{
									xxs: width > 0 ? width : 300,
									lg: width > 0 ? width : 300,
								}}
								layout={structureLayout()}
								onLayoutChange={noOp}
								openCustomWindowConfigurationData={
									openCustomWindowConfigurationData
								}
								orderIndex={orderIndex}
								overrideClick={true}
								rowHeight={rowHeight}
								rowsBaseSize={rowsBaseSize}
								isDraggable={false}
								inMultiUnitPath={false}
							/>
						)}
					</div>
				) : (
					<div className="w-full h-full -mx-6 -mb-4">
						<FinalProductImage cells={cells} code={code} />
					</div>
				)}
			</div>
		</div>
	);
};

export default GridReadOnly;
