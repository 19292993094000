import React from 'react';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';

export const WindowOptionsHardware = ({
	booleanText,
	groupName,
	product,
	quoteProduct,
}) => (
	<>
		{product.configurations[groupName].style && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Style:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].style.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].hardware && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">
						Window Opening Control Device:
					</dt>
					<dd className="heading heading--13">
						{booleanText(
							quoteProduct.values[
								product.configurations[groupName].hardware.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
	</>
);
