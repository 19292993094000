import ProductMapper from '../../../../functionality/ProductMapper';
import {
	color_config,
	glass_config,
	grid_config,
	screen_config,
	sill_config,
} from './constants';

export const mapGlobalSettings = (data, products) => {
	const { colorData, glassData, gridData, screenData, sillData } = data;
	const quoteProducts = [].concat(products);

	quoteProducts.forEach((quoteProduct) => {
		if (
			colorData.selectedInteriorColorValue !== false &&
			colorData.selectedExteriorColorValue !== false
		) {
			const confId = color_config.configurationId;
			const product = ProductMapper(quoteProduct.code);
			if (
				product.configurations[confId] &&
				product.configurations[confId].interiorColor
			) {
				const IOValues = product.configurations[confId].interiorColor.values;
				const interiorOption = IOValues.find(
					(option) => option.value === colorData.selectedInteriorColorValue
				);

				if (interiorOption) {
					// now we check that the exterior option is valid
					if (
						interiorOption.exteriorColorOptions.indexOf(
							colorData.selectedExteriorColorValue
						) !== -1
					) {
						// passed both checks, so update the colors
						quoteProduct.values[color_config.interiorColor.fieldName] =
							colorData.selectedInteriorColorValue;
						quoteProduct.values[color_config.exteriorColor.fieldName] =
							colorData.selectedExteriorColorValue;
					}
				}
			}
		}

		if (glassData.selectedGlassTypeValue !== false) {
			const confId = glass_config.configurationId;
			const product = ProductMapper(quoteProduct.code);

			if (product.configurations[confId]?.type) {
				if (
					product.configurations[confId].type.values.indexOf(
						glassData.selectedGlassTypeValue
					) !== -1
				) {
					// update the glass type
					quoteProduct.values[glass_config.type.fieldName] =
						glassData.selectedGlassTypeValue;
				}
			}
		}

		if (gridData.removeMuntin) {
			const confId = grid_config.configurationId;
			const product = ProductMapper(quoteProduct.code);
			const styleFN = grid_config.style.fieldName;

			if (
				product.configurations[confId]?.style &&
				(quoteProduct.values[styleFN] === 'FLAT' ||
					quoteProduct.values[styleFN] === 'CONTOUR')
			) {
				// grid updates only applies to style=FLAT || style=CONTOUR
				quoteProduct.values[grid_config.style.fieldName] =
					grid_config.style.defaultValue;

				if (product.configurations[confId].pattern) {
					quoteProduct.values[grid_config.pattern.fieldName] =
						grid_config.pattern.defaultValue;
				}

				if (product.configurations[confId].details) {
					quoteProduct.values[grid_config.details.fieldName] =
						grid_config.details.defaultValue;
				}

				if (product.configurations[confId].interiorMuntinColor) {
					quoteProduct.values[grid_config.interiorMuntinColor.fieldName] =
						grid_config.interiorMuntinColor.defaultValue;
				}

				if (product.configurations[confId].exteriorMuntinColor) {
					quoteProduct.values[grid_config.exteriorMuntinColor.fieldName] =
						grid_config.exteriorMuntinColor.defaultValue;
				}
			}
		} else if (
			!gridData.removeMuntin &&
			gridData.selectedInteriorMuntinColorValue !== false &&
			gridData.selectedExteriorMuntinColorValue !== false
		) {
			const confId = grid_config.configurationId;
			const styleFN = grid_config.style.fieldName;
			const product = ProductMapper(quoteProduct.code);

			if (
				product.configurations[confId]?.style &&
				(quoteProduct.values[styleFN] === 'FLAT' ||
					quoteProduct.values[styleFN] === 'CONTOUR')
			) {
				const styleValues = product.configurations[confId].style.values;
				const styleOption = styleValues.find(
					(option) => option.value === quoteProduct.values[styleFN]
				);

				if (
					styleOption?.interiorMutinColorOptions.indexOf(
						gridData?.selectedInteriorMuntinColorValue
					) !== -1
				) {
					const IMCValues =
						product.configurations[confId].interiorMuntinColor.values;
					const interiorOption = IMCValues.find(
						(option) =>
							option.value === gridData.selectedInteriorMuntinColorValue
					);

					if (
						interiorOption?.exteriorMutinColorOptions.indexOf(
							gridData.selectedExteriorMuntinColorValue
						) !== -1
					) {
						quoteProduct.values[grid_config.interiorMuntinColor.fieldName] =
							gridData.selectedInteriorMuntinColorValue;
						quoteProduct.values[grid_config.exteriorMuntinColor.fieldName] =
							gridData.selectedExteriorMuntinColorValue;
					}
				}
			}
		}

		if (
			screenData.selectedScreenSizeValue !== false &&
			screenData.selectedScreenMashValue !== false
		) {
			const confId = screen_config.configurationId;
			const product = ProductMapper(quoteProduct.code);

			if (product.configurations[confId] && product.configurations[confId].size) {
				const sizeValues = product.configurations[confId].size.values;
				const meshValues = product.configurations[confId].mesh.values;

				const sizeOption = sizeValues.find(
					(option) => option === screenData.selectedScreenSizeValue
				);
				const meshOption = meshValues.find(
					(option) => option === screenData.selectedScreenMeshValue
				);

				// passed both checks, so update the values
				if (sizeOption) {
					quoteProduct.values[screen_config.size.fieldName] =
						screenData.selectedScreenSizeValue;
				}

				// passed both checks, so update the values
				if (meshOption) {
					quoteProduct.values[screen_config.mesh.fieldName] =
						screenData.selectedScreenMeshValue;
				}
			}
		}

		if (
			sillData.selectedSillInteriorValue !== false &&
			sillData.selectedSillExteriorValue !== false
		) {
			const confId = sill_config.configurationId;
			const product = ProductMapper(quoteProduct.code);

			if (product?.configurations[confId]?.interiorSill) {
				// first we make sure these size & mesh values are valid
				const interiorSillValues =
					product.configurations[confId].interiorSill.values;
				const exteriorSillValues =
					product.configurations[confId].exteriorSill.values;

				const interiorOption = interiorSillValues.find(
					(option) => option.value === sillData.selectedSillInteriorValue
				);

				const exteriorOption = exteriorSillValues.find(
					(option) => option.value === sillData.selectedSillExteriorValue
				);

				if (interiorOption && exteriorOption) {
					const ISFieldName = sill_config.interiorSill.fieldName;
					const EXFieldName = sill_config.exteriorSill.fieldName;

					quoteProduct.values[ISFieldName] = sillData.selectedSillInteriorValue;
					quoteProduct.values[EXFieldName] = sillData.selectedSillExteriorValue;
				}

				if (sillData.selectedSillDepthValue) {
					quoteProduct.values.configuration_window_sill_dimensions =
						sillData.selectedSillDepthValue ?? 'NONE';
				}
			}
		}

		// Do the same thing for nested windows in the multi-unit.
		if (quoteProduct?.values?.additional_items) {
			const mappedItems = mapGlobalSettings(
				data,
				quoteProduct.values?.additional_items
			);
			quoteProduct.values.additional_items = mappedItems;
		}
	});

	return quoteProducts;
};
