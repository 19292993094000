import React, { useContext, useEffect, useRef } from 'react';
import Input from '../../Input/Input';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';

const ProductConfigurationWindowOutsideInstall = (props) => {
	// get the quote product context
	const {
		product,
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
	} = useContext(CurrentQuoteProductContext);

	// we'll use a reference to monitor for the first run
	// this allows us to not update parts on init
	// while the useRef, doesn't cause aa re-render
	const isFirstRender = useRef(true);

	// set the data we need to use
	const outsideInstallValue = getQuoteProductContextProductValue(
		props.configuration.fieldName
	);

	// we have some relationships with other fields
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
		} else {
			// when you toggle the outside install when wood is the removal type
			// if wood is the removal type, then this needs to toggle the correct parts
			if (product.configurations['WINDOW_INSTRUCTIONS']) {
				const removalTypeValue = product.configurations['REMOVAL_TYPE']
					? getQuoteProductContextProductValue(
							product.configurations['REMOVAL_TYPE'].fieldName
					  )
					: false;
				const findPartFieldName = (groupId, optionId) => {
					let fieldName =
						product.configurations['WINDOW_INSTRUCTIONS'].groups.find(
							(group) => {
								return group.groupId === groupId;
							}
						) || false;
					if (fieldName !== false) {
						fieldName =
							fieldName.options.find((option) => {
								return option.optionId === optionId;
							}) || false;
					}
					if (fieldName !== false) {
						fieldName = fieldName.fieldName;
					}
					return fieldName;
				};
				// update the intial jam selection
				const jamsFieldName = findPartFieldName('PARTS', 'EXTENSION_JAMBS');
				if (jamsFieldName !== false) {
					if (removalTypeValue === 'WOOD') {
						setQuoteProductContextProductValue(jamsFieldName, false);
					}
				}
				// update the bucks
				const bucksFieldName = findPartFieldName('PARTS', 'BUCKS');
				if (bucksFieldName !== false) {
					if (
						product.code === 'GARDEN_PROD' ||
						product.code === 'DOOR_WALL_PROD' ||
						product.code === 'HOPPER_PROD' ||
						product.code === 'DOOR_WALL_CUSTOM'
					) {
						// special case for hopper, garden, doorwall
						setQuoteProductContextProductValue(bucksFieldName, 'FULL_BUCK');
					} else {
						if (removalTypeValue === 'WOOD' && outsideInstallValue) {
							setQuoteProductContextProductValue(bucksFieldName, 'NONE');
						} else if (removalTypeValue === 'WOOD' && !outsideInstallValue) {
							setQuoteProductContextProductValue(
								bucksFieldName,
								'PARTIAL_BUCK'
							);
						}
					}
				}
				// update the casing
				const casingFieldName = findPartFieldName('PARTS', 'CASING');
				if (casingFieldName !== false) {
					if (
						product.code === 'GARDEN_PROD' ||
						product.code === 'DOOR_WALL_PROD' ||
						product.code === 'DOOR_WALL_CUSTOM'
					) {
						// we have a special case for garden and doorwall
						setQuoteProductContextProductValue(casingFieldName, 'PINE');
					} else {
						if (removalTypeValue === 'WOOD') {
							setQuoteProductContextProductValue(casingFieldName, 'NONE');
						}
					}
				}
				// update the stops
				const stopsFieldName = findPartFieldName('PARTS', 'STOPS');
				if (stopsFieldName !== false) {
					if (removalTypeValue === 'WOOD' && outsideInstallValue) {
						setQuoteProductContextProductValue(stopsFieldName, 'NONE');
					} else if (removalTypeValue === 'WOOD' && !outsideInstallValue) {
						setQuoteProductContextProductValue(stopsFieldName, 'PINE');
					}
				}
			}
		}
	}, [
		outsideInstallValue,
		product,
		getQuoteProductContextProductValue,
		setQuoteProductContextProductValue,
	]);

	// return the component
	return (
		<div className="productconfiguration">
			<p className="heading heading--5 productconfiguration__heading">
				<label htmlFor={props.configuration.fieldName}>
					{props.configuration.heading ? props.configuration.heading : ''}
				</label>
			</p>
			<Input
				type={'toggle'}
				id={props.configuration.fieldName}
				name={props.configuration.fieldName}
				checked={getQuoteProductContextProductValue(
					props.configuration.fieldName
				)}
				onChange={(e) => {
					setQuoteProductContextProductValue(e.target.name, e.target.checked);
				}}
			/>
		</div>
	);
};

export default ProductConfigurationWindowOutsideInstall;
