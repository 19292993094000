import React, { useContext } from 'react';

import CurrentQuoteProductContext from '../../../../contexts/CurrentQuoteProductContext';
import ExteriorColorsInput from './ExteriorColorsInput';

const ExteriorColorsButton = ({
	configuration,
	selectedExteriorColorValue,
	selectedInteriorColorOption,
}) => {
	const { setQuoteProductContextProductValue } = useContext(CurrentQuoteProductContext);

	if (!configuration.exteriorColor) {
		return '';
	}

	return (
		<>
			{configuration.interiorColor ? <div className="config-divider"></div> : ''}
			<div className="config-heading heading heading--4">
				{configuration.exteriorColor.heading}
			</div>
			<div className="config-notch-select">
				{configuration.exteriorColor.values.map((value) => {
					const isDisabled =
						selectedInteriorColorOption?.exteriorColorOptions.indexOf(
							value
						) === -1;
					return (
						<ExteriorColorsInput
							configuration={configuration}
							isDisabled={isDisabled}
							key={value}
							selectedExteriorColorValue={selectedExteriorColorValue}
							setQuoteProductContextProductValue={
								setQuoteProductContextProductValue
							}
							value={value}
						/>
					);
				})}
			</div>
		</>
	);
};

export default ExteriorColorsButton;
