// export our pricer
module.exports = function (string, length, ellipsis) {
	const l = length || 20;
	const s = string || '';
	const e = ellipsis || '';

	if (s.length <= l) {
		return {
			truncated: false,
			string: s,
		};
	}

	return {
		truncated: true,
		string: s.slice(0, l) + e,
	};
};
