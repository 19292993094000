import React from 'react';
import IconBtn from '../../IconBtn/IconBtn';

export const PaymentCashOption = ({
	activeQuote,
	paymentOption,
	setPaymentOption,
	toggleOverlay,
	centsToMoney,
}) => {
	return (
		<>
			<input
				type="radio"
				className="presentationbody__payment-input"
				name="payment_option"
				id="cash"
				value="paynow"
				checked={paymentOption === 'paynow'}
				onChange={() => {
					setPaymentOption('paynow');
				}}
			/>
			<label htmlFor="cash" className="presentationbody__payment-button">
				<div className="presentationbody__payment-head">
					<div className="presentationbody__payment-check">
						<span className="icon icon-far icon--check"></span>
					</div>
					<div className="presentationbody__payment-title">
						<div className="heading heading--5">
							Pay Cash, Credit or Check &amp; Save
						</div>
						<IconBtn
							iconWeight="icon-far"
							btnType="iconbtn--solid"
							iconType="icon--plus"
							onClick={() => {
								toggleOverlay({
									active: true,
									type: 'pay_by_cash_discount',
									data: {},
								});
							}}
						/>
					</div>
				</div>
				<div className="presentationbody__payment-body">
					<div className="flex justify-end w-full">
						<div className="flex flex-col items-start justify-start sm:w-1/3 lg:1/4 xl:w-1/6">
							<div className="heading presentationbody__payment-heading">
								{centsToMoney({
									amount: activeQuote.get('paymentOptions')['cash'],
								})}
							</div>
							<div className="w-full mr-4 text-left heading heading--6">
								Total Price
							</div>
						</div>
					</div>
				</div>
			</label>
		</>
	);
};
