import React, { useContext, useRef } from 'react';
import UIManagementContext from '../../../contexts/UIManagementContext';
import SessionDataContext from '../../../contexts/SessionDataContext';
import WallsideError from '../../../functionality/WallsideError';
import Btn from '../../../components/Btn/Btn';

const OverlayRenameQuote = (props) => {
	// setup our contexts
	const { toggleOverlay, toggleSystemNotification } = useContext(UIManagementContext);
	const { activeQuote, saveQuote } = useContext(SessionDataContext);

	// add a ref for use with the input field
	const textInput = useRef(null);

	// return the jsx component
	return (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s1">
						<div className="overlay-s1__heading">
							<h1 className="heading heading--4">Rename Quote</h1>
						</div>
						<div className="overlay-s1__body">
							<label
								htmlFor="quote_name"
								className="heading heading--form-element-2"
							>
								Quote Name
							</label>
							<span className="input input--1 input--white">
								<input
									type="text"
									id="quote_name"
									name="quote_name"
									placeholder="Enter name..."
									ref={textInput}
									defaultValue={activeQuote.get('title')}
								/>
							</span>
						</div>
						<div className="overlay-s1__btns">
							<Btn
								btnType={'btn--secondary'}
								onClick={(e) => {
									toggleOverlay({ active: false });
								}}
							>
								Cancel
							</Btn>
							<Btn
								btnType={'btn--primary'}
								onClick={(e) => {
									if (textInput.current.value.trim() !== '') {
										activeQuote.set(
											'title',
											textInput.current.value.trim()
										);
										saveQuote().then(
											() => {
												// show the notification
												toggleSystemNotification({
													active: true,
													messageKey: 'save_quote',
												});
												// close the overlay
												toggleOverlay({ active: false });
											},
											(e) => {
												const error = new WallsideError(e);
												alert(error.globalErrors[0].message);
											}
										);
									}
								}}
							>
								Update
							</Btn>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayRenameQuote;
