import React, { createContext, useReducer, useContext } from 'react';
import { calcUnitedInches } from '../../functionality/calcUnitedInches';
import { generateKey } from '../../helpers/Generators';
import { toFixed } from '../../helpers/Calculations';
import {
	formatInputs,
	formatPreviousCols,
	formatPreviousRows,
	formatResizingInputCols,
	formatResizingInputRows,
} from './customWindowContextHelpers';

export const customWindowConstants = {
	DIMENSIONS_OF_OPENING_INPUTS_CHANGE: 'DIMENSIONS_OF_OPENING_INPUTS_CHANGE',
	COMMIT_WINDOW_SIZE: 'COMMIT_WINDOW_SIZE',
	SET_PRODUCT: 'SET_PRODUCT',
	CUSTOM_WINDOW_RESET: 'CUSTOM_WINDOW_RESET',
	SET_COMMON_WIDTH: 'SET_COMMON_WIDTH',
	ADD_CUSTOM_WINDOW_PRODUCT: 'ADD_CUSTOM_WINDOW_PRODUCT',
	UPDATE_CUSTOM_WINDOW_GENERAL_SPECIFICATIONS:
		'UPDATE_CUSTOM_WINDOW_GENERAL_SPECIFICATIONS',
	OPEN_MODAL: 'OPEN_MODAL',
	CLOSE_MODAL: 'CLOSE_MODAL',
	SELECT_CELL: 'SELECT_CELL',
	RESET_SELECTED_CELLS: 'RESET_SELECTED_CELLS',
	COMMIT_LAYOUT: 'COMMIT_LAYOUT',
	MERGE_CELLS: 'MERGE_CELLS',
	UNMERGE_CELLS: 'UNMERGE_CELLS',
	UPDATE_ROWS: 'UPDATE_ROWS',
	UPDATE_COLUMNS: 'UPDATE_COLUMNS',
	UPDATE_CELLS: 'UPDATE_CELLS',
	DELETE_PRODUCT: 'DELETE_PRODUCT',
	UPDATE_GRID_DIMENSION: 'UPDATE_GRID_DIMENSION',
	EDIT_MENU_TOGGLE: 'EDIT_MENU_TOGGLE',
	RESIZE_GRID_DIMENSION: 'RESIZE_GRID_DIMENSION',
	UPDATE_DEFAULT_RESIZING_DIMENSION_INPUTS: 'UPDATE_DEFAULT_RESIZING_DIMENSION_INPUTS',
	UPDATE_RESIZING_DIMENSION_INPUTS: 'UPDATE_RESIZING_DIMENSION_INPUTS',
	RESET_RESIZING_DIMENSION_INPUTS: 'RESET_RESIZING_DIMENSION_INPUTS',
};

const {
	DIMENSIONS_OF_OPENING_INPUTS_CHANGE,
	COMMIT_WINDOW_SIZE,
	CUSTOM_WINDOW_RESET,
	SET_COMMON_WIDTH,
	ADD_CUSTOM_WINDOW_PRODUCT,
	UPDATE_CUSTOM_WINDOW_GENERAL_SPECIFICATIONS,
	OPEN_MODAL,
	CLOSE_MODAL,
	SELECT_CELL,
	RESET_SELECTED_CELLS,
	COMMIT_LAYOUT,
	MERGE_CELLS,
	UNMERGE_CELLS,
	UPDATE_ROWS,
	UPDATE_COLUMNS,
	UPDATE_CELLS,
	DELETE_PRODUCT,
	UPDATE_GRID_DIMENSION,
	EDIT_MENU_TOGGLE,
	RESIZE_GRID_DIMENSION,
	UPDATE_DEFAULT_RESIZING_DIMENSION_INPUTS,
	UPDATE_RESIZING_DIMENSION_INPUTS,
	RESET_RESIZING_DIMENSION_INPUTS,
} = customWindowConstants;

const initializerArg = {
	openModalState: false,
	openModalData: false,
	editMenuToggleValue: 0,
	selectedCells: [],
	dimensionsOfOpening: { height: 0, width: 0, unitedInches: 0 },
	dimensionsOfOpeningCommitted: false,
	customWindowLayoutCommitted: false,
	generalSpecificationsCommitted: false,
	generalSpecifications: {},
	defaultResizingInputsValues: { x: 0, y: 0 },
	dimensionsOfOpeningInputs: { width: 0, height: 0 },
	isGridColumnsEmpty: true,
	lastReducedAction: '',
	resizingInputsValues: {},
	canUnmerge: false,
	rowsBaseSize: 1,
	columnsBaseSize: 3,
	savedCellDistributionArray: [],
	savedLayoutData: { columnsBaseSize: 3, rowsBaseSize: 1 },
	savedResizedInputs: [],
	cells: [
		{
			key: generateKey(),
			location: [{ y: 0, x: 0 }],
			colspan: 0,
			rowspan: 0,
			product: {
				configuration_window_size_height: 100,
				configuration_window_size_width: 100,
			},
		},
		{
			key: generateKey(),
			location: [{ y: 0, x: 1 }],
			colspan: 0,
			rowspan: 0,
			product: {
				configuration_window_size_height: 100,
				configuration_window_size_width: 100,
			},
		},
		{
			key: generateKey(),
			location: [{ y: 0, x: 2 }],
			colspan: 0,
			rowspan: 0,
			product: {
				configuration_window_size_height: 100,
				configuration_window_size_width: 100,
			},
		},
	],
	commonWidthList: [
		{ label: 'Select ...', value: '' },
		{ label: '2  (1/2, 1/2)', value: 1, obj: { y: 2, x: [2, 2] } },
		{
			label: '3  (1/3, 1/3, 1/3)',
			value: 2,
			obj: { y: 3, x: [3, 3, 3] },
		},
		{
			label: '3  (1/4, 1/2, 1/4)',
			value: 3,
			obj: { y: 3, x: [4, 2, 4] },
		},
	],
};

// Initial state of our cells.
const cellState = {
	location: [{ y: 0, x: 0 }],
	colspan: 0,
	rowspan: 0,
	product: {
		configuration_window_size_height: 0,
		configuration_window_size_width: 0,
	},
};

/**
 *  This function is called when a user toggles the Columns or Rows of our
 *  custom grid layout.
 *
 *  Update the width/height of our cells to fit in the grid. With our 1D cells array,
 * 	we'll need to loop through each item and change the corresponding x,y of their
 *  location in order to fit them in the grid.
 *
 *  @param cells (array of objects).
 * 	@param dimensionsOfOpeningInputs (new height/width of cell).
 *  @param rowsSize (used for calculating the new height of our updated cells).
 *  @param columnsSize (used for calculating the new width of our updated cells).
 *  @param isAddRemoveColumns (used to check if this is a grid update or a cell merge).
 *  */
const updateGridDimensionResizeInputsHandler = (
	dimensionsOfOpening,
	newRowsSize,
	newColumnsSize,
	oldRowsSize,
	oldColumnsSize,
	resizingInputsValues,
	newCells,
	xArr,
	yArr
) => {
	// If there is not a change in the rows or columns, return the existing resizedInputsValues.
	if (oldRowsSize === newRowsSize && oldColumnsSize === newColumnsSize) {
		return resizingInputsValues;
	}

	let resizingInputs = resizingInputsValues;

	if (oldRowsSize !== newRowsSize) {
		for (let i = 0; i < newRowsSize; i++) {
			const cellHeight = newCells[i].product.configuration_window_size_height;
			resizingInputs.y[i] = cellHeight;
		}

		// Remove all the inputs not needed from the merge.
		for (let i = 0; i < yArr.length; i++) {
			delete resizingInputs.y[yArr[i]];
		}
	}

	if (oldColumnsSize !== newColumnsSize) {
		for (let i = 0; i < newColumnsSize; i++) {
			const cellWidth = newCells[i].product.configuration_window_size_width;
			resizingInputs.x[i] = cellWidth;
		}

		// Remove all the inputs not needed from the merge.
		for (let i = 0; i < xArr.length; i++) {
			delete resizingInputs.x[xArr[i]];
		}
	}

	// If the rowsize will be 1, just set the y property to an empty object to remove any
	// additional floaters that we don't care about, then set the first position to the
	// height of the grid.
	if (newRowsSize === 1) {
		resizingInputs.y = {};
		resizingInputs.y[0] = dimensionsOfOpening.height;
	}

	// If the colsize will be 1, just set the x property to an empty object to remove any
	// additional floaters that we don't care about, then set the first position to the
	// width of the grid.
	if (newColumnsSize === 1) {
		resizingInputs.x = {};
		resizingInputs.x[0] = dimensionsOfOpening.width;
	}

	return formatInputs(resizingInputs);
};

/**
 * This function should be called when adding/removing a row or column.
 * If a row is being added or subtracted, it should keep the dimensions
 * of the columns in tact while adjusting to a new row being added/removed.
 * If a column is being addeed or subtracted, it should keep the dimensions
 * of the rows in tact while adjusting to a new column being added/removed.
 */
const updateGridColDimensions = (
	cells,
	dimensionsOfOpeningInputs,
	newColumnsBaseSize,
	newRowsSize,
	appendedCells,
	oldCells,
	oldColsBaseSize,
	oldResizingInputsValues,
	overrideTotalWidth
) => {
	const { width } = dimensionsOfOpeningInputs;

	const isAdding = appendedCells.length > 0;

	// Our new width will be needed to calculate the % width of the previous cells
	// so they maintain their "dimensions" when we append/remove a new row. We'll need the width
	// of the newest cell so we can calculate the previous ratios.

	const newTotalWidth = overrideTotalWidth
		? overrideTotalWidth
		: width -
		  sizeInInches(cells[cells.length - 1].product.configuration_window_size_width);

	const newCells = formatPreviousCols(isAdding, oldCells, cells, width, newTotalWidth);

	// If adding a new row, add the cells here.
	if (isAdding) {
		for (const cell of appendedCells) {
			newCells.push(cell);
		}
	}

	const resizingInputsValues = formatResizingInputCols(
		isAdding,
		oldResizingInputsValues,
		oldColsBaseSize,
		newColumnsBaseSize,
		width,
		newTotalWidth,
		appendedCells,
		newRowsSize,
		newCells
	);

	return { cells: newCells, resizingInputsValues };
};

/**
 * This function should be called when adding/removing a row or column.
 * If a row is being added or subtracted, it should keep the dimensions
 * of the columns in tact while adjusting to a new row being added/removed.
 * If a column is being addeed or subtracted, it should keep the dimensions
 * of the rows in tact while adjusting to a new column being added/removed.
 */
const updateGridRowDimensions = (
	cells,
	dimensionsOfOpeningInputs,
	newRowsBaseSize,
	newColumnsSize,
	appendedCells,
	oldCells,
	oldRowsBaseSize,
	oldResizingInputsValues,
	overrideTotalHeight
) => {
	const { height } = dimensionsOfOpeningInputs;

	const isAdding = appendedCells.length > 0;

	// Our new height will be needed to calculate the % height of the previous cells
	// so they maintain their "dimensions" when we append/remove a new row. We'll need the height
	// of the newest cell so we can calculate the previous ratios.

	const newTotalHeight = overrideTotalHeight
		? overrideTotalHeight
		: height -
		  sizeInInches(cells[cells.length - 1].product.configuration_window_size_height);

	const newCells = formatPreviousRows(
		isAdding,
		oldCells,
		cells,
		height,
		newTotalHeight
	);

	// If adding a new row, add the cells here.
	if (isAdding) {
		for (const cell of appendedCells) {
			newCells.push(cell);
		}
	}

	const resizingInputsValues = formatResizingInputRows(
		isAdding,
		oldResizingInputsValues,
		oldRowsBaseSize,
		newRowsBaseSize,
		height,
		newTotalHeight,
		appendedCells,
		newColumnsSize,
		newCells
	);

	return { cells: newCells, resizingInputsValues };
};

// Update the grid dimension
const sizeInInches = (size) => toFixed(size, 1000);

const reducer = (state, action) => {
	switch (action.type) {
		// Modal
		case OPEN_MODAL:
			return {
				...state,
				openModalState: true,
				openModalData: action.payload,
			};

		case CLOSE_MODAL:
			return {
				...state,
				openModalState: false,
				openModalData: false,
			};

		// Used for handling our selected cells for merging.
		case SELECT_CELL: {
			const cell = action.payload;
			const tempCells = [...state.selectedCells];

			let checked = false;
			for (const item of tempCells) {
				if (cell.key === item.key) {
					const id = tempCells.indexOf(cell);
					checked = true;
					tempCells.splice(id, 1);
					break;
				}
			}

			if (!checked) {
				tempCells.push(action.payload);
			}

			return {
				...state,
				selectedCells: tempCells,
			};
		}

		case RESET_SELECTED_CELLS: {
			return {
				...state,
				selectedCells: [],
			};
		}

		case DIMENSIONS_OF_OPENING_INPUTS_CHANGE: {
			const { value, id } = action.payload.target;
			const dimensionsOfOpeningInputs = {
				...state.dimensionsOfOpeningInputs,
				[id]: parseFloat(value),
			};
			return {
				...state,
				dimensionsOfOpeningInputs,
			};
		}

		// First function to render grid columns/rows dimensions.
		case COMMIT_WINDOW_SIZE: {
			const { cells, columnsBaseSize, dimensionsOfOpeningInputs, rowsBaseSize } =
				state;
			const { height, width } = dimensionsOfOpeningInputs;
			const unitedInches = calcUnitedInches(height, width);

			// set columns
			const configuration_window_size_height = sizeInInches(height);
			const configuration_window_size_width = sizeInInches(width / 3);
			const resizingInputsValues = { x: {}, y: {} };

			const newCells = [];

			cells.forEach((cell) => {
				const product = {
					configuration_window_size_height,
					configuration_window_size_width,
				};
				const newCell = {
					...cell,
					product,
				};
				newCells.push(newCell);
			});

			for (let i = 0; i < rowsBaseSize; i++) {
				for (let j = 0; j < columnsBaseSize; j++) {
					resizingInputsValues.x[j] = configuration_window_size_width;
				}
				resizingInputsValues.y[i] = configuration_window_size_height;
			}

			return {
				...state,
				cells: newCells,
				defaultResizingInputsValues: { x: { 0: 20, 1: 20 }, y: { 0: 40 } },
				dimensionsOfOpening: { height, width, unitedInches },
				dimensionsOfOpeningCommitted: true,
				resizingInputsValues,
			};
		}

		case CUSTOM_WINDOW_RESET: {
			return {
				...state,
				...initializerArg,
			};
		}

		// Resizing grid dimensions when common columns dropdown changes.
		case SET_COMMON_WIDTH: {
			const { payload } = action;
			const commonWidth = payload.x;
			const cells = [];
			const resizingInputsValues = { x: {}, y: {} };

			if (commonWidth.length > 0) {
				const { height, width } = state.dimensionsOfOpening;
				commonWidth.forEach((col, key) => {
					const location = [{ y: 0, x: key }];
					const configuration_window_size_height = sizeInInches(height);
					const configuration_window_size_width = sizeInInches(width / col);
					const product = {
						configuration_window_size_height,
						configuration_window_size_width,
					};
					const newCell = {
						...cellState,
						key: generateKey(),
						location,
						product,
					};
					cells.push(newCell);
					resizingInputsValues.x[key] = configuration_window_size_width;
					resizingInputsValues.y[0] = configuration_window_size_height;
				});
			}

			return {
				...state,
				cells,
				columnsBaseSize: payload.y,
				defaultResizingInputsValues: JSON.parse(
					JSON.stringify(resizingInputsValues)
				),
				isGridColumnsEmpty: true,
				lastReducedAction: 'SET_COMMON_WIDTH',
				resizingInputsValues,
				rowsBaseSize: 1,
			};
		}

		/* Attach a custom window to a specific grid cell. */
		case ADD_CUSTOM_WINDOW_PRODUCT: {
			const { col, droppedData } = action.payload;
			const { code } = droppedData;
			const { key } = col;

			const newCustomWindowColState = state.cells;

			newCustomWindowColState.find((el, i) => {
				if (el.key === key) {
					newCustomWindowColState[i].product = {
						...action.payload,
						code,
					};
				}
			});

			return {
				...state,
				cells: newCustomWindowColState,
				isGridColumnsEmpty: false,
			};
		}

		case UPDATE_CUSTOM_WINDOW_GENERAL_SPECIFICATIONS:
			return {
				...state,
				customWindow: {
					...state.customWindow,
					generalSpecifications: action.payload,
				},
			};

		case COMMIT_LAYOUT:
			return {
				...state,
				customWindowLayoutCommitted: true,
			};

		// UPDATE_GRID_DIMENSION
		case UPDATE_GRID_DIMENSION: {
			return state;
		}

		// Add/Remove grid columns.
		case UPDATE_COLUMNS: {
			const isAdding = Math.sign(action.payload) > 0;
			const {
				cells,
				columnsBaseSize,
				dimensionsOfOpening,
				resizingInputsValues,
				rowsBaseSize,
			} = state;
			const { width } = dimensionsOfOpening;
			const newColumnsBaseSize = isAdding
				? columnsBaseSize + 1
				: columnsBaseSize - 1;
			let newCells = [...cells];

			const appendedCells = [];
			let overrideTotalWidth;

			// Check that columns are not empty.
			// To Leave one column at the grid
			if (newColumnsBaseSize < 1) {
				return state;
			}

			/**
			 * Check if we are increasing our decreasing our columns.
			 * Positive to add columns.
			 * Negative to remove columns.
			 */
			if (isAdding) {
				for (let index = 0; index < rowsBaseSize; index++) {
					const location = [{ x: newColumnsBaseSize - 1, y: index }];

					// We need to determine what the previous row says in order to make an
					// accurate new row that's consistent.

					// We just need to take the conf_height and conf_width from the column before
					// this one and copy it over to this new column.

					const filteredCell = newCells.filter(({ location }) => {
						return (
							location[0].x === newColumnsBaseSize - 2 &&
							location[0].y === index
						);
					});

					const parentCell =
						filteredCell.length > 0 ? filteredCell[0] : cells[0];

					const product = {
						configuration_window_size_height:
							parentCell.product.configuration_window_size_height,
						configuration_window_size_width: toFixed(
							width / newColumnsBaseSize,
							1000
						),
					};

					const newCell = {
						...cellState,
						key: generateKey(),
						location,
						product,
					};

					// Add the new cell to the existing row.
					newCells.push(newCell);
					appendedCells.push(newCell);
				}
			} else {
				overrideTotalWidth =
					width -
					newCells[newCells.length - 1].product.configuration_window_size_width;

				// Find all cells in the column that we are about to remove, remove them from the array.
				// If newcolsbasesize is 3, then we want to remove all cells that have a y of 3 or greater.
				// This works as expected.
				newCells = newCells.filter(
					(el) =>
						el.location[0].x < newColumnsBaseSize &&
						el.location[0].y < rowsBaseSize
				);
			}

			const resizedCells = updateGridColDimensions(
				newCells,
				dimensionsOfOpening,
				newColumnsBaseSize,
				rowsBaseSize,
				appendedCells,
				cells,
				columnsBaseSize,
				resizingInputsValues,
				overrideTotalWidth
			);

			return {
				...state,
				cells: resizedCells.cells,
				columnsBaseSize: newColumnsBaseSize,
				defaultResizingInputsValues: resizedCells.resizingInputsValues,
				isGridColumnsEmpty: true,
				lastReducedAction: 'UPDATE_COLUMNS',
				resizingInputsValues: resizedCells.resizingInputsValues,
			};
		}
		// Add/Remove grid rows.
		case UPDATE_ROWS: {
			const isAdding = Math.sign(action.payload) > 0;
			const {
				cells,
				columnsBaseSize,
				dimensionsOfOpening,
				resizingInputsValues,
				rowsBaseSize,
			} = state;
			const { height } = dimensionsOfOpening;
			const newRowsBaseSize = isAdding ? rowsBaseSize + 1 : rowsBaseSize - 1;
			let newCells = [...cells];

			const appendedCells = [];
			let overrideTotalHeight;

			// Check that rows will not be empty.
			if (newRowsBaseSize < 1) {
				return state;
			}

			/*
			 * Check if number is Positive / Negative
			 * Positive to add rows.
			 * Negative to remove  rows.
			 */
			if (isAdding) {
				for (let index = 0; index < columnsBaseSize; index++) {
					const location = [{ x: index, y: newRowsBaseSize - 1 }];

					// We need to determine what the previous row says in order to make an
					// accurate new row that's consistent.

					// We just need to take the conf_height and conf_width from the row above
					// and copy it down to this new row.
					const filteredCell = newCells.filter(({ location }) => {
						return (
							location[0].x === index &&
							location[0].y === newRowsBaseSize - 2
						);
					});

					const parentCell =
						filteredCell.length > 0 ? filteredCell[0] : cells[0]; // Would this work?

					const product = {
						configuration_window_size_height: toFixed(
							height / newRowsBaseSize,
							1000
						),
						configuration_window_size_width:
							parentCell.product.configuration_window_size_width,
					};

					const newCell = {
						...cellState,
						key: generateKey(),
						location,
						product,
					};

					// Add the new cell to the existing row.
					newCells.push(newCell);
					appendedCells.push(newCell);
				}
			} else {
				overrideTotalHeight =
					height -
					newCells[newCells.length - 1].product
						.configuration_window_size_height;

				// Find all cells in the column that we are about to remove, remove them from the array.
				// If newcolsbasesize is 3, then we want to remove all cells that have a y of 3 or greater.
				// This works as expected.
				newCells = newCells.filter(
					(el) =>
						el.location[0].x < columnsBaseSize &&
						el.location[0].y < newRowsBaseSize
				);
			}

			const resizedCells = updateGridRowDimensions(
				newCells,
				dimensionsOfOpening,
				newRowsBaseSize,
				columnsBaseSize,
				appendedCells,
				cells,
				rowsBaseSize,
				resizingInputsValues,
				overrideTotalHeight
			);

			return {
				...state,
				cells: resizedCells.cells,
				defaultResizingInputsValues: resizedCells.resizingInputsValues,
				isGridColumnsEmpty: true,
				lastReducedAction: 'UPDATE_ROWS',
				rowsBaseSize: newRowsBaseSize,
				resizingInputsValues: resizedCells.resizingInputsValues,
			};
		}

		case UPDATE_CELLS: {
			const newCells = action.payload;

			return {
				...state,
				cells: newCells,
			};
		}

		/**
		 * MERGE_CELLS works like this:
		 *
		 * With a cell that has already had their row/colspan updated, pass that as
		 * our payload. Next, find the specific cell in our cells array that has been updated
		 * by the key of the merged cell.
		 *
		 * Next, find the other selected cells in a horizontal, vertical, or square manner and
		 * remove them from our cells array.
		 *
		 * Pass the new cell array to our updateGridDimensionResizeInputsHandler function and
		 * update the amount of columns and rows in our grid's UI.
		 */
		case MERGE_CELLS: {
			const mergedCell = action.payload;
			const {
				columnsBaseSize,
				dimensionsOfOpening,
				resizingInputsValues,
				rowsBaseSize,
				savedCellDistributionArray,
				savedResizedInputs,
			} = state;
			const selectedCells = state.selectedCells;
			let newCellsArr = state.cells;
			const tempResizedInputsArr = JSON.parse(JSON.stringify(savedResizedInputs));

			// This will be used for our unmerge function.
			const savedCellMergeArray = savedCellDistributionArray;
			savedCellMergeArray.push(JSON.parse(JSON.stringify(state.cells)));

			// Find the updated cell in our cells array;
			const index = newCellsArr.findIndex((el) => el.key === mergedCell.key);

			// Something went wrong, our element isn't in our array.
			if (index === -1) {
				return;
			}

			// Insert our merged cell into our array of cells.
			newCellsArr[index] = mergedCell;

			// Save all the keys of our selected cells.
			const keyArr = [];

			for (const cell of selectedCells) {
				if (cell.key !== mergedCell.key) {
					keyArr.push(cell.key);
				}
			}

			// Get the index of the cell we're popping off (array)
			let xArr = [];
			let yArr = [];

			// Filter our newCellsArray to not include the cells we've merged.
			const tempArr = newCellsArr.filter((el) => {
				// If the cell is the one we merged, we need to include that in our x and y arrays.
				if (keyArr.includes(el.key)) {
					xArr.push(el.location[0].x);
					yArr.push(el.location[0].y);
				}

				return !keyArr.includes(el.key);
			});

			// Next, we need to loop through our new array and get each x and y values
			// so we can properly determine how many textInputs should show up on the UI.
			const colArr = [];
			const rowArr = [];
			for (const cell of tempArr) {
				if (!colArr.includes(cell.location[0].y)) {
					colArr.push(cell.location[0].y);
				}

				if (!rowArr.includes(cell.location[0].x)) {
					rowArr.push(cell.location[0].x);
				}
			}

			// If we don't have a saved value in the array, we need to push the current
			// resizingInputs before the merge.
			if (tempResizedInputsArr.length === 0) {
				tempResizedInputsArr.push(resizingInputsValues);
			}

			const resizedInputs = updateGridDimensionResizeInputsHandler(
				dimensionsOfOpening,
				colArr.length,
				rowArr.length,
				rowsBaseSize,
				columnsBaseSize,
				resizingInputsValues,
				tempArr,
				xArr,
				yArr
			);

			tempResizedInputsArr.push(resizedInputs);

			return {
				...state,
				canUnmerge: true,
				cells: tempArr,
				columnsBaseSize: rowArr.length,
				isGridColumnsEmpty: true,
				lastReducedAction: 'MERGE_CELLS',
				resizingInputsValues: resizedInputs,
				rowsBaseSize: colArr.length,
				savedCellDistributionArray: savedCellMergeArray,
				savedLayoutData: { columnsBaseSize, rowsBaseSize },
				savedResizedInputs: tempResizedInputsArr,
				selectedCells: [],
			};
		}

		/**
		 * This function will just set our sells array to be the previously saved layout of the cells array
		 * before the previous merge.
		 */
		case UNMERGE_CELLS: {
			const { savedCellDistributionArray, savedLayoutData, savedResizedInputs } =
				state;

			const { columnsBaseSize, rowsBaseSize } = savedLayoutData;
			const tempArr =
				savedCellDistributionArray[savedCellDistributionArray.length - 1];

			const savedCellArr = savedCellDistributionArray.slice(0, -1);

			const savedResizedArr = JSON.parse(
				JSON.stringify(savedResizedInputs.slice(0, -1))
			);

			return {
				...state,
				canUnmerge: savedCellArr.length > 0,
				cells: tempArr,
				columnsBaseSize,
				resizingInputsValues: savedResizedInputs[savedResizedInputs.length - 2],
				rowsBaseSize,
				savedCellDistributionArray: savedCellArr,
				savedResizedInputs: savedResizedArr,
			};
		}

		// Remove a product from a specific grid cell.
		case DELETE_PRODUCT: {
			const { key } = action.payload;
			const newCells = [...state.cells];

			// Find the index of our key we've passed.
			let changedCellIndex = 0;
			newCells.map((el, index) => {
				if (el.key === key) {
					changedCellIndex = index;
				}
			});

			const {
				configuration_window_size_height = 0,
				configuration_window_size_width = 0,
			} = newCells[changedCellIndex]?.product ?? {};

			const product = {
				configuration_window_size_height,
				configuration_window_size_width,
			};

			newCells[changedCellIndex].product = product;
			return { ...state, cells: newCells, editMenuToggleValue: 0 };
		}

		case EDIT_MENU_TOGGLE: {
			const oldValue = state.editMenuToggleValue;
			const newValue = action.payload;
			const editMenuToggleValue = oldValue === newValue ? 0 : newValue;
			return { ...state, editMenuToggleValue };
		}

		// Update our new default resizing
		case UPDATE_DEFAULT_RESIZING_DIMENSION_INPUTS: {
			const newInputsArray = action.payload;
			const updatedResizingInputsValues = JSON.parse(
				JSON.stringify(state.resizingInputsValues)
			);

			return { ...state, defaultResizingInputsValues: updatedResizingInputsValues };
		}

		// Update grid dimensions height/width inputs on change.
		case UPDATE_RESIZING_DIMENSION_INPUTS: {
			const { location, index, value } = action.payload;
			const updatedResizingInputsValues = { ...state.resizingInputsValues };
			updatedResizingInputsValues[location][index] = Number(value);
			return { ...state, resizingInputsValues: updatedResizingInputsValues };
		}

		case RESET_RESIZING_DIMENSION_INPUTS: {
			return { ...state, resizingInputsValues: action.payload };
		}

		case RESIZE_GRID_DIMENSION: {
			const { resizingInputsValues } = state;
			const updatedCells = state.cells;

			for (let cell of updatedCells) {
				cell.product = {
					...cell.product,
					configuration_window_size_height:
						resizingInputsValues.y[cell.location[0].y],
					configuration_window_size_width:
						resizingInputsValues.x[cell.location[0].x],
				};
			}
			return {
				...state,
				cells: updatedCells,
				isGridColumnsEmpty: true,
				lastReducedAction: 'RESIZE_GRID_DIMENSION',
				resizingInputsValues,
			};
		}

		default:
			return { state };
	}
};

export const useCustomWindowReducer = () => {
	const [customWindowState, customWindowDispatch] = useReducer(reducer, initializerArg);
	return { customWindowState, customWindowDispatch };
};

export const CustomWindowContext = createContext();

export const useCustomWindowContext = () => {
	return useContext(CustomWindowContext);
};

export const useCustomWindow = () => {
	const { customWindowState } = useCustomWindowContext();
	return customWindowState;
};

export const CustomWindowContextProvider = ({ children }) => {
	const { customWindowState, customWindowDispatch } = useCustomWindowReducer();
	const value = { customWindowState, customWindowDispatch };
	return (
		<CustomWindowContext.Provider value={value} displayName="Custom Window Context">
			{children}
		</CustomWindowContext.Provider>
	);
};
