import React from 'react';
import './IconBtn.css';

const IconBtn = (props) => {
	// determin the children
	const children = props.children ? (
		<span className="iconbtn__text">{props.children}</span>
	) : (
		''
	);

	// return the component
	return (
		<>
			{props.layoutOnly ? (
				<span type="button" className={`iconbtn ${props.btnType}`}>
					<span
						className={`${props.iconWeight ? props.iconWeight : 'icon-fas'} ${
							props.iconType
						}`}
					>
						{children}
					</span>
				</span>
			) : (
				<button
					type="button"
					className={`iconbtn ${props.btnType}`}
					onClick={props.onClick}
				>
					<span
						className={`${props.iconWeight ? props.iconWeight : 'icon-fas'} ${
							props.iconType
						}`}
					>
						{children}
					</span>
				</button>
			)}
		</>
	);
};
export default IconBtn;
