import React from 'react';
import ForgotPasswordForm from '../../components/ForgotPasswordForm/ForgotPasswordForm';
import NavbarLogo from '../../components/NavbarLogo/NavbarLogo';
import Layout0 from '../../layouts/Layout0/Layout0';

const ForgotPasswordRoute = (props) => {
	return (
		<Layout0 navbar={NavbarLogo}>
			<ForgotPasswordForm />
		</Layout0>
	);
};
export default ForgotPasswordRoute;
