import React, { useContext, useEffect } from 'react';
import SessionDataContext from '../../contexts/SessionDataContext';
import WallsideError from '../../functionality/WallsideError';
import Layout2 from '../../layouts/Layout2/Layout2';
import QuoteNavBar from '../../components/NavBars/QuoteNavBar/QuoteNavBar';
import QuoteDetailActionBar from '../../components/ActionBars/QuoteDetailActionBar/QuoteDetailActionBar';
import QuoteDetailProductList from '../../components/QuoteDetailProductList/QuoteDetailProductList';

const ViewQuoteRoute = (props) => {
	// we'll setup the session data contenxt
	const { loadQuote } = useContext(SessionDataContext);

	// we'll fetch the latest content needed
	useEffect(() => {
		try {
			loadQuote(props.match.params.quoteId, true);
		} catch (e) {
			const error = new WallsideError(e);
			alert(error.globalErrors[0].message);
		}
	}, [loadQuote, props.match.params.quoteId]);

	// return the component ui
	return (
		<Layout2
			contentClass="layout2__content--nopadding layout2__content--flex-column"
			navbar={<QuoteNavBar presentation={false} />}
		>
			<QuoteDetailActionBar presentation={false} />
			<QuoteDetailProductList />
		</Layout2>
	);
};

export default ViewQuoteRoute;
