import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Parse from 'parse';
import SessionDataContext from '../../contexts/SessionDataContext';
import Btn from '../Btn/Btn.js';
import Input from '../Input/Input';
import FieldError from '../FieldError/FieldError';
import URLMapper from '../../functionality/URLMapper';
import {
	getFormData,
	validateFormDataPromise,
} from '../../functionality/FormUtilities.js';
import {
	getRememberedLoginInfo,
	setRememberedLoginInfo,
	removeRememberedLoginInfo,
} from '../../functionality/LocalStorage.js';
import './LoginForm.css';

// the LoginForm Component
const LoginForm = () => {
	// setting up the history object
	const history = useHistory();

	// set the session data context
	const { setUser } = useContext(SessionDataContext);

	// set the errors
	const [formErrors, setFormErrors] = useState({
		// no default errors, so its empty
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			// reset the form errors
			setFormErrors({});
			// we'll now try to get the data from the form
			const formData = getFormData(e.target);
			// now we'll validate these values
			await validateFormDataPromise(formData);
			// let's look at the remember me value
			// if it was set, then let's the login remember me to storage
			// if the flag is not set, then let' make sure we remove the local storage data
			if (formData.user_rememberme) {
				setRememberedLoginInfo({
					user_login: formData.user_login,
					user_rememberme: formData.user_rememberme,
				});
			} else {
				removeRememberedLoginInfo();
			}

			// now let's do the login
			await Parse.User.logIn(formData.user_login, formData.user_password);
			setUser(Parse.User.current());
		} catch (errors) {
			console.error('Auth Error:', errors);
			if (errors.code === 100) {
				setFormErrors({
					user_login: ['Something went wrong, please try again later.'],
				});
			} else {
				setFormErrors(errors);
			}
		}
	};

	return (
		<form className="login" onSubmit={handleSubmit}>
			<div className="login__header">
				<div className="heading heading--6">Welcome</div>
				<div className="heading heading--2">Sign Into Your Account</div>
			</div>
			<div className="login__fields">
				<Input
					type={'text'}
					id={'user_login'}
					name={'user_login'}
					className={'input--1'}
					inputModifier={formErrors.user_login ? 'input--error' : ''}
					placeholder={'Email Address'}
					defaultValue={getRememberedLoginInfo()['user_login']}
				/>
				{formErrors.user_login ? (
					<FieldError>{formErrors.user_login[0]}</FieldError>
				) : (
					''
				)}
				<Input
					type={'password'}
					id={'user_password'}
					name={'user_password'}
					className={'input--1'}
					inputModifier={formErrors.user_password ? 'input--error' : ''}
					placeholder={'Password'}
				></Input>
				{formErrors.user_password ? (
					<FieldError>{formErrors.user_password[0]}</FieldError>
				) : (
					''
				)}
			</div>
			<div className="login__secondary">
				<Input
					type={'checkbox'}
					id={'user_rememberme'}
					name={'user_rememberme'}
					defaultChecked={getRememberedLoginInfo()['user_rememberme']}
				>
					Remember Me
				</Input>
				<Btn
					btnType={'btn--text'}
					onClick={() => {
						history.push(URLMapper('forgot_password'));
					}}
				>
					Forgot Password?
				</Btn>
			</div>
			<Btn type="submit" btnType={'btn--primary btn--100'}>
				Log In
			</Btn>
		</form>
	);
};

export default LoginForm;
