export default {
	FLAT: {
		default: {
			description: '',
			url: '/configuration-images/grid-styles/flat.png',
		},
	},
	CONTOUR: {
		default: {
			description: '',
			url: '/configuration-images/grid-styles/contour.png',
		},
	},
	SLIM_LINE: {
		default: {
			description: '',
			url: '/configuration-images/grid-styles/slim-line.png',
		},
	},
	ETCHED: {
		default: {
			description: '',
			url: '/configuration-images/grid-styles/etched.png',
		},
	},
};
