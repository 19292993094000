/**
 * Check to see if a grid cell's location is the same as an editMenuToggleValue.
 */
export const isEqual = (arr1, arr2) => {
	if (!arr1 || !arr2) {
		return false;
	}
	if (!arr1[0] || !arr2[0]) {
		return false;
	}
	return arr1[0].y === arr2[0].y && arr1[0].x == arr2[0].x;
};

/* Determine if a DragDrop Product can be placed in a grid cell. */
export const canDropProduct = ({ product }, dragData) => {
	const cellHeight = product.configuration_window_size_height;
	const cellWidth = product.configuration_window_size_width;
	const {
		maximumValueHeight,
		maximumValueWidth,
		minimumValueHeight,
		minimumValueWidth,
	} = dragData;

	if (cellHeight >= minimumValueHeight && cellHeight <= maximumValueHeight) {
		if (cellWidth >= minimumValueWidth && cellWidth <= maximumValueWidth) {
			return { canDrop: true, message: 'OK' };
		}
		return {
			canDrop: false,
			message: `Width must be between ${minimumValueWidth} and ${maximumValueWidth}`,
		};
	}
	return {
		canDrop: false,
		message: `Height must be between ${minimumValueHeight} and ${maximumValueHeight}`,
	};
};

/**
 * Determine if the current page/component is the custom multi unit or a bay/bow.
 */
export const isMultiUnit = (array, productCode, codeComparator) => {
	if (!array) {
		return productCode.includes(codeComparator);
	}
	return array.includes(productCode);
};
