import AssetMapper from '../../functionality/AssetMapper.js';

const ImageAsset = (props) => {
	// get the image form asset mapper
	const image = AssetMapper(props.code);

	// if wwe have an alt tag override use it
	if (props.alt) {
		image.description = props.alt;
	}

	// return the component
	return (
		<img
			src={image.url}
			className={props.className ? props.className : ''}
			alt={image.description}
		/>
	);
};
export default ImageAsset;
