import React from 'react';

export const SummaryListOtherItem = ({ other }) => {
	return (
		<>
			<h3 className="presentationsidebar__list-heading heading heading--6">
				Other
			</h3>
			{other.map((productInfo, index) => (
				<div
					key={productInfo.title}
					className="presentationsidebar__product-list"
				>
					<p className="heading heading--5">{productInfo.title}</p>
					<p className="heading heading--5 heading--secondary">
						{productInfo.cost}
					</p>
				</div>
			))}
		</>
	);
};
