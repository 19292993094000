import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import URLMapper from '../../../functionality/URLMapper';
import SessionDataContext from '../../../contexts/SessionDataContext';
import UIManagementContext from '../../../contexts/UIManagementContext';
import WallsideError from '../../../functionality/WallsideError';
import NavbarLogo from '../../NavbarLogo/NavbarLogo';
import ActionBtn from '../../ActionBtn/ActionBtn';
import './QuoteNavBar.css';

const QuoteNavBar = (props) => {
	// setting up the history object
	const history = useHistory();

	// we'll setup the contexts
	const { activeQuote, saveQuote } = useContext(SessionDataContext);
	const { toggleSystemNotification } = useContext(UIManagementContext);

	const handleActionButtonClick = async () => {
		try {
			await saveQuote();

			// show the notification
			toggleSystemNotification({
				active: true,
				messageKey: 'save_quote',
			});
		} catch (e) {
			const error = new WallsideError(e);
			alert(error.globalErrors[0].message);
		}
	};

	// return the component
	return activeQuote !== false ? (
		<div className="quotenavbar">
			<NavbarLogo />
			<div className="quotenavbar__actions">
				<ActionBtn
					actionBtnClass="actionbtn--primary"
					actionBtnWeight="icon-fas"
					actionBtnType="icon--save"
					onClick={handleActionButtonClick}
				/>
				{props.presentation ? (
					<ActionBtn
						actionBtnClass="actionbtn--primary"
						actionBtnWeight="icon-fas"
						actionBtnType="icon--build"
						onClick={() => {
							// go to the quote detail page
							history.push(URLMapper('quote', { quoteId: activeQuote.id }));
						}}
					/>
				) : (
					<>
						{activeQuote.get('products').length > 0 && (
							<ActionBtn
								actionBtnClass="actionbtn--primary"
								actionBtnWeight="icon-fas"
								actionBtnType="icon--present"
								onClick={() => {
									// go to the quote presentation page
									history.push(
										URLMapper('presentation', {
											quoteId: activeQuote.id,
										})
									);
								}}
							/>
						)}
					</>
				)}
				<ActionBtn
					actionBtnClass="actionbtn--primary"
					actionBtnWeight="icon-fas"
					actionBtnType="icon--list"
					onClick={() => {
						// go to the pricing list page
						history.push(
							URLMapper('quotepricing', { quoteId: activeQuote.id })
						);
					}}
				/>
				<ActionBtn
					actionBtnClass="actionbtn--outline"
					actionBtnType="icon--close"
					onClick={() => {
						// go to the dashboard page
						history.push(URLMapper('dashboard'));
					}}
				/>
			</div>
		</div>
	) : (
		''
	);
};

export default QuoteNavBar;
