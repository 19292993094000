import React, { useEffect } from 'react';
import { useCurrentQuoteContext } from '../../../contexts/CurrentQuoteProductContext';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';
import BayBowSkirt from './BayBowSkirt';

const ProductConfigurationWindowLeaveExisting = ({ configuration, isBayBow }) => {
	// product context
	const {
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
		setPreviousQuoteProductContextValue,
	} = useCurrentQuoteContext();

	// Set to use the previous value
	const setPreviousContextValue = React.useCallback(() => {
		setPreviousQuoteProductContextValue(configuration.leave_existing.fieldName);
		setPreviousQuoteProductContextValue(configuration.vinyl.fieldName);
	}, [
		configuration.leave_existing.fieldName,
		configuration.vinyl.fieldName,
		setPreviousQuoteProductContextValue,
	]);

	useEffect(() => {
		setPreviousContextValue();
	}, [setPreviousContextValue]);

	// Values
	const mullionsWoodValue = getQuoteProductContextProductValue(
		configuration.mullions_wood.fieldName
	);
	const mullionsWoodTotalValue = getQuoteProductContextProductValue(
		configuration.mullions_wood_total.fieldName
	);
	const mullionsRemovalTotalValue = getQuoteProductContextProductValue(
		'configuration_window_instructions_removal_mullion'
	);
	const vinylValue = getQuoteProductContextProductValue(configuration.vinyl.fieldName);
	const vinylValueTotal = getQuoteProductContextProductValue(
		configuration.vinyl_total.fieldName
	);

	// Check to see if the existing radio button is checked or not.
	const isChecked = (value) => {
		return (
			value ===
			getQuoteProductContextProductValue(configuration.leave_existing.fieldName)
		);
	};

	// Check to see if the user selected "leave all existing"
	const isDisabled = () => {
		return (
			getQuoteProductContextProductValue(configuration.leave_existing.fieldName) ===
			'LEAVE_ALL_EXISTING'
		);
	};

	// Handle the input change
	const onChange = (e) => {
		if (e.target.value === 'LEAVE_ALL_EXISTING') {
			setQuoteProductContextProductValue('configuration_window_vinyl', false);
			setQuoteProductContextProductValue(
				'configuration_window_mullions_wood',
				false
			);
		}

		setQuoteProductContextProductValue(
			e.target.name,
			doubleCheckValue(e.target.name, e.target.value)
		);
	};

	const doubleCheckValue = (name, value) => {
		if (name === 'configuration_window_vinyl' && vinylValue !== false) {
			return false;
		} else if (
			name === 'configuration_window_mullions_wood' &&
			mullionsWoodValue !== false
		) {
			return false;
		}
		return value;
	};

	// Handle the vinyl minus button click
	const onVinylMinus = () => {
		setQuoteProductContextProductValue(
			configuration.vinyl_total.fieldName,
			Math.max(0, vinylValueTotal - 1)
		);
	};

	// Handle the vinyl plus button click
	const onVinylPlus = () => {
		setQuoteProductContextProductValue(
			configuration.vinyl_total.fieldName,
			vinylValueTotal + 1
		);
	};

	// Handle the wood minus button click
	const onWoodMinus = () => {
		setQuoteProductContextProductValue(
			configuration.mullions_wood_total.fieldName,
			Math.max(0, mullionsWoodTotalValue - 1)
		);
	};

	// Handle the wood plus button click
	const onWoodPlus = () => {
		setQuoteProductContextProductValue(
			configuration.mullions_wood_total.fieldName,
			mullionsWoodTotalValue + 1
		);
	};

	// Handle the center input value change
	const numInputChange = (e) => {
		setQuoteProductContextProductValue(e.target.name, parseFloat(e.target.value));
	};

	// Handle the mullion removal minus button click
	const onMullMinus = () => {
		setQuoteProductContextProductValue(
			'configuration_window_instructions_removal_mullion',
			Math.max(0, mullionsRemovalTotalValue - 1)
		);
	};

	// Handle the mullion removal plus button click
	const onMullPlus = () => {
		setQuoteProductContextProductValue(
			'configuration_window_instructions_removal_mullion',
			mullionsRemovalTotalValue + 1
		);
	};

	// Handle the bay/bow switch change.
	const onBayBowChange = (e) => {
		setQuoteProductContextProductValue(
			'configuration_window_instructions_removal_baybow_removal',
			e.target.checked
		);
	};

	// Handle the bay skirt change.
	const onBaySkirtChange = (e) => {
		setQuoteProductContextProductValue(
			'configuration_window_instructions_installation_bay_skirt',
			e.target.checked
		);
	};

	// Handle the trim out skirt change.
	const onTrimSkirtChange = (e) => {
		setQuoteProductContextProductValue(
			'configuration_window_instructions_installation_trim_out_skirt',
			e.target.checked
		);
	};

	if (!configuration.leave_existing) {
		return '';
	}

	return (
		<>
			<h6 className="mt-8 mb-2 text-gray-400 uppercase">
				{configuration.leave_existing.heading}
			</h6>
			<div className="config-option-select">
				{configuration.leave_existing.values.map((value) => {
					return (
						<React.Fragment key={value}>
							<input
								type="radio"
								name={configuration.leave_existing.fieldName}
								id={`${configuration.leave_existing.fieldName}_${value}`}
								value={value}
								checked={isChecked(value)}
								onChange={onChange}
							/>
							<label
								htmlFor={`${configuration.leave_existing.fieldName}_${value}`}
								className="config-option-select__option"
							>
								<div className="config-option-select__check" />
								<div className="config-option-select__info">
									{ConfigurationLabelMapper(value)}
								</div>
							</label>
						</React.Fragment>
					);
				})}
			</div>

			<div className="w-2/3 productconfiguration">
				<div className="flex justify-between">
					<h6 className="mt-8 mb-2 text-gray-400 uppercase">
						New Mull Material(s)
					</h6>
					<h6 className="mt-8 mb-2 text-gray-400 uppercase">Total</h6>
				</div>
				{/* Vinyl */}
				<div className="flex items-center justify-between gap-5">
					<div className="flex items-center w-2/3">
						<div className="config-option-select">
							<input
								type={'checkbox'}
								id={configuration.vinyl.fieldName}
								name={configuration.vinyl.fieldName}
								checked={vinylValue}
								disabled={isDisabled()}
								onChange={onChange}
							/>
							<label
								htmlFor={configuration.vinyl.fieldName}
								className="config-option-select__option"
							>
								<div className="config-option-select__check"></div>
								<div className="config-option-select__info">
									{configuration.vinyl.heading
										? configuration.vinyl.heading
										: ''}
								</div>
							</label>
						</div>
						<div>
							<div className="font-bold text-center text-gray-700 drop-shadow-md ">
								Vinyl mulls have a max size of 72&quot;
							</div>
						</div>
					</div>
					<div className="config-incremental-number-field">
						<div className="qty-input qty-input__buttons">
							<button
								type="button"
								className="qty-input__minus"
								onClick={onVinylMinus}
								disabled={isDisabled() ? true : !vinylValue}
							>
								-
							</button>
							<input
								type="number"
								name={configuration.vinyl_total.fieldName}
								id={configuration.vinyl_total.fieldName}
								className="qty-input__input"
								step="1"
								min="0"
								max=""
								placeholder="#"
								value={!vinylValue ? 0 : vinylValueTotal}
								onChange={numInputChange}
								disabled={isDisabled() ? true : !vinylValue}
							/>
							<button
								type="button"
								className="qty-input__plus"
								onClick={onVinylPlus}
								disabled={isDisabled() ? true : !vinylValue}
							>
								+
							</button>
						</div>
					</div>
				</div>

				{/* Mullions wood */}
				<div className="flex items-center justify-between gap-5 my-5">
					<div className="config-option-select">
						<input
							type={'checkbox'}
							id={configuration.mullions_wood.fieldName}
							name={configuration.mullions_wood.fieldName}
							checked={mullionsWoodValue}
							disabled={isDisabled()}
							onChange={onChange}
						/>
						<label
							htmlFor={configuration.mullions_wood.fieldName}
							className="config-option-select__option"
						>
							<div className="config-option-select__check"></div>
							<div className="config-option-select__info">
								{configuration.mullions_wood.heading
									? configuration.mullions_wood.heading
									: ''}
							</div>
						</label>
					</div>
					<div className="config-incremental-number-field">
						<div className="qty-input qty-input__buttons">
							<button
								type="button"
								className="qty-input__minus"
								onClick={onWoodMinus}
								disabled={isDisabled() ? true : !mullionsWoodValue}
							>
								-
							</button>
							<input
								type="number"
								name={configuration.mullions_wood_total.fieldName}
								id={configuration.mullions_wood_total.fieldName}
								className="qty-input__input"
								step="1"
								min="0"
								max=""
								placeholder="#"
								value={!mullionsWoodValue ? 0 : mullionsWoodTotalValue}
								onChange={numInputChange}
								disabled={isDisabled() ? true : !mullionsWoodValue}
							/>
							<button
								type="button"
								className="qty-input__plus"
								onClick={onWoodPlus}
								disabled={isDisabled() ? true : !mullionsWoodValue}
							>
								+
							</button>
						</div>
					</div>
				</div>

				{/* Additional Instructions, only show these for Bay/Bow */}
				{isBayBow && (
					<BayBowSkirt
						getQuoteProductContextProductValue={
							getQuoteProductContextProductValue
						}
						onBaySkirtChange={onBaySkirtChange}
						onTrimSkirtChange={onTrimSkirtChange}
					/>
				)}

				{/* Mullions removal */}
				<strong>Removal</strong>
				<div className="flex items-center justify-between gap-5 my-5">
					<h6 className="mt-8 mb-2 text-gray-400 uppercase">Mullion Removal</h6>
					<div className="config-incremental-number-field">
						<div className="qty-input qty-input__buttons">
							<button
								type="button"
								className="qty-input__minus"
								onClick={onMullMinus}
							>
								-
							</button>
							<input
								type="number"
								name="configuration_window_instructions_removal_mullion"
								id="configuration_window_instructions_removal_mullion"
								className="qty-input__input"
								step="1"
								min="0"
								max=""
								placeholder="#"
								value={mullionsRemovalTotalValue}
								onChange={numInputChange}
							/>
							<button
								type="button"
								className="qty-input__plus"
								onClick={onMullPlus}
							>
								+
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default ProductConfigurationWindowLeaveExisting;
