import React from 'react';
import { centsToMoney } from '../../../functionality/Money';

export const PaymentFinanceOption = ({
	activeQuote,
	centsToMoney,
	financeTermMonths,
	setFinanceTermMonths,
	paymentMappings,
	paymentOption,
	setPaymentOption,
	discounts,
}) => {
	const paymentOptions = activeQuote.get('paymentOptions');
	const amount = paymentOptions[paymentMappings[financeTermMonths]];

	const costPerMonth = centsToMoney({
		amount,
		decimals: 2,
	});

	let discountAmt = 0;
	for (const amt of discounts) {
		discountAmt += amt?.financingValueInCents || 0;
	}

	const totalAmount =
		activeQuote.get('totals')['totalBeforeDiscountsInCents'] - discountAmt;

	const totalCost = centsToMoney({
		amount: totalAmount,
	});

	return (
		<>
			<input
				type="radio"
				className="presentationbody__payment-input"
				name="payment_option"
				id="finance"
				value="finance"
				checked={paymentOption === 'finance'}
				onChange={() => {
					setPaymentOption('finance');
				}}
			/>
			<label htmlFor="finance" className="presentationbody__payment-button">
				<div className="presentationbody__payment-head">
					<div className="presentationbody__payment-check">
						<span className="icon icon-far icon--check"></span>
					</div>
					<div className="presentationbody__payment-title">
						Finance Over:
						<span className="input input--select-text">
							<select
								id="dropdown"
								name="dropdown"
								value={financeTermMonths}
								onChange={(evt) => {
									setFinanceTermMonths(evt.currentTarget.value);
								}}
							>
								<option value="72">72 Months</option>
								<option value="60">60 Months</option>
								<option value="48">48 Months</option>
								<option value="36">36 Months</option>
								<option value="24">24 Months</option>
								<option value="18">18 Months</option>
								<option value="12">12 Months</option>
							</select>
						</span>
					</div>
				</div>
				<div className="presentationbody__payment-body">
					<div className="flex justify-end w-full">
						<div className="w-1/3">
							<div className="heading presentationbody__payment-heading">
								{costPerMonth}
							</div>
							<div className="heading heading--6">Per Month</div>
						</div>
						<div className="flex flex-col items-start justify-end w-1/3 sm: lg:w-1/4 xl:w-1/6">
							<div className="heading presentationbody__payment-heading">
								{totalCost}
							</div>
							<div className="w-full mr-4 text-left heading heading--6">
								Total Price
							</div>
						</div>
					</div>
				</div>
			</label>
		</>
	);
};
