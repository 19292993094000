import React from 'react';

export const ProductLocationKeysItem = ({ locationKey, productsByLocation }) => {
	return (
		<>
			<h3 className="presentationsidebar__list-heading heading heading--6">
				{locationKey}
			</h3>
			{productsByLocation[locationKey].map((productInfo, index) => (
				<div
					key={productInfo.title}
					className="presentationsidebar__product-list"
				>
					<p className="heading heading--5">
						{index + 1}. {productInfo.title}
					</p>
					<p className="heading heading--5 heading--secondary">
						{productInfo.cost}
					</p>
				</div>
			))}
		</>
	);
};
