import React, { useContext, useState } from 'react';
import IconBtn from '../../IconBtn/IconBtn';
import ImageAsset from '../../ImageAsset/ImageAsset';
import Input from '../../Input/Input';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';
import UIManagementContext from '../../../contexts/UIManagementContext';

const ProductConfigurationWindowOptionsHardware = (props) => {
	// the ui management context
	const { openConfigurationData, toggleOpenConfiguration } =
		useContext(UIManagementContext);

	// product context
	const {
		getQuoteProductContextProductValue,
		setQuoteProductContextProductValue,
		setQuoteProductNotes,
	} = useContext(CurrentQuoteProductContext);
	const [showNotes, setShowNotes] = useState(false);
	const [text, setText] = useState('');

	// create the section details
	const createSectionHeadingDetails = () => {
		return (
			(props.configuration.style &&
			getQuoteProductContextProductValue(props.configuration.style.fieldName) !==
				false
				? 'Style: ' +
				  ConfigurationLabelMapper(
						getQuoteProductContextProductValue(
							props.configuration.style.fieldName
						)
				  )
				: '') +
			(props.configuration.style &&
			props.configuration.hardware &&
			getQuoteProductContextProductValue(props.configuration.hardware.fieldName)
				? ' | '
				: '') +
			(props.configuration.hardware
				? getQuoteProductContextProductValue(
						props.configuration.hardware.fieldName
				  )
					? 'WOCD'
					: ''
				: '')
		);
	};

	// determine if to show the style disclaimer
	const showHingeStyleDisclaimer =
		props.configuration.style &&
		(props.configuration.style.values.indexOf(
			'OPTION_STYLE_SINGLE_CASEMENT_LEFT_HINGE'
		) !== -1 ||
			props.configuration.style.values.indexOf(
				'OPTION_STYLE_SINGLE_CASEMENT_RIGHT_HINGE'
			) !== -1);

	const handleOnClick = (e) => {
		// if this is the custom value, then open the notes overlay
		if (e.target.value === 'CUSTOM') {
			setShowNotes(true);
		}
	};

	const handleInputOnChange = (e) => {
		setQuoteProductContextProductValue(e.target.name, e.target.checked);
	};

	const handleOnChange = (e) => {
		setQuoteProductContextProductValue(e.target.name, e.target.value);
	};

	const handleOnTextChange = (e) => {
		setText(e.target.value);
		setQuoteProductNotes(e.target.value);
	};

	// return component
	return (
		<div className="productconfiguration">
			<div
				className={`productconfiguration__section ${
					openConfigurationData() === props.orderIndex
						? 'productconfiguration__section--open'
						: ''
				}`}
			>
				<button
					type="button"
					className="productconfiguration__section-header"
					onClick={() => {
						toggleOpenConfiguration(props.orderIndex);
					}}
				>
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{props.orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">
							{props.configuration.heading}
						</div>
						<div className="heading heading--7">
							{createSectionHeadingDetails()}
						</div>
					</div>
					<div className="productconfiguration__section-toggle">
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--primary'}
							iconType={'icon--plus'}
						/>
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--secondary'}
							iconType={'icon--minus'}
						/>
					</div>
				</button>
				<div className="productconfiguration__section-body">
					{props.configuration.style ? (
						<>
							<div className="heading heading--4 config-heading">
								{props.configuration.style.heading}
							</div>
							<div className="config-circle-select">
								{props.configuration.style.values.map((value) => {
									return (
										<React.Fragment key={value}>
											<input
												type="radio"
												name={props.configuration.style.fieldName}
												id={`${props.configuration.style.fieldName}_${value}`}
												value={value}
												checked={
													value ===
													getQuoteProductContextProductValue(
														props.configuration.style
															.fieldName
													)
												}
												onClick={handleOnClick}
												onChange={handleOnChange}
											/>
											<label
												htmlFor={`${props.configuration.style.fieldName}_${value}`}
												className="config-circle-select__option"
											>
												<div className="config-circle-select__circle">
													<ImageAsset code={value} />
												</div>
												<div className="config-circle-select__label heading heading--5">
													{ConfigurationLabelMapper(value)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
								{showNotes && (
									<div className="overlay-s1__body">
										<label
											htmlFor="the_notes"
											className="heading heading--form-element-2"
										>
											Notes
										</label>
										<span className="input input--1 input--white">
											<textarea
												rows="12"
												type="text"
												id="the_notes"
												name="the_notes"
												placeholder="Enter your notes..."
												value={text}
												onChange={handleOnTextChange}
											/>
										</span>
									</div>
								)}
							</div>
							{showHingeStyleDisclaimer && (
								<p className="productconfiguration__disclaimer">
									Hinge is from the outside but the view shown is based
									on the inside.
								</p>
							)}
						</>
					) : (
						''
					)}

					{props.configuration.hardware ? (
						<>
							{props.configuration.style ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="config-boolean-field">
								<div className="config-heading heading heading--4">
									{props.configuration.hardware.heading}
								</div>
								<Input
									type={'toggle'}
									id={props.configuration.hardware.fieldName}
									name={props.configuration.hardware.fieldName}
									checked={getQuoteProductContextProductValue(
										props.configuration.hardware.fieldName
									)}
									onChange={handleInputOnChange}
								/>
							</div>
						</>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
};
export default ProductConfigurationWindowOptionsHardware;
