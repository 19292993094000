import {
	HouseType,
	MiscItem,
	OutsideInstall,
	RemovalType,
	WindowColor,
	WindowGlass,
	WindowGrid,
	WindowHardware,
	WindowInstructions,
	WindowMullions,
	WindowOptionsHardware,
	WindowRoof,
	WindowSeat,
	WindowSill,
	WindowScreen,
	WindowSize,
	WindowStyle,
} from './components';

// our function for generatng details
export const generateDetails = (quoteProduct, product, groupName) => {
	// simple function for yes/no
	const booleanText = (bool) => {
		return bool ? 'Yes' : 'No';
	};

	switch (groupName) {
		case 'WINDOW_SIZE':
			return (
				<WindowSize
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);

		case 'HOUSE_TYPE':
			return (
				<HouseType
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'REMOVAL_TYPE':
			return (
				<RemovalType
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'OUTSIDE_INSTALL':
			return (
				<OutsideInstall
					booleanText={booleanText}
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'WINDOW_STYLE':
			return (
				<WindowStyle
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'WINDOW_COLOR':
			return (
				<WindowColor
					booleanText={booleanText}
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'WINDOW_GRID':
			return (
				<WindowGrid
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'WINDOW_GLASS':
			return (
				<WindowGlass
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'WINDOW_SCREEN':
			return (
				<WindowScreen
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'WINDOW_SEAT':
			return (
				<WindowSeat
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'WINDOW_ROOF':
			return (
				<WindowRoof
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'WINDOW_HARDWARE':
			return (
				<WindowHardware
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'WINDOW_SILL':
			return (
				<WindowSill
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'WINDOW_OPTIONS_HARDWARE':
			return (
				<WindowOptionsHardware
					booleanText={booleanText}
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'WINDOW_INSTRUCTIONS':
			return (
				<WindowInstructions
					booleanText={booleanText}
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'WINDOW_MULLIONS':
			return (
				<WindowMullions
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		case 'MISC_ITEM':
			return (
				<MiscItem
					groupName={groupName}
					product={product}
					quoteProduct={quoteProduct}
				/>
			);
		default:
			return 'UNKNOWN';
	}
};
