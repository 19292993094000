import React from 'react';

import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';

export const WindowScreen = ({ groupName, product, quoteProduct }) => (
	<>
		{product.configurations[groupName].size && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Size:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].size.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].mesh && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Mesh:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].mesh.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
	</>
);
