import React, { Fragment, useEffect, useContext } from 'react';
import IconBtn from '../../IconBtn/IconBtn';
import Input from '../../Input/Input';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper.js';
import UIManagementContext from '../../../contexts/UIManagementContext';
import classNames from 'classnames';

const ProductConfigurationWindowSill = ({
	configuration,
	isBayBow = false,
	orderIndex,
}) => {
	// the ui management context
	const { openConfigurationData, toggleOpenConfiguration } =
		useContext(UIManagementContext);

	// product context
	const { setQuoteProductContextProductValue, getQuoteProductContextProductValue } =
		useContext(CurrentQuoteProductContext);

	const { dimensions, exteriorDimensions, exteriorSill, heading, interiorSill } =
		configuration;

	// we have to handle the available selections as part of use effect
	// here we'll make sure that the exterior value is valid, if its not valid, then we update it.
	const materialValue = interiorSill
		? getQuoteProductContextProductValue(interiorSill.fieldName)
		: false;

	const materialOption = interiorSill?.values?.find(
		(option) => option.value === materialValue
	);

	const dimensionValue = dimensions
		? getQuoteProductContextProductValue(dimensions.fieldName)
		: false;

	const exteriorValue = exteriorSill
		? getQuoteProductContextProductValue(exteriorSill.fieldName)
		: false;

	const exteriorOption = exteriorSill?.values?.find(
		(option) => option.value === exteriorValue
	);

	const exteriorDimensionValue = exteriorDimensions
		? getQuoteProductContextProductValue(exteriorDimensions.fieldName)
		: false;

	const hasInteriorDimensions = dimensions && materialOption.dimensionsOptions;

	// the conditional elements and checks
	useEffect(
		function () {
			if (materialOption !== false && dimensions) {
				if (
					dimensionValue !== false &&
					materialOption.dimensionsOptions === false
				) {
					setQuoteProductContextProductValue(
						dimensions.fieldName,
						materialOption.dimensionsDefault
					);
				} else if (
					dimensionValue === false &&
					materialOption.dimensionsOptions !== false
				) {
					setQuoteProductContextProductValue(
						dimensions.fieldName,
						materialOption.dimensionsDefault
					);
				} else if (
					materialOption.dimensionsOptions !== false &&
					materialOption.dimensionsOptions.indexOf(dimensionValue) === -1
				) {
					setQuoteProductContextProductValue(
						dimensions.fieldName,
						materialOption.dimensionsDefault
					);
				}
			}
			if (exteriorValue !== false && !exteriorOption) {
				setQuoteProductContextProductValue(
					exteriorSill.fieldName,
					exteriorSill.defaultValue
				);
			}
			if (exteriorOption !== false && exteriorDimensions) {
				if (
					exteriorDimensionValue !== false &&
					exteriorOption.dimensions === false
				) {
					setQuoteProductContextProductValue(
						exteriorDimensions.fieldName,
						exteriorDimensions.defaultValue
					);
				} else if (
					exteriorDimensionValue === false &&
					exteriorOption.dimensions !== false
				) {
					setQuoteProductContextProductValue(
						exteriorDimensions.fieldName,
						exteriorDimensions.defaultValue
					);
				}
			}
		},
		[
			materialOption,
			exteriorValue,
			dimensionValue,
			exteriorOption,
			exteriorDimensionValue,
			exteriorDimensions,
			exteriorSill,
			dimensions,
			setQuoteProductContextProductValue,
		]
	);

	// create the section details
	const createSectionHeadingDetails = () => {
		let text = '';
		// add the interior
		if (materialValue !== false) {
			text += 'Interior: ' + ConfigurationLabelMapper(materialValue);
			if (materialValue !== 'NONE' && dimensionValue !== false) {
				text += ' - ' + ConfigurationLabelMapper(dimensionValue) + '"';
			}
		}
		// add the exterior
		if (exteriorValue !== false) {
			text +=
				(text !== '' ? ' | ' : '') +
				'Exterior: ' +
				ConfigurationLabelMapper(exteriorValue);
			if (
				exteriorValue !== 'NONE' &&
				exteriorDimensionValue !== false &&
				exteriorDimensionValue !== 0
			) {
				text += ' - ' + exteriorDimensionValue + "'";
			}
		}
		return text;
	};

	const containerStyle = classNames('productconfiguration__section', {
		'productconfiguration__section--open': openConfigurationData() === orderIndex,
	});

	const btnStyle = classNames('productconfiguration__section-header', {
		'cursor-default': isBayBow,
	});

	const showConfigDivider = interiorSill || hasInteriorDimensions || exteriorSill;

	return (
		<div className="productconfiguration">
			<div className={containerStyle}>
				<button
					type="button"
					className={btnStyle}
					onClick={() => {
						isBayBow ? null : toggleOpenConfiguration(orderIndex);
					}}
				>
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">{heading}</div>
						<div className="heading heading--7">
							{createSectionHeadingDetails()}
						</div>
					</div>
					<div className="productconfiguration__section-toggle">
						{!isBayBow && (
							<>
								<IconBtn
									layoutOnly
									iconWeight="icon-far"
									btnType={'iconbtn--primary'}
									iconType={'icon--plus'}
								/>
								<IconBtn
									layoutOnly
									iconWeight="icon-far"
									btnType={'iconbtn--secondary'}
									iconType={'icon--minus'}
								/>
							</>
						)}
					</div>
				</button>
				<div className="productconfiguration__section-body">
					{interiorSill && (
						<>
							<div className="config-heading heading heading--4">
								{interiorSill.heading}
							</div>
							<div className="config-option-select">
								{interiorSill.values.map((option) => {
									return (
										<Fragment key={option.value}>
											<input
												type="radio"
												name={interiorSill.fieldName}
												id={`${interiorSill.fieldName}_${option.value}`}
												value={option.value}
												checked={
													!!(
														option.value ===
														getQuoteProductContextProductValue(
															interiorSill.fieldName
														)
													)
												}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${interiorSill.fieldName}_${option.value}`}
												className="config-option-select__option"
											>
												<div className="config-option-select__check"></div>
												<div className="config-option-select__info">
													{ConfigurationLabelMapper(
														option.value
													)}
												</div>
											</label>
										</Fragment>
									);
								})}
							</div>
						</>
					)}

					{dimensions && materialOption?.dimensionsOptions && (
						<>
							{interiorSill && <div className="config-divider"></div>}
							<div className="config-heading heading heading--4">
								{dimensions.heading}
							</div>
							<label
								htmlFor={dimensions.fieldName}
								className="config-subheading heading heading--6"
							>
								Depth
							</label>
							<div className="config-select-field">
								<Input
									type={'select'}
									className={'input--1'}
									inputModifier={'input--select input--autowidth'}
									id={dimensions.fieldName}
									name={dimensions.fieldName}
									values={materialOption.dimensionsOptions.map(
										function (value) {
											return {
												label: ConfigurationLabelMapper(value),
												value: value,
											};
										}
									)}
									value={dimensionValue}
									onChange={(e) => {
										setQuoteProductContextProductValue(
											e.target.name,
											e.target.value
										);
									}}
								/>
							</div>
						</>
					)}

					{exteriorSill && (
						<>
							{interiorSill || hasInteriorDimensions ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="config-heading heading heading--4">
								{exteriorSill.heading}
							</div>
							<div className="config-option-select">
								{exteriorSill.values.map((option) => {
									return (
										<Fragment key={option.value}>
											<input
												type="radio"
												name={exteriorSill.fieldName}
												id={`${exteriorSill.fieldName}_${option.value}`}
												value={option.value}
												checked={
													!!(
														option.value ===
														getQuoteProductContextProductValue(
															exteriorSill.fieldName
														)
													)
												}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${exteriorSill.fieldName}_${option.value}`}
												className="config-option-select__option"
											>
												<div className="config-option-select__check"></div>
												<div className="config-option-select__info">
													{ConfigurationLabelMapper(
														option.value
													)}
												</div>
											</label>
										</Fragment>
									);
								})}
							</div>
						</>
					)}

					{exteriorDimensions && exteriorOption?.dimensions && (
						<>
							{showConfigDivider && <div className="config-divider"></div>}
							<div className="config-heading heading heading--4">
								{exteriorDimensions?.heading}
							</div>
							<label
								htmlFor={exteriorDimensions?.fieldName}
								className="config-subheading heading heading--6"
							>
								Length
							</label>
							<div className="config-number-field">
								<Input
									type="text"
									className="input--1 input--feet"
									id={exteriorDimensions.fieldName}
									name={exteriorDimensions.fieldName}
									value={exteriorDimensionValue}
									onBlur={(e) => {
										const val = parseFloat(e.target.value);
										setQuoteProductContextProductValue(
											e.target.name,
											isNaN(val) ||
												val < exteriorDimensions.minimumValue
												? exteriorDimensions.defaultValue
												: val
										);
									}}
									onChange={(e) => {
										setQuoteProductContextProductValue(
											e.target.name,
											e.target.value
										);
									}}
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
export default ProductConfigurationWindowSill;
