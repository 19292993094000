import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UIManagementContext from '../../../contexts/UIManagementContext';
import SessionDataContext from '../../../contexts/SessionDataContext';
import WallsideError from '../../../functionality/WallsideError';
import URLMapper from '../../../functionality/URLMapper';
import Btn from '../../../components/Btn/Btn';

const OverlayConfirmDeleteQuote = () => {
	// setting up the history object
	const history = useHistory();

	// setting up the contexts
	const { overlayData, toggleOverlay, toggleSystemNotification } =
		useContext(UIManagementContext);
	const { deleteQuote } = useContext(SessionDataContext);

	// handle saving
	const handleDelete = async () => {
		try {
			await deleteQuote();
			// go to the correct next page
			history.push(
				overlayData().data.goToQuoteId
					? URLMapper('quote', { quoteId: overlayData().data.goToQuoteId })
					: URLMapper('dashboard')
			);
			// show the notification
			toggleSystemNotification({ active: true, messageKey: 'delete_quote' });
			hideOverlay();
		} catch (e) {
			const error = new WallsideError(e);
			alert(error.globalErrors[0].message);
		}
	};

	const hideOverlay = () => {
		toggleOverlay({ active: false });
	};

	// return the component
	return (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s1">
						<div className="overlay-s1__heading">
							<h1 className="heading heading--4">Confirm Delete</h1>
						</div>
						<div className="overlay-s1__body overlay-s1__body--message">
							<p className="heading heading--5">
								Are you sure you want to delete this quote? You will not
								be able to bring back this quote.
							</p>
						</div>
						<div className="overlay-s1__btns">
							<Btn btnType={'btn--secondary'} onClick={hideOverlay}>
								Cancel
							</Btn>
							<Btn btnType={'btn--danger--filled'} onClick={handleDelete}>
								Yes, Delete
							</Btn>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayConfirmDeleteQuote;
