export default {
	CH: 'CH Cathedral',
	CHX: 'CHX Cathedral Extended Legs',
	CT: 'CT Circle Top',
	CT1_4: 'CT1/4 1/4 Arch Circle Top',
	CTX: 'CTX Circle Top Extended Legs',
	DCC: 'DCC Double Clipped Corner',
	EB: 'EB Eyebrow',
	EBX: 'EBX Eyebrow Extended Legs',
	EP: 'EP Ellipse',
	EPX: 'EPX Ellipse Extended Legs',
	FC: 'FC Full Circle',
	HBLR: 'HBLR Half Eye Brow Left/Right',
	HX: 'HX Hexagon',
	HXLR: 'HXLR Half Eyebrow Extended Legs',
	HXX: 'HXX Hexagon With Extended Legs',
	OT: 'OT Octagon',
	OTX: 'OTX Octagon Extended Legs',
	OV: 'OV Oval',
	PNT: 'PNT Pentagon',
	PTE: 'PTE Pentagon Equilateral',
	PTLR: 'PTLR Pentagon Left/Right',
	QALR: 'QALR Quarter Arch Left/Right',
	QXLR: 'QXLR Quarter Arch Left/Right Extended Legs',
	RT: 'RT Rectangle',
	TRE: 'TRE Triangle Equal',
	TRI: 'TRI Triangle Isosceles',
	TRLR: 'TRLR Triangle Left/Right',
	TZLR: 'TZLR Trapezoid Left/Right',
};
