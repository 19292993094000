import ContentBox1Empty from './ContentBox1Empty/ContentBox1Empty';
import './ContentBox1.css';

const ContentBox1 = (props) => (
	<div className="contentbox1">
		<div className="contentbox1__header">
			<h2 className="heading heading--3 contentbox1__title">
				{props.headingTitle}
			</h2>
			{props.headingElement ? props.headingElement : ''}
		</div>
		<div className="contentbox1__content">
			{props.children ? (
				props.children
			) : (
				<ContentBox1Empty message={props.emptyMessage}></ContentBox1Empty>
			)}
		</div>
	</div>
);
export default ContentBox1;
