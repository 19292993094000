import React from 'react';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';

export const WindowGrid = ({ groupName, product, quoteProduct }) => (
	<>
		{product.configurations[groupName].style && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Style:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].style.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].style &&
			quoteProduct.values[product.configurations[groupName].style.fieldName] !==
				'NONE' &&
			product.configurations[groupName].pattern && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Pattern:</dt>
						<dd className="heading heading--13">
							{ConfigurationLabelMapper(
								quoteProduct.values[
									product.configurations[groupName].pattern.fieldName
								]
							)}
						</dd>
					</dl>
				</div>
			)}
		{product.configurations[groupName].style &&
			quoteProduct.values[product.configurations[groupName].style.fieldName] !==
				'NONE' &&
			product.configurations[groupName].location && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Location:</dt>
						<dd className="heading heading--13">
							{product.configurations[groupName].location.values
								.map((value) => {
									return quoteProduct.values[value.fieldName]
										? value.label
										: '';
								})
								.join(', ')}
						</dd>
					</dl>
				</div>
			)}
		{product.configurations[groupName].style &&
			quoteProduct.values[product.configurations[groupName].style.fieldName] !==
				'NONE' &&
			product.configurations[groupName].details && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Grid Details:</dt>
						<dd className="heading heading--13">
							{
								quoteProduct.values[
									product.configurations[groupName].details.fieldName
								]
							}
						</dd>
					</dl>
				</div>
			)}
		{product.configurations[groupName].style &&
			quoteProduct.values[product.configurations[groupName].style.fieldName] !==
				'NONE' &&
			quoteProduct.values[product.configurations[groupName].style.fieldName] !==
				'ETCHED' &&
			product.configurations[groupName].interiorMuntinColor && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Interior Muntin Color:</dt>
						<dd className="heading heading--13">
							{ConfigurationLabelMapper(
								quoteProduct.values[
									product.configurations[groupName].interiorMuntinColor
										.fieldName
								]
							)}
						</dd>
					</dl>
				</div>
			)}
		{product.configurations[groupName].style &&
			quoteProduct.values[product.configurations[groupName].style.fieldName] !==
				'NONE' &&
			quoteProduct.values[product.configurations[groupName].style.fieldName] !==
				'ETCHED' &&
			product.configurations[groupName].exteriorMuntinColor && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Exterior Muntin Color:</dt>
						<dd className="heading heading--13">
							{ConfigurationLabelMapper(
								quoteProduct.values[
									product.configurations[groupName].exteriorMuntinColor
										.fieldName
								]
							)}
						</dd>
					</dl>
				</div>
			)}
	</>
);
