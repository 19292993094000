import WINDOW_LOCATIONS from './label-data/window-locations.js';
import COLORS from './label-data/colors.js';
import SHAPES from './label-data/shapes.js';

const labelArray = [
	WINDOW_LOCATIONS,
	COLORS,
	SHAPES,
	{
		// house type
		FRAME: 'Frame (Sided)',
		BLOCK: 'Block',
		BRICK: 'Brick',

		// removal type
		VINYL: 'Vinyl',
		WOOD: 'Wood',
		ALUMINUM: 'Aluminum',
		STEEL: 'Steel',
		GLASS_BLOCK: 'Glass Block',

		// exterior trim material
		VINYL_TRIM: 'Standard',
		PAINTED: 'Painted',
		PVC: 'PVC',
		CEDAR: 'Cedar',
		NONE: 'None',

		// window grid
		FLAT: 'Flat',
		CONTOUR: 'Contour',
		SLIM_LINE: 'Slim Line',
		ETCHED: 'Etched',

		// window grid pattern
		COLONIAL: 'Colonial',
		VERTICAL: 'Vertical',
		HORIZONTAL: 'Horizontal',
		SINGLE_PRAIRIE: 'Single Prairie',
		DOUBLE_PRAIRIE: 'Double Prairie',
		DIAMOND: 'Diamond',
		DIAMOND_ETCHED: 'Diamond',

		SPOKES: 'Spokes',
		HUB_AND_SPOKES: 'Hub & Spokes',
		DOUBLE_HUB_AND_SPOKES: 'Double Hub & Spokes',

		CUSTOM: 'Custom',
		MARQUIS: 'Marquis',
		FLORENTINE: 'Florentine',
		PETAL: 'Petal',
		TRELLIS: 'Trellis',
		TUDOR: 'Tudor',
		SQUARE: 'Square',

		// window glass
		RLE: 'Regular Low-E',
		PLE: 'Premium/ ES7',
		TPLE: 'Triple Pane - Premium Low-E',
		SLB60: 'Solarban 60',

		// glass temper
		TEMPER_DOUBLE_HUNG_BOTH: 'Both',
		TEMPER_DOUBLE_HUNG_LOWER: 'Lower',
		TEMPER_DOUBLE_SLIDER_BOTH: 'Both',
		TEMPER_DOUBLE_CASEMENT_BOTH: 'Both',
		TEMPER_SINGLE_CASEMENT_ALL: 'All',
		TEMPER_AWNING_CASEMENT_ALL: 'All',
		TEMPER_CENTER_VENT_ALL: 'All',
		TEMPER_END_VENT_ALL: 'All',
		TEMPER_HOPPER_ALL: 'All',
		TEMPER_DOORWALL_ALL: 'All',
		TEMPER_GARDEN_ALL: 'All',
		TEMPER_PICTURE_ALL: 'All',
		TEMPER_SPECIALTY_ALL: 'All',

		// glass obscure
		OBSCURE_DOUBLE_HUNG_BOTH: 'Both',
		OBSCURE_DOUBLE_HUNG_UPPER: 'Upper',
		OBSCURE_DOUBLE_HUNG_LOWER: 'Lower',
		OBSCURE_DOUBLE_SLIDER_BOTH: 'Both',
		OBSCURE_DOUBLE_CASEMENT_BOTH: 'Both',
		OBSCURE_SINGLE_CASEMENT_ALL: 'All',
		OBSCURE_AWNING_CASEMENT_ALL: 'All',
		OBSCURE_CENTER_VENT_ALL: 'All',
		OBSCURE_END_VENT_ALL: 'All',
		OBSCURE_HOPPER_ALL: 'All',
		OBSCURE_DOORWALL_ALL: 'All',
		OBSCURE_GARDEN_ALL: 'All',
		OBSCURE_PICTURE_ALL: 'All',
		OBSCURE_SPECIALTY_ALL: 'All',

		// glass tint
		TINT_GRAY: 'Gray',
		TINT_BRONZE: 'Bronze',

		// Window Screen
		// --------------------------

		// window screen
		HALF_SCREEN: '1/2 Screen',
		FULL_SCREEN: 'Full Screen',
		TWO_FULL_SCREEN: '2 Full Screens',
		TWO_HALF_SCREEN: '2 1/2 Screens',

		// window mesh
		BLACK_MESH: 'Black',
		GRAY_MESH: 'Gray',
		PET_MESH: 'Pet Screen',
		ALUMINUM_MESH: 'Aluminum',

		// sill material
		PINE: 'Pine',
		MARBLE_LITE_WHITE: 'Marble-Lite (White)',
		MARBLE_LITE_BUFF: 'Marble-Lite (Buff)',
		OAK_VENEER: 'Oak (Veneer Plywood)',

		// sill sizes
		SILL_UP_TO_4: 'up to 4"',
		SILL_3_TO_4: '3"-4"',
		SILL_6_TO_12: '6"-12"',
		SILL_OVER_12: 'over 12"',
		SILL_WIDE_6_TO_12: 'extra wide 6"-12"',
		SILL_WIDE_OVER_12: 'extra wide over 12"',

		// exterior sill
		STONE: 'Stone',

		// window options hardware
		OPTION_STYLE_DOUBLE_HUNG_NORMAL: 'Normal',
		OPTION_STYLE_DOUBLE_HUNG_ORIEL: 'Oriel',
		OPTION_STYLE_DOUBLE_HUNG_COTTAGE: 'Cottage',
		OPTION_STYLE_DOUBLE_SLIDER_NORMAL: 'Normal',
		OPTION_STYLE_DOUBLE_SLIDER_ORIEL: 'Oriel',
		OPTION_STYLE_DOUBLE_SLIDER_COTTAGE: 'Cottage',
		OPTION_STYLE_DOUBLE_SLIDER_TRACK_OPPOSITE: 'Track Opposite',
		OPTION_STYLE_SINGLE_CASEMENT_LEFT_HINGE: 'Left Hinge',
		OPTION_STYLE_SINGLE_CASEMENT_RIGHT_HINGE: 'Right Hinge',
		OPTION_STYLE_END_VENT_3_3_3: '1/3 - 1/3 - 1/3',
		OPTION_STYLE_END_VENT_4_2_4: '1/4 - 1/2 - 1/4',

		// window instructions
		PARTIAL_BUCK: 'Partial Buck',
		FULL_BUCK: 'Full Buck',
		PRIMED: 'Primed',
		OAK: 'Oak',
		WHITE_VINYL: 'White Vinyl',
		FLUTED_CASING: 'Fluted Casing',
		CUSTOMER_SUPPLIED: 'Customer Supplied',
		CURVED_CASING: 'Casing/Jamb Curved Windows',
		PRIME_COATED: 'Primed',
		REMOVE: 'Remove',
		REMOVE_AND_INSTALL: 'Remove and Install',
		MULL_EXISTING: 'Keep Existing',
		MULL_WOOD: 'Wood',
		MULL_VINYL: 'Vinyl',

		// window style sections
		SECTIONS_2_DOORWALL: '2',
		SECTIONS_3_DOORWALL: '3',
		NUMBER_WINDOWS_4: '4',
		NUMBER_WINDOWS_5: '5',
		NUMBER_WINDOWS_6: '6',

		// window style style
		EQUAL_SECTIONS: '1/3 1/3 1/3',
		STANDARD_SECTIONS: '1/4 1/2 1/4',

		// window style materiaal
		BIRCH_PINE_MATERIAL: 'Birch/Pine',
		OAK_MATERIAL: 'Oak',

		// window style operation
		OPERATION_LEFT_TO_RIGHT_DOORWALL: 'Left to Right',
		OPERATION_LEFT_TO_RIGHT_DOORWALL_SECTIONS_3: 'Left to Right',
		OPERATION_RIGHT_TO_LEFT_DOORWALL: 'Right to Left',
		OPERATION_RIGHT_TO_LEFT_DOORWALL_SECTIONS_3: 'Right to Left',

		// window style vent position
		VENT_POSITION_LEFT_DOORWALL: 'Left',
		VENT_POSITION_CENTER_DOORWALL: 'Center',
		VENT_POSITION_RIGHT_DOORWALL: 'Right',

		// window hardware
		WHITE_HANDLE: 'White',
		ALMOND_HANDLE: 'Almond',
		TERRATONE_HANDLE: 'Terratone',
		BRASS_HANDLE: 'Brass',
		BRUSHED_CHROME_HANDLE: 'Brushed Chrome',
		ANTIQUE_BRASS_HANDLE: 'Antique Brass',
		BLACK_NICKEL_HANDLE: 'Black Nickel',
		FAUX_OIL_RUBBED_BRONZE_HANDLE: 'Oil Rubbed Bronze (faux)',

		// window seat
		PIONITE: 'Pionite',
		ACRYLIC: 'Acrylic',
		AVONITE: 'Avonite',

		// window roof type
		HIP: 'Hip',
		SHED: 'Shed',
		ATTACHED_TO_OVERHANG: 'Attached to Overhang',

		// window roof material
		SHINGLE: 'Shingle',

		// a/c Location
		VENT: 'Vent',
		STAT: 'Stat',
		LEFT: 'Left',
		CENTER: 'Center',
		RIGHT: 'Right',
	},
];

const labelMap = {};
labelArray.forEach(function (labelCollection) {
	Object.assign(labelMap, labelCollection);
});

export default function (c, s) {
	// set our code and size
	const code = c ? c : '?';
	const strict = s ? true : false;

	if (labelMap[code]) {
		return labelMap[code];
	}

	return strict ? '?' : c;
}
