import React, { Fragment } from 'react';
import ImageAsset from '../../../../ImageAsset/ImageAsset';
import ConfigurationLabelMapper from '../../../../../functionality/ConfigurationLabelMapper';
/**
 * Moving our color tab conditional jsx to it's own component for simplicity.
 */
export const ColorTab = ({ color_config, colorData, setColorData }) => {
	return (
		<>
			<div className="config-heading heading heading--4">
				{color_config.interiorColor.heading}
			</div>
			<div className="config-notch-select">
				{color_config.interiorColor.values.map((option) => {
					const isNone = option.value === 'NONE';
					return (
						<Fragment key={option.value}>
							<input
								type="radio"
								name={color_config.interiorColor.fieldName}
								id={`${color_config.interiorColor.fieldName}_${option.value}`}
								value={option.value}
								checked={
									option.value === colorData.selectedInteriorColorValue
								}
								onChange={(e) => {
									const value = e.currentTarget.value;
									const option = color_config.interiorColor.values.find(
										(option) => {
											return option.value === value;
										}
									);
									setColorData((prev) => {
										return {
											...prev,
											...{
												selectedInteriorColorValue: value,
												selectedInteriorColorOption: option,
												selectedExteriorColorValue:
													option.defaultExteriorColor,
											},
										};
									});
								}}
							/>
							<label
								htmlFor={`${color_config.interiorColor.fieldName}_${option.value}`}
								className="config-notch-select__option"
							>
								<div className="config-notch-select__button">
									<span className="config-notch-select__notch"></span>
									{!isNone ? <ImageAsset code={option.value} /> : ''}
								</div>
								<div className="config-notch-select__label heading heading--10">
									{ConfigurationLabelMapper(option.value)}
								</div>
							</label>
						</Fragment>
					);
				})}
			</div>
			<div className="config-divider"></div>
			<div className="config-heading heading heading--4">
				{color_config.exteriorColor.heading}
			</div>
			<div className="config-notch-select">
				{color_config.exteriorColor.values.map((value) => {
					const isDisabled =
						colorData.selectedInteriorColorOption === false ||
						colorData.selectedInteriorColorOption.exteriorColorOptions.indexOf(
							value
						) === -1;
					return (
						<Fragment key={value}>
							<input
								type="radio"
								name={color_config.exteriorColor.fieldName}
								id={`${color_config.exteriorColor.fieldName}_${value}`}
								value={value}
								{...(isDisabled && {
									disabled: true,
								})}
								checked={value === colorData.selectedExteriorColorValue}
								onChange={(e) => {
									const value = e.currentTarget.value;
									setColorData((prev) => {
										return {
											...prev,
											...{
												selectedExteriorColorValue: value,
											},
										};
									});
								}}
							/>
							<label
								htmlFor={`${color_config.exteriorColor.fieldName}_${value}`}
								className={`config-notch-select__option ${
									isDisabled
										? 'config-notch-select__option--inactive'
										: ''
								}`}
							>
								<div className="config-notch-select__button">
									<span className="config-notch-select__notch"></span>
									<ImageAsset code={value} />
								</div>
								<div className="config-notch-select__label heading heading--10">
									{ConfigurationLabelMapper(value)}
								</div>
							</label>
						</Fragment>
					);
				})}
			</div>
		</>
	);
};
