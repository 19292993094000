import React, { useContext, useEffect } from 'react';
import SessionDataContext from '../../contexts/SessionDataContext';
import Layout2 from '../../layouts/Layout2/Layout2';
import ProductSelectNavBar from '../../components/NavBars/ProductSelectNavBar/ProductSelectNavBar';
import ProductSelectList from '../../components/ProductSelectList/ProductSelectList';
import WallsideError from '../../functionality/WallsideError';

const ProductSelectionRoute = (props) => {
	// use the session data context and set the quote ID to the url params
	const { loadQuote } = useContext(SessionDataContext);

	// we need to fetch the correct quote for this account
	useEffect(
		function () {
			loadQuote(props.match.params.quoteId).then(
				() => {
					// nothing
				},
				(e) => {
					const error = new WallsideError(e);
					alert(error.globalErrors[0].message);
				}
			);
		},
		[loadQuote, props.match.params.quoteId]
	);

	// return the component
	return (
		<Layout2 navbar={<ProductSelectNavBar title="Select A Product" />}>
			<ProductSelectList />
		</Layout2>
	);
};

export default ProductSelectionRoute;
