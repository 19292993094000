import React, { useCallback, useContext, useEffect, useRef } from 'react';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import ImageAsset from '../../ImageAsset/ImageAsset';
import Input from '../../Input/Input';
import UIManagementContext from '../../../contexts/UIManagementContext';

const ProductConfigurationWindowColors = ({ configuration, orderIndex }) => {
	// the ui management context
	const { toggleOverlay, openConfigurationData, toggleOpenConfiguration } =
		useContext(UIManagementContext);

	// product context
	const {
		product,
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
		setPreviousQuoteProductContextValue,
	} = useContext(CurrentQuoteProductContext);

	const selectedExteriorColorValue = configuration.exteriorColor
		? getQuoteProductContextProductValue(configuration.exteriorColor.fieldName)
		: false;
	const selectedExteriorTrimValue = configuration.exteriorTrim
		? getQuoteProductContextProductValue(configuration.exteriorTrim.fieldName)
		: 'NONE';
	const selectedExteriorTrimOption = configuration.exteriorTrim
		? configuration.exteriorTrim.values.find((option) => {
				return option.value === selectedExteriorTrimValue;
		  })
		: false;
	const selectedExteriorTrimColorValue = configuration.trimColor
		? getQuoteProductContextProductValue(configuration.trimColor.fieldName)
		: false;
	const selectedExteriorCedarTrimValue = configuration.exteriorCedarTrim
		? getQuoteProductContextProductValue(configuration.exteriorCedarTrim.fieldName)
		: false;
	const selectedExteriorCedarSizeValue = configuration.exteriorCedarSize
		? getQuoteProductContextProductValue(configuration.exteriorCedarSize.fieldName)
		: false;
	const selectedExteriorCedarDimensionValue = configuration.exteriorCedarDimensions
		? getQuoteProductContextProductValue(
				configuration.exteriorCedarDimensions.fieldName
		  )
		: 0;

	// Set to use the previous value
	const setPreviousContextValue = useCallback(async () => {
		configuration.exteriorColor?.fieldName &&
			(await setPreviousQuoteProductContextValue(
				configuration.exteriorColor?.fieldName
			));
		configuration.exteriorTrim?.fieldName &&
			(await setPreviousQuoteProductContextValue(
				configuration.exteriorTrim?.fieldName
			));
		configuration.exteriorCedarTrim?.fieldName &&
			(await setPreviousQuoteProductContextValue(
				configuration.exteriorCedarTrim?.fieldName
			));
		configuration.trimColor?.fieldName &&
			(await setPreviousQuoteProductContextValue(
				configuration.trimColor?.fieldName
			));
		configuration.exteriorCedarSize?.fieldName &&
			(await setPreviousQuoteProductContextValue(
				configuration.exteriorCedarSize?.fieldName
			));
		configuration.exteriorCedarDimensions?.fieldName &&
			(await setPreviousQuoteProductContextValue(
				configuration.exteriorCedarDimensions?.fieldName
			));
	}, [configuration, setPreviousQuoteProductContextValue]);

	useEffect(() => {
		setPreviousContextValue();
	}, [setPreviousContextValue]);

	// we have to make sure the color values are appropriate
	useEffect(() => {
		// make sure the exterior trim color value work for the selected exterior trim
		if (selectedExteriorTrimOption) {
			// check the trim color
			if (
				selectedExteriorTrimOption?.trimColorOptions === false &&
				selectedExteriorTrimColorValue !== false
			) {
				setQuoteProductContextProductValue(
					configuration.trimColor.fieldName,
					false
				);
			} else if (
				selectedExteriorTrimOption?.trimColorOptions !== false &&
				selectedExteriorTrimOption.trimColorOptions.indexOf(
					selectedExteriorTrimColorValue
				) === -1
			) {
				setQuoteProductContextProductValue(
					configuration.trimColor.fieldName,
					selectedExteriorTrimOption.trimColorDefault
				);
			}
		}

		// check the cedar size
		if (
			selectedExteriorCedarTrimValue === false &&
			configuration.exteriorCedarSize &&
			selectedExteriorCedarSizeValue !== false
		) {
			setQuoteProductContextProductValue(
				configuration.exteriorCedarSize.fieldName,
				false
			);
		} else if (
			selectedExteriorCedarTrimValue !== false &&
			configuration.exteriorCedarSize &&
			configuration.exteriorCedarSize.values.indexOf(
				selectedExteriorCedarSizeValue
			) === -1
		) {
			setQuoteProductContextProductValue(
				configuration.exteriorCedarSize.fieldName,
				configuration.exteriorCedarSize.defaultValue
			);
			setQuoteProductContextProductValue(
				configuration.exteriorCedarDimensions.fieldName,
				configuration.exteriorCedarDimensions.defaultValue
			);
		}

		// check the cedar dimensions
		if (
			selectedExteriorCedarTrimValue === false &&
			selectedExteriorCedarDimensionValue !== 0
		) {
			setQuoteProductContextProductValue(
				configuration.exteriorCedarDimensions.fieldName,
				0
			);
		}
	}, [
		selectedExteriorColorValue,
		selectedExteriorTrimOption,
		selectedExteriorTrimColorValue,
		configuration.exteriorColor,
		configuration.trimColor,
		configuration.exteriorCedarSize,
		configuration.exteriorCedarDimensions,
		selectedExteriorCedarDimensionValue,
		selectedExteriorCedarTrimValue,
		selectedExteriorCedarSizeValue,
		setQuoteProductContextProductValue,
	]);

	// we'll use a reference to monitor for the first run
	// this allows us to not update parts on init
	// while the useRef, doesn't cause a re-render
	const isFirstRender1 = useRef(true);
	const isFirstRender2 = useRef(true);

	// we have some other relationships with other fields
	useEffect(() => {
		if (isFirstRender1.current) {
			isFirstRender1.current = false;
		} else {
			if (product.configurations['WINDOW_GRID']) {
				const gridStyleValue = product.configurations['WINDOW_GRID'].style
					? getQuoteProductContextProductValue(
							product.configurations['WINDOW_GRID'].style.fieldName
					  )
					: false;
				if (gridStyleValue === 'FLAT' || gridStyleValue === 'CONTOUR') {
					const gridStyleOption = product.configurations[
						'WINDOW_GRID'
					].style.values.find((option) => {
						return option.value === gridStyleValue;
					});
				}
			}
		}
	}, [product, getQuoteProductContextProductValue, setQuoteProductContextProductValue]);

	// we have some other relationships with other fields
	useEffect(() => {
		if (isFirstRender2.current) {
			isFirstRender2.current = false;
		} else {
			if (product.configurations['WINDOW_GRID']) {
				const gridStyleValue = product.configurations['WINDOW_GRID'].style
					? getQuoteProductContextProductValue(
							product.configurations['WINDOW_GRID'].style.fieldName
					  )
					: false;
				if (gridStyleValue === 'FLAT' || gridStyleValue === 'CONTOUR') {
					const selectedInteriorColorGrid = getQuoteProductContextProductValue(
						product.configurations['WINDOW_GRID'].interiorMuntinColor
							.fieldName
					);
					const selectedInteriorColorGridOption = product.configurations[
						'WINDOW_GRID'
					].interiorMuntinColor.values.find((option) => {
						return option.value === selectedInteriorColorGrid;
					});
					if (
						selectedInteriorColorGridOption &&
						selectedInteriorColorGridOption.exteriorMutinColorOptions.indexOf(
							selectedExteriorColorValue
						) !== -1
					) {
						setQuoteProductContextProductValue(
							product.configurations['WINDOW_GRID'].exteriorMuntinColor
								.fieldName,
							selectedExteriorColorValue
						);
					}
				}
			}
		}
	}, [
		selectedExteriorColorValue,
		product,
		getQuoteProductContextProductValue,
		setQuoteProductContextProductValue,
	]);

	// return the component
	return (
		<div className="productconfiguration">
			<div
				className={`productconfiguration__section ${
					openConfigurationData() === orderIndex
						? 'productconfiguration__section--open'
						: ''
				}`}
			>
				<div className="productconfiguration__section-body">
					{configuration.exteriorTrim ? (
						<>
							<div className="config-heading heading heading--4">
								{configuration.exteriorTrim.heading}
							</div>
							<div className="config-option-select">
								{configuration.exteriorTrim.values.map((option) => {
									return (
										<React.Fragment key={option.value}>
											<input
												type="radio"
												name={
													configuration.exteriorTrim.fieldName
												}
												id={`${configuration.exteriorTrim.fieldName}_${option.value}`}
												value={option.value}
												checked={
													option.value ===
													selectedExteriorTrimValue
												}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${configuration.exteriorTrim.fieldName}_${option.value}`}
												className="config-option-select__option"
											>
												<div className="config-option-select__check"></div>
												<div className="config-option-select__info">
													{ConfigurationLabelMapper(
														option.value
													)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>

							{configuration.trimColor &&
							selectedExteriorTrimOption?.trimColorOptions ? (
								<>
									<div className="productconfiguration__color-secondary">
										<div className="config-subheading heading heading--6">
											{configuration.trimColor.heading}
										</div>
										<div className="config-notch-select">
											{configuration.trimColor.values.map(
												(value) => {
													const isVisible =
														selectedExteriorTrimOption.trimColorOptions.indexOf(
															value
														) !== -1;
													return isVisible ? (
														<React.Fragment key={value}>
															<input
																type="radio"
																name={
																	configuration
																		.trimColor
																		.fieldName
																}
																id={`${configuration.trimColor.fieldName}_${value}`}
																value={value}
																checked={
																	value ===
																	selectedExteriorTrimColorValue
																}
																onClick={(e) => {
																	// if this is the custom value, then open the notes overlay
																	if (
																		e.target.value ===
																		'CUSTOM'
																	) {
																		toggleOverlay({
																			active: true,
																			type: 'product_notes',
																			data: {},
																		});
																	}
																}}
																onChange={(e) => {
																	setQuoteProductContextProductValue(
																		e.target.name,
																		e.target.value
																	);
																}}
															/>
															<label
																htmlFor={`${configuration.trimColor.fieldName}_${value}`}
																className="config-notch-select__option"
															>
																<div className="config-notch-select__button">
																	<span className="config-notch-select__notch"></span>
																	<ImageAsset
																		code={value}
																	/>
																</div>
																<div className="config-notch-select__label heading heading--10">
																	{ConfigurationLabelMapper(
																		value
																	)}
																</div>
															</label>
														</React.Fragment>
													) : (
														''
													);
												}
											)}
										</div>
									</div>
								</>
							) : (
								''
							)}
						</>
					) : (
						''
					)}

					{configuration.exteriorCedarTrim ? (
						<>
							<div className="config-boolean-field">
								<div className="config-heading heading heading--4">
									{configuration.exteriorCedarTrim.heading}
								</div>
								<Input
									type="toggle"
									id={configuration.exteriorCedarTrim.fieldName}
									name={configuration.exteriorCedarTrim.fieldName}
									checked={selectedExteriorCedarTrimValue}
									onChange={(e) => {
										setQuoteProductContextProductValue(
											e.target.name,
											e.target.checked
										);
									}}
								/>
							</div>
							{configuration.exteriorCedarSize &&
							selectedExteriorCedarTrimValue === true ? (
								<div className="productconfiguration__color-secondary">
									<div className="heading heading--6">
										{configuration.exteriorCedarSize.heading}
									</div>
									<div className="config-option-select">
										{configuration.exteriorCedarSize.values.map(
											(value) => {
												return (
													<React.Fragment key={value}>
														<input
															type="radio"
															name={
																configuration
																	.exteriorCedarSize
																	.fieldName
															}
															id={`${configuration.exteriorCedarSize.fieldName}_${value}`}
															value={value}
															checked={
																value ===
																selectedExteriorCedarSizeValue
															}
															onChange={(e) => {
																setQuoteProductContextProductValue(
																	e.target.name,
																	e.target.value
																);
															}}
														/>
														<label
															htmlFor={`${configuration.exteriorCedarSize.fieldName}_${value}`}
															className="config-option-select__option"
														>
															<div className="config-option-select__check"></div>
															<div className="config-option-select__info">
																{ConfigurationLabelMapper(
																	value
																)}
															</div>
														</label>
													</React.Fragment>
												);
											}
										)}
									</div>
								</div>
							) : (
								''
							)}
							{configuration.exteriorCedarDimensions &&
							selectedExteriorCedarTrimValue === true ? (
								<>
									<div className="config-divider"></div>
									<div className="config-heading heading heading--4">
										{configuration.exteriorCedarDimensions.heading}
									</div>
									<label
										htmlFor={
											configuration.exteriorCedarDimensions
												.fieldName
										}
										className="config-subheading heading heading--6"
									>
										Length
									</label>
									<div className="config-number-field">
										<Input
											type={'text'}
											className={'input--1 input--feet'}
											id={
												configuration.exteriorCedarDimensions
													.fieldName
											}
											name={
												configuration.exteriorCedarDimensions
													.fieldName
											}
											value={selectedExteriorCedarDimensionValue}
											onBlur={(e) => {
												let val = parseFloat(e.target.value);
												setQuoteProductContextProductValue(
													e.target.name,
													isNaN(val) ||
														val <
															configuration
																.exteriorCedarDimensions
																.minimumValue
														? configuration
																.exteriorCedarDimensions
																.defaultValue
														: val
												);
											}}
											onChange={(e) => {
												setQuoteProductContextProductValue(
													e.target.name,
													e.target.value
												);
											}}
										/>
									</div>
								</>
							) : (
								''
							)}
						</>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
};
export default ProductConfigurationWindowColors;
