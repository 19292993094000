import React from 'react';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';

export const WindowSeat = ({ groupName, product, quoteProduct }) => (
	<>
		{product.configurations[groupName].type && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Type:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].type.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].type &&
			quoteProduct.values[product.configurations[groupName].type.fieldName] !==
				'NONE' &&
			product.configurations[groupName].color && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Color:</dt>
						<dd className="heading heading--13">
							{ConfigurationLabelMapper(
								quoteProduct.values[
									product.configurations[groupName].color.fieldName
								]
							)}
						</dd>
					</dl>
				</div>
			)}
	</>
);
