import React, { useContext, useState, useEffect, useRef } from 'react';
import UIManagementContext from '../../../contexts/UIManagementContext';
import SessionDataContext from '../../../contexts/SessionDataContext';
import WallsideError from '../../../functionality/WallsideError';
import Btn from '../../../components/Btn/Btn';
import ActionBtn from '../../../components/ActionBtn/ActionBtn';
import Input from '../../../components/Input/Input';
import FieldError from '../../FieldError/FieldError';
import {
	getFormData,
	validateFormDataPromise,
} from '../../../functionality/FormUtilities.js';
import { centsToMoney } from '../../../functionality/Money';
import ProductMapper from '../../../functionality/ProductMapper.js';
import './OverlayPersonalSettings.css';
import { toFixed } from '../../../helpers/Calculations';

const OverlayPersonalSettings = (props) => {
	// setup our contexts
	const { toggleOverlay, toggleSystemNotification } = useContext(UIManagementContext);
	const { setActivityIndicator, activeUser, setUser } = useContext(SessionDataContext);

	// set our refs for the forms
	const detailsFormEl = useRef();
	const pricingFormEl = useRef();
	const resetFormEl = useRef();

	// we need the collection of product codes
	const productCodes = [
		'AWNING_CASEMENT_PROD',
		'BAY_SHELL_PROD',
		'BOW_SHELL_PROD',
		'CENTER_VENT_SLIDER_PROD',
		'DOOR_WALL_PROD',
		'DOOR_WALL_CUSTOM',
		'DOUBLE_CASEMENT_PROD',
		'DOUBLE_HUNG_PROD',
		'DOUBLE_SLIDER_PROD',
		'END_VENT_SLIDER_PROD',
		'GARDEN_PROD',
		'HOPPER_PROD',
		'PICTURE_CASEMENT_PROD',
		'PICTURE_PROD',
		'SINGLE_CASEMENT_PROD',
		'SPECIALTY_PROD',
	];

	// set some state
	const [productMarkupList, setProductMarkupList] = useState([]);
	const [activeTab, setActiveTab] = useState('details'); // details, pricing, reset
	const [formErrors, setFormErrors] = useState({
		// no default errors, so its empty
	});

	// do some work everytime the active tab changes
	useEffect(() => {
		if (activeTab === 'details') {
			detailsFormEl.current.querySelector('#user_firstname').value =
				activeUser.get('firstName');
			detailsFormEl.current.querySelector('#user_lastname').value =
				activeUser.get('lastName');
			detailsFormEl.current.querySelector('#user_email').value =
				activeUser.get('email');
			detailsFormEl.current.querySelector('#user_phone').value =
				activeUser.get('phone');
		} else if (activeTab === 'pricing') {
			pricingFormEl.current.querySelector('#user_pricing').value = (
				activeUser.get('priceMarkupPercentage') * 100
			).toFixed(2);
			const currentProductMarkups = activeUser.get('productPriceMarkupCents');
			setProductMarkupList(
				Object.keys(currentProductMarkups)
					.map((code) => {
						const prod = ProductMapper(code);
						return {
							title: prod.title,
							code: prod.code,
							costInCents: currentProductMarkups[code],
							costInDollars: centsToMoney({
								amount: currentProductMarkups[code],
								symbol: false,
							}),
						};
					})
					.concat([
						{
							title: '',
							code: '',
							costInCents: 0,
							costInDollars: '0',
						},
					])
			);
		} else if (activeTab === 'reset') {
			resetFormEl.current.querySelector('#user_existingpassword').value = '';
			resetFormEl.current.querySelector('#user_newpassword').value = '';
		}
	}, [activeTab, activeUser]);

	// our submission function
	const submitForm = () => {
		// reset the form errors
		setFormErrors({});
		// get the form data
		let isVerifyError = false;
		const formData = getFormData(
			activeTab === 'details'
				? detailsFormEl.current
				: activeTab === 'pricing'
				? pricingFormEl.current
				: resetFormEl.current
		);
		// validate the form data
		validateFormDataPromise(formData).then(
			() => {
				setActivityIndicator(true);
				Promise.resolve()
					.then(() => {
						if (activeTab === 'details') {
							// update the active user
							activeUser.set('firstName', formData.user_firstname);
							activeUser.set('lastName', formData.user_lastname);
							activeUser.set('email', formData.user_email);
							activeUser.set('phone', formData.user_phone);
							return activeUser.save();
						} else if (activeTab === 'pricing') {
							activeUser.set(
								'priceMarkupPercentage',
								formData.user_pricing / 100
							);
							const productPriceMarkups = {};
							productMarkupList.forEach((entry) => {
								if (entry.code !== '' && entry.costInCents !== 0) {
									productPriceMarkups[entry.code] = entry.costInCents;
								}
							});
							activeUser.set(
								'productPriceMarkupCents',
								productPriceMarkups
							);
							return activeUser.save();
						} else if (activeTab === 'reset') {
							return activeUser
								.verifyPassword(formData.user_existingpassword)
								.then(
									() => {
										activeUser.set(
											'password',
											formData.user_newpassword
										);
										return activeUser.save();
									},
									(e) => {
										isVerifyError = true;
										return Promise.reject(e);
									}
								);
						}
					})
					.then(
						(user) => {
							// remove thee activity indicator
							setActivityIndicator(false);
							// if this is the password form, lets reset it
							if (activeTab === 'reset') {
								resetFormEl.current.reset();
							}
							// set the active user
							setUser(user);
							// lets set the notification
							toggleSystemNotification({
								active: true,
								messageKey: 'save_user',
							});
							// now we close the overlay
							toggleOverlay({ active: false });
						},
						(e) => {
							// remove the activity indicator
							setActivityIndicator(false);
							// revert the fields that were changed
							if (activeTab === 'details') {
								activeUser.revert(
									'firstName',
									'lastName',
									'email',
									'phone'
								);
							} else if (activeTab === 'pricing') {
								activeUser.revert(
									'priceMarkupPercentage',
									'productPriceMarkupCents'
								);
							} else if (activeTab === 'reset') {
								if (!isVerifyError) {
									activeUser.revert('password');
								}
							}

							// set the error messages
							const error = new WallsideError(e);
							if (Object.keys(error.fieldErrors).length > 0) {
								const newErrors = {};
								const keyMappings = {
									firstName: 'user_firstname',
									lastName: 'user_lastname',
									email: 'user_email',
									phone: 'user_phone',
									priceMarkupPercentage: 'user_pricing',
									password: 'user_newpassword',
								};
								for (let key in error.fieldErrors) {
									newErrors[keyMappings[key]] = [
										error.fieldErrors[key][0].message,
									];
								}
								setFormErrors(newErrors);
							} else {
								if (isVerifyError) {
									setFormErrors({
										user_existingpassword: [
											'Your password is incorrect.',
										],
									});
								} else {
									alert(error.globalErrors[0].message);
								}
							}
						}
					);
			},
			(errors) => {
				// the form data didn't validate, so let set the form Errors
				setFormErrors(errors);
			}
		);
	};

	const hideOverlay = () => {
		toggleOverlay({ active: false });
	};

	// return the jsx component
	return (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s2">
						<div className="overlay-s2__heading">
							<h1 className="heading heading--4">Personal Settings</h1>
							<button
								type="button"
								className="icon-far icon icon--close"
								onClick={hideOverlay}
							/>
						</div>
						<div className="overlay-s2__body">
							<div className="overlay-s2__body-col">
								<button
									type="button"
									className={`overlay-s2__navigation-item ${
										activeTab === 'details' &&
										'overlay-s2__navigation-item--active'
									}`}
									onClick={() => {
										setActiveTab('details');
									}}
								>
									Account Details
								</button>
								<button
									type="button"
									className={`overlay-s2__navigation-item ${
										activeTab === 'pricing' &&
										'overlay-s2__navigation-item--active'
									}`}
									onClick={() => {
										setActiveTab('pricing');
									}}
								>
									Pricing
								</button>
								<button
									type="button"
									className={`overlay-s2__navigation-item ${
										activeTab === 'reset' &&
										'overlay-s2__navigation-item--active'
									}`}
									onClick={() => {
										setActiveTab('reset');
									}}
								>
									Reset Password
								</button>
							</div>
							<div className="overlay-s2__body-col overlay-s2__body-col--content">
								<div className="personalsettingsoverlay">
									{activeTab === 'details' ? (
										<form
											ref={detailsFormEl}
											onSubmit={(evt) => {
												evt.preventDefault();
											}}
										>
											<h2 className="overlay-s2__body-title">
												Account Details
											</h2>
											<div className="overlay-s2__field-entry">
												<label
													htmlFor="user_firstname"
													className="heading heading--form-element-2"
												>
													First Name
												</label>
												<Input
													type="text"
													id="user_firstname"
													name="user_firstname"
													className={'input--1'}
													inputModifier={
														formErrors.user_firstname &&
														'input--error'
													}
												/>
												{formErrors.user_firstname ? (
													<FieldError>
														{formErrors.user_firstname[0]}
													</FieldError>
												) : (
													''
												)}
											</div>
											<div className="overlay-s2__field-entry">
												<label
													htmlFor="user_lastname"
													className="heading heading--form-element-2"
												>
													Last Name
												</label>
												<Input
													type="text"
													id="user_lastname"
													name="user_lastname"
													className={'input--1'}
													inputModifier={
														formErrors.user_lastname &&
														'input--error'
													}
												/>
												{formErrors.user_lastname ? (
													<FieldError>
														{formErrors.user_lastname[0]}
													</FieldError>
												) : (
													''
												)}
											</div>
											<div className="overlay-s2__field-entry">
												<label
													htmlFor="user_email"
													className="heading heading--form-element-2"
												>
													Email
												</label>
												<Input
													type="text"
													id="user_email"
													name="user_email"
													className={'input--1'}
													inputModifier={
														formErrors.user_email &&
														'input--error'
													}
												/>
												{formErrors.user_email ? (
													<FieldError>
														{formErrors.user_email[0]}
													</FieldError>
												) : (
													''
												)}
											</div>
											<div className="overlay-s2__field-entry">
												<label
													htmlFor="user_phone"
													className="heading heading--form-element-2"
												>
													Phone
												</label>
												<Input
													type="text"
													id="user_phone"
													name="user_phone"
													className={'input--1'}
													inputModifier={
														formErrors.user_phone &&
														'input--error'
													}
												/>
												{formErrors.user_phone ? (
													<FieldError>
														{formErrors.user_phone[0]}
													</FieldError>
												) : (
													''
												)}
											</div>
										</form>
									) : activeTab === 'pricing' ? (
										<form
											ref={pricingFormEl}
											onSubmit={(evt) => {
												evt.preventDefault();
											}}
										>
											<h2 className="overlay-s2__body-title">
												Pricing Markup
											</h2>
											<div className="overlay-s2__field-entry">
												<label
													htmlFor="user_pricing"
													className="heading heading--form-element-2"
												>
													Amount
												</label>
												<Input
													type="text"
													id="user_pricing"
													name="user_pricing"
													className={'input--1'}
													inputModifier={`input--percent ${
														formErrors.user_pricing &&
														'input--error'
													}`}
													onBlur={(evt) => {
														let value = parseFloat(
															evt.currentTarget.value
														);
														if (isNaN(value)) {
															evt.currentTarget.value =
																activeUser
																	.get(
																		'priceMarkupPercentage'
																	)
																	.toFixed(2);
														} else {
															value = Math.min(
																100,
																Math.max(0, value)
															);
															evt.currentTarget.value =
																toFixed(value, 100);
														}
													}}
												/>
												{formErrors.user_pricing ? (
													<FieldError>
														{formErrors.user_pricing[0]}
													</FieldError>
												) : (
													''
												)}
											</div>
											<p className="overlay-s2__body-note">
												Note: The pricing of all products and misc
												items within a new quote will be increased
												by the percentage amount in the markup
												field.
											</p>
											<div className="config-divider"></div>
											<h2 className="overlay-s2__body-title">
												Product Markup
											</h2>
											<div className="overlay-s2__field-entry">
												<div className="personalsettingsoverlay_pplist">
													{productMarkupList.length > 0 && (
														<div className="personalsettingsoverlay_pplist-entry personalsettingsoverlay_pplist-entry--labels">
															<div className="personalsettingsoverlay_pplist-group">
																<label
																	htmlFor="user_pricing"
																	className="heading heading--form-element-2"
																>
																	Product
																</label>
															</div>
															<div className="personalsettingsoverlay_pplist-group">
																<label
																	htmlFor="user_pricing"
																	className="heading heading--form-element-2"
																>
																	Amount
																</label>
															</div>
														</div>
													)}
													{productMarkupList.map((entry, i) => {
														return (
															<div
																key={i}
																className="personalsettingsoverlay_pplist-entry"
															>
																<div className="personalsettingsoverlay_pplist-group">
																	<Input
																		type="select"
																		id={`product_name+${i}`}
																		name={`product_name+${i}`}
																		className={
																			'input--1'
																		}
																		inputModifier={
																			'input--select'
																		}
																		values={[
																			{
																				label: 'Select ...',
																				value: '',
																			},
																		].concat(
																			productCodes.map(
																				function (
																					code
																				) {
																					const prod =
																						ProductMapper(
																							code
																						);
																					return {
																						label: prod.title,
																						value: prod.code,
																					};
																				}
																			)
																		)}
																		value={entry.code}
																		onChange={(e) => {
																			const v =
																				e.target
																					.value;
																			setProductMarkupList(
																				(
																					prev
																				) => {
																					const updated =
																						prev.concat(
																							[]
																						);
																					updated[
																						i
																					].code =
																						v;
																					return updated;
																				}
																			);
																		}}
																	/>
																</div>
																<div className="personalsettingsoverlay_pplist-group">
																	<Input
																		type={'text'}
																		id={`product_cost+${i}`}
																		name={`product_cost+${i}`}
																		className={
																			'input--1 input--price'
																		}
																		placeholder="markup..."
																		value={
																			entry.costInDollars ===
																			'0'
																				? ''
																				: entry.costInDollars
																		}
																		onBlur={(e) => {
																			const valueDigitsPlusDecimal =
																				e.target.value.replace(
																					/[^0-9.]/g,
																					''
																				);
																			const dollars =
																				toFixed(
																					valueDigitsPlusDecimal,
																					100
																				);

																			const cents =
																				isNaN(
																					dollars
																				)
																					? 0
																					: parseInt(
																							dollars *
																								100
																					  );
																			setProductMarkupList(
																				(
																					prev
																				) => {
																					const updated =
																						prev.concat(
																							[]
																						);
																					updated[
																						i
																					].costInCents =
																						cents;
																					updated[
																						i
																					].costInDollars =
																						centsToMoney(
																							{
																								amount: cents,
																								symbol: false,
																							}
																						);
																					return updated;
																				}
																			);
																		}}
																		onChange={(e) => {
																			const v =
																				e.target
																					.value;
																			setProductMarkupList(
																				(
																					prev
																				) => {
																					const updated =
																						prev.concat(
																							[]
																						);
																					updated[
																						i
																					].costInDollars =
																						v;
																					return updated;
																				}
																			);
																		}}
																	/>
																	<ActionBtn
																		actionBtnClass="actionbtn--negative-outline"
																		actionBtnType="icon--x"
																		onClick={() => {
																			setProductMarkupList(
																				(
																					prev
																				) => {
																					const updatedArr =
																						prev.concat(
																							[]
																						);
																					updatedArr.splice(
																						i,
																						1
																					);
																					return updatedArr;
																				}
																			);
																		}}
																	/>
																</div>
															</div>
														);
													})}
												</div>
												<Btn
													btnType={'btn--text'}
													onClick={() => {
														setProductMarkupList((prev) => {
															return prev.concat([
																{
																	title: '',
																	code: '',
																	costInCents: 0,
																	costInDollars: '0',
																},
															]);
														});
													}}
												>
													Add new Product Price Markup
												</Btn>
											</div>
										</form>
									) : (
										<form
											ref={resetFormEl}
											onSubmit={(evt) => {
												evt.preventDefault();
											}}
										>
											<h2 className="overlay-s2__body-title">
												Reset Password
											</h2>
											<div className="overlay-s2__field-entry">
												<label
													htmlFor="user_existingpassword"
													className="heading heading--form-element-2"
												>
													Current Password
												</label>
												<Input
													type="password"
													id="user_existingpassword"
													name="user_existingpassword"
													className={'input--1'}
													inputModifier={
														formErrors.user_existingpassword &&
														'input--error'
													}
												/>
												{formErrors.user_existingpassword ? (
													<FieldError>
														{
															formErrors
																.user_existingpassword[0]
														}
													</FieldError>
												) : (
													''
												)}
											</div>
											<div className="overlay-s2__field-entry">
												<label
													htmlFor="user_newpassword"
													className="heading heading--form-element-2"
												>
													New Password
												</label>
												<Input
													type="password"
													id="user_newpassword"
													name="user_newpassword"
													className={'input--1'}
													inputModifier={
														formErrors.user_newpassword &&
														'input--error'
													}
												/>
												{formErrors.user_newpassword ? (
													<FieldError>
														{formErrors.user_newpassword[0]}
													</FieldError>
												) : (
													''
												)}
											</div>
										</form>
									)}
								</div>
							</div>
						</div>
						<div className="overlay-s2__btns">
							<Btn btnType={'btn--secondary'} onClick={hideOverlay}>
								Cancel
							</Btn>
							<Btn btnType={'btn--primary'} onClick={submitForm}>
								Update
							</Btn>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayPersonalSettings;
