import React, { useEffect, useRef, useContext, useCallback } from 'react';
import CurrentQuoteProductContext from '../../../../contexts/CurrentQuoteProductContext';
import ConfigurationLabelMapper from '../../../../functionality/ConfigurationLabelMapper';
import UIManagementContext from '../../../../contexts/UIManagementContext';
import OpenConfigurationButton from './OpenConfigurationButton';
import ConfigurationInteriorColorInput from './ConfigurationInteriorColorInput';
import ExteriorColorsButton from './ExteriorColorsButton';
import ExteriorTrimButton from './ExteriorTrimButton/ExteriorTrimButton';
import ExteriorCedarTrimButton from './ExteriorCedarTrimButton';

const ProductConfigurationWindowColor = ({ configuration, orderIndex }) => {
	// the ui management context
	const { openConfigurationData } = useContext(UIManagementContext);
	const {
		exteriorCedarDimensions,
		exteriorCedarSize,
		exteriorCedarTrim,
		exteriorColor,
		exteriorTrim,
		interiorColor,
		trimColor,
	} = configuration;

	// product context
	const {
		product,
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
		setPreviousQuoteProductContextValue,
	} = useContext(CurrentQuoteProductContext);

	// some selected values and options
	const selectedInteriorColorValue = interiorColor
		? getQuoteProductContextProductValue(interiorColor.fieldName)
		: false;

	const selectedInteriorColorOption = interiorColor?.values?.find(
		(option) => option.value === selectedInteriorColorValue
	);

	const selectedExteriorColorValue = exteriorColor
		? getQuoteProductContextProductValue(exteriorColor.fieldName)
		: false;

	const selectedExteriorTrimValue = exteriorTrim
		? getQuoteProductContextProductValue(exteriorTrim.fieldName)
		: 'NONE';

	const selectedExteriorTrimOption = exteriorTrim?.values?.find(
		(option) => option.value === selectedExteriorTrimValue
	);

	const selectedExteriorTrimColorValue = trimColor
		? getQuoteProductContextProductValue(trimColor.fieldName)
		: false;

	const selectedExteriorCedarTrimValue = exteriorCedarTrim
		? getQuoteProductContextProductValue(exteriorCedarTrim.fieldName)
		: false;

	const selectedExteriorCedarSizeValue = exteriorCedarSize
		? getQuoteProductContextProductValue(exteriorCedarSize.fieldName)
		: false;

	const selectedExteriorCedarDimensionValue = exteriorCedarDimensions
		? getQuoteProductContextProductValue(exteriorCedarDimensions.fieldName)
		: 0;

	// Set to use the previous value
	const setPreviousContextValue = useCallback(async () => {
		if (interiorColor?.fieldName) {
			await setPreviousQuoteProductContextValue(interiorColor?.fieldName);
		}

		if (exteriorColor?.fieldName) {
			await setPreviousQuoteProductContextValue(exteriorColor?.fieldName);
		}

		if (exteriorTrim?.fieldName) {
			await setPreviousQuoteProductContextValue(exteriorTrim?.fieldName);
		}

		if (exteriorCedarTrim?.fieldName) {
			await setPreviousQuoteProductContextValue(exteriorCedarTrim?.fieldName);
		}

		if (trimColor?.fieldName) {
			await setPreviousQuoteProductContextValue(trimColor?.fieldName);
		}

		if (exteriorCedarSize?.fieldName) {
			await setPreviousQuoteProductContextValue(exteriorCedarSize?.fieldName);
		}

		if (exteriorCedarDimensions?.fieldName) {
			await setPreviousQuoteProductContextValue(exteriorCedarDimensions?.fieldName);
		}
	}, [configuration, setPreviousQuoteProductContextValue]);

	useEffect(() => {
		setPreviousContextValue();
	}, [setPreviousContextValue]);

	// create the section details
	const createSectionHeadingDetails = () => {
		let text = '';

		// go through window color
		if (interiorColor || exteriorColor) {
			text += product.type === 'window_shell' ? 'Shell: ' : 'Window: ';

			if (interiorColor) {
				text += ConfigurationLabelMapper(
					getQuoteProductContextProductValue(interiorColor.fieldName)
				);
			}

			if (exteriorColor) {
				text +=
					(interiorColor ? '/' : '') +
					ConfigurationLabelMapper(
						getQuoteProductContextProductValue(exteriorColor.fieldName)
					);
			}
		}

		// go through the trim values
		if (exteriorTrim) {
			text +=
				(text !== '' ? ' | ' : '') +
				'Trim: ' +
				ConfigurationLabelMapper(selectedExteriorTrimValue);

			if (selectedExteriorTrimColorValue) {
				text += ' - ' + ConfigurationLabelMapper(selectedExteriorTrimColorValue);
			}
		}

		// return completed text
		return text;
	};

	// we have to make sure the color values are appropriate
	useEffect(() => {
		// make sur the exterior color is available for the interior color selected
		if (selectedExteriorColorValue !== false && selectedInteriorColorOption) {
			if (
				selectedInteriorColorOption?.exteriorColorOptions.indexOf(
					selectedExteriorColorValue
				) === -1
			) {
				setQuoteProductContextProductValue(
					exteriorColor.fieldName,
					selectedInteriorColorOption.defaultExteriorColor
				);
			}
		}
		// make sure the exterior trim color value work for the selected exterior trim
		if (selectedExteriorTrimOption) {
			// check the trim color
			if (
				selectedExteriorTrimOption?.trimColorOptions === false &&
				selectedExteriorTrimColorValue !== false
			) {
				setQuoteProductContextProductValue(trimColor.fieldName, false);
			} else if (
				selectedExteriorTrimOption?.trimColorOptions !== false &&
				selectedExteriorTrimOption.trimColorOptions.indexOf(
					selectedExteriorTrimColorValue
				) === -1
			) {
				setQuoteProductContextProductValue(
					trimColor.fieldName,
					selectedExteriorTrimOption.trimColorDefault
				);
			}
		}

		// check the cedar size
		if (
			selectedExteriorCedarTrimValue === false &&
			exteriorCedarSize &&
			selectedExteriorCedarSizeValue !== false
		) {
			setQuoteProductContextProductValue(exteriorCedarSize.fieldName, false);
		} else if (
			selectedExteriorCedarTrimValue !== false &&
			exteriorCedarSize &&
			exteriorCedarSize.values.indexOf(selectedExteriorCedarSizeValue) === -1
		) {
			setQuoteProductContextProductValue(
				exteriorCedarSize.fieldName,
				exteriorCedarSize.defaultValue
			);

			setQuoteProductContextProductValue(
				exteriorCedarDimensions.fieldName,
				exteriorCedarDimensions.defaultValue
			);
		}

		// check the cedar dimensions
		if (
			selectedExteriorCedarTrimValue === false &&
			selectedExteriorCedarDimensionValue !== 0
		) {
			setQuoteProductContextProductValue(exteriorCedarDimensions.fieldName, 0);
		}
	}, [
		selectedInteriorColorValue,
		selectedInteriorColorOption,
		selectedExteriorColorValue,
		selectedExteriorTrimOption,
		selectedExteriorTrimColorValue,
		exteriorColor,
		trimColor,
		exteriorCedarSize,
		exteriorCedarDimensions,
		selectedExteriorCedarDimensionValue,
		selectedExteriorCedarTrimValue,
		selectedExteriorCedarSizeValue,
		setQuoteProductContextProductValue,
	]);

	// we'll use a reference to monitor for the first run
	// this allows us to not update parts on init
	// while the useRef, doesn't cause a re-render
	const isFirstRender = useRef(true);

	// we have some other relationships with other fields
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
		} else {
			if (product.configurations['WINDOW_GRID']) {
				const gridStyleValue = product.configurations['WINDOW_GRID'].style
					? getQuoteProductContextProductValue(
							product.configurations['WINDOW_GRID'].style.fieldName
					  )
					: false;
				if (gridStyleValue === 'FLAT' || gridStyleValue === 'CONTOUR') {
					const gridStyleOption = product.configurations[
						'WINDOW_GRID'
					].style.values.find((option) => {
						return option.value === gridStyleValue;
					});
					if (
						gridStyleOption &&
						gridStyleOption.interiorMutinColorOptions.indexOf(
							selectedInteriorColorValue
						) !== -1
					) {
						setQuoteProductContextProductValue(
							product.configurations['WINDOW_GRID'].interiorMuntinColor
								.fieldName,
							selectedInteriorColorValue
						);
					}
				}
			}
		}
	}, [
		selectedInteriorColorValue,
		selectedInteriorColorOption,
		product,
		getQuoteProductContextProductValue,
		setQuoteProductContextProductValue,
	]);

	// we have some other relationships with other fields
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
		} else {
			if (product.configurations['WINDOW_GRID']) {
				const gridStyleValue = product.configurations['WINDOW_GRID'].style
					? getQuoteProductContextProductValue(
							product.configurations['WINDOW_GRID'].style.fieldName
					  )
					: false;
				if (gridStyleValue === 'FLAT' || gridStyleValue === 'CONTOUR') {
					const selectedInteriorColorGrid = getQuoteProductContextProductValue(
						product.configurations['WINDOW_GRID'].interiorMuntinColor
							.fieldName
					);
					const selectedInteriorColorGridOption = product.configurations[
						'WINDOW_GRID'
					].interiorMuntinColor.values.find((option) => {
						return option.value === selectedInteriorColorGrid;
					});
					if (
						selectedInteriorColorGridOption &&
						selectedInteriorColorGridOption.exteriorMutinColorOptions.indexOf(
							selectedExteriorColorValue
						) !== -1
					) {
						setQuoteProductContextProductValue(
							product.configurations['WINDOW_GRID'].exteriorMuntinColor
								.fieldName,
							selectedExteriorColorValue
						);
					}
				}
			}
		}
	}, [
		selectedExteriorColorValue,
		product,
		getQuoteProductContextProductValue,
		setQuoteProductContextProductValue,
	]);

	// return the component
	return (
		<div className="productconfiguration">
			<div
				className={`productconfiguration__section ${
					openConfigurationData() === orderIndex
						? 'productconfiguration__section--open'
						: ''
				}`}
			>
				<OpenConfigurationButton
					configuration={configuration}
					createSectionHeadingDetails={createSectionHeadingDetails}
					orderIndex={orderIndex}
					selectedExteriorColorValue={selectedExteriorColorValue}
					selectedExteriorTrimColorValue={selectedExteriorTrimColorValue}
					selectedInteriorColorValue={selectedInteriorColorValue}
				/>
				<div className="productconfiguration__section-body">
					<ConfigurationInteriorColorInput
						configuration={configuration}
						selectedInteriorColorValue={selectedInteriorColorValue}
						setQuoteProductContextProductValue={
							setQuoteProductContextProductValue
						}
					/>

					<ExteriorColorsButton
						configuration={configuration}
						selectedExteriorColorValue={selectedExteriorColorValue}
						selectedInteriorColorValue={selectedInteriorColorValue}
					/>

					<ExteriorTrimButton
						configuration={configuration}
						selectedExteriorTrimColorValue={selectedExteriorTrimColorValue}
						selectedExteriorTrimOption={selectedExteriorTrimOption}
						selectedExteriorTrimValue={selectedExteriorTrimValue}
					/>

					<ExteriorCedarTrimButton
						configuration={configuration}
						selectedExteriorCedarDimensionValue={
							selectedExteriorCedarDimensionValue
						}
						selectedExteriorCedarSizeValue={selectedExteriorCedarSizeValue}
						selectedExteriorCedarTrimValue={selectedExteriorCedarTrimValue}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductConfigurationWindowColor;
