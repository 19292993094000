import React, { useContext } from 'react';
import UIManagementContext from '../../../contexts/UIManagementContext';
import Btn from '../../../components/Btn/Btn';
import { useCustomWindowContext } from '../../../containers/CustomWindow/customWindowContext';
import { customWindowResetAction } from '../../../containers/CustomWindow/Actions';

const OverlayConfirmResetting = (_props) => {
	const { toggleOverlay, toggleCustomWindowOpenConfiguration } =
		useContext(UIManagementContext);
	const { customWindowDispatch } = useCustomWindowContext();

	// handle add to quote
	const handleResetClick = () => {
		customWindowDispatch(customWindowResetAction());
		toggleOverlay({ active: false });
		toggleCustomWindowOpenConfiguration(1);
	};

	const hideOverlay = () => {
		toggleOverlay({ active: false });
	};

	// return the component
	return (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s1">
						<div className="overlay-s1__heading">
							<h1 className="heading heading--4">Warning</h1>
						</div>
						<div className="overlay-s1__body overlay-s1__body--message">
							<p className="heading heading--5">
								This will reset all choices. You will lose everything.
							</p>
						</div>
						<div className="overlay-s1__btns">
							<Btn btnType={'btn--secondary'} onClick={hideOverlay}>
								Cancel
							</Btn>
							<Btn
								btnType={'btn--primary'}
								onClick={() => {
									handleResetClick();
								}}
							>
								Reset
							</Btn>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayConfirmResetting;
