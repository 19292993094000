import React, { Fragment, useContext } from 'react';
import ConfigurationLabelMapper from '../../../../../functionality/ConfigurationLabelMapper';
import ImageAsset from '../../../../ImageAsset/ImageAsset';
import UIManagementContext from '../../../../../contexts/UIManagementContext';

const ExteriorTrimColorInput = ({
	configuration,
	selectedExteriorTrimColorValue,
	setQuoteProductContextProductValue,
	value,
}) => {
	const { toggleOverlay } = useContext(UIManagementContext);

	// If this is the custom value, then open the notes overlay
	const handleOnClick = ({ target }) => {
		if (target.value === 'CUSTOM') {
			toggleOverlay({
				active: true,
				type: 'product_notes',
				data: {},
			});
		}
	};

	const handleOnChange = ({ target }) => {
		setQuoteProductContextProductValue(target.name, target.value);
	};

	return (
		<Fragment>
			<input
				type="radio"
				name={configuration.trimColor.fieldName}
				id={`${configuration.trimColor.fieldName}_${value}`}
				value={value}
				checked={value === selectedExteriorTrimColorValue}
				onClick={handleOnClick}
				onChange={handleOnChange}
			/>
			<label
				htmlFor={`${configuration.trimColor.fieldName}_${value}`}
				className="config-notch-select__option"
			>
				<div className="config-notch-select__button">
					<span className="config-notch-select__notch"></span>
					<ImageAsset code={value} />
				</div>
				<div className="config-notch-select__label heading heading--10">
					{ConfigurationLabelMapper(value)}
				</div>
			</label>
		</Fragment>
	);
};

export default ExteriorTrimColorInput;
