import React from 'react';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';

export const WindowColor = ({ booleanText, groupName, product, quoteProduct }) => (
	<>
		{product.configurations[groupName].interiorColor && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Interior Color:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].interiorColor.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].exteriorColor && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Exterior Color:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].exteriorColor.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].exteriorTrim && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Exterior Trim:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].exteriorTrim.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].trimColor && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Trim Color:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].trimColor.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].exteriorCedarTrim &&
			quoteProduct.values[
				product.configurations[groupName].exteriorCedarTrim.fieldName
			] && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Cedar Trim:</dt>
						<dd className="heading heading--13">
							{booleanText(
								quoteProduct.values[
									product.configurations[groupName].exteriorCedarTrim
										.fieldName
								]
							)}
						</dd>
					</dl>
				</div>
			)}
		{product.configurations[groupName].exteriorCedarSize &&
			quoteProduct.values[
				product.configurations[groupName].exteriorCedarTrim.fieldName
			] && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Cedar Trim Size:</dt>
						<dd className="heading heading--13">
							{ConfigurationLabelMapper(
								quoteProduct.values[
									product.configurations[groupName].exteriorCedarSize
										.fieldName
								]
							)}
						</dd>
					</dl>
				</div>
			)}
		{product.configurations[groupName].exteriorCedarDimensions &&
			quoteProduct.values[
				product.configurations[groupName].exteriorCedarTrim.fieldName
			] && (
				<div className="quotelistviewlist__lineitem">
					<dl>
						<dt className="heading heading--12">Cedar Trim Dimensions:</dt>
						<dd className="heading heading--13">
							{ConfigurationLabelMapper(
								quoteProduct.values[
									product.configurations[groupName]
										.exteriorCedarDimensions.fieldName
								]
							)}{' '}
							ft.
						</dd>
					</dl>
				</div>
			)}
	</>
);
