import React from 'react';

/**
 * Moved some logic from our monolithic OverlayGlobalChanges component to here.
 **/
export const SidebarButtonGroup = ({ activeTab, setActiveTab }) => {
	return (
		<div className="overlay-s2__body-col">
			<button
				type="button"
				className={`overlay-s2__navigation-item ${
					activeTab === 'color' && 'overlay-s2__navigation-item--active'
				}`}
				onClick={() => {
					setActiveTab('color');
				}}
			>
				Color
			</button>
			<button
				type="button"
				className={`overlay-s2__navigation-item ${
					activeTab === 'grid' && 'overlay-s2__navigation-item--active'
				}`}
				onClick={() => {
					setActiveTab('grid');
				}}
			>
				Grid
			</button>
			<button
				type="button"
				className={`overlay-s2__navigation-item ${
					activeTab === 'glass' && 'overlay-s2__navigation-item--active'
				}`}
				onClick={() => {
					setActiveTab('glass');
				}}
			>
				Glass
			</button>

			<button
				type="button"
				className={`overlay-s2__navigation-item ${
					activeTab === 'screen' && 'overlay-s2__navigation-item--active'
				}`}
				onClick={() => {
					setActiveTab('screen');
				}}
			>
				Screen
			</button>
			<button
				type="button"
				className={`overlay-s2__navigation-item ${
					activeTab === 'sill' && 'overlay-s2__navigation-item--active'
				}`}
				onClick={() => {
					setActiveTab('sill');
				}}
			>
				Sill
			</button>
		</div>
	);
};
