import React from 'react';
import useElementSize from '../../hooks/useElementSize';

const getCollapseStyle = (collapse, maxHeight, height) => {
	return {
		height: collapse ? `${height}` : `${maxHeight}`,
		overflow: collapse ? `hidden` : 'initial',
		// transition: 'all .5s ease',
	};
};

const QuoteProductItemCollapse = (props) => {
	const { children, collapse, height } = props;
	const [collapseRef, { initialHeight: initialHeight }] = useElementSize();

	const maxHeight = initialHeight > 0 ? `${initialHeight}px` : 'auto';

	return (
		<div ref={collapseRef} style={getCollapseStyle(collapse, maxHeight, height)}>
			{children}
		</div>
	);
};

export default QuoteProductItemCollapse;
