import ConfigurationLabelMapper from '../../functionality/ConfigurationLabelMapper';
import ImageAsset from '../ImageAsset/ImageAsset';
import ProductMapper from '../../functionality/ProductMapper';
import { toFixed } from '../../helpers/Calculations';
import RenderProductDescriptionFragment from './RenderProductDescriptionFragment';
import RenderProductSectionFragment from './RenderProductSectionFragment';
import './QuoteDetailProductList.css';

// Index as the position in the quoteproduct's values.additional_items array
const MultiUnitProductRowItem = ({ index, quoteProduct }) => {
	const product = ProductMapper(quoteProduct.code);

	return (
		<tr
			className="multiunitproductlist__row"
			style={{ marginBottom: 0, marginTop: 0, width: '100%' }}
		>
			<td>
				<div className="heading heading--8 quotedetailproductlist__number">
					{index}
				</div>
				<div className="quotedetailproductlist__header">
					<div className="quotedetailproductlist__image">
						<ImageAsset
							code={
								product.code === 'DOOR_WALL_CUSTOM'
									? 'DOOR_WALL_PROD'
									: product.code
							}
						/>
					</div>
					<div className="quotedetailproductlist__overview">
						<div>
							<div className="heading heading--6">{product.title}</div>
							<div>Custom Multi-Unit</div>
						</div>
						<div className="quotedetailproductlist__actions"></div>
					</div>
				</div>
			</td>
			<td>
				<dl className="quotedetailproductlist__configuration">
					{product.configurationGroups &&
						product.configurationGroups.group2 &&
						product.configurationGroups.group2.map((configurationKey) => (
							<RenderProductSectionFragment
								configurationKey={configurationKey}
								key={configurationKey}
								product={product}
								quoteProduct={quoteProduct}
							/>
						))}
					{product.configurationGroups &&
						product.configurationGroups.group1 &&
						product.configurationGroups.group1.map((configurationKey) => (
							<RenderProductDescriptionFragment
								configurationKey={configurationKey}
								key={configurationKey}
								product={product}
								quoteProduct={quoteProduct}
							/>
						))}
				</dl>
			</td>
			<td>
				{product.configurations['WINDOW_SIZE'] && (
					<div className="quotedetailproductlist__spec-wrapper">
						<div className="heading heading--5">
							{
								quoteProduct.values[
									product.configurations['WINDOW_SIZE'].fieldNameWidth
								]
							}
							&quot; X{' '}
							{
								quoteProduct.values[
									product.configurations['WINDOW_SIZE'].fieldNameHeight
								]
							}
							&quot;
						</div>
						<div className="heading heading--6">
							{toFixed(
								quoteProduct.values[
									product.configurations['WINDOW_SIZE'].fieldNameWidth
								] +
									quoteProduct.values[
										product.configurations['WINDOW_SIZE']
											.fieldNameHeight
									],
								1000
							)}{' '}
							UI
						</div>
					</div>
				)}
			</td>
			<td>
				{product.configurations['HOUSE_TYPE'] && (
					<div className="quotedetailproductlist__spec-wrapper">
						<div className="heading heading--5">
							{ConfigurationLabelMapper(
								quoteProduct.values[
									product.configurations['HOUSE_TYPE'].fieldName
								]
							)}
						</div>
					</div>
				)}
			</td>
			<td>
				{product.configurations['REMOVAL_TYPE'] && (
					<div className="quotedetailproductlist__spec-wrapper">
						<div className="heading heading--5">
							{ConfigurationLabelMapper(
								quoteProduct.values[
									product.configurations['REMOVAL_TYPE'].fieldName
								]
							)}
						</div>
					</div>
				)}
			</td>
		</tr>
	);
};

export default MultiUnitProductRowItem;
