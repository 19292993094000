import React, { useContext, useEffect, useState, useRef } from 'react';
import Layout1 from '../../layouts/Layout1/Layout1';
import SessionDataContext from '../../contexts/SessionDataContext';
import CurrentQuoteProductContext from '../../contexts/CurrentQuoteProductContext';
import ProductConfigurationNavBar from '../../components/NavBars/ProductConfigurationNavBar/ProductConfigurationNavBar';
import ProductConfigurationSideBar from '../../components/ProductConfigurationSideBar/ProductConfigurationSideBar';
import ProductConfigurations from '../../components/ProductConfigurations/ProductConfigurations';
import UIManagementContext from '../../contexts/UIManagementContext';
import WallsideError from '../../functionality/WallsideError';

const ProductConfigurationRoute = (props) => {
	// the contexts
	const { toggleOpenConfiguration } = useContext(UIManagementContext);
	const { loadQuote, activeQuote } = useContext(SessionDataContext);
	const { product, quoteProductReset } = useContext(CurrentQuoteProductContext);

	// use some state
	const [ready, setReady] = useState(false);

	// we only want our effect to trigger 1 time, so we'll use a ref
	const isFirstTime = useRef(true);

	// we need to fetch the correct quote for this account
	useEffect(
		function () {
			if (isFirstTime.current) {
				// turn off first time
				isFirstTime.current = false;
				// load the quote and setup
				loadQuote(props.match.params.quoteId)
					.then(() => {
						// we need to determin if this is an existing product or a new product
						const urlParams = new URLSearchParams(props.location.search);
						const isNew = urlParams.has('code')
							? urlParams.get('code').trim() !== '' &&
							  props.match.params.productId === '-1'
							: false;
						let productCode = isNew ? urlParams.get('code').trim() : false;
						let productValues = {};
						let productNotes = '';

						const products = activeQuote.get('products');

						// if this isn't a new one, then we need to
						// find the data in the current quote
						if (!isNew) {
							let index = -1;
							for (let i = 0; i < products.length; i++) {
								if (products[i].id === props.match.params.productId) {
									index = i;
									break;
								}
							}
							// if we couldn't find this product, throw error
							if (index === -1) {
								return Promise.reject(
									new WallsideError({ message: 'Product not found.' })
								);
							}
							// the index exists so grab the product, and update
							const quoteProduct = {
								...products[index],
							};

							productCode = quoteProduct.code;
							productValues = quoteProduct.values;
							productNotes = quoteProduct.notes;
						}

						// now we init the quote product
						return quoteProductReset(
							productCode,
							productValues,
							productNotes
						);
					})
					.then(
						() => {
							// done
							setReady(true);
						},
						(e) => {
							const error = new WallsideError(e);
							alert(error.globalErrors[0].message);
						}
					);
			}
		},
		[
			loadQuote,
			activeQuote,
			quoteProductReset,
			props.location.search,
			props.match.params.quoteId,
			props.match.params.productId,
		]
	);

	// when the product changes, we want to trigger the toggles
	useEffect(() => {
		if (product !== false) {
			toggleOpenConfiguration(-1);
			toggleOpenConfiguration(
				product.configurationGroups.group2.length === 1 ? 1 : -1
			);
		}
	}, [product, toggleOpenConfiguration]);

	if (!ready) {
		return null;
	}

	return (
		<Layout1
			mainClass="layout1--productconfiguration"
			navbar={
				<ProductConfigurationNavBar productId={props.match.params.productId} />
			}
			sideBarChildren={<ProductConfigurationSideBar />}
		>
			{product &&
				product?.configurationGroups?.group2.map((configId, i) => (
					<ProductConfigurations
						orderIndex={i + 1}
						configuration={product.configurations[configId]}
						key={configId}
					/>
				))}
		</Layout1>
	);
};

export default ProductConfigurationRoute;
