import ShowcaseLayout from '../CustomWindowLayout/Grid/ShowcaseLayout';
import {
	calculateHeight,
	calculateWidth,
	calcSnapshotX,
	calcY,
	computeRowHeight,
} from '../../helpers/Calculations';
import { noOp } from '../../helpers/Operations';

const MultiUnitSnapshot = ({ values }) => {
	const cells = values.additional_items;
	const height = values.configuration_window_size_height;
	let width = values.configuration_window_size_width;
	const rowsBaseSize = values.rowsBaseSize;

	if (rowsBaseSize > 2) {
		width -= 100;
	}

	const structureLayout = () => {
		try {
			return cells.map((cell, index) => {
				const droppedData = cell.values.droppedData ?? {};
				cell = cell.values.col;
				cell.product.droppedData = droppedData;
				cell.i = index.toString();
				cell.h = parseInt(calculateHeight(cell, { height }, rowsBaseSize));
				cell.maxH = rowsBaseSize;
				cell.w = parseInt(calculateWidth(cell));
				cell.x = calcSnapshotX(cell, index, cells[index - 1]);
				cell.y = calcY(cell, { height }, rowsBaseSize);
				cell.key = index.toString();
				return cell;
			});
		} catch (error) {
			console.error('Structured Layout Error:', error);
			return [];
		}
	};

	return (
		<div className="flex items-center justify-center w-full h-full">
			<ShowcaseLayout
				cols={{
					xxs: width > 0 ? width : 20,
					lg: width > 0 ? width : 20,
				}}
				layout={structureLayout()}
				onLayoutChange={noOp}
				openCustomWindowConfigurationData={noOp}
				orderIndex={undefined}
				overrideClick={true}
				rowHeight={computeRowHeight(height, width) / 10}
				isDraggable={false}
				inMultiUnitPath={false}
			/>
		</div>
	);
};

export default MultiUnitSnapshot;
