import React, { useContext, useEffect, useState, useRef } from 'react';
/*import { useHistory } from 'react-router-dom';
import URLMapper from '../../../functionality/URLMapper'
import ActionBtn from '../../ActionBtn/ActionBtn';
import Btn from '../../Btn/Btn';
import SessionDataContext from '../../../contexts/SessionDataContext'
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext'
import UIManagementContext from '../../../contexts/UIManagementContext';
import WallsideError from '../../../functionality/WallsideError';
*/

import UIManagementContext from '../../contexts/UIManagementContext';
import './SystemNotification.css';

const SystemNotification = (props) => {
	// need to use the contexts
	const { systemNotification, toggleSystemNotification } =
		useContext(UIManagementContext);

	// use state
	const [running, setRunning] = useState(false);

	// we'll use a ref for our animation timeouts
	let autoCloseTimeout = useRef();

	const messages = {
		create_quote: 'You have created a new quote.',
		save_quote: 'Your quote has been saved.',
		save_user: 'Your account settings have been saved.',
		save_global_changes: 'Your changes have been applied.',
		delete_quote: 'The quote has been deleted.',
		product_duplicated: 'Product has been duplicated and quote has been updated.',
		product_deleted: 'Product has been deleted and quote has been updated.',
		quote_duplicated: 'Quote has been duplicated and saved.',
		quote_emailed: 'Quote was sent shared via email.',
	};

	// track when active state changes
	useEffect(() => {
		if (systemNotification.active) {
			setTimeout(() => {
				setRunning(true);
			}, 50);
			clearTimeout(autoCloseTimeout.current);
			autoCloseTimeout.current = setTimeout(() => {
				clearTimeout(autoCloseTimeout.current);
				setRunning(false);
				setTimeout(() => {
					toggleSystemNotification({ active: false });
				}, 350);
			}, 3000);
		}
	}, [systemNotification, toggleSystemNotification]);

	// return the JSX
	return (
		<div
			className={`system-notification ${
				running ? 'system-notification--running' : ''
			} icon-far icon--check`}
		>
			<p className="system-notification__text">
				{messages[systemNotification.messageKey]}
			</p>
			<button
				type="button"
				className="system-notification__btn"
				onClick={() => {
					clearTimeout(autoCloseTimeout.current);
					setRunning(false);
					setTimeout(() => {
						toggleSystemNotification({ active: false });
					}, 350);
				}}
			>
				Close
			</button>
		</div>
	);
};

export default SystemNotification;
