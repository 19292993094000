import React, { useContext, useEffect, useMemo, useState } from 'react';
import Parse from 'parse';
import SessionDataContext from '../../contexts/SessionDataContext';
import UIManagementContext from '../../contexts/UIManagementContext';
import Btn from '../Btn/Btn';
import ActionBtn from '../ActionBtn/ActionBtn';
import { centsToMoney } from '../../functionality/Money';
import WallsideError from '../../functionality/WallsideError';
import moment from 'moment';
import { PresentationBodyPaymentOptions } from './PresentationBodyPaymentOptions';
import { DiscountItem } from './components/DiscountItem';
import { SystemDiscountItem } from './components/SystemDiscountItem';
import './PresentationBody.css';

const PresentationBody = () => {
	const { activeQuote, saveQuote } = useContext(SessionDataContext);
	const { toggleSystemNotification, toggleOverlay } = useContext(UIManagementContext);
	const [systemDiscounts, setSystemDiscount] = useState([]);
	const [paymentOption, setPaymentOption] = useState('paynow'); // paynow || finance
	const [financeTermMonths, setFinanceTermMonths] = useState('60'); // months
	const [showingDiscounts, setShowingDiscounts] = useState(false);
	const totalInvestment = useMemo(
		() =>
			centsToMoney({
				amount: activeQuote.get('totals')['totalBeforeDiscountsInCents'],
			}),
		[]
	);
	const discounts = activeQuote.get('discounts');

	// payment method mappings
	const paymentMappings = {
		paynow: 'cash',
		72: 'finance72months',
		60: 'finance60months',
		48: 'finance48months',
		36: 'finance36months',
		24: 'finance24months',
		18: 'finance18months',
		12: 'finance12months',
	};

	// use effect, on the first load, let's get the available discounts
	useEffect(() => {
		new Parse.Query('AvailableDiscount')
			.equalTo('active', true)
			.find()
			.then((results) => {
				setSystemDiscount(results);
			});
	}, []);

	const addDiscount = () => {
		toggleOverlay({
			active: true,
			type: 'add_discount',
			data: {
				paymentOption,
			},
		});
	};

	const showDiscounts = () => {
		setShowingDiscounts(true);
	};

	if (!activeQuote) {
		return null;
	}

	return (
		<div className="presentationbody">
			<div className="flex flex-col w-full">
				<div className="flex flex-row-reverse w-full">
					<div className="presentationbody__total">
						<div className="presentationbody__total-box">
							<div className="heading heading--4">Retail Price</div>
							<div className="heading presentationbody__total-box-heading">
								{totalInvestment}
							</div>
							<div className="heading heading--6">
								As of {moment(activeQuote.updatedAt).format('MM/DD/YYYY')}
							</div>
						</div>
					</div>
				</div>

				{showingDiscounts || discounts.length > 0 ? (
					<div className="presentationbody__discounts">
						<h2 className="heading heading--3">Discounts</h2>
						{systemDiscounts.map((discount) => {
							const key = discount.get('code');
							return (
								<SystemDiscountItem
									activeQuote={activeQuote}
									discount={discount}
									discounts={discounts}
									key={key}
									saveQuote={saveQuote}
									toggleSystemNotification={toggleSystemNotification}
								/>
							);
						})}
						{discounts.map((discount, index) => {
							const key = discount.title;
							return (
								<DiscountItem
									discount={discount}
									discounts={discounts}
									index={index}
									key={key}
									paymentOption={paymentOption}
									saveQuote={saveQuote}
									toggleSystemNotification={toggleSystemNotification}
								/>
							);
						})}
						<ActionBtn
							actionBtnClass="actionbtn--primary presentationbody__add-discount"
							actionBtnType="icon--plus"
							onClick={addDiscount}
						/>
					</div>
				) : (
					<Btn
						btnType="btn--primary presentationbody__show-discounts"
						onClick={showDiscounts}
					>
						Show Discounts
					</Btn>
				)}

				<PresentationBodyPaymentOptions
					activeQuote={activeQuote}
					paymentMappings={paymentMappings}
					paymentOption={paymentOption}
					setPaymentOption={setPaymentOption}
					toggleOverlay={toggleOverlay}
					centsToMoney={centsToMoney}
					financeTermMonths={financeTermMonths}
					setFinanceTermMonths={setFinanceTermMonths}
					discounts={discounts}
				/>
			</div>
		</div>
	);
};

export default PresentationBody;
