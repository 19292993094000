import { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import URLMapper from '../../../functionality/URLMapper';
import ActionBtn from '../../ActionBtn/ActionBtn';
import Btn from '../../Btn/Btn';
import Context from '../../../contexts/store/context';
import SessionDataContext from '../../../contexts/SessionDataContext';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import UIManagementContext from '../../../contexts/UIManagementContext';
import WallsideError from '../../../functionality/WallsideError';

import './ProductConfigurationNavBar.css';
import { generateKey } from '../../../helpers/Generators';
import { MultiUnitUtil } from '../../../util/MultiUnitUtil';

const ProductConfigurationNavBar = (props) => {
	// setting up the history object
	const history = useHistory();

	// we'll use a ref, for the back
	const isFirstTime = useRef(true);

	// need to use the ui context
	const {
		toggleOverlay,
		overlayData,
		toggleSystemNotification,
		toggleBlockBack,
		getBlockBack,
	} = useContext(UIManagementContext);

	// current product context
	const {
		additional_items,
		product,
		product_notes,
		product_values,
		quoteProductErrorList,
		rowsBaseSize,
		setQuoteProductAdditionalItems,
		setQuoteRowsBaseSize,
	} = useContext(CurrentQuoteProductContext);

	// add to cart functionality
	const { activeQuote, saveQuoteProduct } = useContext(SessionDataContext);
	const { globalState } = useContext(Context);
	const { selectedCustomProduct } = globalState;

	const specialArray = [
		'CH_CATHEDRAL',
		'CHX_CATHEDRAL_WITH_EXTENDED_LEGS',
		'CT_CIRCLE_TOP',
		'CTX_CIRCLE_TOP_EXTENDED_LEGS',
		'DCC_DOUBLE_CLIPPED_CORNER',
		'EB_EYEBROW',
		'EBX_EYEBROW_WITH_EXTENDED_LEGS',
		'EP_ELLIPSE',
		'FC_FULL_CIRCLE',
		'HBL_BR_HALF_EYEBROW_LEFT',
		'HBL_BR_HALF_EYEBROW_RIGHT',
		'HX_HEXAGON',
		'HXL_HXR_HALF_EYEBROW_LEFT',
		'HXL_HXR_HALF_EYEBROW_RIGHT',
		'HXX_HEXAGON_WITH_EXTENDED_LEGS',
		'OT_OCTAGON',
		'OTX_OCTAGON_WITH_EXTENDED_LEGS',
		'OV_OVAL',
		'PT_PENTAGON',
		'PTE_PENTAGON_EQUILATERAL',
		'PTL_PTR_PENTAGON_LEFT',
		'PTL_PTR_PENTAGON_RIGHT',
		'QAL_QAR_QUARTER_ARCH_LEFT_1',
		'QAL_QAR_QUARTER_ARCH_LEFT',
		'QAL_QAR_QUARTER_ARCH_RIGHT_1',
		'QAL_QAR_QUARTER_ARCH_RIGHT',
		'RT_RECTANGLE',
		'SPECIALTY_PROD',
		'TRE_TRIANGLE_EQUILATERAL',
		'TRI_TRIANGLE_ISOSCELES',
		'TRL_OR_TRR_TRIANGLE_LEFT',
		'TRL_OR_TRR_TRIANGLE_RIGHT',
		'TZL_TZR_TRAPEZOID_LEFT',
		'TZL_TZR_TRAPEZOID_RIGHT',
	];

	// check for valid product
	const isValidProduct = () => {
		const errorList = quoteProductErrorList();
		if (errorList.length > 0) {
			toggleOverlay({
				active: true,
				type: 'error_messages',
				data: {
					heading: 'Error Adding To Quote',
					errors: errorList,
				},
			});
			return false;
		}
		return true;
	};

	// handle go to edit notes
	const handleEditNotes = (e) => {
		toggleOverlay({
			active: true,
			type: 'product_notes',
			data: {},
		});
	};

	// Parse throws an error is our object is too nested.
	// To fix this, we're just pulling the data we need and removing the rest.
	const formattedItems = (arr) => {
		let tempArr = [];
		for (const item of arr) {
			let code = item.product.code;
			let type = 'window';
			let values = item.product;

			// Under certain circumstances, there will be a different "type" of window.
			if (code && code.includes('door_wall')) {
				type = 'doorwall';
			}

			if (code && specialArray.includes(code)) {
				code = 'SPECIALTY_PROD';
			}

			// Create the object we'll use and push to our array.
			const obj = {
				code,
				id: item.id ?? generateKey(),
				notes: '',
				type,
				values,
			};
			tempArr.push(obj);
		}
		return tempArr;
	};

	// Add up all the heights for our custom multi unit.
	const calculateHeight = (arr) => {
		let height = 0;
		let temp = [];
		for (const item of arr) {
			if (!temp.includes(item.location[0].y)) {
				height += item.product.configuration_window_size_height;
				temp.push(item.location[0].y);
			}
		}

		return height;
	};

	// Add up all the widths for our custom multi unit.
	const calculateWidth = (arr) => {
		let width = 0;
		let temp = [];
		for (const item of arr) {
			if (!temp.includes(item.location[0].x)) {
				width += item.product.configuration_window_size_width;
				temp.push(item.location[0].y);
			}
		}
		return width;
	};

	const formatId = (productId) => {
		if (productId && productId.includes('productid')) {
			return productId.split('=')[1];
		}
		return productId;
	};

	// handle add to quote
	const handleAddToQuoteClick = async () => {
		if (isValidProduct()) {
			// close any overlay
			toggleOverlay({ active: false });
			const attr = product_values;
			if (additional_items && additional_items.length > 0) {
				attr.additional_items = formattedItems(additional_items);
				attr.configuration_window_size_height = calculateHeight(additional_items);
				attr.configuration_window_size_width = calculateWidth(additional_items);
				// Only for bow
			}

			if (
				additional_items &&
				additional_items.length > 0 &&
				product.code === 'CUSTOM_WINDOW'
			) {
				attr.rowsBaseSize = rowsBaseSize;
			}

			const customArr = MultiUnitUtil.getMultiList();

			// If is edit action, change saveQuoteProduct to updateQuoteProduct?
			const id = formatId(props.productId);
			const isBayBow =
				customArr.includes(product.code) &&
				customArr.includes(selectedCustomProduct);

			if (isBayBow && selectedCustomProduct.includes('BOW_SHELL')) {
				attr.configuration_window_style_number_of_windows =
					additional_items.length.toString();
			}

			await saveQuoteProduct({
				id,
				code: isBayBow ? selectedCustomProduct : product.code,
				type: product.type,
				notes: product_notes,
				values: attr,
			}).then(
				() => {
					setQuoteProductAdditionalItems([]);
					setQuoteRowsBaseSize(1);

					// show the notification
					toggleSystemNotification({ active: true, messageKey: 'save_quote' });

					// since we have a duplicate history entry, just replace the duplicate
					history.replace(URLMapper('quote', { quoteId: activeQuote.id }));
				},
				(e) => {
					console.error('Quote Submission Error:', e);
					const error = new WallsideError(e);
					alert(error.globalErrors[0].message);
				}
			);
		}
	};

	// let's use an effect to handle the block the back button
	useEffect(() => {
		if (isFirstTime.current) {
			isFirstTime.current = false;
			// toggle the back block
			toggleBlockBack(true);
			// add extra history entry
			history.push(history.location.pathname);
			// now we'll listen for the 'pop' event, which we know is a 'back' action
			const unlisten = history.listen((location, action) => {
				if (getBlockBack() && action === 'POP') {
					// useer hit back, so we move them back forward
					history.go(1);
					// if not showing the notes overlay, then we prompt for the without saving
					if (overlayData().type !== 'product_notes') {
						toggleOverlay({
							active: true,
							type: 'confirm_close_without_saving',
							data: {
								productId: props.productId,
							},
						});
					}
				}
			});
			return () => {
				unlisten();
			};
		}
	}, [
		history,
		toggleOverlay,
		toggleBlockBack,
		getBlockBack,
		overlayData,
		props.productId,
	]);

	// return the component
	return (
		<div className="productconfigurationnavbar">
			<div className="productconfigurationnavbar__action-group">
				<button
					type="button"
					className="icon-fas icon--back icon--white icon--large"
					onClick={() => {
						history.goBack();
					}}
				/>
				<div className="productconfigurationnavbar__actions">
					<ActionBtn
						actionBtnClass="actionbtn--light"
						actionBtnType="icon--notes"
						onClick={handleEditNotes}
					/>
				</div>
			</div>
			<Btn btnType={'btn--primary'} onClick={handleAddToQuoteClick}>
				{props.productId === '-1' ? 'Add to quote' : 'Save'}
			</Btn>
		</div>
	);
};

export default ProductConfigurationNavBar;
