import React, { useContext, useEffect } from 'react';
import Input from '../../Input/Input';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper.js';

const ProductConfigurationWindowLocation = (props) => {
	// get the quote product context
	const { setQuoteProductContextProductValue, getQuoteProductContextProductValue } =
		useContext(CurrentQuoteProductContext);

	// we'll use an effect to update the custom label
	const locationValue = getQuoteProductContextProductValue(
		props.configuration.standard.fieldName
	);
	const customValue = getQuoteProductContextProductValue(
		props.configuration.custom.fieldName
	);
	useEffect(() => {
		if (locationValue !== 'CUSTOM' && customValue !== '') {
			setQuoteProductContextProductValue(props.configuration.custom.fieldName, '');
		}
	}, [
		props.configuration.custom.fieldName,
		setQuoteProductContextProductValue,
		locationValue,
		customValue,
	]);

	const handleOnChange = (e) => {
		setQuoteProductContextProductValue(e.target.name, e.target.value);
	};

	const handleSecondOnChange = (e) => {
		setQuoteProductContextProductValue(
			props.configuration.custom.fieldName,
			e.target.value
		);
	};

	// return the component
	return (
		<div className="productconfiguration">
			<p className="heading heading--5 productconfiguration__heading">
				<label htmlFor={props.configuration.fieldName}>
					{props.configuration.heading}
				</label>
			</p>
			<Input
				type={'select'}
				className={'input--1'}
				inputModifier={'input--select'}
				id={props.configuration.standard.fieldName}
				name={props.configuration.standard.fieldName}
				values={[{ label: 'Select ...', value: '' }].concat(
					props.configuration.standard.values.map(function (value) {
						return { label: ConfigurationLabelMapper(value), value: value };
					})
				)}
				value={locationValue}
				onChange={handleOnChange}
			/>
			{locationValue === 'CUSTOM' && (
				<div className="productconfiguration__custom-location">
					<label
						htmlFor={props.configuration.custom.fieldName}
						className="config-subheading heading heading--6"
					>
						Label
					</label>
					<Input
						type={'text'}
						id={props.configuration.custom.fieldName}
						name={props.configuration.custom.fieldName}
						className={'input--1'}
						value={customValue}
						onChange={handleSecondOnChange}
					/>
				</div>
			)}
		</div>
	);
};
export default ProductConfigurationWindowLocation;
