import React, { Fragment, useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import ConfigurationLabelMapper from '../../functionality/ConfigurationLabelMapper';
import { toFixed } from '../../helpers/Calculations';
import { isMultiUnit } from '../../helpers/Comparators';
import ImageAsset from '../ImageAsset/ImageAsset';
import Input from '../Input/Input';
import IconBtn from '../IconBtn/IconBtn';
import MultiUnitProductRowItem from './MultiUnitProductRowItem';
import MultiUnitSnapshot from './MultiUnitSnapshot';
import URLMapper from '../../functionality/URLMapper';
import WallsideError from '../../functionality/WallsideError';
import Context from '../../contexts/store/context';
import SessionDataContext from '../../contexts/SessionDataContext';
import UIManagementContext from '../../contexts/UIManagementContext';
import { setSelectedCustomProduct } from '../../contexts/store/actions';
import RenderProductDescriptionFragment from './RenderProductDescriptionFragment';
import RenderProductSectionFragment from './RenderProductSectionFragment';

import { MultiUnitUtil } from '../../util/MultiUnitUtil';
import './QuoteDetailProductList.css';

const QuoteProductItem = ({
	getProductList,
	index,
	notesTruncationData,
	product,
	productList,
	quoteProduct,
	renderMultiUnitConfigurationItems,
	visibleNotes,
	setDraggableHeights,
	isDragging,
}) => {
	const [list, setList] = useState([]);
	const [notes, setVisibleNotes] = useState(visibleNotes);
	const [containerHeight, setContainerHeight] = useState();
	const containerRef = useRef();

	useEffect(() => {
		setDraggableHeights((prevState) => {
			return { ...prevState, [quoteProduct.id]: containerHeight };
		});
	}, [containerHeight]);

	const getContainerHeight = () => {
		const containerHeight = containerRef.current.clientHeight;
		setContainerHeight(containerHeight);
	};

	useEffect(() => {
		getContainerHeight();
	}, [list]);

	// Setting up the history object
	const history = useHistory();

	// Need to use the ui context
	const { toggleOverlay, toggleSystemNotification } = useContext(UIManagementContext);

	// We'll need the global store to save a product code.
	const { globalDispatch } = useContext(Context);

	// We'll setup the session data context
	const { activeQuote, saveQuoteProduct } = useContext(SessionDataContext);

	const multiList = MultiUnitUtil.getMultiList();

	/* Everytime visibleNotes updates in the parent, override the state value here. */
	useEffect(() => {
		if (visibleNotes !== notes) {
			setVisibleNotes(visibleNotes);
		}
	}, [visibleNotes]);

	useEffect(() => {
		if (list !== productList) {
			setList(productList);
		}
	}, [productList]);

	useEffect(() => {
		if (activeQuote) {
			const temp = getProductList();

			setList(temp);
		}
		setVisibleNotes({});

		return () => {
			setList([]);
		};
	}, [activeQuote]);

	// Detemine whether or not a product is a multi unit window or not.
	const isMulti = (product) => {
		return isMultiUnit(multiList, product.code);
	};

	// Determine whether or not a product is a bay or bow shell.
	const isNotMulti = (product) => {
		return product.code.includes('CUSTOM_WINDOW') ? false : true;
	};

	// If a multi unit, render a grid snapshot.
	const renderProductImage = (product) => {
		if (product.code === 'CUSTOM_WINDOW') {
			return <MultiUnitSnapshot values={product.values} />;
		}

		return (
			<ImageAsset
				code={product.code === 'CUSTOM_WINDOW' ? 'CUSTOM' : product.code}
			/>
		);
	};

	const handleChange = async (e, product, quoteProduct) => {
		try {
			// update this product
			await saveQuoteProduct({
				id: quoteProduct.id,
				code: quoteProduct.code,
				type: quoteProduct.type,
				notes: quoteProduct.notes,
				values: Object.assign({}, quoteProduct.values, {
					[product.configurations['WINDOW_LOCATION'].standard.fieldName]:
						e.currentTarget.value,
					[product.configurations['WINDOW_LOCATION'].custom.fieldName]: '',
				}),
				costInCents: quoteProduct.costInCents,
			});

			// show the notification
			toggleSystemNotification({
				active: true,
				messageKey: 'save_quote',
			});
		} catch (e) {
			const error = new WallsideError(e);
			alert(error.globalErrors[0].message);
		}
	};

	// Handle the duplicate click action
	const handleOnClickDuplicate = async (e, quoteProduct) => {
		try {
			// duplicate this product
			await saveQuoteProduct({
				id: '-1',
				code: quoteProduct.code,
				type: quoteProduct.type,
				notes: quoteProduct.notes,
				values: quoteProduct.values,
				costInCents: quoteProduct.costInCents,
			});

			// show the notification
			toggleSystemNotification({
				active: true,
				messageKey: 'product_duplicated',
			});
		} catch (e) {
			const error = new WallsideError(e);
			alert(error.globalErrors[0].message);
		}
	};

	// Handle the edit click action
	const handleOnClickEdit = (e, quoteProduct) => {
		// If this is a multi-unit item, we need to navigate the user to step 3.
		if (quoteProduct.code === 'CUSTOM_WINDOW') {
			// Need to set the openCustomWindowConfiguration to 3 so we go to step 3.
			globalDispatch(setSelectedCustomProduct('CUSTOM_WINDOW'));
			history.push(
				URLMapper('editCustomWindow', {
					quoteId: activeQuote.id,
					productCode: 'CUSTOM_WINDOW',
					productId: quoteProduct.id,
				})
			);
		} else {
			history.push(
				URLMapper('editproduct', {
					quoteId: activeQuote.id,
					productId: quoteProduct.id,
				})
			);
		}
	};

	// Handle the confirm delete call
	const handleConfirmDelete = (e, quoteProduct) => {
		toggleOverlay({
			active: true,
			type: 'confirm_delete_product',
			data: {
				productId: quoteProduct.id,
			},
		});
	};

	// Show our notes for each product
	const handleNotesClick = (quoteProduct) => {
		setVisibleNotes((prev) => {
			const next = {
				...prev,
			};
			next[quoteProduct.id] = true;
			return next;
		});
	};

	const renderItemIndex = (currentProduct) => {
		return list.indexOf(currentProduct.id) + 1;
	};

	return (
		<Fragment>
			<tr className="w-full" ref={containerRef}>
				<td>
					<div
						className="heading heading--8 quotedetailproductlist__number"
						style={
							!isNotMulti(product)
								? {
										color: '#F4F7FB',
								  }
								: {}
						}
					>
						{renderItemIndex(quoteProduct)}
					</div>
					<div className="quotedetailproductlist__header">
						<div className="quotedetailproductlist__image">
							{renderProductImage(quoteProduct)}
						</div>
						<div className="quotedetailproductlist__overview">
							<div>
								<div className="heading heading--6">{product.title}</div>
								{product.type !== 'misc_item' && (
									<Input
										type="select"
										inputModifier="input--select-text"
										id={`window_location_${index}`}
										name={`window_location_${index}`}
										values={product.configurations[
											'WINDOW_LOCATION'
										].standard.values.map((code) => {
											return {
												label: ConfigurationLabelMapper(code),
												value: code,
											};
										})}
										value={
											quoteProduct.values[
												product.configurations['WINDOW_LOCATION']
													.standard.fieldName
											]
										}
										onChange={(e) => {
											handleChange(e, product, quoteProduct);
										}}
									/>
								)}
							</div>
							<div className="quotedetailproductlist__actions">
								<IconBtn
									iconWeight="icon-fas"
									btnType="iconbtn--primary"
									iconType="icon--duplicate"
									onClick={(e) => {
										handleOnClickDuplicate(e, quoteProduct);
									}}
								/>
								<IconBtn
									iconWeight="icon-fas"
									btnType="iconbtn--primary"
									iconType="icon--pencil"
									onClick={(e) => {
										handleOnClickEdit(e, quoteProduct);
									}}
								/>
								<IconBtn
									iconWeight="icon-fas"
									btnType="iconbtn--secondary"
									iconType="icon--delete"
									onClick={(e) => {
										handleConfirmDelete(e, quoteProduct);
									}}
								/>
							</div>
						</div>
					</div>
				</td>
				<td>
					<dl className="quotedetailproductlist__configuration">
						{isMulti(product) &&
							renderMultiUnitConfigurationItems(quoteProduct)}
						{!isMulti(product) &&
							product.configurationGroups.group2 &&
							product.configurationGroups.group2.map((configurationKey) => (
								<RenderProductSectionFragment
									configurationKey={configurationKey}
									key={configurationKey}
									product={product}
									quoteProduct={quoteProduct}
								/>
							))}
						{!isMulti(product) &&
							product.configurationGroups.group1 &&
							product.configurationGroups.group1.map((configurationKey) => (
								<RenderProductDescriptionFragment
									configurationKey={configurationKey}
									key={configurationKey}
									product={product}
									quoteProduct={quoteProduct}
								/>
							))}
						{quoteProduct.notes !== '' && (
							<>
								<dt className="heading heading--12">Notes:</dt>
								<dd className="heading heading--13">
									{notes[quoteProduct.id] ? (
										quoteProduct.notes
									) : (
										<>
											{notesTruncationData.string}
											{notesTruncationData.truncated && (
												<>
													&nbsp;
													<button
														type="button"
														className="quotedetailproductlist__text-link"
														onClick={() =>
															handleNotesClick(quoteProduct)
														}
													>
														...show more
													</button>
												</>
											)}
										</>
									)}
								</dd>
							</>
						)}
					</dl>
				</td>
				<td>
					{product.configurations['WINDOW_SIZE'] && (
						<div className="quotedetailproductlist__spec-wrapper">
							<div className="heading heading--5">
								{
									quoteProduct.values[
										product.configurations['WINDOW_SIZE']
											.fieldNameWidth
									]
								}
								&quot; X
								{
									quoteProduct.values[
										product.configurations['WINDOW_SIZE']
											.fieldNameHeight
									]
								}
								&quot;
							</div>
							<div className="heading heading--6">
								{toFixed(
									quoteProduct.values[
										product.configurations['WINDOW_SIZE']
											.fieldNameWidth
									] +
										quoteProduct.values[
											product.configurations['WINDOW_SIZE']
												.fieldNameHeight
										],
									1000
								)}
								UI
							</div>
						</div>
					)}
				</td>
				<td>
					{product.configurations['HOUSE_TYPE'] && (
						<div className="quotedetailproductlist__spec-wrapper">
							<div className="heading heading--5">
								{ConfigurationLabelMapper(
									quoteProduct.values[
										product.configurations['HOUSE_TYPE'].fieldName
									]
								)}
							</div>
						</div>
					)}
				</td>
				<td>
					{product.configurations['REMOVAL_TYPE'] && (
						<div className="quotedetailproductlist__spec-wrapper">
							<div className="heading heading--5">
								{ConfigurationLabelMapper(
									quoteProduct.values[
										product.configurations['REMOVAL_TYPE'].fieldName
									]
								)}
							</div>
						</div>
					)}
				</td>
			</tr>

			{quoteProduct.values &&
				quoteProduct.values.additional_items &&
				quoteProduct.values.additional_items.map((item) => {
					const ind = renderItemIndex(item);
					return (
						<MultiUnitProductRowItem
							handleChange={handleChange}
							index={ind}
							key={item.id}
							quoteProduct={item}
						/>
					);
				})}
		</Fragment>
	);
};

export default QuoteProductItem;
