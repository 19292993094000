import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Parse from 'parse';
import Btn from '../Btn/Btn.js';
import Input from '../Input/Input';
import FieldError from '../FieldError/FieldError';
import URLMapper from '../../functionality/URLMapper';
import {
	getFormData,
	validateFormDataPromise,
} from '../../functionality/FormUtilities.js';
import { getRememberedLoginInfo } from '../../functionality/LocalStorage.js';
import './ForgotPasswordForm.css';

const ForgotPasswordForm = (props) => {
	// setting up the history object
	const history = useHistory();

	// use state
	const [done, setDone] = useState(false);
	const [formErrors, setFormErrors] = useState({
		// no default errors, so its empty
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			// reset the form errors
			setFormErrors({});
			// we'll now try to get the data from the form
			const formData = getFormData(e.target);
			// now we'll validate these values
			await validateFormDataPromise(formData);
			Parse.User.requestPasswordReset(formData.user_login);
			setDone(true);
		} catch (error) {
			// the form data didn't validate, so let set the form Errors
			setFormErrors(error);
		}
	};

	return done ? (
		<div className="forgot-password">
			<p className="heading heading--2">
				Check your email for intructions to reset your password.
			</p>
			<Btn
				type="button"
				btnType="btn--primary btn--100"
				onClick={() => {
					history.push(URLMapper('login'));
				}}
			>
				Back to Login
			</Btn>
		</div>
	) : (
		<form className="forgot-password" onSubmit={handleSubmit}>
			<div className="forgot-password__header">
				<div className="heading heading--6">Forgot Password?</div>
				<div className="heading heading--2">Reset Your Password</div>
				<p className="forgot-password__instructions">
					Please enter your email, and we will send you a link to reset your
					password.
				</p>
			</div>
			<div className="forgot-password__fields">
				<Input
					type="text"
					id="user_login"
					name="user_login"
					className="input--1"
					inputModifier={formErrors.user_login ? 'input--error' : ''}
					placeholder={'Email Address'}
					defaultValue={getRememberedLoginInfo()['user_login']}
				></Input>
				{formErrors.user_login ? (
					<FieldError>{formErrors.user_login[0]}</FieldError>
				) : (
					''
				)}
			</div>
			<Btn type="submit" btnType="btn--primary btn--100">
				Send Email
			</Btn>
			<Btn
				type="button"
				btnType="btn--secondary btn--100"
				onClick={() => {
					history.push(URLMapper('login'));
				}}
			>
				Cancel
			</Btn>
		</form>
	);
};

export default ForgotPasswordForm;
