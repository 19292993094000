import React, { useContext, useState, useEffect, useRef } from 'react';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import { toFixed } from '../../../helpers/Calculations';
import Input from '../../Input/Input';

const ProductConfigurationDoorwallSize = (props) => {
	// get and set context values
	const { setQuoteProductContextProductValue, getQuoteProductContextProductValue } =
		useContext(CurrentQuoteProductContext);

	// use a ref for first time
	const firstTime = useRef(true);

	// usee some state
	const [doorwallSize, setDoorwallSize] = useState('');

	// the total ui
	const width = parseFloat(
		getQuoteProductContextProductValue(props.configuration.fieldNameWidth)
	);
	const height = parseFloat(
		getQuoteProductContextProductValue(props.configuration.fieldNameHeight)
	);
	let unitedInches = isNaN(width) || isNaN(height) ? 0 : toFixed(width + height, 1000);

	// we need to try and st the doorwell value properly
	useEffect(() => {
		const sizes = [
			['', 0, 0],
			['5', 58.5, 79.25],
			['6', 70.5, 79.25],
			['8', 94.5, 79.25],
			['9', 106.125, 79.25],
			['12', 142, 79.25],
		];
		for (let i = 0; i < sizes.length; i++) {
			if (width === sizes[i][1] && height === sizes[i][2]) {
				// found a staandaard doorwall
				setDoorwallSize(sizes[i][0]);
				return;
			}
		}
		// we didn't find the value so set the doorwall size
		setDoorwallSize('custom');
	}, [width, height, setQuoteProductContextProductValue]);

	// we'll use an effeect ot handle when the doorwall size changes
	useEffect(() => {
		if (!firstTime.current) {
			if (doorwallSize === '') {
				setQuoteProductContextProductValue(props.configuration.fieldNameWidth, 0);
				setQuoteProductContextProductValue(
					props.configuration.fieldNameHeight,
					0
				);
			} else if (doorwallSize !== 'custom') {
				const sizes = {
					5: [58.5, 79.25],
					6: [70.5, 79.25],
					8: [94.5, 79.25],
					9: [106.125, 79.25],
					12: [142, 79.25],
				};
				setQuoteProductContextProductValue(
					props.configuration.fieldNameWidth,
					sizes[doorwallSize][0]
				);
				setQuoteProductContextProductValue(
					props.configuration.fieldNameHeight,
					sizes[doorwallSize][1]
				);
			}
		} else {
			firstTime.current = false;
		}
	}, [
		doorwallSize,
		setQuoteProductContextProductValue,
		props.configuration.fieldNameWidth,
		props.configuration.fieldNameHeight,
	]);

	// return the jsx component
	return (
		<div className="productconfiguration">
			<div className="heading heading--5 productconfiguration__heading">
				{props.configuration.heading}
			</div>
			<Input
				type={'select'}
				className={'input--1'}
				inputModifier={'input--select'}
				id="doorwall_size_select"
				name="doorwall_size_select"
				values={[
					{ label: 'Select ...', value: '' },
					{ label: '5\' (58.5" x 79.25")', value: '5' },
					{ label: '6\' (70.5" x 79.25")', value: '6' },
					{ label: '8\' (94.5" x 79.25")', value: '8' },
					{ label: '9\' (106.125" x 79.25")', value: '9' },
					{ label: '12\' (142" x 79.25")', value: '12' },
					{ label: 'Custom', value: 'custom' },
				]}
				value={doorwallSize}
				onChange={(e) => {
					setDoorwallSize(e.target.value);
				}}
			/>
			{doorwallSize === 'custom' && (
				<>
					<div className="productconfiguration__windowsize-inputs">
						<div className="productconfiguration__windowsize-input">
							<div>
								<label
									htmlFor={props.configuration.fieldNameWidth}
									className="config-subheading heading heading--6"
								>
									Width
								</label>
								<Input
									type={'text'}
									id={props.configuration.fieldNameWidth}
									name={props.configuration.fieldNameWidth}
									className={'input--1 input--inches'}
									value={getQuoteProductContextProductValue(
										props.configuration.fieldNameWidth
									)}
									onBlur={(e) => {
										const value = parseFloat(e.target.value);
										setQuoteProductContextProductValue(
											props.configuration.fieldNameWidth,
											isNaN(value)
												? props.configuration.defaultValueWidth
												: Math.max(0, value)
										);
									}}
									onChange={(e) => {
										setQuoteProductContextProductValue(
											props.configuration.fieldNameWidth,
											e.target.value
										);
									}}
								/>
							</div>
						</div>
						<div className="productconfiguration__windowsize-input">
							<span className="icon-fal icon icon--x productconfiguration__windowsize-times"></span>
							<div>
								<label
									htmlFor={props.configuration.fieldNameHeight}
									className="config-subheading heading heading--6"
								>
									Height
								</label>
								<Input
									type={'text'}
									id={props.configuration.fieldNameHeight}
									name={props.configuration.fieldNameHeight}
									className={'input--1 input--inches'}
									value={getQuoteProductContextProductValue(
										props.configuration.fieldNameHeight
									)}
									onBlur={(e) => {
										const value = parseFloat(e.target.value);
										setQuoteProductContextProductValue(
											props.configuration.fieldNameHeight,
											isNaN(value)
												? props.configuration.defaultValueHeight
												: Math.max(0, value)
										);
									}}
									onChange={(e) => {
										setQuoteProductContextProductValue(
											props.configuration.fieldNameHeight,
											e.target.value
										);
									}}
								/>
							</div>
						</div>
					</div>
					<div className="productconfiguration__windowsize-output">
						<div className="productconfiguration__windowsize-output-icon">
							<span className="icon-far icon icon--ruler"></span>
						</div>
						<div className="productconfiguration__windowsize-output-calculation">
							<div className="heading heading--5">
								=  {unitedInches} United Inches
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default ProductConfigurationDoorwallSize;
