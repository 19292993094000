// window color
export const color_config = {
	configurationId: 'WINDOW_COLOR',
	configurationType: 'window_color',
	interiorColor: {
		heading: 'Interior Color',
		fieldName: 'configuration_window_interior_color',
		defaultValue: 'WHITE',
		values: [
			{
				value: 'WHITE',
				defaultExteriorColor: 'WHITE',
				exteriorColorOptions: ['WHITE', 'ALMOND', 'BLACK', 'CLAY', 'TERRATONE'],
			},
			{
				value: 'ALMOND',
				defaultExteriorColor: 'ALMOND',
				exteriorColorOptions: ['ALMOND'],
			},
			{
				value: 'MEDIUM_WOOD',
				defaultExteriorColor: 'WHITE',
				exteriorColorOptions: ['WHITE', 'ALMOND', 'CLAY', 'TERRATONE'],
			},
			{
				value: 'DARK_WOOD',
				defaultExteriorColor: 'WHITE',
				exteriorColorOptions: ['WHITE', 'ALMOND', 'CLAY', 'TERRATONE'],
			},
		],
	},
	exteriorColor: {
		heading: 'Exterior Color',
		fieldName: 'configuration_window_exterior_color',
		values: ['WHITE', 'ALMOND', 'BLACK', 'CLAY', 'TERRATONE'],
	},
};

// window glass
export const glass_config = {
	configurationId: 'WINDOW_GLASS',
	configurationType: 'window_glass',
	type: {
		heading: 'Glass Type',
		fieldName: 'configuration_window_glass_type',
		defaultValue: 'RLE',
		values: ['RLE', 'PLE', 'TPLE'],
	},
};

// window grids
export const grid_config = {
	configurationId: 'WINDOW_GRID',
	configurationType: 'window_grid',
	removeMuntin: {
		heading: 'Remove Muntins',
		fieldName: 'configuration_window_grid_remove_muntins',
	},
	style: {
		fieldName: 'configuration_window_grid_style',
		defaultValue: 'NONE',
	},
	pattern: {
		fieldName: 'configuration_window_grid_pattern',
		defaultValue: false,
	},
	details: {
		fieldName: 'configuration_window_grid_details',
		defaultValue: '',
	},
	interiorMuntinColor: {
		heading: 'Interior Muntin Color',
		fieldName: 'configuration_window_interior_muntin_color',
		defaultValue: false,
		values: [
			{
				value: 'WHITE',
				exteriorMutinColorDefault: 'WHITE',
				exteriorMutinColorOptions: [
					'WHITE',
					'ALMOND',
					'BLACK',
					'CLAY',
					'TERRATONE',
				],
			},
			{
				value: 'ALMOND',
				exteriorMutinColorDefault: 'ALMOND',
				exteriorMutinColorOptions: ['ALMOND'],
			},
			{
				value: 'BLACK',
				exteriorMutinColorDefault: 'BLACK',
				exteriorMutinColorOptions: ['BLACK'],
			},
			{
				value: 'BRONZEH',
				exteriorMutinColorDefault: 'BRONZEH',
				exteriorMutinColorOptions: ['BRONZEH'],
			},
			{
				value: 'CLAY',
				exteriorMutinColorDefault: 'CLAY',
				exteriorMutinColorOptions: ['CLAY'],
			},
			{
				value: 'DARK_WOOD',
				exteriorMutinColorDefault: 'WHITE',
				exteriorMutinColorOptions: [
					'WHITE',
					'ALMOND',
					'BLACK',
					'CLAY',
					'TERRATONE',
				],
			},
			{
				value: 'MEDIUM_WOOD',
				exteriorMutinColorDefault: 'WHITE',
				exteriorMutinColorOptions: [
					'WHITE',
					'ALMOND',
					'BLACK',
					'CLAY',
					'TERRATONE',
				],
			},
			{
				value: 'TERRATONE',
				exteriorMutinColorDefault: 'TERRATONE',
				exteriorMutinColorOptions: ['TERRATONE'],
			},
		],
	},
	exteriorMuntinColor: {
		heading: 'Exterior Muntin Color',
		fieldName: 'configuration_window_exterior_muntin_color',
		defaultValue: false,
		values: ['WHITE', 'ALMOND', 'BLACK', 'BRONZEH', 'CLAY', 'TERRATONE'],
	},
};

// window screen
export const screen_config = {
	configurationId: 'WINDOW_SCREEN',
	configurationType: 'window_screen',
	heading: 'Screen',
	size: {
		heading: 'Size',
		fieldName: 'configuration_window_screen_size',
		defaultValue: 'FULL_SCREEN',
		values: ['HALF_SCREEN', 'FULL_SCREEN'],
	},
	mesh: {
		heading: 'Mesh',
		fieldName: 'configuration_window_screen_mesh',
		defaultValue: 'BLACK_MESH',
		values: ['BLACK_MESH', 'GRAY_MESH', 'PET_MESH'],
	},
};

// window sill
export const sill_config = {
	configurationId: 'WINDOW_SILL',
	configurationType: 'window_sill',
	heading: 'Sill',
	interiorSill: {
		heading: 'Interior Sill',
		fieldName: 'configuration_window_sill_material',
		defaultValue: 'NONE',
		values: [
			{
				value: 'NONE',
				dimensionsDefault: false,
				dimensionsOptions: false,
			},
			{
				value: 'PINE',
				dimensionsDefault: 'SILL_3_TO_4',
				dimensionsOptions: [
					'SILL_3_TO_4',
					'SILL_WIDE_6_TO_12',
					'SILL_WIDE_OVER_12',
				],
			},
			{
				value: 'MARBLE_LITE_WHITE',
				dimensionsDefault: 'SILL_3_TO_4',
				dimensionsOptions: [
					'SILL_3_TO_4',
					'SILL_WIDE_6_TO_12',
					'SILL_WIDE_OVER_12',
				],
			},
			{
				value: 'MARBLE_LITE_BUFF',
				dimensionsDefault: 'SILL_3_TO_4',
				dimensionsOptions: [
					'SILL_3_TO_4',
					'SILL_WIDE_6_TO_12',
					'SILL_WIDE_OVER_12',
				],
			},
		],
	},
	exteriorSill: {
		heading: 'Exterior Sill',
		fieldName: 'configuration_window_sill_exterior',
		defaultValue: 'NONE',
		values: [
			{
				value: 'NONE',
				dimensions: false,
			},
			{
				value: 'WOOD',
				dimensions: false,
			},
			{
				value: 'STONE',
				dimensions: true,
			},
		],
	},
	dimensions: {
		heading: 'Interior Sill Dimensions',
		fieldName: 'configuration_window_sill_dimensions',
		values: [
			'SILL_UP_TO_4',
			'SILL_3_TO_4',
			'SILL_6_TO_12',
			'SILL_OVER_12',
			'SILL_WIDE_6_TO_12',
			'SILL_WIDE_OVER_12',
		],
	},
	exteriorDimensions: {
		heading: 'Exterior Sill Dimensions',
		fieldName: 'configuration_window_sill_exterior_dimensions',
		defaultValue: 0,
		minimumValue: 0,
	},
};
