export default function URLMapper(t, d) {
	const data = Object.assign(
		{
			userId: '',
			quoteId: '',
			productId: '',
			productCode: '',
		},
		d
	);

	switch (t) {
		case 'login':
			return '/login';
		case 'forgot_password':
			return '/forgot-password';
		case 'dashboard':
			return '/dashboard';
		case 'quote':
			return '/quotes/' + data.quoteId;
		case 'quotepricing':
			return '/quotes/' + data.quoteId + '/pricing';
		case 'presentation':
			return '/quotes/' + data.quoteId + '/presentation';
		case 'productnotes':
			return '/quotes/' + data.quoteId + '/products/' + data.productId + '/notes';
		case 'addproduct':
			return '/quotes/' + data.quoteId + '/products/new';
		case 'addproductMultiUnit':
			return '/quotes/' + data.quoteId + '/products/new/multi-unit';
		case 'addCustomWindow':
			return (
				'/quotes/' +
				data.quoteId +
				'/products/new/multi-unit/-1?code=' +
				data.productCode
			);
		case 'newproduct':
			return '/quotes/' + data.quoteId + '/products/-1?code=' + data.productCode;
		case 'editproduct':
			return '/quotes/' + data.quoteId + '/products/' + data.productId;
		case 'editCustomWindow':
			return `/quotes/${data.quoteId}/products/new/multi-unit/productid=${data.productId}`;
		default:
			return '';
	}
}
