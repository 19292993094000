import { useRef, useState } from 'react';
import { useCustomWindowContext } from '../../../containers/CustomWindow/customWindowContext';
import { useUIManagementContext } from '../../../contexts/UIManagementContext';
import {
	resizeGridDimensionAction,
	updateCells,
	updateDefaultResizingDimensionInputs,
	updateResizingDimensionInputsAction,
} from '../../../containers/CustomWindow/Actions';
import ShowcaseLayout from './ShowcaseLayout';
import Btn from '../../Btn/Btn';
import { computeRowHeight, toFixed } from '../../../helpers/Calculations';
import { isMobile } from 'react-device-detect';
import Snackbar from '../../Snackbar/Snackbar';
import { isMultiUnit } from '../../../helpers/Comparators';

/**
 *  Our Grid component.
 * 	This is used for our drag n drop features of the custom (magic) window.
 *  Basically, the grid is populated with cells from the columns and rows toggle
 *  above it in the UI. Those buttons add x amount of cells based on the calculation they have.
 *
 * 	If Col = 3 and Rows = 3 and we toggle the Cols to be 4. The system will then generate
 *  us 3 more cells or (rows number) of cells. The inverse goes for if we toggle Rows.
 *
 * 	Cols and Rows have a hard min of 1.
 *
 *  */
const Grid = ({
	code,
	handleMerge,
	handleUnMerge,
	openCustomWindowConfigurationData,
	orderIndex,
}) => {
	const [showResizingBtns, setShowResizingBtns] = useState(false);
	const { toggleOverlay } = useUIManagementContext();
	const [layout, setLayout] = useState([]);
	const [message, setMessage] = useState('');
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [title, setTitle] = useState('');
	const inputRef = useRef();
	const layoutRef = useRef();

	const { customWindowState, customWindowDispatch } = useCustomWindowContext();
	const {
		canUnmerge,
		cells,
		columnsBaseSize,
		defaultResizingInputsValues,
		dimensionsOfOpening,
		lastReducedAction,
		resizingInputsValues,
		rowsBaseSize,
		selectedCells,
	} = customWindowState;

	const rowHeight = computeRowHeight(
		isMobile ? dimensionsOfOpening.height / 1.75 : dimensionsOfOpening.height * 1,
		dimensionsOfOpening.width
	);

	const isMulti = isMultiUnit(null, code, 'CUSTOM_ICON_SM');

	const toggleResizingBtns = (submitted) =>
		setShowResizingBtns((s) => {
			if (typeof submitted !== 'boolean') {
				snapBackValues();
			}
			return !s;
		});

	/* If the user cancels the edit action, reset our resizing input values. */
	const snapBackValues = () => {
		const xKeys = Object.keys(defaultResizingInputsValues.x);
		const yKeys = Object.keys(defaultResizingInputsValues.y);

		for (const key of xKeys) {
			customWindowDispatch(
				updateResizingDimensionInputsAction({
					location: 'x',
					index: key,
					value: defaultResizingInputsValues.x[key],
				})
			);
		}
		for (const key of yKeys) {
			customWindowDispatch(
				updateResizingDimensionInputsAction({
					location: 'y',
					index: key,
					value: defaultResizingInputsValues.y[key],
				})
			);
		}
	};

	const updateGridDimensionInputs = (location, e) => {
		const value = e.target.value || 0;
		const index = e.target.name;
		customWindowDispatch(
			updateResizingDimensionInputsAction({ location, index, value })
		);
	};

	const handleResizingHeightChange = (e) => updateGridDimensionInputs('y', e);
	const handleResizingWidthChange = (e) => updateGridDimensionInputs('x', e);

	/*
	 *  Check if new resizing dimensions is equal to existing total dimensions.
	 *  IF: Resize grid columns with the new values.
	 *  ELSE: Display warning popup with details message.
	 *
	 *  This is our resizing logic for the textfields on the sides of our grid layout.
	 *  We NEED the dimensions of the rows and columns to agree with the dimensions
	 *  the user established in step 1. No more, no less.
	 */
	const resizeGridDimension = async () => {
		let totalHeightInches = 0;
		Object.values(resizingInputsValues.y).forEach((height) => {
			totalHeightInches = totalHeightInches + Number(height);
		});
		totalHeightInches = toFixed(totalHeightInches, 100);

		let totalWidthInches = 0;
		Object.values(resizingInputsValues.x).forEach((width) => {
			totalWidthInches = totalWidthInches + Number(width);
		});
		totalWidthInches = toFixed(totalWidthInches, 100);

		if (
			dimensionsOfOpening.height === Number(totalHeightInches) &&
			dimensionsOfOpening.width === parseFloat(totalWidthInches)
		) {
			await customWindowDispatch(resizeGridDimensionAction());
			await customWindowDispatch(updateCells(structureLayout()));
			await customWindowDispatch(
				updateDefaultResizingDimensionInputs(resizingInputsValues)
			);
		} else {
			toggleOverlay({
				active: true,
				type: 'error_messages',
				data: {
					errors: [
						'You entered window dimensions less than the original opening dimensions.',
						`- Open dimensions height: ${dimensionsOfOpening.height}in. You entered Height of ${totalHeightInches}in.`,
						`- Open dimensions width: ${dimensionsOfOpening.width}in. You entered width of ${totalWidthInches}in.`,
					],
				},
			});
			return;
		}
		toggleResizingBtns(true);
	};

	const handleOnBlur = (e) => {
		let value = Number(e.currentTarget.value);
		if (isNaN(value)) {
			e.currentTarget.value = 0;
		} else {
			value = Math.min(100, Math.max(0, value));
			e.currentTarget.value = toFixed(value, 1000);
		}
	};

	const resizingButtonsActiveStyle = showResizingBtns
		? ' bg-ws-x-light-blue border-ws-m-light-blue '
		: ' bg-white border-transparent ';

	const isMergeButtonDisabled = () => {
		return selectedCells && selectedCells.length < 2;
	};

	/**
	 *   When our layout changes in our ShowcaseLayout, reupdate the
	 *   positioning of our grid by remapping values.
	 */
	const onLayoutChange = (newLayout) => {
		setLayout(structureLayout(newLayout));
	};

	/* 
	   Parse through our cells array to something the ShowcaseLayout can understand.
	   We'll need the x and y values to be something that ShowcaseLayout creates so 
	   we can keep our position on the grid.

	   h and w will need to be on a whole number basis. What essentially h = 1, w = 1 
	   means that a cell should take up 1 space on our grid. If a cell has been merged 
	   horizontally or vertically, then the appropriate h,w value will be multiplied 
	   below to the new value.

	   We want our cells to maintain their height and width if we merge them while
	   constraining their maxHeight and maxWidth to the size of the grid the user 
	   created.

	   @Params: Optional new layout from our RGL.
	 */
	const structureLayout = (newLayout) => {
		const tempArr = [];
		const tempCells = JSON.parse(JSON.stringify(cells));
		for (let index = 0; index < tempCells.length; index++) {
			const cell = tempCells[index];
			cell.i = cell.key;
			cell.h = parseInt(calculateHeight(cell));
			cell.w = parseInt(calculateWidth(cell));
			cell.x =
				newLayout && index < newLayout.length
					? calcX(cell, index, tempArr)
					: parseX(cell, index, tempArr);
			cell.y = newLayout && index < newLayout.length ? calcY() : parseY(cell);
			tempArr.push(cell);
		}

		return tempArr.sort((a, b) => {
			return a.location[0].x - b.location[0].x && a.location[0].y - b.location[0].y;
		});
	};

	const parseX = (cell, index, tempArr) => {
		if (
			lastReducedAction === 'SET_COMMON_WIDTH' ||
			lastReducedAction === 'RESIZE_GRID_DIMENSION'
		) {
			return calcX(cell, index, tempArr);
		}
		const location = cell.location[0].x;
		const locCalc = location * dimensionsOfOpening.width;
		return parseInt(locCalc / columnsBaseSize);
	};

	const parseY = (cell) => {
		return parseInt((cell.location[0].y * dimensionsOfOpening.height) / rowsBaseSize);
	};

	/* 
		If the start of a row, x should be 0. 
		If the y of the previous cell matches the current one, 
		they must be in the same row.
		If they don't match. It must be a new row.
	*/
	const calcX = (cell, index, tempArr) => {
		if (index !== 0 && tempArr[index - 1].location[0].y === cell.location[0].y) {
			return tempArr[index - 1].w + tempArr[index - 1].x;
		}
		return 0;
	};

	const calcY = () => {
		return Math.floor(dimensionsOfOpening.height / rowsBaseSize);
	};

	// Calculate the height of the cell based on the textinputs in the grid.
	const calculateHeight = (cell) => {
		const currentHeight = cell.product.configuration_window_size_height;
		const ratio = currentHeight / dimensionsOfOpening.height;
		return ratio * 22;
	};

	// Calculate the width of the cell based on the textinputs in the grid.
	const calculateWidth = (cell) => {
		const currentWidth = cell.product.configuration_window_size_width;
		return currentWidth;
	};

	const renderValue = (index, pos) => {
		return showResizingBtns
			? resizingInputsValues[pos]?.[index] ?? 0
			: (resizingInputsValues[pos]?.[index] ?? 0).toFixed(3);
	};

	/**
	 * Show all of our needed inputs to the left of our RGL.
	 */
	const renderVerticalInputs = () => {
		const temp = [];
		for (let index = 0; index < rowsBaseSize; index++) {
			temp.push(
				<input
					className={` h-8 w-20 mr-2 text-center text-ws-second-gray ${resizingButtonsActiveStyle} border border-solid  rounded transition-all focus:border-ws-blue`}
					disabled={!showResizingBtns}
					type="number"
					key={index.toString()}
					placeholder="0"
					name={index}
					value={renderValue(index, 'y')}
					onChange={handleResizingHeightChange}
					onBlur={handleOnBlur}
				/>
			);
		}
		return temp;
	};

	/**
	 * Show all of our needed inputs on the bottom row of our RGL.
	 */
	const renderHorizontalInputs = () => {
		const temp = [];
		for (let index = 0; index < columnsBaseSize; index++) {
			temp.push(
				<input
					className={`h-8 w-20 mr-2 text-center text-ws-second-gray ${resizingButtonsActiveStyle} border border-solid  rounded transition-all focus:border-ws-blue`}
					type="number"
					key={index.toString()}
					placeholder="0"
					name={index}
					value={renderValue(index, 'x')}
					onChange={handleResizingWidthChange}
					disabled={!showResizingBtns}
					onBlur={handleOnBlur}
				/>
			);
		}
		return temp;
	};

	const handleShowSnackbar = () => {
		setMessage('Please select a window that fits in that cell');
		setTitle('Cannot place window');
		setShowSnackbar(true);
		handleSnackbarTimeout();
	};

	const handleSnackbarTimeout = () => {
		setTimeout(() => {
			setShowSnackbar(false);
		}, 3000);
	};

	return (
		<>
			<div className="w-3/4 mr-2">
				<div className="grid w-full">
					{isMulti && (
						<div className="flex items-center self-center justify-end w-full mb-4">
							{canUnmerge && (
								<Btn
									btnType="btn--small btn--danger .cursor-pointer mr-3"
									onClick={handleUnMerge}
								>
									Unmerge
								</Btn>
							)}
							<Btn
								btnType={
									isMergeButtonDisabled()
										? 'btn--small btn--outline .cursor-not-allowed'
										: 'btn--small btn--primary .cursor-pointer'
								}
								onClick={handleMerge}
							>
								Merge
							</Btn>
						</div>
					)}

					{/* Our parent container for our grid stuff. */}
					<div className="flex flex-row w-full h-full overflow-hidden">
						{/* Our linear input layout to the left of the dnd grid. */}
						<div className="flex flex-col h-full">
							<div
								className="flex flex-col justify-around h-full"
								ref={inputRef}
							>
								{renderVerticalInputs()}
							</div>

							{/* Resizing dimension buttons */}
							{isMulti && (
								<div className="flex flex-row items-center gap-3 mt-4 w-min">
									{/* Toggle resizing dimensions Buttons */}
									{showResizingBtns ? (
										<>
											{/* Confirm resizing button */}
											<button
												className="flex items-center justify-center w-8 h-8 text-sm text-white border border-solid rounded bg-ws-blue"
												onClick={resizeGridDimension}
											>
												<span className="icon-fas icon--check" />
											</button>
											{/* Cancel resizing button */}
											<button
												onClick={toggleResizingBtns}
												className="flex items-center justify-center w-8 h-8 text-sm border border-solid rounded bg-second-light-sky text-ws-blue"
											>
												<span className="icon-fas icon--close" />
											</button>
										</>
									) : (
										<button
											onClick={toggleResizingBtns}
											className="flex items-center justify-center w-8 h-8 text-sm border border-solid rounded bg-ws-second-light-sky text-ws-blue"
										>
											<span className="icon-fas icon--pencil" />
										</button>
									)}
								</div>
							)}
						</div>

						<div
							className="flex flex-col items-start justify-start w-full"
							ref={layoutRef}
						>
							<div className="flex items-start self-center justify-start w-full">
								<ShowcaseLayout
									cols={{
										xxs: dimensionsOfOpening.width,
										lg: dimensionsOfOpening.width,
									}}
									layout={structureLayout()}
									onLayoutChange={onLayoutChange}
									openCustomWindowConfigurationData={
										openCustomWindowConfigurationData
									}
									orderIndex={orderIndex}
									overrideClick={false}
									rowHeight={rowHeight}
									isDraggable={false}
									rowsBaseSize={rowsBaseSize}
									inMultiUnitPath={true}
									showSnackbar={handleShowSnackbar}
								/>
							</div>

							{/* Our horizontal input layout at the bottom of our dnd grid. */}
							<div className="flex flex-row items-center justify-around w-full pt-3">
								{renderHorizontalInputs()}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Snackbar message={message} open={showSnackbar} title={title} />
		</>
	);
};

export default Grid;
