/**
 * This file should hold all of our helper math-like functions.
 */

/**
 * Use this function instead of the native "toFixed(x)" Number function so we can
 * keep numbers as numbers and strings as strings etc.
 * @param: num (our value to show), digits (decimal places that should show.)
 */
export const toFixed = (num, digits) => {
	return Math.round(num * digits) / digits;
};

/**
 * Use this function to check to see if a number is a square or not.
 * @param n (any javascript number)
 * @returns true/false if square
 */
export const isSquare = (n) => {
	return n > 0 && Math.sqrt(n) % 1 === 0;
};

/**
 * This is a helper function for determining height in our responsive grid layout.
 * Calculate the height of the cell based on the textinputs in the grid.
 */
export const calculateHeight = (cell, dimensionsOfOpening) => {
	const currentHeight = cell.product.configuration_window_size_height;
	const ratio = currentHeight / dimensionsOfOpening.height;
	return ratio * 20;
};

/**
 * This is a helper function for determining width in our responsive grid layout.
 * Calculate the width of the cell based on the textinputs in the grid.
 */
export const calculateWidth = (cell) => {
	return cell.product.configuration_window_size_width;
};

/**
 * This is a helper function for determining X position in our responsive grid layout.
 * If the start of a row, x should be 0.
 * If the y of the previous cell matches the current one,
 * they must be in the same row.
 * If they don't match. It must be a new row.
 */
export const calcX = (cell, index, tempArr) => {
	if (index !== 0 && tempArr[index - 1].location[0].y === cell.location[0].y) {
		return tempArr[index - 1].w + tempArr[index - 1].x;
	}

	return 0;
};

/**
 * This is a workaround for the MultiUnitSnapshot.
 */
export const calcSnapshotX = (cell, index, prevCell) => {
	if (!prevCell) {
		return 0;
	}

	prevCell = prevCell.values.col;

	if (index !== 0 && prevCell.location[0].y === cell.location[0].y) {
		return prevCell.w + prevCell.x;
	}

	return 0;
};

/**
 * This is a helper function for determining Y position in our responsive grid layout.
 */
export const calcY = (cell, dimensionsOfOpening, rowsBaseSize) => {
	return parseInt((cell.location[0].y * dimensionsOfOpening.height) / rowsBaseSize);
};

/**
 * Compute the parent container's height for the grid layout.
 * Do some calculation to get a ratio for the height based on the dimension from step 1.
 */
export const computeHeight = (height, width) => {
	const total = height + width;
	const ratio = height / total;
	return toFixed(ratio * 700, 1000);
};
/**
 * Compute the parent container's width for the grid layout.
 * Do some calculation to get a ratio for the width based on the dimensino from step 1.
 */
export const computeWidth = (height, width) => {
	const total = height + width;
	const ratio = width / total;
	const result = toFixed(ratio * 700, 1000);
	if (result * 2 > 800) {
		return 370;
	}
	return result;
};

/**
 * Compute the rowHeight for our grid layout.
 * Do some calculation to get the ShowcaseLayout's row height.
 */
export const computeRowHeight = (dimHeight, dimWidth) => {
	const height = computeHeight(dimHeight, dimWidth);
	const width = computeWidth(dimHeight, dimWidth);
	const ratio = height / width;
	let multiplier = 30;

	// Height is greater than width, lower multiplier.
	if (ratio > 1) {
		if (ratio > 9) {
			multiplier = 6;
		} else if (ratio > 5) {
			multiplier = 10;
		} else if (ratio > 3) {
			multiplier = 14;
		} else if (ratio > 2.45) {
			multiplier = 18;
		} else if (ratio > 2) {
			multiplier = 20;
		} else if (ratio > 1.6) {
			multiplier = 23;
		} else if (ratio > 1.3) {
			multiplier = 25;
		} else if (ratio > 1.2) {
			multiplier = 27;
		} else {
			multiplier = 28;
		}
	}

	// Width is greater than height, higher multiplier.
	if (ratio < 1) {
		multiplier = 37;

		if (ratio < 0.18) {
			multiplier = 0;
		} else if (ratio < 0.2) {
			multiplier = 6;
		} else if (ratio < 0.4) {
			multiplier = 13;
		} else if (ratio < 0.5) {
			multiplier = 15;
		} else if (ratio < 0.7) {
			multiplier = 28;
		} else if (ratio < 0.8) {
			multiplier = 32; //
		} else if (ratio < 0.9) {
			multiplier = 35;
		}
	}

	return ratio * multiplier; // 20 is our magic number for a "full" container.
};
