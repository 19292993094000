import React, { Fragment } from 'react';
import Input from '../../../../Input/Input';
import ImageAsset from '../../../../ImageAsset/ImageAsset';
import ConfigurationLabelMapper from '../../../../../functionality/ConfigurationLabelMapper';
/**
 *
 */
export const GridTab = ({ grid_config, gridData, setGridData }) => {
	const { exteriorMuntinColor, interiorMuntinColor, removeMuntin } = grid_config;

	const setData = (e, key) => {
		const value = e.currentTarget.checked;
		setGridData((prev) => {
			return {
				...prev,
				...{
					[key]: value,
				},
			};
		});
	};

	const onInteriorMuntinChange = (e) => {
		const value = e.currentTarget.value;
		const option = interiorMuntinColor.values.find((option) => {
			return option.value === value;
		});
		setGridData((prev) => {
			return {
				...prev,
				...{
					selectedInteriorMuntinColorValue: value,
					selectedInteriorMuntinColorOption: option,
					selectedExteriorMuntinColorValue: option.exteriorMutinColorDefault,
				},
			};
		});
	};

	return (
		<>
			<div className="config-heading heading heading--4">
				<label htmlFor={removeMuntin.fieldName}>{removeMuntin.heading}</label>
			</div>
			<Input
				type="toggle"
				id={removeMuntin.fieldName}
				name={removeMuntin.fieldName}
				checked={!!gridData.removeMuntin}
				onChange={(e) => setData(e, 'removeMuntin')}
			/>
			<p className="overlay-s2__body-note">
				Note: These settings only affect 'Flat' and 'Contour' Muntins.
			</p>
			{!gridData.removeMuntin && (
				<>
					<div className="config-divider"></div>
					<div className="config-heading heading heading--4">
						{interiorMuntinColor.heading}
					</div>
					<div className="config-subheading heading heading--6">
						Select a Muntin color
					</div>
					<div className="config-notch-select">
						{interiorMuntinColor.values.map((option) => {
							return (
								<Fragment key={option.value}>
									<input
										type="radio"
										name={interiorMuntinColor.fieldName}
										id={`${interiorMuntinColor.fieldName}_${option.value}`}
										value={option.value}
										checked={
											option.value ===
											gridData.selectedInteriorMuntinColorValue
										}
										onChange={onInteriorMuntinChange}
									/>
									<label
										htmlFor={`${interiorMuntinColor.fieldName}_${option.value}`}
										className={`config-notch-select__option`}
									>
										<div className="config-notch-select__button">
											<span className="config-notch-select__notch"></span>
											<ImageAsset code={option.value} />
										</div>
										<div className="config-notch-select__label heading heading--10">
											{ConfigurationLabelMapper(option.value)}
										</div>
									</label>
								</Fragment>
							);
						})}
					</div>
					<div className="config-divider"></div>
					<div className="config-heading heading heading--4">
						{exteriorMuntinColor.heading}
					</div>
					<div className="config-subheading heading heading--6">
						Select a Muntin color
					</div>
					<div className="config-notch-select">
						{exteriorMuntinColor.values.map((value) => {
							const isDisabled =
								gridData.selectedInteriorMuntinColorOption === false ||
								gridData.selectedInteriorMuntinColorOption.exteriorMutinColorOptions.indexOf(
									value
								) === -1;
							return (
								<Fragment key={value}>
									<input
										type="radio"
										name={exteriorMuntinColor.fieldName}
										id={`${exteriorMuntinColor.fieldName}_${value}`}
										value={value}
										{...(isDisabled && {
											disabled: true,
										})}
										checked={
											value ===
											gridData.selectedExteriorMuntinColorValue
										}
										onChange={(e) =>
											setData(e, 'selectedExteriorMuntinColorValue')
										}
									/>
									<label
										htmlFor={`${exteriorMuntinColor.fieldName}_${value}`}
										className={`config-notch-select__option ${
											isDisabled
												? 'config-notch-select__option--inactive'
												: ''
										}`}
									>
										<div className="config-notch-select__button">
											<span className="config-notch-select__notch"></span>
											<ImageAsset code={value} />
										</div>
										<div className="config-notch-select__label heading heading--10">
											{ConfigurationLabelMapper(value)}
										</div>
									</label>
								</Fragment>
							);
						})}
					</div>
				</>
			)}
		</>
	);
};
