import React, { useContext } from 'react';
import { scrollWindow } from '../functionality/UIUtilities';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const UIManagementContext = React.createContext();
export default UIManagementContext;

export const useUIManagementContext = () => {
	return useContext(UIManagementContext);
};

export class UIManagementContextProvider extends React.Component {
	// the component state
	state = {
		// keep track if we should bee blocking the back button
		blockBack: false,
		// keep track of what configuration is open
		openConfiguration: 0,
		openCustomWindowConfiguration: 1,
		// data for the overlays
		overlay: {
			active: false,
			type: null,
			data: {},
		},
		// data for the system notification
		systemNotification: {
			active: false,
			messageKey: '',
		},
	};

	// toggle system notification methods
	toggleSystemNotification = (data) => {
		this.setState((prev) => {
			return { systemNotification: { ...prev.systemNotification, ...data } };
		});
	};

	// toggle the block back
	toggleBlockBack = (block) => {
		return new Promise((res, _rej) => {
			this.setState(
				{
					blockBack: block,
				},
				() => {
					res();
				}
			);
		});
	};

	// get block back
	getBlockBack = () => {
		return this.state.blockBack;
	};

	// ----------------------
	// overlay methods

	// get the overlay
	overlayData = () => {
		return this.state.overlay;
	};
	// toggle the overlay
	toggleOverlay = (data) => {
		this.setState(
			{
				overlay: Object.assign(
					{
						active: false,
						type: '',
						data: {},
					},
					data
				),
			},
			() => {
				if (this.state.overlay.active) {
					disableBodyScroll(document.querySelector('.overlay'));
				} else {
					clearAllBodyScrollLocks();
				}
			}
		);
	};

	// ----------------------
	// open configuration methods

	// the get the open configuration
	openConfigurationData = () => {
		return this.state.openConfiguration;
	};

	openCustomWindowConfigurationData = () => {
		return this.state.openCustomWindowConfiguration;
	};

	// toggle configuration open / closed
	toggleOpenConfiguration = (index) => {
		this.setState(
			(prev) => {
				return {
					openConfiguration: prev.openConfiguration === index ? -1 : index,
				};
			},
			() => {
				if (this.state.openConfiguration !== -1) {
					// let's get the height of the header, and the amount to scroll
					const headerHeight =
						document.querySelector('.layout1__navbar')?.offsetHeight;
					const positionTop = document.querySelector(
						'.layout1__content .productconfiguration:nth-of-type(' +
							this.state.openConfiguration +
							')'
					)?.offsetTop;
					const scrollTop = positionTop - (headerHeight + 25);
					scrollWindow({ destination: scrollTop });
				}
			}
		);
	};

	toggleCustomWindowOpenConfiguration = (index) => {
		this.setState(
			(prev) => {
				return {
					openCustomWindowConfiguration:
						prev.openCustomWindowConfiguration === index ? -1 : index,
				};
			},
			() => {
				if (this.state.openCustomWindowConfiguration !== -1) {
					// let's get the height of the header, and the amount to scroll
					const headerHeight =
						document.querySelector('.layout1__navbar')?.offsetHeight;
					const positionTop = document.querySelector(
						'.layout1__content .productconfiguration:nth-of-type(' +
							this.state.openCustomWindowConfiguration +
							')'
					)?.offsetTop;
					const scrollTop = positionTop - (headerHeight + 25);
					scrollWindow({ destination: scrollTop });
				}
			}
		);
	};

	render() {
		const value = {
			// data
			systemNotification: this.state.systemNotification,
			// methods
			overlayData: this.overlayData,
			toggleOverlay: this.toggleOverlay,
			toggleSystemNotification: this.toggleSystemNotification,
			openConfigurationData: this.openConfigurationData,
			toggleOpenConfiguration: this.toggleOpenConfiguration,
			toggleBlockBack: this.toggleBlockBack,
			getBlockBack: this.getBlockBack,
			openCustomWindowConfigurationData: this.openCustomWindowConfigurationData,
			toggleCustomWindowOpenConfiguration: this.toggleCustomWindowOpenConfiguration,
		};
		return (
			<UIManagementContext.Provider
				value={value}
				displayName="UI Management Context"
			>
				{this.props.children}
			</UIManagementContext.Provider>
		);
	}
}
