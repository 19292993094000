export default {
	// we need to track the high level product information
	code: 'OTX_OCTAGON_WITH_EXTENDED_LEGS',
	title: 'OTX Octagon with Extended Legs',
	description: '',
	type: 'window',
	images: ['SPECIALTY_PROD'],

	// let's define our configurations
	configurations: {
		// window location
		WINDOW_LOCATION: {
			configurationId: 'WINDOW_LOCATION',
			configurationType: 'window_location',
			heading: 'Window Location',
			standard: {
				fieldName: 'configuration_window_location',
				defaultValue: '',
				values: [
					'ATTIC_FRONT',
					'ATTIC_SIDE',
					'ATTIC_REAR',
					'BASEMENT_FRONT',
					'BASEMENT_SIDE',
					'BASEMENT_REAR',
					'BATHROOM1_FRONT',
					'BATHROOM1_SIDE',
					'BATHROOM1_REAR',
					'BATHROOM2_FRONT',
					'BATHROOM2_SIDE',
					'BATHROOM2_REAR',
					'BATHROOM3_FRONT',
					'BATHROOM3_SIDE',
					'BATHROOM3_REAR',
					'BEDROOM1_FRONT',
					'BEDROOM1_SIDE',
					'BEDROOM1_REAR',
					'BEDROOM2_FRONT',
					'BEDROOM2_SIDE',
					'BEDROOM2_REAR',
					'BEDROOM3_FRONT',
					'BEDROOM3_SIDE',
					'BEDROOM3_REAR',
					'BEDROOM4_FRONT',
					'BEDROOM4_SIDE',
					'BEDROOM4_REAR',
					'BEDROOM5_FRONT',
					'BEDROOM5_SIDE',
					'BEDROOM5_REAR',
					'BEDROOM6_FRONT',
					'BEDROOM6_SIDE',
					'BEDROOM6_REAR',
					'BREEZEWAY_FRONT',
					'BREEZEWAY_SIDE',
					'BREEZEWAY_REAR',
					'CLOSET_FRONT',
					'CLOSET_SIDE',
					'CLOSET_REAR',
					'DEN_FRONT',
					'DEN_SIDE',
					'DEN_REAR',
					'DINING_ROOM_FRONT',
					'DINING_ROOM_SIDE',
					'DINING_ROOM_REAR',
					'FAMILY_ROOM_FRONT',
					'FAMILY_ROOM_SIDE',
					'FAMILY_ROOM_REAR',
					'FOYER_FRONT',
					'FOYER_SIDE',
					'FOYER_REAR',
					'GARAGE_FRONT',
					'GARAGE_SIDE',
					'GARAGE_REAR',
					'HALLWAY_FRONT',
					'HALLWAY_SIDE',
					'HALLWAY_REAR',
					'KITCHEN_FRONT',
					'KITCHEN_SIDE',
					'KITCHEN_REAR',
					'LAUNDRY_ROOM',
					'LIBRARY_FRONT',
					'LIBRARY_SIDE',
					'LIBRARY_REAR',
					'LIVING_ROOM_FRONT',
					'LIVING_ROOM_SIDE',
					'LIVING_ROOM_REAR',
					'NOOK_FRONT',
					'NOOK_SIDE',
					'NOOK_REAR',
					'NURSERY',
					'OFFICE_FRONT',
					'OFFICE_SIDE',
					'OFFICE_REAR',
					'PORCH_FRONT',
					'PORCH_SIDE',
					'PORCH_REAR',
					'SANCTUARY',
					'STAIRWAY_FRONT',
					'STAIRWAY_SIDE',
					'STAIRWAY_REAR',
					'UNIT1',
					'UNIT2',
					'UNIT3',
					'UNIT4',
					'UNIT5',
					'UNIT6',
					'UNIT7',
					'UNIT8',
					'UNIT9',
					'UNIT10',
					'UNIT11',
					'UNIT12',
					'UNIT13',
					'UNIT14',
					'UNIT15',
					'FRONT',
					'SIDE',
					'REAR',
					'BUILD_ONLY',
					'CUSTOM',
				],
			},
			custom: {
				fieldName: 'configuration_window_location_custom',
				defaultValue: '',
			},
		},

		// window size
		WINDOW_SIZE: {
			configurationId: 'WINDOW_SIZE',
			configurationType: 'window_size',
			heading: 'Window Size',
			fieldNameWidth: 'configuration_window_size_width',
			fieldNameHeight: 'configuration_window_size_height',
			defaultValueWidth: 0,
			minimumValueWidth: 12,
			maximumValueWidth: 130,
			defaultValueHeight: 0,
			minimumValueHeight: 12,
			maximumValueHeight: 130,
			maximumValueTotal: false,
		},

		// house type
		HOUSE_TYPE: {
			configurationId: 'HOUSE_TYPE',
			configurationType: 'basic_select',
			heading: 'House Type',
			fieldName: 'configuration_window_house_type',
			defaultValue: '',
			values: ['FRAME', 'BRICK', 'BLOCK'],
		},

		// removal type
		REMOVAL_TYPE: {
			configurationId: 'REMOVAL_TYPE',
			configurationType: 'window_removal_type',
			heading: 'Removal Type',
			fieldName: 'configuration_window_removal_type',
			defaultValue: '',
			values: ['VINYL', 'WOOD', 'ALUMINUM', 'STEEL', 'GLASS_BLOCK'],
		},

		// outside install
		OUTSIDE_INSTALL: {
			configurationId: 'OUTSIDE_INSTALL',
			configurationType: 'window_outside_install',
			heading: 'Outside Install',
			fieldName: 'configuration_window_outside_install',
			defaultValue: false,
		},

		// window color
		WINDOW_COLOR: {
			configurationId: 'WINDOW_COLOR',
			configurationType: 'window_color',
			heading: 'Color',
			interiorColor: {
				heading: 'Interior Color',
				fieldName: 'configuration_window_interior_color',
				defaultValue: 'WHITE',
				values: [
					{
						value: 'WHITE',
						defaultExteriorColor: 'WHITE',
						exteriorColorOptions: [
							'WHITE',
							'ALMOND',
							'BLACK',
							'CLAY',
							'TERRATONE',
						],
					},
					{
						value: 'ALMOND',
						defaultExteriorColor: 'ALMOND',
						exteriorColorOptions: ['ALMOND'],
					},
					{
						value: 'MEDIUM_WOOD',
						defaultExteriorColor: 'WHITE',
						exteriorColorOptions: ['WHITE', 'ALMOND', 'CLAY', 'TERRATONE'],
					},
					{
						value: 'DARK_WOOD',
						defaultExteriorColor: 'WHITE',
						exteriorColorOptions: ['WHITE', 'ALMOND', 'CLAY', 'TERRATONE'],
					},
				],
			},
			exteriorColor: {
				heading: 'Exterior Color',
				fieldName: 'configuration_window_exterior_color',
				values: ['WHITE', 'ALMOND', 'BLACK', 'CLAY', 'TERRATONE'],
			},
			exteriorTrim: {
				heading: 'Exterior Trim',
				fieldName: 'configuration_window_exterior_trim_material',
				defaultValue: 'ALUMINUM',
				values: [
					{
						value: 'ALUMINUM',
						trimColorDefault: 'WHITE_280',
						trimColorOptions: [
							'PEARL_GRAY_102',
							'ANTIQUE_IVORY_105',
							'TUXEDO_GRAY_112',
							'BROWN_190',
							'BLACK_200',
							'ROYAL_BROWN_202',
							'GRECIAN_GREEN_204',
							'SCOTCH_RED_209',
							'IMPERIAL_BROWN_223',
							'BUCKSKIN_224',
							'FLAGSTONE_232',
							'HICKORY_CLAY_238',
							'MUSKET_BROWN_250',
							'WHITE_280',
							'DESERT_TAN_327',
							'TERRATONE_360',
							'OXFORD_BLUE_396',
							'WICKER_713',
							'NORWEGIAN_WOOD_791',
							'SANDCASTLE_793',
							'ALMOND_801',
							'HERRINGBONE_819',
							'CRANBERRY_820',
							'IVY_821',
							'BUCKSKIN_841',
						],
					},
					{
						value: 'PVC',
						trimColorDefault: 'PVC_WHITE_800',
						trimColorOptions: ['PVC_WHITE_800', 'PVC_ALMOND_801', 'CUSTOM'],
					},
					{
						value: 'NONE',
						trimColorDefault: false,
						trimColorOptions: false,
					},
				],
			},
			trimColor: {
				heading: 'Select a Trim Color',
				fieldName: 'configuration_window_exterior_trim_color',
				values: [
					'WHITE_280',
					'PEARL_GRAY_102',
					'ANTIQUE_IVORY_105',
					'TUXEDO_GRAY_112',
					'BROWN_190',
					'BLACK_200',
					'ROYAL_BROWN_202',
					'GRECIAN_GREEN_204',
					'SCOTCH_RED_209',
					'IMPERIAL_BROWN_223',
					'BUCKSKIN_224',
					'FLAGSTONE_232',
					'HICKORY_CLAY_238',
					'MUSKET_BROWN_250',
					'DESERT_TAN_327',
					'TERRATONE_360',
					'OXFORD_BLUE_396',
					'WICKER_713',
					'NORWEGIAN_WOOD_791',
					'SANDCASTLE_793',
					'ALMOND_801',
					'HERRINGBONE_819',
					'CRANBERRY_820',
					'IVY_821',
					'BUCKSKIN_841',
					'PVC_WHITE_800',
					'PVC_ALMOND_801',
					'CUSTOM',
				],
			},
			exteriorCedarTrim: {
				heading: 'Exterior Cedar Trim',
				fieldName: 'configuration_window_exterior_trim_cedar',
				defaultValue: false,
			},
			exteriorCedarSize: {
				heading: 'Select a Size',
				fieldName: 'configuration_window_exterior_trim_cedar_size',
				defaultValue: '1X6',
				values: ['1X6', '1X8', '1X10', '1X12'],
			},
			exteriorCedarDimensions: {
				heading: 'Exterior Cedar Trim Dimensions',
				fieldName: 'configuration_window_exterior_trim_cedar_dimensions',
				defaultValue: 0,
				minimumValue: 0,
			},
		},

		// window grid
		WINDOW_GRID: {
			configurationId: 'WINDOW_GRID',
			configurationType: 'window_grid',
			heading: 'Grid',
			style: {
				heading: 'Muntin Style',
				fieldName: 'configuration_window_grid_style',
				defaultValue: 'NONE',
				values: [
					{
						value: 'NONE',
						interiorMutinColorDefault: false,
						interiorMutinColorOptions: false,
						patternDefault: false,
						patternOptions: false,
					},
					{
						value: 'FLAT',
						interiorMutinColorDefault: 'WHITE',
						interiorMutinColorOptions: [
							'WHITE',
							'ALMOND',
							'BLACK',
							'BRONZEH',
							'CLAY',
							'DARK_WOOD',
							'MEDIUM_WOOD',
							'TERRATONE',
						],
						patternDefault: 'COLONIAL',
						patternOptions: [
							'COLONIAL',
							'VERTICAL',
							'HORIZONTAL',
							'SINGLE_PRAIRIE',
							'DOUBLE_PRAIRIE',
							'DIAMOND',
							'SPOKES',
							'HUB_AND_SPOKES',
							'DOUBLE_HUB_AND_SPOKES',
							'CUSTOM',
						],
					},
					{
						value: 'CONTOUR',
						interiorMutinColorDefault: 'WHITE',
						interiorMutinColorOptions: [
							'WHITE',
							'ALMOND',
							'BLACK',
							'BRONZEH',
							'CLAY',
							'DARK_WOOD',
							'MEDIUM_WOOD',
							'TERRATONE',
						],
						patternDefault: 'COLONIAL',
						patternOptions: [
							'COLONIAL',
							'VERTICAL',
							'HORIZONTAL',
							'SINGLE_PRAIRIE',
							'DOUBLE_PRAIRIE',
							'SPOKES',
							'HUB_AND_SPOKES',
							'DOUBLE_HUB_AND_SPOKES',
							'CUSTOM',
						],
					},
					{
						value: 'SLIM_LINE',
						interiorMutinColorDefault: 'WHITE_SLIM',
						interiorMutinColorOptions: [
							'WHITE_SLIM',
							'GOLD_SLIM',
							'BRONZE_SLIM',
						],
						patternDefault: 'COLONIAL',
						patternOptions: [
							'COLONIAL',
							'VERTICAL',
							'HORIZONTAL',
							'SINGLE_PRAIRIE',
							'DOUBLE_PRAIRIE',
							'SPOKES',
							'HUB_AND_SPOKES',
							'DOUBLE_HUB_AND_SPOKES',
							'CUSTOM',
						],
					},
				],
			},
			pattern: {
				heading: 'Grid Pattern',
				fieldName: 'configuration_window_grid_pattern',
				values: [
					{
						value: 'COLONIAL',
					},
					{
						value: 'VERTICAL',
					},
					{
						value: 'HORIZONTAL',
					},
					{
						value: 'SINGLE_PRAIRIE',
					},
					{
						value: 'DOUBLE_PRAIRIE',
					},
					{
						value: 'DIAMOND',
					},
					{
						value: 'SPOKES',
					},
					{
						value: 'HUB_AND_SPOKES',
					},
					{
						value: 'DOUBLE_HUB_AND_SPOKES',
					},
					{
						value: 'CUSTOM',
					},
				],
			},
			details: {
				heading: 'Grid Details',
				fieldName: 'configuration_window_grid_details',
			},
			interiorMuntinColor: {
				heading: 'Interior Muntin Color',
				fieldName: 'configuration_window_interior_muntin_color',
				values: [
					{
						value: 'WHITE',
						exteriorMutinColorDefault: 'WHITE',
						exteriorMutinColorOptions: [
							'WHITE',
							'ALMOND',
							'BLACK',
							'CLAY',
							'TERRATONE',
						],
					},
					{
						value: 'ALMOND',
						exteriorMutinColorDefault: 'ALMOND',
						exteriorMutinColorOptions: ['ALMOND'],
					},
					{
						value: 'BLACK',
						exteriorMutinColorDefault: 'BLACK',
						exteriorMutinColorOptions: ['BLACK'],
					},
					{
						value: 'BRONZEH',
						exteriorMutinColorDefault: 'BRONZEH',
						exteriorMutinColorOptions: ['BRONZEH'],
					},
					{
						value: 'CLAY',
						exteriorMutinColorDefault: 'CLAY',
						exteriorMutinColorOptions: ['CLAY'],
					},
					{
						value: 'DARK_WOOD',
						exteriorMutinColorDefault: 'WHITE',
						exteriorMutinColorOptions: [
							'WHITE',
							'ALMOND',
							'BLACK',
							'CLAY',
							'TERRATONE',
						],
					},
					{
						value: 'MEDIUM_WOOD',
						exteriorMutinColorDefault: 'WHITE',
						exteriorMutinColorOptions: [
							'WHITE',
							'ALMOND',
							'BLACK',
							'CLAY',
							'TERRATONE',
						],
					},
					{
						value: 'TERRATONE',
						exteriorMutinColorDefault: 'TERRATONE',
						exteriorMutinColorOptions: ['TERRATONE'],
					},
					{
						value: 'WHITE_SLIM',
						exteriorMutinColorDefault: 'WHITE_SLIM',
						exteriorMutinColorOptions: ['WHITE_SLIM'],
					},
					{
						value: 'GOLD_SLIM',
						exteriorMutinColorDefault: 'GOLD_SLIM',
						exteriorMutinColorOptions: ['GOLD_SLIM'],
					},
					{
						value: 'BRONZE_SLIM',
						exteriorMutinColorDefault: 'BRONZE_SLIM',
						exteriorMutinColorOptions: ['BRONZE_SLIM'],
					},
				],
			},
			exteriorMuntinColor: {
				heading: 'Exterior Muntin Color',
				fieldName: 'configuration_window_exterior_muntin_color',
				values: [
					'WHITE',
					'ALMOND',
					'BLACK',
					'BRONZEH',
					'CLAY',
					'TERRATONE',
					'WHITE_SLIM',
					'GOLD_SLIM',
					'BRONZE_SLIM',
				],
			},
		},

		// window glass
		WINDOW_GLASS: {
			configurationId: 'WINDOW_GLASS',
			configurationType: 'window_glass',
			heading: 'Glass',
			type: {
				heading: 'Type',
				fieldName: 'configuration_window_glass_type',
				defaultValue: 'RLE',
				values: ['RLE', 'PLE', 'TPLE'],
			},
			temper: {
				heading: 'Temper',
				fieldName: 'configuration_window_glass_temper',
				defaultValue: 'NONE',
				values: ['NONE', 'TEMPER_SPECIALTY_ALL'],
			},
			obscure: {
				heading: 'Obscure',
				fieldName: 'configuration_window_glass_obscure',
				defaultValue: 'NONE',
				values: ['NONE', 'OBSCURE_SPECIALTY_ALL'],
			},
			tint: {
				heading: 'Tint',
				fieldName: 'configuration_window_glass_tint',
				defaultValue: 'NONE',
				values: ['NONE', 'TINT_GRAY', 'TINT_BRONZE'],
			},
		},

		// window sill
		WINDOW_SILL: {
			configurationId: 'WINDOW_SILL',
			configurationType: 'window_sill',
			heading: 'Sill',
			interiorSill: {
				heading: 'Interior Sill',
				fieldName: 'configuration_window_sill_material',
				defaultValue: 'NONE',
				values: [
					{
						value: 'NONE',
						dimensionsDefault: false,
						dimensionsOptions: false,
					},
					{
						value: 'PINE',
						dimensionsDefault: 'SILL_3_TO_4',
						dimensionsOptions: [
							'SILL_3_TO_4',
							'SILL_WIDE_6_TO_12',
							'SILL_WIDE_OVER_12',
						],
					},
					{
						value: 'MARBLE_LITE_WHITE',
						dimensionsDefault: 'SILL_3_TO_4',
						dimensionsOptions: [
							'SILL_3_TO_4',
							'SILL_WIDE_6_TO_12',
							'SILL_WIDE_OVER_12',
						],
					},
					{
						value: 'MARBLE_LITE_BUFF',
						dimensionsDefault: 'SILL_3_TO_4',
						dimensionsOptions: [
							'SILL_3_TO_4',
							'SILL_WIDE_6_TO_12',
							'SILL_WIDE_OVER_12',
						],
					},
				],
			},
			exteriorSill: {
				heading: 'Exterior Sill',
				fieldName: 'configuration_window_sill_exterior',
				defaultValue: 'NONE',
				values: [
					{
						value: 'NONE',
						dimensions: false,
					},
					{
						value: 'WOOD',
						dimensions: false,
					},
					{
						value: 'STONE',
						dimensions: true,
					},
				],
			},
			dimensions: {
				heading: 'Interior Sill Dimensions',
				fieldName: 'configuration_window_sill_dimensions',
				values: [
					'SILL_UP_TO_4',
					'SILL_3_TO_4',
					'SILL_6_TO_12',
					'SILL_OVER_12',
					'SILL_WIDE_6_TO_12',
					'SILL_WIDE_OVER_12',
				],
			},
			exteriorDimensions: {
				heading: 'Exterior Sill Dimensions',
				fieldName: 'configuration_window_sill_exterior_dimensions',
				defaultValue: 0,
				minimumValue: 0,
			},
		},

		// window instructions
		WINDOW_INSTRUCTIONS: {
			configurationId: 'WINDOW_INSTRUCTIONS',
			configurationType: 'window_instructions',
			heading: 'Instructions',
			groups: [
				{
					groupId: 'PARTS',
					heading: 'Parts',
					options: [
						{
							optionId: 'EXTENSION_JAMBS',
							heading: 'Extension Jambs',
							fieldName:
								'configuration_window_instructions_extension_jambs',
							configurationType: 'boolean',
							defaultValue: false,
							defaultBuck: 'PARTIAL_BUCK',
							defaultCasing: 'PINE',
						},
						{
							optionId: 'BUCKS',
							heading: 'Bucks',
							fieldName: 'configuration_window_instructions_bucks',
							configurationType: 'radio',
							defaultValue: 'NONE',
							values: ['NONE', 'PARTIAL_BUCK', 'FULL_BUCK'],
						},
						{
							optionId: 'CASING',
							heading: 'Casing',
							fieldName: 'configuration_window_instructions_casing',
							configurationType: 'radio',
							defaultValue: 'NONE',
							values: [
								{ value: 'NONE', defaultStop: 'NONE' },
								{ value: 'PINE', defaultStop: 'PINE' },
								{ value: 'PRIMED', defaultStop: 'PRIME_COATED' },
								{ value: 'OAK', defaultStop: 'OAK' },
								{ value: 'WHITE_VINYL', defaultStop: 'WHITE_VINYL' },
								{ value: 'FLUTED_CASING', defaultStop: 'PINE' },
								{ value: 'CUSTOMER_SUPPLIED', defaultStop: 'PINE' },
								{ value: 'CURVED_CASING', defaultStop: 'NONE' },
							],
						},
						{
							optionId: 'STOPS',
							heading: 'Stops',
							fieldName: 'configuration_window_instructions_stop',
							configurationType: 'radio',
							defaultValue: 'NONE',
							values: [
								'NONE',
								'OAK',
								'PINE',
								'WHITE_VINYL',
								'PRIME_COATED',
							],
						},
						{
							optionId: 'ROSETTES',
							heading: 'Rosettes',
							fieldName: 'configuration_window_instructions_rosettes',
							configurationType: 'number',
							defaultValue: 0,
						},
					],
				},
				{
					groupId: 'INSTALLATION',
					heading: 'Installation',
					options: [
						{
							optionId: 'REDUCE_WINDOW_OPENING',
							heading: 'Reduce Window Opening',
							fieldName:
								'configuration_window_instructions_installation_reduce_window_opening',
							configurationType: 'boolean',
							defaultValue: false,
							expand: {
								expandType: 'sqft',
								heading: 'Square Foot of Alteration',
								notes: 'Note: Please include alteration notes, and a photo of the opening',
								fieldName:
									'configuration_window_instructions_installation_reduce_window_square_foot',
								defaultValue: 0,
							},
						},
						{
							optionId: 'ENLARGE_WINDOW_OPENING',
							heading: 'Enlarge Window Opening',
							fieldName:
								'configuration_window_instructions_installation_enlarge_window_opening',
							configurationType: 'boolean',
							defaultValue: false,
							expand: {
								expandType: 'sqft',
								heading: 'Square Foot of Alteration',
								notes: 'Note: Please include alteration notes, and a photo of the opening',
								fieldName:
									'configuration_window_instructions_installation_expand_window_square_foot',
								defaultValue: 0,
							},
						},
						{
							optionId: 'NEW_HEADER',
							heading: 'New Header',
							fieldName:
								'configuration_window_instructions_installation_new_header',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							optionId: 'NEW_LINEL',
							heading: 'New Steel Lintel',
							fieldName:
								'configuration_window_instructions_installation_new_lintel',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							optionId: 'CUTBACK_SIZE',
							heading: 'Cutback to Original Size',
							fieldName:
								'configuration_window_instructions_installation_cutback_original',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							optionId: 'CUTBACK_DRYWALL',
							heading: 'Cutback Drywall',
							fieldName:
								'configuration_window_instructions_installation_cutback_drywall',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							optionId: 'CUTBACK_SIDING',
							heading: 'Cutback for Siding',
							fieldName:
								'configuration_window_instructions_installation_cutback_siding',
							configurationType: 'boolean',
							defaultValue: false,
						},
					],
				},
				{
					groupId: 'REMOVAL',
					heading: 'Removal',
					options: [
						{
							heading: 'Mullion Removal',
							fieldName:
								'configuration_window_instructions_removal_mullion',
							configurationType: 'number',
							defaultValue: 0,
						},
						{
							heading: 'Shutters',
							fieldName: 'configuration_window_instructions_shutters',
							configurationType: 'radio',
							defaultValue: 'NONE',
							values: ['NONE', 'REMOVE', 'REMOVE_AND_INSTALL'],
						},
						{
							heading: 'Blinds',
							fieldName: 'configuration_window_instructions_blinds',
							configurationType: 'radio',
							defaultValue: 'NONE',
							values: ['NONE', 'REMOVE', 'REMOVE_AND_INSTALL'],
						},
						{
							heading: 'Security Bars',
							fieldName: 'configuration_window_instructions_securitybars',
							configurationType: 'radio',
							defaultValue: 'NONE',
							values: ['NONE', 'REMOVE', 'REMOVE_AND_INSTALL'],
						},
						{
							heading: 'Window Awnings',
							fieldName: 'configuration_window_instructions_awnings',
							configurationType: 'radio',
							defaultValue: 'NONE',
							values: ['NONE', 'REMOVE', 'REMOVE_AND_INSTALL'],
						},
						{
							heading: 'Security Roll Up Shutter Removal',
							fieldName:
								'configuration_window_instructions_security_shutter_removal',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							heading: 'Bay/Bow Removal',
							fieldName:
								'configuration_window_instructions_removal_baybow_removal',
							configurationType: 'boolean',
							defaultValue: false,
						},
						{
							heading: 'Garden Window Removal',
							fieldName:
								'configuration_window_instructions_removal_garden_removal',
							configurationType: 'boolean',
							defaultValue: false,
						},
					],
				},
			],
		},
	},

	// let's group our configurations into thee order we want them to display
	configurationGroups: {
		group1: [
			'WINDOW_LOCATION',
			'WINDOW_SIZE',
			'HOUSE_TYPE',
			'REMOVAL_TYPE',
			'OUTSIDE_INSTALL',
		],
		group2: [
			'WINDOW_COLOR',
			'WINDOW_GRID',
			'WINDOW_GLASS',
			'WINDOW_SILL',
			'WINDOW_INSTRUCTIONS',
		],
	},
};
