export default {
	// we need to track the high level product information
	code: 'MISC_ITEM_PROD',
	title: 'Misc Item',
	description: 'A Misc Item',
	type: 'misc_item',
	images: ['MISC_ITEM_PROD'],

	// let's define our configurations
	configurations: {
		// misc item
		MISC_ITEM: {
			configurationId: 'MISC_ITEM',
			configurationType: 'misc_item',
			heading: 'Details',
			name: {
				heading: 'Name',
				fieldName: 'configuration_misc_item_name',
				defaultValue: '',
			},
			description: {
				heading: 'Description',
				fieldName: 'configuration_misc_item_description',
				defaultValue: '',
			},
			price: {
				heading: 'Price',
				fieldName: 'configuration_misc_item_cost',
				defaultValue: 0,
			},
		},
	},

	// let's group our configurations into thee order we want them to display
	configurationGroups: {
		group1: [],
		group2: ['MISC_ITEM'],
	},
};
