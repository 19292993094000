import PRODUCTS from './asset-data/products.js';
import GLASS from './asset-data/glass.js';
import COLORS from './asset-data/colors.js';
import OPTIONS from './asset-data/options.js';
import STYLES from './asset-data/styles.js';
import GRID_PATTERNS from './asset-data/grid-patterns.js';
import GRID_STYLES from './asset-data/grid-styles.js';
import ICONS from './asset-data/icons.js';

const assetArray = [
	// basic collection
	{
		FALLBACK_IMAGE: {
			default: {
				description: '',
				url: '/misc-images/fallback.png',
			},
		},
	},
	PRODUCTS,
	GLASS,
	COLORS,
	OPTIONS,
	STYLES,
	GRID_PATTERNS,
	GRID_STYLES,
	ICONS,
];

const assetMap = {};
assetArray.forEach(function (assetCollection) {
	Object.assign(assetMap, assetCollection);
});

export default function (c, s) {
	// set our code and size
	const code = c ? c : '_';
	const size = s ? s : 'default';

	// use the image based on code
	if (assetMap[code]) {
		if (assetMap[code][size]) {
			return {
				description: assetMap[code][size].description,
				url: process.env.PUBLIC_URL + assetMap[code][size].url,
			};
		} else if (assetMap[code]['default']) {
			return {
				description: assetMap[code]['default'].description,
				url: process.env.PUBLIC_URL + assetMap[code]['default'].url,
			};
		}
	}

	// use the fallback
	return {
		description: assetMap['FALLBACK_IMAGE']['default'].description,
		url: process.env.PUBLIC_URL + assetMap['FALLBACK_IMAGE']['default'].url,
	};
}
