import './ActionBtn.css';

const ActionBtn = (props) => (
	<button
		type="button"
		className={`actionbtn ${props.actionBtnClass ? props.actionBtnClass : ''}`}
		onClick={props.onClick}
	>
		<span
			className={`${props.actionBtnWeight ? props.actionBtnWeight : 'icon-far'} ${
				props.actionBtnType ? props.actionBtnType : ''
			}`}
		/>
	</button>
);

export default ActionBtn;
