import React, { useCallback } from 'react';
import * as DragDropAssetMapper from '../../functionality/DragDropAssetMapper';
import ProductMapper from '../../functionality/ProductMapper';
import { useCustomWindowContext } from '../../containers/CustomWindow/customWindowContext';
import { DragDropContainer } from 'react-drag-drop-container';
import './style.css';

export const DragDropItemsHandler = ({ isNotCustom, product }) => {
	const { customWindowState } = useCustomWindowContext();
	const { cells } = customWindowState;

	// If we're working with a bay/bow window, we can't list
	// the specialty windows, doorwall, and garden windows.
	const mapAssets = useCallback(() => {
		const tempArr = DragDropAssetMapper.all('png').map((window) => {
			if ((isNotCustom && !window.windowType) || !product) {
				const {
					maximumValueHeight,
					maximumValueWidth,
					minimumValueHeight,
					minimumValueWidth,
				} = ProductMapper(window.code).configurations.WINDOW_SIZE;
				return {
					...window,
					maximumValueHeight,
					maximumValueWidth,
					minimumValueHeight,
					minimumValueWidth,
				};
			}
			return null;
		});

		return tempArr.filter((el) => el);
	}, [cells, DragDropAssetMapper, ProductMapper]);

	const data = mapAssets() ?? [];

	if (data.length < 1) {
		return (
			<div className="px-4 mt-20">
				<h5>
					You cannot drop any cells into the multi-unit. Please add a row or
					column.
				</h5>
			</div>
		);
	}

	return data.map((element) => (
		<div
			className="flex flex-col w-1/2 p-5"
			key={element.description}
			style={{ maxHeight: '50%' }}
		>
			<DragDropContainer targetKey="window" dragData={element}>
				<div className="flex flex-col items-center justify-end h-full bg-light-sky">
					<img className="w-16 my-3 h-21" src={element.img} />
					<h3 className="w-full px-5 py-2 text-center text-white bg-ws-blue">
						{element.description}
					</h3>
				</div>
			</DragDropContainer>
		</div>
	));
};
