import React, { useContext, useState, useRef } from 'react';
import Parse from 'parse';
import Input from '../../Input/Input';
import FieldError from '../../FieldError/FieldError';
import UIManagementContext from '../../../contexts/UIManagementContext';
import SessionDataContext from '../../../contexts/SessionDataContext';
import WallsideError from '../../../functionality/WallsideError';
import {
	getFormData,
	validateFormDataPromise,
} from '../../../functionality/FormUtilities.js';
import Btn from '../../../components/Btn/Btn';
import html2canvas from 'html2canvas';

const OverlayEmailQuote = () => {
	// setup our contexts
	const { toggleOverlay, toggleSystemNotification } = useContext(UIManagementContext);
	const { activeQuote } = useContext(SessionDataContext);

	// add a ref for use with the input field
	const formEl = useRef(null);

	// use some state
	const [formErrors, setFormErrors] = useState({
		// no default errors, so its empty
	});

	const handleSubmit = async () => {
		try {
			// reset the form errors
			setFormErrors({});
			// we'll now try to get the data from the form
			const formData = getFormData(formEl.current);
			// now we'll validate these values
			await validateFormDataPromise(formData);
			// create our screenshot
			const canvas = await html2canvas(document.querySelector('.layout2'));
			const screenshotBase64 = await Promise.resolve(
				canvas.toDataURL().split(';base64,')[1]
			);
			// run the email endpoint
			Parse.Cloud.run('email-quote', {
				email: formData.share_email,
				id: activeQuote.id,
				screenshot: screenshotBase64,
			}).then(
				() => {
					// show the notification
					toggleSystemNotification({
						active: true,
						messageKey: 'quote_emailed',
					});
					// close this overlay
					toggleOverlay({
						active: false,
					});
				},
				(e) => {
					const error = new WallsideError(e);
					alert(error.globalErrors[0].message);
				}
			);
		} catch (errors) {
			// the form data didn't validate, so let set the form Errors
			setFormErrors(errors);
		}
	};

	const hideOverlay = () => {
		toggleOverlay({ active: false });
	};

	// return the jsx component
	return (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s1">
						<div className="overlay-s1__heading">
							<h1 className="heading heading--4">Email Quote</h1>
						</div>
						<div ref={formEl} className="overlay-s1__body">
							<label
								htmlFor="quote_name"
								className="heading heading--form-element-2"
							>
								Email Address
							</label>
							<Input
								type="text"
								id="share_email"
								name="share_email"
								className="input--1 input--white"
								inputModifier={
									formErrors.user_login ? 'input--error' : ''
								}
								placeholder="Enter Email..."
							/>
							{formErrors.share_email ? (
								<FieldError>{formErrors.share_email[0]}</FieldError>
							) : (
								''
							)}
						</div>
						<div className="overlay-s1__btns">
							<Btn btnType={'btn--secondary'} onClick={hideOverlay}>
								Cancel
							</Btn>
							<Btn btnType={'btn--primary'} onClick={handleSubmit}>
								Send
							</Btn>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayEmailQuote;
