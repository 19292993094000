import React, { useState } from 'react';
import IconBtn from '../IconBtn/IconBtn';
import './SubMenu.css';

const SubMenu = (props) => {
	// we need to track the state of it bering open / clossed
	const [open, setOpen] = useState(false);

	// handles the toggling of the sub menu
	const handleToggleSubmenu = function (e) {
		setOpen(!open);
	};

	// return the ui of the component
	return (
		<div
			className={`submenu ${props.alt ? 'submenu--alt' : ''} ${
				open ? 'submenu--open' : ''
			}`}
		>
			<IconBtn
				iconWeight="icon-far"
				btnType={'iconbtn--primary'}
				iconType={'icon--more'}
				onClick={handleToggleSubmenu}
			/>
			<div className="submenu__wrapper">
				<div className="submenu__primary-actions">
					{props.onEdit ? (
						<button
							className="submenu__item"
							onClick={() => {
								setOpen(false);
								props.onEdit();
							}}
						>
							<span className="icon-fas icon--pencil submenu__item-icon"></span>
							<span className="heading heading--5 heading--active submenu_item-text">
								Edit
							</span>
						</button>
					) : (
						''
					)}
					{props.onListview ? (
						<button
							className="submenu__item"
							onClick={() => {
								setOpen(false);
								props.onListview();
							}}
						>
							<span className="icon-fas icon--list submenu__item-icon"></span>
							<span className="heading heading--5 heading--active submenu_item-text">
								List View
							</span>
						</button>
					) : (
						''
					)}
					{props.onDuplicate ? (
						<button
							className="submenu__item"
							onClick={() => {
								setOpen(false);
								props.onDuplicate();
							}}
						>
							<span className="icon-fas icon--duplicate submenu__item-icon"></span>
							<span className="heading heading--5 heading--active submenu_item-text">
								Duplicate
							</span>
						</button>
					) : (
						''
					)}
					{props.onRename ? (
						<button
							className="submenu__item"
							onClick={() => {
								setOpen(false);
								props.onRename();
							}}
						>
							<span className="icon-fas icon--rename submenu__item-icon"></span>
							<span className="heading heading--5 heading--active submenu_item-text">
								Rename
							</span>
						</button>
					) : (
						''
					)}
				</div>
				{props.onDelete ? (
					<div className="submenu__secondary-actions">
						{props.onDelete ? (
							<button
								className="submenu__item"
								onClick={() => {
									setOpen(false);
									props.onDelete();
								}}
							>
								<span className="icon-fas icon--delete submenu__item-icon"></span>
								<span className="heading heading--5 heading--inactive submenu_item-text">
									Delete
								</span>
							</button>
						) : (
							''
						)}
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
};

export default SubMenu;
