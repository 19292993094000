import React, { useContext } from 'react';
import UIManagementContext from '../../../contexts/UIManagementContext';
import SessionDataContext from '../../../contexts/SessionDataContext';
import Btn from '../../../components/Btn/Btn';

const OverlayConfirmDeleteProduct = () => {
	// get the UIManagement context
	const { toggleOverlay, toggleSystemNotification, overlayData } =
		useContext(UIManagementContext);

	// session data context
	const { deleteQuoteProduct } = useContext(SessionDataContext);

	// handle saving
	const handleDelete = (e) => {
		deleteQuoteProduct(overlayData().data.productId).then(() => {
			// show the notification
			toggleSystemNotification({ active: true, messageKey: 'product_deleted' });
			// close this overlay
			toggleOverlay({ active: false });
		});
	};

	// return the component
	return (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s1">
						<div className="overlay-s1__heading">
							<h1 className="heading heading--4">Confirm Delete</h1>
						</div>
						<div className="overlay-s1__body overlay-s1__body--message">
							<p className="heading heading--5">
								Are you sure you want to delete this product? You will not
								be able to bring back this product.
							</p>
						</div>
						<div className="overlay-s1__btns">
							<Btn
								btnType={'btn--secondary'}
								onClick={(e) => {
									toggleOverlay({ active: false });
								}}
							>
								Cancel
							</Btn>
							<Btn btnType={'btn--danger--filled'} onClick={handleDelete}>
								Yes, Delete
							</Btn>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayConfirmDeleteProduct;
