import './NavbarLogo.css';

const NavbarLogo = () => (
	<img
		src={'https://sales.wallsidewindows.com/misc-images/wallside-logo-white.svg'}
		alt="wallside-logo"
		className="navbarlogo"
	/>
);

export default NavbarLogo;
