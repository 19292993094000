export default {
	WHITE: {
		default: {
			description: '',
			url: '/configuration-images/colors/white.png',
		},
	},
	ALMOND: {
		default: {
			description: '',
			url: '/configuration-images/colors/almond.png',
		},
	},
	DESERT_TAN: {
		default: {
			description: '',
			url: '/configuration-images/colors/desert-tan.png',
		},
	},
	MEDIUM_WOOD: {
		default: {
			description: '',
			url: '/configuration-images/colors/medium-wood.png',
		},
	},
	DARK_WOOD: {
		default: {
			description: '',
			url: '/configuration-images/colors/dark-wood.png',
		},
	},
	BLACK: {
		default: {
			description: '',
			url: '/configuration-images/colors/black.png',
		},
	},
	CLAY: {
		default: {
			description: '',
			url: '/configuration-images/colors/clay.png',
		},
	},
	TERRATONE: {
		default: {
			description: '',
			url: '/configuration-images/colors/terratone.png',
		},
	},

	// Trim Color
	PEARL_GRAY_102: {
		default: {
			description: '',
			url: '/configuration-images/colors/pearl-gray-102.png',
		},
	},
	ANTIQUE_IVORY_105: {
		default: {
			description: '',
			url: '/configuration-images/colors/antique-ivory-105.png',
		},
	},
	TUXEDO_GRAY_112: {
		default: {
			description: '',
			url: '/configuration-images/colors/tuxedo-gray-112.png',
		},
	},
	BROWN_190: {
		default: {
			description: '',
			url: '/configuration-images/colors/brown-190.png',
		},
	},
	BLACK_200: {
		default: {
			description: '',
			url: '/configuration-images/colors/black.png',
		},
	},
	ROYAL_BROWN_202: {
		default: {
			description: '',
			url: '/configuration-images/colors/royal-brown-202.png',
		},
	},
	GRECIAN_GREEN_204: {
		default: {
			description: '',
			url: '/configuration-images/colors/grecian-green-204.png',
		},
	},
	SCOTCH_RED_209: {
		default: {
			description: '',
			url: '/configuration-images/colors/scotch-red-209.png',
		},
	},
	WOODLAND_GREEN_214: {
		default: {
			description: '',
			url: '/configuration-images/colors/woodland-green-214.png',
		},
	},
	AUTUMN_RED_215: {
		default: {
			description: '',
			url: '/configuration-images/colors/autumn-red-215.png',
		},
	},
	IMPERIAL_BROWN_223: {
		default: {
			description: '',
			url: '/configuration-images/colors/imperial-brown-223.png',
		},
	},
	BUCKSKIN_224: {
		default: {
			description: '',
			url: '/configuration-images/colors/buckskin-224.png',
		},
	},
	FLAGSTONE_232: {
		default: {
			description: '',
			url: '/configuration-images/colors/flagstone-232.png',
		},
	},
	HICKORY_CLAY_238: {
		default: {
			description: '',
			url: '/configuration-images/colors/hickory-clay-238.png',
		},
	},
	MUSKET_BROWN_250: {
		default: {
			description: '',
			url: '/configuration-images/colors/musket-brown-250.png',
		},
	},
	WHITE_280: {
		default: {
			description: '',
			url: '/configuration-images/colors/white.png',
		},
	},
	SILVER_ASH_318: {
		default: {
			description: '',
			url: '/configuration-images/colors/silver-ash-318.png',
		},
	},
	SANDSTONE_BEIGE_325: {
		default: {
			description: '',
			url: '/configuration-images/colors/sandstone-beige-325.png',
		},
	},
	DESERT_TAN_327: {
		default: {
			description: '',
			url: '/configuration-images/colors/desert-tan-327.png',
		},
	},
	LIGHT_MAPLE_355: {
		default: {
			description: '',
			url: '/configuration-images/colors/light-maple-355.png',
		},
	},
	TERRATONE_360: {
		default: {
			description: '',
			url: '/configuration-images/colors/terratone.png',
		},
	},
	OXFORD_BLUE_396: {
		default: {
			description: '',
			url: '/configuration-images/colors/oxford-blue-396.png',
		},
	},
	WICKER_713: {
		default: {
			description: '',
			url: '/configuration-images/colors/wicker-713.png',
		},
	},
	NORWEGIAN_WOOD_791: {
		default: {
			description: '',
			url: '/configuration-images/colors/norwegian-wood-791.png',
		},
	},
	HEATHER_792: {
		default: {
			description: '',
			url: '/configuration-images/colors/heather-792.png',
		},
	},
	SANDCASTLE_793: {
		default: {
			description: '',
			url: '/configuration-images/colors/sandcastle-793.png',
		},
	},
	ALMOND_801: {
		default: {
			description: '',
			url: '/configuration-images/colors/almond.png',
		},
	},
	PEWTER_805: {
		default: {
			description: '',
			url: '/configuration-images/colors/pewter-805.png',
		},
	},
	CREAM_817: {
		default: {
			description: '',
			url: '/configuration-images/colors/cream-817.png',
		},
	},
	BRONZE_818: {
		default: {
			description: '',
			url: '/configuration-images/colors/bronze-818.png',
		},
	},
	HERRINGBONE_819: {
		default: {
			description: '',
			url: '/configuration-images/colors/herringbone-819.png',
		},
	},
	CRANBERRY_820: {
		default: {
			description: '',
			url: '/configuration-images/colors/cranberry-820.png',
		},
	},
	IVY_821: {
		default: {
			description: '',
			url: '/configuration-images/colors/ivy-821.png',
		},
	},
	PACIFIC_BLUE_822: {
		default: {
			description: '',
			url: '/configuration-images/colors/pacific-blue-822.png',
		},
	},
	CHAMPAGNE_827: {
		default: {
			description: '',
			url: '/configuration-images/colors/champagne-827.png',
		},
	},
	BUCKSKIN_841: {
		default: {
			description: '',
			url: '/configuration-images/colors/buckskin-224.png',
		},
	},
	IVORY_901: {
		default: {
			description: '',
			url: '/configuration-images/colors/ivory-901.png',
		},
	},
	PVC_PACIFIC_BLUE_27: {
		default: {
			description: '',
			url: '/configuration-images/colors/pacific-blue-822.png',
		},
	},
	PVC_IVY_28: {
		default: {
			description: '',
			url: '/configuration-images/colors/ivy-821.png',
		},
	},
	PVC_WHITE_800: {
		default: {
			description: '',
			url: '/configuration-images/colors/white.png',
		},
	},
	PVC_ALMOND_801: {
		default: {
			description: '',
			url: '/configuration-images/colors/almond.png',
		},
	},
	PVC_PEWTER_805: {
		default: {
			description: '',
			url: '/configuration-images/colors/pewter-805.png',
		},
	},
	PVC_CLAY_907: {
		default: {
			description: '',
			url: '/configuration-images/colors/clay.png',
		},
	},
	PVC_WICKER_PVC_SHALE_810: {
		default: {
			description: '',
			url: '/configuration-images/GrecianGreen.png',
		},
	},
	PVC_CREAM_817: {
		default: {
			description: '',
			url: '/configuration-images/colors/cream-817.png',
		},
	},
	PVC_HERRINGBONE_819: {
		default: {
			description: '',
			url: '/configuration-images/colors/herringbone-819.png',
		},
	},
	PVC_ICE_832: {
		default: {
			description: '',
			url: '/configuration-images/colors/pvc-ice-832.png',
		},
	},
	PVC_SAND_833: {
		default: {
			description: '',
			url: '/configuration-images/colors/pvc-sand-833.png',
		},
	},
	PVC_WHEAT_836: {
		default: {
			description: '',
			url: '/configuration-images/colors/pvc-wheat-836.png',
		},
	},
	PVC_IVORY_901: {
		default: {
			description: '',
			url: '/configuration-images/colors/ivory-901.png',
		},
	},
	PVC_MUSKET_BROWN_909: {
		default: {
			description: '',
			url: '/configuration-images/colors/musket-brown-250.png',
		},
	},
	PVC_DESERT_TAN: {
		default: {
			description: '',
			url: '/configuration-images/colors/desert-tan-327.png',
		},
	},
	BRONZEH: {
		default: {
			description: '',
			url: '/configuration-images/colors/bronze-818.png',
		},
	},
	DARK_BROWN: {
		default: {
			description: '',
			url: '/configuration-images/colors/dark-brown.png',
		},
	},
	WHITE_SLIM: {
		default: {
			description: '',
			url: '/configuration-images/colors/white.png',
		},
	},
	GOLD_SLIM: {
		default: {
			description: '',
			url: '/configuration-images/colors/gold.png',
		},
	},
	BRONZE_SLIM: {
		default: {
			description: '',
			url: '/configuration-images/colors/bronze-818.png',
		},
	},
	CAMEO_WHITE: {
		default: {
			description: '',
			url: '/configuration-images/colors/cameo-white.png',
		},
	},
	BEACH: {
		default: {
			description: '',
			url: '/configuration-images/colors/beach.png',
		},
	},
	SANDSTONE: {
		default: {
			description: '',
			url: '/configuration-images/colors/sandstone.png',
		},
	},
	DUSK: {
		default: {
			description: '',
			url: '/configuration-images/colors/dusk.png',
		},
	},
	ARCTIC_WHITE_S06: {
		default: {
			description: '',
			url: '/configuration-images/colors/S06-arctic-white.png',
		},
	},
	ALMOND_S02: {
		default: {
			description: '',
			url: '/configuration-images/colors/S02-almond.png',
		},
	},
	WHITE_QUARTZ_G04: {
		default: {
			description: '',
			url: '/configuration-images/colors/G04-white-quartz.png',
		},
	},
	ROSE_QUARTZ_G06: {
		default: {
			description: '',
			url: '/configuration-images/colors/G06-rose-quartz.png',
		},
	},
	PLATINUM_GRANITE_G07: {
		default: {
			description: '',
			url: '/configuration-images/colors/G07-platinum-granite.png',
		},
	},
	BLACK_PEARL_G10: {
		default: {
			description: '',
			url: '/configuration-images/colors/G10-black-pearl.png',
		},
	},
	VANILLA_SUGAR_GT913: {
		default: {
			description: '',
			url: '/configuration-images/colors/GT913-vanilla-sugar.png',
		},
	},
};
