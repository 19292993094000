import React, { useContext } from 'react';

import ConfigurationLabelMapper from '../../../../functionality/ConfigurationLabelMapper';
import CurrentQuoteProductContext from '../../../../contexts/CurrentQuoteProductContext';
import Input from '../../../Input/Input';

const ExteriorCedarTrimButton = ({
	configuration,
	selectedExteriorCedarDimensionValue,
	selectedExteriorCedarSizeValue,
	selectedExteriorCedarTrimValue,
}) => {
	const { setQuoteProductContextProductValue } = useContext(CurrentQuoteProductContext);

	if (!configuration.exteriorCedarTrim) {
		return '';
	}

	return (
		<>
			{configuration.interiorColor ||
			configuration.exteriorColor ||
			configuration.exteriorTrim ? (
				<div className="config-divider"></div>
			) : (
				''
			)}
			<div className="config-boolean-field">
				<div className="config-heading heading heading--4">
					{configuration.exteriorCedarTrim.heading}
				</div>
				<Input
					type="toggle"
					id={configuration.exteriorCedarTrim.fieldName}
					name={configuration.exteriorCedarTrim.fieldName}
					checked={selectedExteriorCedarTrimValue}
					onChange={(e) => {
						setQuoteProductContextProductValue(
							e.target.name,
							e.target.checked
						);
					}}
				/>
			</div>
			{configuration.exteriorCedarSize &&
			selectedExteriorCedarTrimValue === true ? (
				<div className="productconfiguration__color-secondary">
					<div className="heading heading--6">
						{configuration.exteriorCedarSize.heading}
					</div>
					<div className="config-option-select">
						{configuration.exteriorCedarSize.values.map((value) => {
							return (
								<React.Fragment key={value}>
									<input
										type="radio"
										name={configuration.exteriorCedarSize.fieldName}
										id={`${configuration.exteriorCedarSize.fieldName}_${value}`}
										value={value}
										checked={value === selectedExteriorCedarSizeValue}
										onChange={(e) => {
											setQuoteProductContextProductValue(
												e.target.name,
												e.target.value
											);
										}}
									/>
									<label
										htmlFor={`${configuration.exteriorCedarSize.fieldName}_${value}`}
										className="config-option-select__option"
									>
										<div className="config-option-select__check"></div>
										<div className="config-option-select__info">
											{ConfigurationLabelMapper(value)}
										</div>
									</label>
								</React.Fragment>
							);
						})}
					</div>
				</div>
			) : (
				''
			)}
			{configuration.exteriorCedarDimensions &&
			selectedExteriorCedarTrimValue === true ? (
				<>
					<div className="config-divider"></div>
					<div className="config-heading heading heading--4">
						{configuration.exteriorCedarDimensions.heading}
					</div>
					<label
						htmlFor={configuration.exteriorCedarDimensions.fieldName}
						className="config-subheading heading heading--6"
					>
						Length
					</label>
					<div className="config-number-field">
						<Input
							type="text"
							className={'input--1 input--feet'}
							id={configuration.exteriorCedarDimensions.fieldName}
							name={configuration.exteriorCedarDimensions.fieldName}
							value={selectedExteriorCedarDimensionValue}
							onBlur={(e) => {
								let val = parseFloat(e.target.value);
								setQuoteProductContextProductValue(
									e.target.name,
									isNaN(val) ||
										val <
											configuration.exteriorCedarDimensions
												.minimumValue
										? configuration.exteriorCedarDimensions
												.defaultValue
										: val
								);
							}}
							onChange={(e) => {
								setQuoteProductContextProductValue(
									e.target.name,
									e.target.value
								);
							}}
						/>
					</div>
				</>
			) : (
				''
			)}
		</>
	);
};

export default ExteriorCedarTrimButton;
