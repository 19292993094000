import React, { useEffect, useRef, useState } from 'react';

import { Transition } from '@headlessui/react';

/* A simple UI snackbar that shows a small message and disappears after some time or button click. */
const Snackbar = ({
	message = 'This is a message for the dialog.',
	open,
	title = 'This is the title',
}) => {
	const [show, setShow] = useState(open);
	const buttonRef = useRef(null);

	// Setup a timeout that will setState to false;

	useEffect(() => {
		if (open !== show) {
			setShow(open);
		}

		return () => {
			setShow(false);
		};
	}, [open]);

	// Handle the onclose event.
	const handleOnClose = () => {
		setShow(false);
	};

	return (
		<>
			<div className="absolute top-96 right-10 z-50" onClick={handleOnClose}>
				<Transition
					show={show}
					enter="transition-opacity duration-700"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-700"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="flex-col bg-blue-300 z-10 rounded-lg p-3">
						<div className="flex flex-row-reverse items-center justify-start w-full">
							<span className="cursor-pointer icon-fas icon--delete text-xs bg-white flex items-center justify-center h-6 w-6 rounded text-ws-red group-hover:bg-ws-red" />
						</div>
						{title && <h6 className="text-lg">{title}</h6>}
						{message && <p>{message}</p>}
					</div>
				</Transition>
			</div>
		</>
	);
};

export default Snackbar;
