import React, { useContext, useEffect } from 'react';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import Input from '../../Input/Input';

const ProductConfigurationWindowNote = ({ configuration }) => {
	// product context
	const {
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
		setPreviousQuoteProductContextValue,
	} = useContext(CurrentQuoteProductContext);

	// Set to use the previous value
	const setPreviousContextValue = React.useCallback(() => {
		setPreviousQuoteProductContextValue(configuration.fieldName);
	}, [configuration.fieldName, setPreviousQuoteProductContextValue]);

	useEffect(() => {
		setPreviousContextValue();
	}, [setPreviousContextValue]);

	// Values
	const noteValue = getQuoteProductContextProductValue(configuration.fieldName);

	// return the component
	return (
		<div className="w-2/3">
			<h3 className="mt-8 mb-2 font-semibold">{configuration.heading}</h3>
			<div className="input input--1">
				<Input
					type={'textarea'}
					id={configuration.fieldName}
					name={configuration.fieldName}
					value={noteValue}
					rows="7"
					placeholder={configuration.desc}
					onChange={(e) => {
						setQuoteProductContextProductValue(e.target.name, e.target.value);
					}}
				/>
			</div>
		</div>
	);
};
export default ProductConfigurationWindowNote;
