import React, { useContext, useEffect } from 'react';
import { useUIManagementContext } from '../../contexts/UIManagementContext';
import Input from '../Input/Input';
import IconBtn from '../IconBtn/IconBtn';
import ImageAsset from '../ImageAsset/ImageAsset';
import { useCustomWindowContext } from '../../containers/CustomWindow/customWindowContext';
import {
	dimensionsOfOpeningInputsAction,
	commitOpeningDimensionAction,
	customWindowResetAction,
} from '../../containers/CustomWindow/Actions';
import CurrentQuoteProductContext from '../../contexts/CurrentQuoteProductContext';
import { calcUnitedInches } from '../../functionality/calcUnitedInches';
import ProductMapper from '../../functionality/ProductMapper';

const CustomWindowDimension = ({ code, orderIndex }) => {
	const { customWindowDispatch, customWindowState } = useCustomWindowContext();
	const {
		dimensionsOfOpening,
		dimensionsOfOpeningCommitted,
		dimensionsOfOpeningInputs,
	} = customWindowState;

	const {
		openCustomWindowConfigurationData,
		toggleCustomWindowOpenConfiguration,
		toggleOverlay,
	} = useUIManagementContext();

	const { setQuoteProductContextProductValue } = useContext(CurrentQuoteProductContext);

	const product = code ? ProductMapper(code) : null;
	const openIndex = openCustomWindowConfigurationData();
	const isOpen = openIndex === orderIndex;

	const commitDimensionChange = () => {
		const { width, height } = dimensionsOfOpeningInputs;

		if (!product.code) {
			if (width > 0 && height > 0) {
				customWindowDispatch(commitOpeningDimensionAction());
				// Setting our width to the global context
				setQuoteProductContextProductValue(
					'configuration_window_size_height',
					height
				);
				setQuoteProductContextProductValue(
					'configuration_window_size_width',
					width
				);
				toggleCustomWindowOpenConfiguration(2);
			} else {
				toggleOverlay({
					active: true,
					type: 'error_messages',
					data: {
						errors: ['Width is required.', 'Height is required.'],
					},
				});
			}
		} else if (
			(product && product.code && product.code.includes('BAY_SHELL')) ||
			product.code.includes('BOW_SHELL')
		) {
			const {
				maximumValueHeight,
				maximumValueWidth,
				minimumValueHeight,
				minimumValueWidth,
			} = product.configurations.WINDOW_SIZE;
			if (width < minimumValueWidth || width > maximumValueWidth) {
				toggleOverlay({
					active: true,
					type: 'error_messages',
					data: {
						errors: [
							`Please provide a width between ${minimumValueWidth} and ${maximumValueWidth}`,
						],
					},
				});
			} else if (height < minimumValueHeight || height > maximumValueHeight) {
				toggleOverlay({
					active: true,
					type: 'error_messages',
					data: {
						errors: [
							`Please provide a height between ${minimumValueHeight} and ${maximumValueHeight}`,
						],
					},
				});
			} else {
				customWindowDispatch(commitOpeningDimensionAction());
				// Setting our width to the global context
				setQuoteProductContextProductValue(
					'configuration_window_size_height',
					height
				);
				setQuoteProductContextProductValue(
					'configuration_window_size_width',
					width
				);
				toggleCustomWindowOpenConfiguration(2);
			}
		}
	};

	const inputsChangesHandler = (e) => {
		customWindowDispatch(dimensionsOfOpeningInputsAction(e));
	};

	const toggleOpenConfigurationHandler = () => {
		if (!dimensionsOfOpeningCommitted) {
			toggleCustomWindowOpenConfiguration(orderIndex);
		}
	};

	useEffect(() => {
		if (!isOpen) {
			toggleCustomWindowOpenConfiguration(1);
		}
		customWindowDispatch(customWindowResetAction());
	}, []);

	const handleExpandPhotos = () => {
		toggleOverlay({
			active: true,
			type: 'expand_photos',
			data: {},
		});
	};

	const isOpaque = () => {
		if (
			openCustomWindowConfigurationData() !== orderIndex ||
			dimensionsOfOpeningCommitted
		) {
			return true;
		}
		return false;
	};

	return (
		<div className={`productconfiguration flex gap-2 ${isOpaque() && 'opacity-50'}`}>
			{/* Product Section Icon */}
			<div
				className={`flex items-center p-2 justify-center bg-white w-1/4 ${
					openIndex === orderIndex ? 'flex-col' : 'flex-row'
				}`}
			>
				<ImageAsset
					className={`m-1 ${isOpen ? '' : 'max-w-xs w-12'}`}
					code={code ?? 'CUSTOM_ICON_SM'}
				/>
				{isOpen ? (
					<div className="flex flex-row w-full">
						<div
							className={
								code === 'CUSTOM_ICON_SM'
									? 'flex justify-center w-full'
									: 'flex justify-end -mx-2 w-2/3'
							}
						>
							<span className="p-1 text-center">
								{product && product.title
									? product.title
									: 'Custom Multi-Unit'}
							</span>
						</div>
						{code !== 'CUSTOM_ICON_SM' && (
							<div className="flex justify-end w-1/3 ml-4">
								<IconBtn
									btnType={'iconbtn--primary'}
									iconType={'icon--expand'}
									onClick={handleExpandPhotos}
								/>
							</div>
						)}
					</div>
				) : (
					<span className="p-1 text-center">
						{product && product.title ? product.title : 'Custom'}
					</span>
				)}
			</div>

			{/* Product Section */}
			<div
				className={`productconfiguration__section w-11/12 ${
					isOpen ? 'productconfiguration__section--open' : ''
				}`}
			>
				<button
					type="button"
					className="productconfiguration__section-header"
					onClick={toggleOpenConfigurationHandler}
				>
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">Dimension of Opening</div>
						<div className="heading heading--7">
							{`Dimension of Opening: ${dimensionsOfOpening.width}” x ${dimensionsOfOpening.height}” (${dimensionsOfOpening.unitedInches} UI)`}
						</div>
					</div>
					{!dimensionsOfOpeningCommitted && (
						<div className="productconfiguration__section-toggle">
							<IconBtn
								layoutOnly
								iconWeight="icon-far"
								btnType={'iconbtn--primary'}
								iconType={'icon--plus'}
							/>
							<IconBtn
								layoutOnly
								iconWeight="icon-far"
								btnType={'iconbtn--secondary'}
								iconType={'icon--minus'}
							/>
						</div>
					)}
				</button>

				<div className="productconfiguration__section-body">
					{/* Section Header */}
					<div className="flex flex-row flex-wrap items-end gap-10">
						<div className="productconfiguration__windowsize-inputs">
							<div className="productconfiguration__windowsize-input">
								<div>
									<label
										htmlFor="width"
										className="config-subheading heading heading--6"
									>
										Width
									</label>
									<Input
										type={'number'}
										id="width"
										name="width"
										className="w-1/2 input--1 input--inches"
										value={dimensionsOfOpeningInputs.width}
										placeholder="0"
										onChange={inputsChangesHandler}
									/>
								</div>
							</div>
							<div className="productconfiguration__windowsize-input">
								<span className="icon-fal icon icon--x productconfiguration__windowsize-times"></span>
								<div>
									<label
										htmlFor="height"
										className="config-subheading heading heading--6"
									>
										Height
									</label>
									<Input
										type={'number'}
										id="height"
										name="height"
										placeholder="0"
										className="w-1/2 input--1 input--inches"
										value={dimensionsOfOpeningInputs.height}
										onChange={inputsChangesHandler}
									/>
								</div>
							</div>
						</div>
						<div className="self-end productconfiguration__windowsize-output">
							<div className="productconfiguration__windowsize-output-icon">
								<span className="icon-far icon icon--ruler" />
							</div>
							<div className="productconfiguration__windowsize-output-calculation">
								<div className="pr-2 heading heading--5">
									{`= ${calcUnitedInches(
										dimensionsOfOpeningInputs.height,
										dimensionsOfOpeningInputs.width
									)} United Inches`}
								</div>
							</div>
						</div>
						<button
							type="button"
							className="ml-auto btn btn--primary"
							onClick={commitDimensionChange}
						>
							Commit Dimensions
						</button>
					</div>
					{/* END: Section body */}
				</div>
			</div>
		</div>
	);
};

export default CustomWindowDimension;
