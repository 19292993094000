import React, { useContext } from 'react';
import OverlayExpandPhotos from './OverlayExpandPhotos/OverlayExpandPhotos';
import OverlayConfirmDeleteProduct from './OverlayConfirmDeleteProduct/OverlayConfirmDeleteProduct';
import OverlayConfirmDeleteQuote from './OverlayConfirmDeleteQuote/OverlayConfirmDeleteQuote';
import OverlayErrorMessages from './OverlayErrorMessages/OverlayErrorMessages';
import OverlayProductNotes from './OverlayProductNotes/OverlayProductNotes';
import OverlayQuoteNotes from './OverlayQuoteNotes/OverlayQuoteNotes';
import OverlayRenameQuote from './OverlayRenameQuote/OverlayRenameQuote';
import OverlayPersonalSettings from './OverlayPersonalSettings/OverlayPersonalSettings';
import OverlayGlobalChanges from './OverlayGlobalChanges/OverlayGlobalChanges';
import OverlayAddDiscount from './OverlayAddDiscount/OverlayAddDiscount';
import OverlayPayByCashDiscount from './OverlayPayByCashDiscount/OverlayPayByCashDiscount';
import OverlayEmailQuote from './OverlayEmailQuote/OverlayEmailQuote';
import OverlayConfirmCloseWithoutSaving from './OverlayConfirmCloseWithoutSaving/OverlayConfirmCloseWithoutSaving';
import UIManagementContext from '../../contexts/UIManagementContext';
import './Overlays.css';

const Overlays = (_props) => {
	// get the UIManagementContext
	const { overlayData } = useContext(UIManagementContext);

	// based on the overlayType, render the appropriate component
	let component = '';
	switch (overlayData().type) {
		case 'expand_photos':
			component = <OverlayExpandPhotos />;
			break;
		case 'confirm_delete_product':
			component = <OverlayConfirmDeleteProduct />;
			break;
		case 'confirm_delete_quote':
			component = <OverlayConfirmDeleteQuote />;
			break;
		case 'confirm_close_without_saving':
			component = <OverlayConfirmCloseWithoutSaving />;
			break;
		case 'error_messages':
			component = <OverlayErrorMessages />;
			break;
		case 'product_notes':
			component = <OverlayProductNotes />;
			break;
		case 'quote_notes':
			component = <OverlayQuoteNotes />;
			break;
		case 'rename_quote':
			component = <OverlayRenameQuote />;
			break;
		case 'personal_settings':
			component = <OverlayPersonalSettings />;
			break;
		case 'global_changes':
			component = <OverlayGlobalChanges />;
			break;
		case 'add_discount':
			component = <OverlayAddDiscount />;
			break;
		case 'pay_by_cash_discount':
			component = <OverlayPayByCashDiscount />;
			break;
		case 'email_quote':
			component = <OverlayEmailQuote />;
			break;
		default:
			break;
	}

	// return the overlay component
	return <>{component}</>;
};

export default Overlays;
