import { useCallback, useLayoutEffect, useState } from 'react';

import useEventListener from './useEventListener';

const useElementSize = () => {
	// Mutable values like 'ref.current' aren't valid dependencies
	// because mutating them doesn't re-render the component.
	// Instead, we use a state as a ref to be reactive.
	const [ref, setRef] = useState(null);
	const [size, setSize] = useState({
		width: 0,
		height: 0,
		initialHeight: 0,
	});

	// Prevent too many rendering using useCallback
	const handleSize = useCallback(() => {
		const { height, width } = size;
		if (ref?.offsetHeight !== height && ref?.offsetWidth !== width && ref) {
			setSize((s) => {
				return {
					...s,
					initialHeight:
						ref.offsetHeight > s.initialHeight
							? ref.offsetHeight
							: s.initialHeight,
					height: ref.offsetHeight || 0,
					width: ref.offsetWidth || 0,
				};
			});
		}
	}, [ref?.offsetHeight, ref?.offsetWidth, size]);

	useEventListener('resize', handleSize);

	useLayoutEffect(() => {
		handleSize();
	}, [handleSize, ref?.offsetHeight, ref?.offsetWidth]);

	const resetHeight = () => {
		setSize({ width: 0, height: 0 });
	};

	return [setRef, size, resetHeight];
};

export default useElementSize;
