import React, { useContext } from 'react';
import Input from '../../components/Input/Input';
import CurrentQuoteProductContext from '../../contexts/CurrentQuoteProductContext';

const UiExamples = () => {
	// context values
	const { setQuoteProductContextProductValue, getQuoteProductContextProductValue } =
		useContext(CurrentQuoteProductContext);

	const selectOnChange = (e) => {
		return;
	};
	return (
		<div className="container lg mx-auto p-8">
			<div className="grid grid-cols-3 gap-4">
				<div className="bg-white p-5 rounded">
					<div className="productconfiguration">
						<p className="heading heading--5 productconfiguration__heading">
							<label htmlFor="fieldName">Field Name</label>
						</p>
						<Input
							type={'select'}
							className={'input--1'}
							inputModifier={'input--select'}
							id="fieldName"
							name="fieldName"
							values={[{ label: 'Select ...', value: '' }]}
							value="Selected Value"
							onChange={selectOnChange}
						/>
					</div>
				</div>

				{/* Title/Desc */}
				<div className="bg-white p-5 rounded">
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">Layout</div>
						<div className="heading heading--7">Some details</div>
					</div>
				</div>

				{/* Title/Desc */}
				<div className="bg-white p-5 rounded">
					<div className="config-incremental-number-field">
						<div className="config-heading heading heading--4">
							Numbers Input
						</div>
						<div className="qty-input qty-input__buttons">
							<button
								type="button"
								className="qty-input__minus"
								onClick={() => {
									setQuoteProductContextProductValue(
										'fieldNameNumbersInput',
										Math.max(
											0,
											getQuoteProductContextProductValue(
												'fieldNameNumbersInput'
											) - 1
										)
									);
								}}
							>
								-
							</button>
							<input
								type="number"
								name={'fieldNameNumbersInput'}
								id={'fieldNameNumbersInput'}
								className="qty-input__input"
								step="1"
								min="0"
								max=""
								placeholder="#"
								value={getQuoteProductContextProductValue(
									'fieldNameNumbersInput'
								)}
								onChange={(e) => {
									setQuoteProductContextProductValue(
										e.target.name,
										parseFloat(e.target.value)
									);
								}}
							/>
							<button
								type="button"
								className="qty-input__plus"
								onClick={() => {
									setQuoteProductContextProductValue(
										'fieldNameNumbersInput',
										getQuoteProductContextProductValue(
											'fieldNameNumbersInput'
										) + 1
									);
								}}
							>
								+
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UiExamples;
