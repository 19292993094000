// field configs
const fieldConfigs = {
	job_address: ['notempty'],
	search: ['notempty'],
	user_login: ['notempty', 'email'],
	user_password: ['notempty'],
	user_firstname: ['notempty'],
	user_lastname: ['notempty'],
	user_email: ['notempty', 'email'],
	user_pricing: ['notempty'],
	user_existingpassword: ['notempty'],
	user_newpassword: ['notempty'],
	share_email: ['email'],
};

// validators
const errorValidators = {
	// not empty
	notempty: function (value) {
		return new Promise(function (res, rej) {
			if (typeof value === 'string' && value.trim() === '') {
				res(false);
			} else if (typeof value === 'object' && value.length === 0) {
				res(false);
			}
			res(true);
		});
	},
	// email address
	email: function (value) {
		return new Promise(function (res, rej) {
			if (typeof value !== 'string' || !/.+@.+/.test(value)) {
				res(false);
			}
			res(true);
		});
	},
	// search minimum
	searchminimum: function (value) {
		return new Promise(function (res, rej) {
			if (typeof value !== 'string' || value.length < 3) {
				res(false);
			}
			res(true);
		});
	},
};

// error messages
const errorMessages = {
	notempty: 'This field is required.',
	email: 'This field requires an email address.',
	optionalemail: 'This field requires an email address.',
};

// get the form elements
const getFormData = (formEl) => {
	// get aall the input elements
	let elements = formEl.querySelectorAll('input, select, textarea');
	// now we go through the elements and do the proper excludes, and data checks
	let values = {};
	for (let i = 0; i < elements.length; i++) {
		let thisElement = elements[i];
		if (
			thisElement.type === 'button' ||
			thisElement.type === 'reset' ||
			thisElement.type === 'submit' ||
			(thisElement.type === 'radio' && !thisElement.checked)
		) {
			// excluded from data
		} else if (thisElement.type === 'checkbox') {
			// if its a checkbox
			values[thisElement.name] = thisElement.checked
				? thisElement.value === 'on'
					? true
					: thisElement.value.trim()
				: false;
		} else {
			// handle all other types
			values[thisElement.name] = thisElement.value;
			if (thisElement.type !== 'password' && thisElement.type !== 'file') {
				// we trim results
				values[thisElement.name] = values[thisElement.name].trim();
				// we update the value
				thisElement.value = values[thisElement.name];
			}
		}
	}
	// return our collected values
	return values;
};

// run validators against the form data
const validateFormDataPromise = (formData) => {
	let errors = {};
	let promises = [];
	for (let key in formData) {
		// if this field has a config, then process it
		if (fieldConfigs[key]) {
			// get the value of this key
			let value = formData[key];
			// we'll now loop through the configurated validators
			for (let i = 0; i < fieldConfigs[key].length; i++) {
				let validator = fieldConfigs[key][i];
				// add this validation as a promise
				promises.push(
					errorValidators[validator](value).then(function (valid) {
						if (!valid) {
							if (!errors[key]) {
								errors[key] = [];
							}
							errors[key].push(errorMessages[validator]);
						}
					})
				);
			}
		}
	}

	// use promises, so we can do ajax lookups if needed
	return Promise.all(promises).then(function () {
		return Object.keys(errors).length > 0
			? Promise.reject(errors)
			: Promise.resolve();
	});
};

export { getFormData, validateFormDataPromise };
