import React from 'react';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';

export const HouseType = ({ groupName, product, quoteProduct }) => (
	<div className="quotelistviewlist__lineitem">
		<dl>
			<dt className="heading heading--12">House Type:</dt>
			<dd className="heading heading--13">
				{ConfigurationLabelMapper(
					quoteProduct.values[product.configurations[groupName].fieldName]
				)}
			</dd>
		</dl>
	</div>
);
