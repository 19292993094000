import React from 'react';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';

export const WindowHardware = ({ groupName, product, quoteProduct }) => (
	<>
		{product.configurations[groupName].interiorHandleColor && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Interior Handle Color:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].interiorHandleColor
									.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
		{product.configurations[groupName].exteriorHandleColor && (
			<div className="quotelistviewlist__lineitem">
				<dl>
					<dt className="heading heading--12">Exterior Handle Color:</dt>
					<dd className="heading heading--13">
						{ConfigurationLabelMapper(
							quoteProduct.values[
								product.configurations[groupName].exteriorHandleColor
									.fieldName
							]
						)}
					</dd>
				</dl>
			</div>
		)}
	</>
);
