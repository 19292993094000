import React, { useContext, useEffect } from 'react';
import IconBtn from '../../IconBtn/IconBtn';
import Input from '../../Input/Input';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import UIManagementContext from '../../../contexts/UIManagementContext';

const ProductConfigurationWindowRoof = (props) => {
	// the ui management context
	const { openConfigurationData, toggleOpenConfiguration } =
		useContext(UIManagementContext);

	// product context
	const { setQuoteProductContextProductValue, getQuoteProductContextProductValue } =
		useContext(CurrentQuoteProductContext);

	// the selected values
	const selectedTypeValue = props.configuration.type
		? getQuoteProductContextProductValue(props.configuration.type.fieldName)
		: false;
	const selectedTypeOption = props.configuration.type
		? props.configuration.type.values.find((option) => {
				return option.value === selectedTypeValue;
		  })
		: false;
	const selectedMaterialValue = props.configuration.material
		? getQuoteProductContextProductValue(props.configuration.material.fieldName)
		: false;
	const selectedMaterialColorValue = props.configuration.materialColor
		? getQuoteProductContextProductValue(props.configuration.materialColor.fieldName)
		: '';

	// create the section details
	const createSectionHeadingDetails = () => {
		return (
			(selectedTypeValue !== false
				? ConfigurationLabelMapper(selectedTypeValue)
				: '') +
			(selectedMaterialValue !== false
				? ' | ' + ConfigurationLabelMapper(selectedMaterialValue)
				: '')
		);
	};

	// we need to check defaults as they switch
	useEffect(() => {
		if (props.configuration.material) {
			if (
				selectedTypeOption.materialOptions === false &&
				selectedMaterialValue !== false
			) {
				setQuoteProductContextProductValue(
					props.configuration.material.fieldName,
					false
				);
			} else if (
				selectedTypeOption.materialOptions !== false &&
				selectedTypeOption.materialOptions.indexOf(selectedMaterialValue) === -1
			) {
				setQuoteProductContextProductValue(
					props.configuration.material.fieldName,
					selectedTypeOption.materialDefault
				);
			}
		}
		if (props.configuration.materialColor) {
			if (
				(selectedTypeValue === false ||
					selectedTypeValue === 'ATTACHED_TO_OVERHANG') &&
				selectedMaterialColorValue !== ''
			) {
				setQuoteProductContextProductValue(
					props.configuration.materialColor.fieldName,
					''
				);
			}
		}
	}, [
		props.configuration.material,
		props.configuration.materialColor,
		selectedTypeOption,
		selectedMaterialValue,
		selectedMaterialColorValue,
		selectedTypeValue,
		setQuoteProductContextProductValue,
	]);

	// return the component
	return (
		<div className="productconfiguration">
			<div
				className={`productconfiguration__section ${
					openConfigurationData() === props.orderIndex
						? 'productconfiguration__section--open'
						: ''
				}`}
			>
				<button
					type="button"
					className={
						props.isBayBow
							? 'productconfiguration__section-header cursor-default'
							: 'productconfiguration__section-header'
					}
					onClick={() => {
						props.isBayBow ? null : toggleOpenConfiguration(props.orderIndex);
					}}
				>
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{props.orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">
							{props.configuration.heading}
						</div>
						<div className="heading heading--7">
							{createSectionHeadingDetails()}
						</div>
					</div>
					<div className="productconfiguration__section-toggle">
						{!props.isBayBow && (
							<>
								<IconBtn
									layoutOnly
									iconWeight="icon-far"
									btnType={'iconbtn--primary'}
									iconType={'icon--plus'}
								/>
								<IconBtn
									layoutOnly
									iconWeight="icon-far"
									btnType={'iconbtn--secondary'}
									iconType={'icon--minus'}
								/>
							</>
						)}
					</div>
				</button>
				<div className="productconfiguration__section-body">
					{props.configuration.type ? (
						<>
							<div className="config-heading heading heading--4">
								{props.configuration.type.heading}
							</div>
							<div className="config-option-select">
								{props.configuration.type.values.map((option) => {
									return (
										<React.Fragment key={option.value}>
											<input
												type="radio"
												name={props.configuration.type.fieldName}
												id={`${props.configuration.type.fieldName}_${option.value}`}
												value={option.value}
												checked={
													option.value === selectedTypeValue
												}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${props.configuration.type.fieldName}_${option.value}`}
												className="config-option-select__option"
											>
												<div className="config-option-select__check"></div>
												<div className="config-option-select__info">
													{ConfigurationLabelMapper(
														option.value
													)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
						</>
					) : (
						''
					)}
					{props.configuration.material &&
					selectedTypeOption.materialOptions ? (
						<>
							{props.configuration.type ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="config-heading heading heading--4">
								{props.configuration.material.heading}
							</div>
							<div className="config-option-select">
								{props.configuration.material.values.map((value) => {
									return (
										<React.Fragment key={value}>
											<input
												type="radio"
												name={
													props.configuration.material.fieldName
												}
												id={`${props.configuration.material.fieldName}_${value}`}
												value={value}
												checked={value === selectedMaterialValue}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${props.configuration.material.fieldName}_${value}`}
												className="config-option-select__option"
											>
												<div className="config-option-select__check"></div>
												<div className="config-option-select__info">
													{ConfigurationLabelMapper(value)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
						</>
					) : (
						''
					)}
					{props.configuration.materialColor &&
					selectedTypeOption.materialOptions ? (
						<>
							{props.configuration.type ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="config-heading heading heading--4">
								{props.configuration.materialColor.heading}
							</div>
							<div className="config-text-field">
								<Input
									type="text"
									id={props.configuration.materialColor.fieldName}
									name={props.configuration.materialColor.fieldName}
									className={'input--1'}
									placeholder="Enter color details..."
									value={getQuoteProductContextProductValue(
										props.configuration.materialColor.fieldName
									)}
									onChange={(evt) => {
										setQuoteProductContextProductValue(
											evt.currentTarget.name,
											evt.currentTarget.value
										);
									}}
								/>
							</div>
						</>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
};
export default ProductConfigurationWindowRoof;
