import React, { useContext } from 'react';
import Input from '../../Input/Input';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';

const ProductConfigurationBasicBoolean = (props) => {
	// get the quote product context
	const { setQuoteProductContextProductValue, getQuoteProductContextProductValue } =
		useContext(CurrentQuoteProductContext);

	const handleOnChange = (e) => {
		setQuoteProductContextProductValue(e.target.name, e.target.checked);
	};

	// return the component
	return (
		<div className="productconfiguration">
			<p className="heading heading--5 productconfiguration__heading">
				<label htmlFor={props.configuration.fieldName}>
					{props.configuration.heading ? props.configuration.heading : ''}
				</label>
			</p>
			<Input
				type={'toggle'}
				id={props.configuration.fieldName}
				name={props.configuration.fieldName}
				checked={getQuoteProductContextProductValue(
					props.configuration.fieldName
				)}
				onChange={handleOnChange}
			/>
		</div>
	);
};
export default ProductConfigurationBasicBoolean;
