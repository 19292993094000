import React, { useContext, useEffect } from 'react';
import IconBtn from '../../IconBtn/IconBtn';
import ImageAsset from '../../ImageAsset/ImageAsset';
import ConfigurationLabelMapper from '../../../functionality/ConfigurationLabelMapper';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import UIManagementContext from '../../../contexts/UIManagementContext';

const ProductConfigurationWindowGlass = (props) => {
	// the ui management context
	const { openConfigurationData, toggleOpenConfiguration } =
		useContext(UIManagementContext);

	// product context
	const {
		product,
		setQuoteProductContextProductValue,
		getQuoteProductContextProductValue,
		setPreviousQuoteProductContextValue,
	} = useContext(CurrentQuoteProductContext);

	// the selected values
	const selectedTemper = props.configuration.temper
		? getQuoteProductContextProductValue(props.configuration.temper.fieldName)
		: false;
	const selectedObscure = props.configuration.obscure
		? getQuoteProductContextProductValue(props.configuration.obscure.fieldName)
		: false;
	const selectedTint = props.configuration.tint
		? getQuoteProductContextProductValue(props.configuration.tint.fieldName)
		: false;

	// Set to use the previous value
	const setPreviousContextValue = React.useCallback(() => {
		setPreviousQuoteProductContextValue(props.configuration.type.fieldName);
		setPreviousQuoteProductContextValue(props.configuration.tint.fieldName);
	}, [
		props.configuration.type.fieldName,
		props.configuration.tint.fieldName,
		setPreviousQuoteProductContextValue,
	]);

	useEffect(() => {
		setPreviousContextValue();
	}, [setPreviousContextValue]);

	// some overrides
	let overrideOnlyTemperAll = false;
	let overrideOnlyObscureNone = false;
	if (product.code === 'PICTURE_PROD' || product.code === 'PICTURE_CASEMENT_PROD') {
		const windowWidth = getQuoteProductContextProductValue(
			product.configurations.WINDOW_SIZE.fieldNameWidth
		);
		const windowHeight = getQuoteProductContextProductValue(
			product.configurations.WINDOW_SIZE.fieldNameHeight
		);
		const totalUnitedInches = windowWidth + windowHeight;
		const windowMinLeg = Math.min(windowWidth, windowHeight);
		const windowMaxleg = Math.max(windowWidth, windowHeight);
		overrideOnlyTemperAll = totalUnitedInches > 160;
		overrideOnlyObscureNone = !(windowMinLeg <= 70 && windowMaxleg <= 94);
	}
	if (product.code === 'SPECIALTY_PROD') {
		const windowWidth = getQuoteProductContextProductValue(
			product.configurations.WINDOW_SIZE.fieldNameWidth
		);
		const windowHeight = getQuoteProductContextProductValue(
			product.configurations.WINDOW_SIZE.fieldNameHeight
		);
		const totalUnitedInches = windowWidth + windowHeight;
		overrideOnlyTemperAll = totalUnitedInches > 120;
	}

	// create the section details
	const createSectionHeadingDetails = () => {
		return (
			ConfigurationLabelMapper(
				getQuoteProductContextProductValue(props.configuration.type.fieldName)
			) +
			(selectedTemper !== false && selectedTemper !== 'NONE'
				? ' | Temper: ' + ConfigurationLabelMapper(selectedTemper)
				: '') +
			(selectedObscure !== false && selectedObscure !== 'NONE'
				? ' | Obscure: ' + ConfigurationLabelMapper(selectedObscure)
				: '') +
			(selectedTint !== false && selectedTint !== 'NONE'
				? ' | Tint: ' + ConfigurationLabelMapper(selectedTint)
				: '')
		);
	};

	// we need to check some values when they switch
	useEffect(() => {
		// if we have the temper all override set, update the value if its not set
		if (overrideOnlyTemperAll && selectedTemper.indexOf('_ALL') === -1) {
			setQuoteProductContextProductValue(
				props.configuration.temper.fieldName,
				props.configuration.temper.values.find((value) => {
					return value.indexOf('_ALL') !== -1;
				})
			);
		}
		// if we have the obscure none oveerride set
		if (overrideOnlyObscureNone && selectedObscure !== 'NONE') {
			setQuoteProductContextProductValue(
				props.configuration.obscure.fieldName,
				'NONE'
			);
		}
	}, [
		selectedTemper,
		overrideOnlyTemperAll,
		selectedObscure,
		overrideOnlyObscureNone,
		props.configuration.obscure,
		props.configuration.temper,
		setQuoteProductContextProductValue,
	]);

	// return the component
	return (
		<div className="productconfiguration">
			<div
				className={`productconfiguration__section ${
					openConfigurationData() === props.orderIndex
						? 'productconfiguration__section--open'
						: ''
				}`}
			>
				<button
					type="button"
					className="productconfiguration__section-header"
					onClick={() => {
						toggleOpenConfiguration(props.orderIndex);
					}}
				>
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{props.orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">
							{props.configuration.heading}
						</div>
						<div className="heading heading--7">
							{createSectionHeadingDetails()}
						</div>
					</div>
					{getQuoteProductContextProductValue(
						props.configuration.tint.fieldName
					) !== 'NONE' ? (
						<div className="productconfiguration__section-thumbnail">
							<ImageAsset
								code={getQuoteProductContextProductValue(
									props.configuration.tint.fieldName
								)}
							/>
						</div>
					) : (
						''
					)}
					<div className="productconfiguration__section-toggle">
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--primary'}
							iconType={'icon--plus'}
						/>
						<IconBtn
							layoutOnly
							iconWeight="icon-far"
							btnType={'iconbtn--secondary'}
							iconType={'icon--minus'}
						/>
					</div>
				</button>
				<div className="productconfiguration__section-body">
					{props.configuration.type ? (
						<>
							<div className="config-heading heading heading--4">
								{props.configuration.type.heading}
							</div>
							<div className="config-option-select">
								{props.configuration.type.values.map((value) => {
									return (
										<React.Fragment key={value}>
											<input
												type="radio"
												name={props.configuration.type.fieldName}
												id={`${props.configuration.type.fieldName}_${value}`}
												value={value}
												checked={
													value ===
													getQuoteProductContextProductValue(
														props.configuration.type.fieldName
													)
												}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${props.configuration.type.fieldName}_${value}`}
												className="config-option-select__option"
											>
												<div className="config-option-select__check"></div>
												<div className="config-option-select__info">
													{ConfigurationLabelMapper(value)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
						</>
					) : (
						''
					)}

					{props.configuration.temper ? (
						<>
							{props.configuration.type ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="heading heading--4 config-heading">
								{props.configuration.temper.heading}
							</div>
							<div className="config-circle-select">
								{props.configuration.temper.values.map((value) => {
									const isNone = value === 'NONE';
									const isDisabled =
										overrideOnlyTemperAll &&
										value.indexOf('_ALL') === -1;
									return (
										<React.Fragment key={value}>
											<input
												type="radio"
												name={
													props.configuration.temper.fieldName
												}
												id={`${props.configuration.temper.fieldName}_${value}`}
												value={value}
												{...(isDisabled && { disabled: true })}
												checked={
													getQuoteProductContextProductValue(
														props.configuration.temper
															.fieldName
													) === value
												}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${props.configuration.temper.fieldName}_${value}`}
												className={`config-circle-select__option ${
													isDisabled
														? 'config-circle-select__option--inactive'
														: ''
												}`}
											>
												<div
													className={`config-circle-select__circle ${
														isNone
															? 'config-circle-select__circle--none'
															: ''
													}`}
												>
													{isNone ? (
														''
													) : (
														<ImageAsset code={value} />
													)}
												</div>
												<div className="config-circle-select__label heading heading--5">
													{ConfigurationLabelMapper(value)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
						</>
					) : (
						''
					)}

					{props.configuration.obscure ? (
						<>
							{props.configuration.type || props.configuration.temper ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="heading heading--4 config-heading">
								{props.configuration.obscure.heading}
							</div>
							<div className="config-circle-select">
								{props.configuration.obscure.values.map((value) => {
									const isNone = value === 'NONE';
									const isDisabled =
										overrideOnlyObscureNone && value !== 'NONE';
									return (
										<React.Fragment key={value}>
											<input
												type="radio"
												name={
													props.configuration.obscure.fieldName
												}
												id={`${props.configuration.obscure.fieldName}_${value}`}
												value={value}
												{...(isDisabled && { disabled: true })}
												checked={
													getQuoteProductContextProductValue(
														props.configuration.obscure
															.fieldName
													) === value
												}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${props.configuration.obscure.fieldName}_${value}`}
												className={`config-circle-select__option ${
													isDisabled
														? 'config-circle-select__option--inactive'
														: ''
												}`}
											>
												<div
													className={`config-circle-select__circle ${
														isNone
															? 'config-circle-select__circle--none'
															: ''
													}`}
												>
													{isNone ? (
														''
													) : (
														<ImageAsset code={value} />
													)}
												</div>
												<div className="config-circle-select__label heading heading--5">
													{ConfigurationLabelMapper(value)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
						</>
					) : (
						''
					)}

					{props.configuration.tint ? (
						<>
							{props.configuration.type ||
							props.configuration.temper ||
							props.configuration.obscure ? (
								<div className="config-divider"></div>
							) : (
								''
							)}
							<div className="heading heading--4 config-heading">
								{props.configuration.tint.heading}
							</div>
							<div className="config-circle-select">
								{props.configuration.tint.values.map((value) => {
									const isNone = value === 'NONE';
									return (
										<React.Fragment key={value}>
											<input
												type="radio"
												name={props.configuration.tint.fieldName}
												id={`${props.configuration.tint.fieldName}_${value}`}
												value={value}
												checked={
													getQuoteProductContextProductValue(
														props.configuration.tint.fieldName
													) === value
												}
												onChange={(e) => {
													setQuoteProductContextProductValue(
														e.target.name,
														e.target.value
													);
												}}
											/>
											<label
												htmlFor={`${props.configuration.tint.fieldName}_${value}`}
												className="config-circle-select__option"
											>
												<div
													className={`config-circle-select__circle ${
														isNone
															? 'config-circle-select__circle--none'
															: ''
													}`}
												>
													{isNone ? (
														''
													) : (
														<ImageAsset code={value} />
													)}
												</div>
												<div className="config-circle-select__label heading heading--5">
													{ConfigurationLabelMapper(value)}
												</div>
											</label>
										</React.Fragment>
									);
								})}
							</div>
						</>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
};
export default ProductConfigurationWindowGlass;
