import React, { useContext, useState, useEffect, useRef } from 'react';
import UIManagementContext from '../../../contexts/UIManagementContext';
import SessionDataContext from '../../../contexts/SessionDataContext';
import WallsideError from '../../../functionality/WallsideError';
import Btn from '../../Btn/Btn';
import Input from '../../Input/Input';
import { centsToMoney } from '../../../functionality/Money';
import centsToNextDollar from '../../../functionality/cents-to-next-dollar';

import './OverlayAddDiscount.css';
import { toFixed } from '../../../helpers/Calculations';

const OverlayAddDiscount = () => {
	// setup our contexts
	const { toggleOverlay, overlayData, toggleSystemNotification } =
		useContext(UIManagementContext);
	const { activeQuote, saveQuote } = useContext(SessionDataContext);

	// we'll use state for our inputs
	const [amount, setAmount] = useState(0);
	const [displayAmount, setDisplayAmount] = useState('');
	const [description, setDescription] = useState('');
	const [type, setType] = useState('cash'); // cash, percentage
	const [computedAmount, setComputedAmount] = useState('$0');

	// we'll use a ref for the form
	const formEl = useRef();

	// use an effect to run the amount blur anytime the type changes
	useEffect(() => {
		if (formEl.current) {
			const value = formEl.current.querySelector('#discount_amount').value;
			if (value !== '') {
				if (type === 'cash') {
					const valueDigitsPlusDecimal = value.replace(/[^0-9.]/g, '');
					const dollars = toFixed(valueDigitsPlusDecimal, 100);
					const cents = isNaN(dollars) ? 0 : parseInt(dollars * 100);
					setAmount(cents);
					setDisplayAmount(
						cents === 0
							? ''
							: centsToMoney({
									amount: cents,
									symbol: false,
									decimals: 2,
							  })
					);
				} else {
					const valueDigitsPlusDecimal = value.replace(/[^0-9.]/g, '');
					const floatNumber = toFixed(valueDigitsPlusDecimal, 100);
					const displayPercentage = isNaN(floatNumber) ? 0 : floatNumber;
					setAmount(displayPercentage / 100);
					setDisplayAmount(displayPercentage === 0 ? '' : displayPercentage);
				}
			}
		}
	}, [type]);

	// anytime the amount change, we'll update the computed amount
	useEffect(() => {
		if (amount === 0) {
			setComputedAmount('$0');
		} else {
			if (type === 'cash') {
				setComputedAmount(
					centsToMoney({
						amount: centsToNextDollar(amount),
					})
				);
			} else {
				setComputedAmount(
					centsToMoney({
						amount: centsToNextDollar(
							activeQuote.get('totals')[
								overlayData().data.paymentOption === 'paynow'
									? 'totalCashAfterDiscountsInCents'
									: 'totalFinancingAfterDiscountsInCents'
							] * amount
						),
					})
				);
			}
		}
	}, [amount, type, activeQuote, overlayData]);

	const handleOnBlur = (e) => {
		if (type === 'cash') {
			const valueDigitsPlusDecimal = e.target.value.replace(/[^0-9.]/g, '');
			const dollars = toFixed(valueDigitsPlusDecimal, 100);
			const cents = isNaN(dollars) ? 0 : parseInt(dollars * 100);
			setAmount(cents);
			setDisplayAmount(
				cents === 0
					? ''
					: centsToMoney({
							amount: cents,
							symbol: false,
							decimals: 2,
					  })
			);
		} else {
			const valueDigitsPlusDecimal = e.target.value.replace(/[^0-9.]/g, '');
			const floatNumber = toFixed(valueDigitsPlusDecimal, 100);
			const displayPercentage = isNaN(floatNumber) ? 0 : floatNumber;
			setAmount(displayPercentage / 100);
			setDisplayAmount(displayPercentage === 0 ? '' : displayPercentage);
		}
	};

	const handleButtonOnClick = () => {
		if (amount !== 0) {
			// add this discount to quote discounts (at the end)
			activeQuote.set(
				'discounts',
				activeQuote.get('discounts').concat([
					{
						title: 'Custom Discount',
						description: description,
						type: type,
						amountInPercent: type === 'cash' ? 0 : amount,
						amountInCents: type === 'cash' ? amount : 0,
					},
				])
			);
			// now resave the quote
			saveQuote().then(
				() => {
					// show the notification
					toggleSystemNotification({
						active: true,
						messageKey: 'save_quote',
					});
					// now we close the overlay
					toggleOverlay({ active: false });
				},
				(e) => {
					const error = new WallsideError(e);
					alert(error.globalErrors[0].message);
				}
			);
		}
	};

	const handleCancelButtonOnClick = () => {
		toggleOverlay({ active: false });
	};

	// return the jsx component
	return (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s2">
						<div className="overlay-s2__heading">
							<h1 className="heading heading--4">
								Apply Additional Discount
							</h1>
							<button
								type="button"
								className="icon-far icon icon--close"
								onClick={handleCancelButtonOnClick}
							/>
						</div>
						<div className="overlay-s2__body">
							<div ref={formEl} className="additionaldiscountoverlay">
								<div className="additionaldiscountoverlay__info">
									<Input
										type="text"
										id="discount_amount"
										name="discount_amount"
										className="input--1 input--white"
										placeholder="Enter Discount..."
										value={displayAmount}
										onBlur={handleOnBlur}
										onChange={(e) => {
											setDisplayAmount(e.target.value);
										}}
									/>
									<Input
										type="textarea"
										id="discount_description"
										name="discount_description"
										rows="5"
										className="input--1 input--white additionaldiscountoverlay__description"
										placeholder="Discount Description (Optional)"
										value={description}
										onChange={(e) => {
											setDescription(e.target.value);
										}}
									/>
								</div>
								<div className="additionaldiscountoverlay__number">
									<span className="additionaldiscountoverlay__preview">
										{computedAmount}
									</span>
									<span className="additionaldiscountoverlay__type input input--toggle">
										<input
											type="checkbox"
											id="discount_type"
											name="discount_type"
											className="input__toggle-checkbox"
											value={type === 'percentage'}
											onChange={(e) => {
												setType(
													e.target.checked
														? 'percentage'
														: 'cash'
												);
											}}
										/>
										<label
											htmlFor="discount_type"
											className="input__toggle-switch"
										>
											<span className="input__toggle-switch-label">
												Cash
											</span>
											<span className="input__toggle-switch-label">
												Percentage
											</span>
										</label>
									</span>
								</div>
							</div>
						</div>
						<div className="overlay-s2__btns">
							<Btn
								btnType={'btn--secondary'}
								onClick={handleCancelButtonOnClick}
							>
								Cancel
							</Btn>
							<Btn btnType={'btn--primary'} onClick={handleButtonOnClick}>
								Apply
							</Btn>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayAddDiscount;
