import React from 'react';
import Input from '../../Input/Input';

const BayBowSkirt = ({
	getQuoteProductContextProductValue,
	onBaySkirtChange,
	onTrimSkirtChange,
}) => (
	<>
		<strong>Installation</strong>
		<div className="flex items-center justify-between gap-5 my-5">
			<h6 className="mt-8 mb-2 text-gray-400 uppercase">Bay Skirt</h6>
			<div className="config-incremental-number-field">
				<div className="pt-4">
					<Input
						type="toggle"
						id="configuration_window_instructions_installation_bay_skirt"
						name="configuration_window_instructions_installation_bay_skirt"
						checked={getQuoteProductContextProductValue(
							'configuration_window_instructions_installation_bay_skirt'
						)}
						onChange={onBaySkirtChange}
					/>
				</div>
			</div>
		</div>
		<div className="flex items-center justify-between gap-5 my-5">
			<h6 className="mt-8 mb-2 text-gray-400 uppercase">Trim Out Skirt</h6>
			<div className="config-incremental-number-field">
				<div className="pt-4">
					<Input
						type="toggle"
						id="configuration_window_instructions_installation_trim_out_skirt"
						name="configuration_window_instructions_installation_trim_out_skirt"
						checked={getQuoteProductContextProductValue(
							'configuration_window_instructions_installation_trim_out_skirt'
						)}
						onChange={onTrimSkirtChange}
					/>
				</div>
			</div>
		</div>
	</>
);

export default BayBowSkirt;
