import React, { useContext } from 'react';
import SessionDataContext from '../../contexts/SessionDataContext';
import { useHistory } from 'react-router-dom';
import ImageAsset from '../ImageAsset/ImageAsset';
import ProductMapper from '../../functionality/ProductMapper.js';
import './ProductSelectList.css';
import URLMapper from '../../functionality/URLMapper';

const ProductSelectList = (_props) => {
	// lets setup the session data context
	const { activeQuote } = useContext(SessionDataContext);

	// set up the history object
	const history = useHistory();

	// let's add the list of products
	const availableProducts = [
		'DOUBLE_HUNG_PROD',
		'DOUBLE_SLIDER_PROD',
		'SINGLE_CASEMENT_PROD',
		'DOUBLE_CASEMENT_PROD',
		'AWNING_CASEMENT_PROD',
		'CENTER_VENT_SLIDER_PROD',
		'END_VENT_SLIDER_PROD',
		'PICTURE_PROD',
		'PICTURE_CASEMENT_PROD',
		'SPECIALTY_PROD',
		'HOPPER_PROD',
		'GARDEN_PROD',
		'DOOR_WALL_PROD',
		'MISC_ITEM_PROD',
	];

	// handle the product selection action
	const handleProductSelection = (e) => {
		// get the product selected
		const productSelected = e.currentTarget.dataset.code;
		// now we've setup the product, so redirect to the product configuration page
		history.push(`/quotes/${activeQuote.id}/products/-1?code=${productSelected}`);
	};

	// handle the multi unit selection action
	const handleMultiUnitSelection = () => {
		// Go to MultiUnitSelectionRoute
		history.push(URLMapper('addproductMultiUnit', { quoteId: activeQuote.id }));
	};

	// return the component
	return (
		<div className="productselectionlist">
			{availableProducts.map((productCode) => {
				const product = ProductMapper(productCode);
				if (product) {
					return (
						<button
							type="button"
							className="productselectionlist__item"
							key={product.code}
							data-code={product.code}
							onClick={handleProductSelection}
						>
							<div className="productselectionlist__item-image">
								<ImageAsset
									className="productselectionlist__item-image-file"
									code={product.code}
								/>
							</div>
							<p className="productselectionlist__item-name">
								{product.title}
							</p>
						</button>
					);
				}
				return '';
			})}
			<button
				type="button"
				className="productselectionlist__item"
				onClick={handleMultiUnitSelection}
			>
				<div className="productselectionlist__item-image">
					<ImageAsset
						className="productselectionlist__item-image-file"
						code="MULTI_UNIT"
					/>
				</div>
				<p className="productselectionlist__item-name">Multi-Unit</p>
			</button>
		</div>
	);
};
export default ProductSelectList;
