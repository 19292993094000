import React from 'react';

export const WindowSize = ({ groupName, product, quoteProduct }) => {
	const configGroupHeight =
		quoteProduct.values[product.configurations[groupName].fieldNameHeight];
	const configGroupWidth =
		quoteProduct.values[product.configurations[groupName].fieldNameWidth];
	return (
		<div className="quotelistviewlist__lineitem">
			<LineItem label="In" title="Width:" value={configGroupWidth} />
			<LineItem label="In" title="Height:" value={configGroupHeight} />
			<LineItem
				label="U.I."
				title="United Inches:"
				value={configGroupWidth + configGroupHeight}
			/>
		</div>
	);
};

/**
 * Helper component for rendering repeated UI.
 */
const LineItem = ({ label, title, value }) => (
	<dl>
		<dt className="heading heading--12">{title}</dt>
		<dd className="heading heading--13">
			{value} {label}
		</dd>
	</dl>
);
