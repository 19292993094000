import React, { useContext, useEffect } from 'react';
import SessionDataContext from '../../contexts/SessionDataContext';
import WallsideError from '../../functionality/WallsideError';
import QuoteNavBar from '../../components/NavBars/QuoteNavBar/QuoteNavBar';
import QuoteDetailActionBar from '../../components/ActionBars/QuoteDetailActionBar/QuoteDetailActionBar';
import PresentationSidebar from '../../components/PresentationSidebar/PresentationSidebar';
import PresentationBody from '../../components/PresentationBody/PresentationBody';
import Layout2 from '../../layouts/Layout2/Layout2';

const PresentQuoteRoute = (props) => {
	// we'll setup the session data contenxt
	const { loadQuote } = useContext(SessionDataContext);

	// we'll fetch the latest content needed
	useEffect(
		function () {
			loadQuote(props.match.params.quoteId, true).then(
				() => {
					// nothing
				},
				(e) => {
					const error = new WallsideError(e);
					alert(error.globalErrors[0].message);
				}
			);
		},
		[loadQuote, props.match.params.quoteId]
	);

	// return the component ui
	return (
		<Layout2
			contentClass="layout2__content--nopadding layout2__content--flex-column"
			navbar={<QuoteNavBar presentation={true} />}
		>
			<QuoteDetailActionBar presentation={true} />
			<section className="layout1__main">
				<div className="layout1__sidebar">
					<PresentationSidebar />
				</div>
				<div className="layout1__content">
					<PresentationBody />
				</div>
			</section>
		</Layout2>
	);
};

export default PresentQuoteRoute;
