import React, { useContext } from 'react';
import UIManagementContext from '../../../contexts/UIManagementContext';
import Btn from '../../../components/Btn/Btn';
import './OverlayErrorMessages.css';

const OverlayErrorMessages = (_props) => {
	// get the UIManagement context
	const { toggleOverlay, overlayData } = useContext(UIManagementContext);

	const hideOverlay = () => {
		toggleOverlay({ active: false });
	};

	return (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s1">
						<div className="overlay-s1__heading">
							<h1 className="heading heading--4">
								{overlayData().data.heading}
							</h1>
							<button
								type="button"
								className="icon-fal icon--close"
								onClick={hideOverlay}
							/>
						</div>
						<div className="overlay-s1__body">
							<div className="erroroverlay">
								<h2 className="heading heading--6">
									Please correct errors
								</h2>
								<ul>
									{overlayData().data.errors.map((message, i) => {
										return (
											<li
												key={i}
												className="erroroverlay__error icon-far icon--error"
											>
												<p className="heading heading--5">
													{message}
												</p>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
						<div className="overlay-s1__btns">
							<Btn btnType={'btn--primary'} onClick={hideOverlay}>
								Close
							</Btn>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayErrorMessages;
