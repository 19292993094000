import { useContext } from 'react';
import IconBtn from '../IconBtn/IconBtn';
import { useUIManagementContext } from '../../contexts/UIManagementContext';
import DragDropProducts from '../DragDropProducts';
import Grid from './Grid';
import GridStructureForm from './GridStructureForm';
import ProductMapper from '../../functionality/ProductMapper';
import { isSquare } from '../../helpers/Calculations';

import CurrentQuoteProductContext from '../../contexts/CurrentQuoteProductContext';
import { useCustomWindowContext } from '../../containers/CustomWindow/customWindowContext';
import {
	commitLayoutAction,
	mergeCellsAction,
	unmergeCellsAction,
} from '../../containers/CustomWindow/Actions.js';
import { findLowestXY, formatMergedCell } from './CustomWindowHelpers/MergeCellHelpers';
import './style.css';

const CustomWindowLayout = (props) => {
	// the ui management context
	const {
		openCustomWindowConfigurationData,
		toggleCustomWindowOpenConfiguration,
		toggleOverlay,
	} = useUIManagementContext();

	const { customWindowState, customWindowDispatch } = useCustomWindowContext();
	const {
		dimensionsOfOpeningCommitted,
		customWindowLayoutCommitted,
		dimensionsOfOpening,
		selectedCells,
	} = customWindowState;
	const { setQuoteProductAdditionalItems, setQuoteRowsBaseSize } = useContext(
		CurrentQuoteProductContext
	);

	/* 
		This function will take the selected "cells" and merge the adjacent one's together.
		If the selected cells are 0, then we'll just return the function.
	 */
	const mergeSelectedCellsHandler = () => {
		const tempArr = [...selectedCells];

		if (tempArr.length < 2) {
			return;
		}

		/* 
			To know if the cells have been selected vertically or horizontally or squared. 
			Essentially, we need to find the first selected cell's x and y, check the other cell's 
			x and y, and determine if it's a horizontal or vertical merge. After the merge, remove 
			the other cell(s) from the cells array as they have been spliced from our grid.
		*/
		let mergeSelectedCells = [];
		tempArr.map((item) => {
			mergeSelectedCells.push({
				colspan: item.colspan,
				rowspan: item.rowspan,
				x: item.x,
				y: item.y,
			});
		});

		// Check to see if we are merging two different columns.
		const isItVertical = mergeSelectedCells.every((e) => {
			const firstSelectedCell = mergeSelectedCells[0];
			return e.x === firstSelectedCell.x;
		});

		// Check to see if we are merging two different rows.
		const isItHorizontal = mergeSelectedCells.every((e) => {
			const firstSelectedCell = mergeSelectedCells[0];
			return e.y === firstSelectedCell.y;
		});

		// If a cell has an increased colspan or rowspan, then we need to make sure the other cells in the
		// array are allowed to be merged.
		const canCellsMergeArr = mergeSelectedCells.map((cell, index, elements) => {
			const nextCell = elements[index + 1];

			if (nextCell) {
				if (
					cell.colspan === nextCell.colspan &&
					cell.rowspan === nextCell.rowspan
				) {
					return true;
				} else if (cell.x === nextCell.x || cell.y === nextCell.y) {
					return true;
				}
			} else {
				return true;
			}
		});

		// If any cell is "undefined" from the previous loop, then we shouldn't perform the merge.
		const cannotMerge = canCellsMergeArr.some((el) => el === undefined);

		if (cannotMerge) {
			return;
		}

		// First we need a placeholder to tell us the height and width of our selected cells.
		// Basically, at the end of the loop, if height/width = 1 then we know that we have a square.

		const checkedX = mergeSelectedCells.filter((el) => {
			return el.x !== mergeSelectedCells[0].x;
		});

		const checkedY = mergeSelectedCells.filter((el) => {
			return el.y !== mergeSelectedCells[0].y;
		});

		const doubleCheckedX = checkedX.every((el) => {
			return el.x !== checkedX.x;
		});

		const doubleCheckedY = checkedY.every((el) => {
			return el.y !== checkedY.y;
		});

		// If half of the y values in our selected cells are the same value and the other half are the same value (not first group)
		// If half of the x values in our selected cells are the same value and the other half are the same value (not first group)
		const isItSquare =
			doubleCheckedX && doubleCheckedY && isSquare(mergeSelectedCells.length);

		if (isItVertical) {
			const newColumnArray = [...tempArr];
			const mergedCell = formatMergedCell(newColumnArray, 'height');

			mergedCell.rowspan = newColumnArray.length;

			customWindowDispatch(mergeCellsAction(mergedCell));
		} else if (isItHorizontal) {
			const newRowArray = [...tempArr];
			const mergedCell = formatMergedCell(newRowArray, 'width');

			mergedCell.colspan = newRowArray.length;

			customWindowDispatch(mergeCellsAction(mergedCell));
		} else if (isItSquare) {
			const newCellArray = [...tempArr];
			const mergedCell = { ...tempArr[0] };

			const newLen = Math.sqrt(newCellArray.length);

			mergedCell.colspan = newLen;
			mergedCell.rowspan = newLen;

			const lowestXY = findLowestXY(newCellArray);

			for (let i = 1; i < newCellArray.length; i++) {
				mergedCell.location[0].x = lowestXY.x;
				mergedCell.location[0].y = lowestXY.y;
				mergedCell.product.configuration_window_size_width +=
					newCellArray[i].product.configuration_window_size_width;
				mergedCell.product.configuration_window_size_height +=
					newCellArray[i].product.configuration_window_size_height;
			}

			// Divide the width/height by the rowpan/colspan
			mergedCell.product.configuration_window_size_height =
				mergedCell.product.configuration_window_size_height / mergedCell.rowspan;
			mergedCell.product.configuration_window_size_width =
				mergedCell.product.configuration_window_size_width / mergedCell.colspan;

			customWindowDispatch(mergeCellsAction(mergedCell));
		}

		mergeSelectedCells = [];
	};

	/* 
		I think the best course of action is to keep track of the previous merge state. 
		We can discuss keeping an array of previous layouts but let's just do this for now.
	 */
	const unmergeCellsHandler = () => {
		customWindowDispatch(unmergeCellsAction());
	};

	// Common window width list

	const saveLayout = async () => {
		try {
			const { cells, rowsBaseSize } = customWindowState;
			validateCells();
			setQuoteProductAdditionalItems(cells);
			setQuoteRowsBaseSize(rowsBaseSize);
			toggleCustomWindowOpenConfiguration(3);

			customWindowDispatch(commitLayoutAction());
		} catch (e) {
			console.error('Validation Error:', e);
			toggleOverlay({
				active: true,
				type: 'error_messages',
				data: {
					errors: ['Please add a window to each cell.'],
				},
			});
		}
	};

	// Loop through each cell and make sure that they have
	// a product attached to them.
	const validateCells = () => {
		const { cells } = customWindowState;

		cells.forEach((cell) => {
			if (!cell.product.code) {
				throw 'Not valid';
			}
		});
	};

	const toggleCustomWindowOpenConfigurationHandler = () => {
		if (dimensionsOfOpeningCommitted) {
			toggleCustomWindowOpenConfiguration(props.orderIndex);
		} else {
			toggleOverlay({
				active: true,
				type: 'error_messages',
				data: {
					errors: ['Please enter dimensions of opening first!'],
				},
			});
		}
	};

	const opacityHandler =
		!dimensionsOfOpeningCommitted || customWindowLayoutCommitted ? 'opacity-50' : '';

	// Stuff for our Bay/Bow
	const product = props.code ? ProductMapper(props.code) : null;

	return (
		<div className={`productconfiguration ${opacityHandler}`}>
			<div
				className={`productconfiguration__section ${
					openCustomWindowConfigurationData() === props.orderIndex
						? 'productconfiguration__section--open'
						: ''
				}`}
			>
				<div className="cursor-pointer productconfiguration__section-header">
					<div className="productconfiguration__header-number">
						<div className="heading heading--9">{props.orderIndex}</div>
					</div>
					<div className="productconfiguration__header-info">
						<div className="heading heading--3">Layout</div>
						<div className="heading heading--7">Some details</div>
					</div>
					{openCustomWindowConfigurationData() === props.orderIndex && (
						<div className="flex flex-row items-center gap-5">
							<p className="heading heading--7">
								{`Calculated Size: ${dimensionsOfOpening.width}” x ${dimensionsOfOpening.height}” (${dimensionsOfOpening.unitedInches} UI)`}
							</p>
							<button
								type="button"
								className="mx-2 mr-3 btn btn--primary"
								onClick={saveLayout}
							>
								Save layout
							</button>
						</div>
					)}
					<button onClick={toggleCustomWindowOpenConfigurationHandler}>
						<div className="flex flex-row items-center productconfiguration__section-toggle">
							<IconBtn
								layoutOnly
								iconWeight="icon-far"
								btnType={'iconbtn--primary'}
								iconType={'icon--plus'}
							/>
							<IconBtn
								layoutOnly
								iconWeight="icon-far"
								btnType={'iconbtn--secondary'}
								iconType={'icon--minus'}
							/>
						</div>
					</button>
				</div>

				<div className="productconfiguration__section-body">
					{/* Section Header */}
					<GridStructureForm product={product} />
					{/* START: Section body */}
					<div className="flex flex-row w-full gap-5 pt-10">
						{/* START: grid container*/}
						<Grid
							code={props.code}
							handleMerge={mergeSelectedCellsHandler}
							handleUnMerge={unmergeCellsHandler}
							openCustomWindowConfigurationData={
								openCustomWindowConfigurationData
							}
							orderIndex={props.orderIndex}
						/>
						{/* END: grid container*/}

						{/* Drag Drop Products */}
						<DragDropProducts product={product} />
					</div>
					{/* END: Section body */}
				</div>
			</div>
		</div>
	);
};

export default CustomWindowLayout;
