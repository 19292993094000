import { MultiUnitUtil } from '../../../util/MultiUnitUtil';

/**
 * Determine if a code is a multi unit.
 */
export const isMultiUnit = (code) => {
	const list = MultiUnitUtil.getMultiList();
	return list.includes(code);
};

/**
 * Determine if a code is a bay/bow shell window.
 */
export const isBayBow = (code) => {
	const list = [
		'BAY_SHELL_PROD',
		'BAY_SHELL_PROD_1',
		'BAY_SHELL_PROD_2',
		'BAY_SHELL_PROD_3',
		'BOW_SHELL_PROD',
		'BOW_SHELL_PROD_1',
		'BOW_SHELL_PROD_2',
		'BOW_SHELL_PROD_3',
	];
	return list.includes(code);
};

/**
 * Get the total amount of products in the product list.
 */
export const getTotalProducts = (products) => {
	try {
		if (!products || products.length === 0) {
			return 0;
		}

		let len = 0;
		for (const product of products) {
			// If there's a multi unit, we need to iterate inside of that nested list.
			if (isMultiUnit(product.code)) {
				if (product.values.additional_items) {
					len += product.values.additional_items.length;
				}
				if (isBayBow(product.code)) {
					len++;
				}
			} else {
				len++;
			}
		}

		return len;
	} catch (error) {
		console.error('GET PRODUCTS ERROR:', error);
		return 0;
	}
};

/**
 * Get the total amount of windows in the product list.
 */
export const getTotalWindows = (products) => {
	try {
		if (!products || products.length === 0) {
			return 0;
		}

		let len = 0;
		for (const product of products) {
			// If there's a multi unit, we need to iterate inside of that nested list.
			if (isMultiUnit(product.code)) {
				if (product.values.additional_items) {
					len += product.values.additional_items.length;
				}
			} else {
				len++;
			}
		}

		return len;
	} catch (error) {
		console.error('GET PRODUCTS ERROR:', error);
		return 0;
	}
};

/**
 * Get the total amount of a given item.
 */
export const getTotalOfItem = (products, itemtype, code) => {
	try {
		if (!products || products.length === 0) {
			return 0;
		}

		let len = 0;
		for (const product of products) {
			// If there's a multi unit, we need to iterate inside of that nested list.
			if (isMultiUnit(product.code)) {
				if (product.values.additional_items) {
					for (const window of product.values.additional_items) {
						if (window.type === itemtype || window.code === code) {
							len++;
						}
					}
				}
			} else {
				if (product.type === itemtype) {
					len++;
				}
			}
		}

		return len;
	} catch (error) {
		console.error('GET PRODUCTS ERROR:', error);
		return 0;
	}
};

/**
 * Get the total amount of bay/bow shells.
 */
export const getTotalOfShell = (products) => {
	try {
		if (!products || products.length === 0) {
			return 0;
		}

		let len = 0;
		for (const product of products) {
			// If there's a multi unit, we need to iterate inside of that nested list.
			if (isBayBow(product.code)) {
				len++;
			}
		}

		return len;
	} catch (error) {
		console.error('GET PRODUCTS ERROR:', error);
		return 0;
	}
};
