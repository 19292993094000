import React, { useContext, useState } from 'react';
import CurrentQuoteProductContext from '../../../contexts/CurrentQuoteProductContext';
import UIManagementContext from '../../../contexts/UIManagementContext';
import ProductMapper from '../../../functionality/ProductMapper';
import Context from '../../../contexts/store/context';
import ImageAsset from '../../ImageAsset/ImageAsset';
import './OverlayExpandPhotos.css';

const OverlayExpandPhotos = (props) => {
	// get the UIManagement context
	const { toggleOverlay } = useContext(UIManagementContext);

	// get the current product context
	const { product } = useContext(CurrentQuoteProductContext);

	let data = product;

	const { globalState } = useContext(Context);
	const { selectedCustomProduct } = globalState;

	if (selectedCustomProduct.includes('BAY_SHELL')) {
		data = ProductMapper(selectedCustomProduct);
	}

	if (selectedCustomProduct.includes('BOW_SHELL')) {
		data = ProductMapper(selectedCustomProduct);
	}

	// we need to track the seleected image, so use state for this
	const [selectedImageCode, setSelectedImageCode] = useState(data.images[0]);

	// handle forward and backward
	const handleClickBack = () => {
		let index = data.images.indexOf(selectedImageCode);
		index = index === 0 ? data.images.length - 1 : index - 1;
		setSelectedImageCode(data.images[index]);
	};
	const handleClickForward = () => {
		let index = data.images.indexOf(selectedImageCode);
		index = index === data.images.length - 1 ? 0 : index + 1;
		setSelectedImageCode(data.images[index]);
	};

	// return the component
	return (
		<div className="overlay">
			<div className="overlay__content">
				<button
					type="button"
					className="icon-fal icon--close"
					onClick={() => {
						toggleOverlay({ active: false });
					}}
				></button>
				<div className="overlay__content-inner">
					<div className="productphotooverlay">
						<div className="productphotooverlay__content">
							<div className="productphotooverlay__gallery">
								<div className="productphotooverlay__gallery-viewer">
									<span className="productphotooverlay__gallery-btn-container">
										{product.images.length > 1 ? (
											<button
												className="productphotooverlay__gallery-btn icon-fal icon--back"
												onClick={handleClickBack}
											/>
										) : (
											''
										)}
									</span>
									<div className="productphotooverlay__gallery-image">
										<div className="productphotooverlay__gallery-image-file">
											<ImageAsset code={selectedImageCode} />
										</div>
									</div>
									<span className="productphotooverlay__gallery-btn-container">
										{product.images.length > 1 ? (
											<button
												className="productphotooverlay__gallery-btn icon-fal icon--forward"
												onClick={handleClickForward}
											/>
										) : (
											''
										)}
									</span>
								</div>
								<div className="productphotooverlay__gallery-thumbnails">
									{data.images.map((code) => {
										return (
											<button
												key={code}
												type="button"
												className={`productphotooverlay__gallery-thumbnail ${
													code === selectedImageCode
														? 'productphotooverlay__gallery-thumbnail--active'
														: ''
												}`}
												onClick={() => {
													setSelectedImageCode(code);
												}}
											>
												<ImageAsset code={code} />
											</button>
										);
									})}
								</div>
							</div>
							<div className="productphotooverlay__info">
								<div className="productphotooverlay__info-title heading heading--2">
									{data.title}
								</div>
								<div className="divider-line"></div>
								<div
									className="generic-content heading heading--5-paragraph"
									dangerouslySetInnerHTML={{
										__html: data.description,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayExpandPhotos;
