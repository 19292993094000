import React, { Fragment } from 'react';

import ConfigurationLabelMapper from '../../../../../functionality/ConfigurationLabelMapper';

const ExteriorTrimInput = ({
	configuration,
	option,
	selectedExteriorTrimValue,
	setQuoteProductContextProductValue,
}) => {
	return (
		<Fragment>
			<input
				type="radio"
				name={configuration.exteriorTrim.fieldName}
				id={`${configuration.exteriorTrim.fieldName}_${option.value}`}
				value={option.value}
				checked={option.value === selectedExteriorTrimValue}
				onChange={(e) => {
					setQuoteProductContextProductValue(e.target.name, e.target.value);
				}}
			/>
			<label
				htmlFor={`${configuration.exteriorTrim.fieldName}_${option.value}`}
				className="config-option-select__option"
			>
				<div className="config-option-select__check"></div>
				<div className="config-option-select__info">
					{ConfigurationLabelMapper(option.value)}
				</div>
			</label>
		</Fragment>
	);
};

export default ExteriorTrimInput;
