import React, { useContext, useState } from 'react';
import UIManagementContext from '../../../contexts/UIManagementContext';
import SessionDataContext from '../../../contexts/SessionDataContext';
import WallsideError from '../../../functionality/WallsideError';
import Btn from '../../Btn/Btn';
import { SidebarButtonGroup } from './Components';
import { ColorTab, GlassTab, GridTab, ScreenTab, SillTab } from './Components/Tabs';
import {
	color_config,
	glass_config,
	grid_config,
	screen_config,
	sill_config,
} from './Helpers/constants';
import { mapGlobalSettings } from './Helpers/mapGlobalSettings';

const OverlayGlobalChanges = (_props) => {
	// setup our contexts
	const { toggleOverlay, toggleSystemNotification } = useContext(UIManagementContext);
	const { activeQuote, saveQuote } = useContext(SessionDataContext);
	const products = activeQuote?.get('products');

	// set some state
	const [activeTab, setActiveTab] = useState('color'); // color, grids, glass
	const [colorData, setColorData] = useState({
		selectedInteriorColorValue: false,
		selectedInteriorColorOption: false,
		selectedExteriorColorValue: false,
	});
	const [glassData, setGlassData] = useState({
		selectedGlassTypeValue: false,
	});
	const [gridData, setGridData] = useState({
		selectedInteriorMuntinColorValue: false,
		selectedInteriorMuntinColorOption: false,
		selectedExteriorMuntinColorValue: false,
		removeMuntin: false,
	});

	// Window screen state
	const [screenData, setScreenData] = useState({
		selectedScreenSizeValue: false,
		selectedScreenMeshValue: false,
	});

	// Window sill state
	const [sillData, setSillData] = useState({
		selectedSillInteriorValue: false,
		selectedSillDepthValue: false,
		selectedSillExteriorValue: false,
	});

	const screenSizeChangeHandler = (e) => {
		const value = e.currentTarget.value;
		setScreenData((prev) => {
			return {
				...prev,
				...{
					selectedScreenMeshValue:
						screenData.selectedScreenMeshValue || 'BLACK_MESH',
					selectedScreenSizeValue: value,
				},
			};
		});
	};
	const screenMeshChangeHandler = (e) => {
		const value = e.currentTarget.value;
		setScreenData((prev) => {
			return {
				...prev,
				...{
					selectedScreenSizeValue:
						screenData.selectedScreenSizeValue || 'FULL_SCREEN',
					selectedScreenMeshValue: value,
				},
			};
		});
	};

	const sillInteriorChangeHandler = (e) => {
		const value = e.currentTarget.value;
		setSillData((prev) => {
			return {
				...prev,
				...{
					selectedSillExteriorValue:
						sillData.selectedSillExteriorValue || 'NONE',
					selectedSillInteriorValue: value,
				},
			};
		});
	};

	const sillDepthChangeHandler = (e) => {
		const value = e.currentTarget.value;
		setSillData((prev) => {
			return {
				...prev,
				...{
					selectedSillDepthValue: value,
				},
			};
		});
	};

	// our submission function
	const submitForm = async () => {
		const quoteProducts = mapGlobalSettings(
			{ colorData, glassData, gridData, screenData, sillData },
			products
		);
		// Save the new quote
		await activeQuote.set('products', quoteProducts);
		saveQuote().then(
			() => {
				// show the notification
				toggleSystemNotification({
					active: true,
					messageKey: 'save_global_changes',
				});
				// close the overlay
				hideOverlay();
			},
			(e) => {
				const error = new WallsideError(e);
				alert(error.globalErrors[0].message);
			}
		);
	};

	const hideOverlay = () => {
		toggleOverlay({ active: false });
	};

	return (
		<div className="overlay">
			<div className="overlay__content">
				<div className="overlay__content-inner">
					<div className="overlay-s2">
						<div className="overlay-s2__heading">
							<h1 className="heading heading--4">Global Settings</h1>
							<button
								type="button"
								className="icon-far icon icon--close"
								onClick={hideOverlay}
							/>
						</div>
						<div className="overlay-s2__body">
							<SidebarButtonGroup
								activeTab={activeTab}
								setActiveTab={setActiveTab}
							/>
							<div className="overlay-s2__body-col overlay-s2__body-col--content">
								{activeTab === 'color' ? (
									<ColorTab
										color_config={color_config}
										colorData={colorData}
										setColorData={setColorData}
									/>
								) : activeTab === 'grid' ? (
									<GridTab
										grid_config={grid_config}
										gridData={gridData}
										setGridData={setGridData}
									/>
								) : activeTab === 'glass' ? (
									<GlassTab
										glass_config={glass_config}
										glassData={glassData}
										setGlassData={setGlassData}
									/>
								) : // Screen
								activeTab === 'screen' ? (
									<ScreenTab
										screen_config={screen_config}
										screenData={screenData}
										screenMeshChangeHandler={screenMeshChangeHandler}
										screenSizeChangeHandler={screenSizeChangeHandler}
									/>
								) : (
									<SillTab
										sill_config={sill_config}
										sillData={sillData}
										sillInteriorChangeHandler={
											sillInteriorChangeHandler
										}
										sillDepthChangeHandler={sillDepthChangeHandler}
										products={products}
									/>
								)}
							</div>
						</div>
						<div className="overlay-s2__btns">
							<Btn btnType={'btn--secondary'} onClick={hideOverlay}>
								Cancel
							</Btn>
							<Btn btnType={'btn--primary'} onClick={submitForm}>
								Apply
							</Btn>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayGlobalChanges;
