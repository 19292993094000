import React from 'react';
import ProductConfigurationBasicSelect from './ConfigurationComponents/ProductConfigurationBasicSelect';
import ProductConfigurationBasicBoolean from './ConfigurationComponents/ProductConfigurationBasicBoolean';
import ProductConfigurationWindowLocation from './ConfigurationComponents/ProductConfigurationWindowLocation';
import ProductConfigurationWindowSize from './ConfigurationComponents/ProductConfigurationWindowSize';
import ProductConfigurationDoorwallSize from './ConfigurationComponents/ProductConfigurationDoorwallSize';
import ProductConfigurationWindowOutsideInstall from './ConfigurationComponents/ProductConfigurationWindowOutsideInstall';
import ProductConfigurationWindowRemovalType from './ConfigurationComponents/ProductConfigurationWindowRemovalType';
import ProductConfigurationWindowGlass from './ConfigurationComponents/ProductConfigurationWindowGlass';
import ProductConfigurationWindowSill from './ConfigurationComponents/ProductConfigurationWindowSill';
import ProductConfigurationWindowOptionsHardware from './ConfigurationComponents/ProductConfigurationWindowOptionsHardware';
import ProductConfigurationWindowColor from './ConfigurationComponents/ProductConfigurationWindowColor/ProductConfigurationWindowColor';
import ProductConfigurationWindowScreen from './ConfigurationComponents/ProductConfigurationWindowScreen';
import ProductConfigurationWindowStyle from './ConfigurationComponents/ProductConfigurationWindowStyle';
import ProductConfigurationWindowInstructions from './ConfigurationComponents/ProductConfigurationWindowInstructions';
import ProductConfigurationWindowGrid from './ConfigurationComponents/ProductConfigurationWindowGrid';
import ProductConfigurationWindowHardware from './ConfigurationComponents/ProductConfigurationWindowHardware';
import ProductConfigurationWindowSeat from './ConfigurationComponents/ProductConfigurationWindowSeat';
import ProductConfigurationWindowRoof from './ConfigurationComponents/ProductConfigurationWindowRoof';
import ProductConfigurationMiscItem from './ConfigurationComponents/ProductConfigurationMiscItem';

import './ProductConfigurations.css';

// NOTE: All products renders
const ProductConfigurations = (props) => {
	let component = '';
	switch (props.configuration.configurationType) {
		// House type
		case 'basic_select':
			component = (
				<ProductConfigurationBasicSelect configuration={props.configuration} />
			);
			break;
		case 'basic_boolean':
			component = (
				<ProductConfigurationBasicBoolean configuration={props.configuration} />
			);
			break;
		case 'window_location':
			component = (
				<ProductConfigurationWindowLocation configuration={props.configuration} />
			);
			break;
		case 'window_removal_type':
			component = (
				<ProductConfigurationWindowRemovalType
					configuration={props.configuration}
				/>
			);
			break;
		case 'window_outside_install':
			component = (
				<ProductConfigurationWindowOutsideInstall
					configuration={props.configuration}
				/>
			);
			break;
		case 'window_size':
			component = (
				<ProductConfigurationWindowSize
					configuration={props.configuration}
					disabled={props?.disabledWindowSize}
				/>
			);
			break;
		case 'doorwall_size':
			component = (
				<ProductConfigurationDoorwallSize configuration={props.configuration} />
			);
			break;
		case 'window_color':
			component = (
				<ProductConfigurationWindowColor
					orderIndex={props.orderIndex}
					configuration={props.configuration}
				/>
			);
			break;
		case 'window_glass':
			component = (
				<ProductConfigurationWindowGlass
					orderIndex={props.orderIndex}
					configuration={props.configuration}
				/>
			);
			break;
		case 'window_sill':
			component = (
				<ProductConfigurationWindowSill
					orderIndex={props.orderIndex}
					configuration={props.configuration}
				/>
			);
			break;
		case 'window_style':
			component = (
				<ProductConfigurationWindowStyle
					orderIndex={props.orderIndex}
					configuration={props.configuration}
				/>
			);
			break;
		case 'window_options_hardware':
			component = (
				<ProductConfigurationWindowOptionsHardware
					orderIndex={props.orderIndex}
					configuration={props.configuration}
				/>
			);
			break;
		case 'window_screen':
			component = (
				<ProductConfigurationWindowScreen
					orderIndex={props.orderIndex}
					configuration={props.configuration}
				/>
			);
			break;
		case 'window_instructions':
			component = (
				<ProductConfigurationWindowInstructions
					orderIndex={props.orderIndex}
					configuration={props.configuration}
				/>
			);
			break;
		case 'window_grid':
			component = (
				<ProductConfigurationWindowGrid
					orderIndex={props.orderIndex}
					configuration={props.configuration}
				/>
			);
			break;
		case 'window_hardware':
			component = (
				<ProductConfigurationWindowHardware
					orderIndex={props.orderIndex}
					configuration={props.configuration}
				/>
			);
			break;
		case 'window_seat':
			component = (
				<ProductConfigurationWindowSeat
					orderIndex={props.orderIndex}
					configuration={props.configuration}
				/>
			);
			break;
		case 'window_roof':
			component = (
				<ProductConfigurationWindowRoof
					orderIndex={props.orderIndex}
					configuration={props.configuration}
				/>
			);
			break;
		case 'misc_item':
			component = (
				<ProductConfigurationMiscItem
					orderIndex={props.orderIndex}
					configuration={props.configuration}
				/>
			);
			break;
		default:
			break;
	}

	return <>{component}</>;
};

export default ProductConfigurations;
