import React, { useContext, useEffect } from 'react';
import CustomWindowLayout from '../../components/CustomWindowLayout';
import CustomWindowDimension from '../../components/ProductConfigurations/CustomWindowDimension';
import CustomWindowGeneralSpecifications from '../../components/CustomWindowGeneralSpecifications';
import { useUIManagementContext } from '../../contexts/UIManagementContext';
import Context from '../../contexts/store/context';
import { CustomWindowContextProvider } from './customWindowContext';
import { ConfirmMessageContextProvider } from '../../components/Overlays/ConfirmMessage/confirmMessageContext';
import OverlayConfirmResetting from '../../components/Overlays/OverlayConfirmResetting/OverlayConfirmResetting';

const CustomWindow = ({ location, product, product_values, match }) => {
	const { globalDispatch, globalState } = useContext(Context);
	const { selectedCustomProduct } = globalState;

	const { overlayData, toggleCustomWindowOpenConfiguration } = useUIManagementContext();

	// If our location.search has a validish id, we need to route
	// the user to step 3 of the configuration. We can just reset
	// openCustomWindowConfigurationData to be 3. We probably also
	// need a check to make sure we're not looping a route.
	const navigateToEdit = () => {
		if ((location.pathname && location.pathname.includes('productid')) || product) {
			toggleCustomWindowOpenConfiguration(3);
		}
	};

	return (
		<CustomWindowContextProvider>
			<ConfirmMessageContextProvider>
				<div className="layout1__content">
					{/* Multi-unit step 1 */}
					<CustomWindowDimension
						code={selectedCustomProduct ?? 'CUSTOM_ICON_SM'}
						orderIndex={1}
					/>
					{/* Multi-unit step 2 */}
					<CustomWindowLayout
						code={selectedCustomProduct ?? 'CUSTOM_ICON_SM'}
						orderIndex={2}
					/>
					{/* Multi-unit step 3 */}
					<CustomWindowGeneralSpecifications
						code={selectedCustomProduct ?? 'CUSTOM_ICON_SM'}
						orderIndex={3}
						configurations={product.configurations}
						location={location}
						match={match}
						productValues={product_values}
						triggerEdit={navigateToEdit}
						globalState={globalState}
						globalDispatch={globalDispatch}
					/>
					{overlayData().type === 'confirm_resetting' && (
						<OverlayConfirmResetting />
					)}
				</div>
			</ConfirmMessageContextProvider>
		</CustomWindowContextProvider>
	);
};

export default CustomWindow;
