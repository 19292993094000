const dollarsToCents = (dollars) => {
	if (typeof dollars !== 'number') {
		return false;
	}

	return Math.round(parseFloat(dollars) * 100);
};

const centsToDollars = (cents) => {
	if (typeof cents !== 'number') {
		return false;
	}

	let dollars = parseInt(cents) / 100;
	return new Intl.NumberFormat('en-US', {
		style: 'decimal',
		currency: 'USD',
		useGrouping: false,
	}).format(dollars);
};

const centsToMoney = (opts) => {
	const options = {
		...{
			amount: 0,
			symbol: true,
			decimals: 0,
		},
		...opts,
	};

	if (typeof options.amount !== 'number') {
		return false;
	}

	let dollars = parseInt(options.amount) / 100;
	let formatted = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: options.decimals,
		maximumFractionDigits: options.decimals,
		useGrouping: true,
	}).format(dollars);

	return options.symbol ? formatted : formatted.replace('$', '');
};

module.exports = { dollarsToCents, centsToDollars, centsToMoney };
