/**
 * Our general util class for multi-unit like things
 */
export const MultiUnitUtil = {
	getMultiList: () => {
		return [
			'BAY_SHELL_PROD',
			'BAY_SHELL_PROD_1',
			'BAY_SHELL_PROD_2',
			'BAY_SHELL_PROD_3',
			'BOW_SHELL_PROD',
			'BOW_SHELL_PROD_1',
			'BOW_SHELL_PROD_2',
			'BOW_SHELL_PROD_3',
			'CUSTOM_WINDOW',
		];
	},
};
